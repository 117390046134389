export const setRequestLinkState = (data) => ({
  type: 'SET_REQUEST_LINK_STATE',
  formInJSON: data.formInJSON,
  user: data.user,
});

export const setRequestLinkForm = (payload) => ({
  type: 'SET_REQUEST_LINK_FORM',
  payload,
});

export const resetRequestLinkValues = () => ({
  type: 'RESET_REQUEST_LINK_VALUES',
});

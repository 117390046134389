import React, { useState } from "react";
import styled from "styled-components";

import TemplatesDropboxItem from "./TemplatesDropboxItem";

const StyledTemplatesCategory = styled.div`
  width: 90%;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #505356;
  margin: ${({ expanded }) => (expanded ? "10px 0 0 0" : "10px 0")};
  .category__header {
    display: flex;
    align-items: center;
  }
  .category__body {
    display: ${({ expanded }) => (expanded ? "auto" : "none")};
    visibility: ${({ expanded }) => (expanded ? "visbile" : "hidden")};
    opacity: ${({ expanded }) => (expanded ? "1" : "0")};
    padding-left: 20px;
    text-align: left;
  }
  .category__arrow-icon {
    transform: ${({ expanded }) => (expanded ? "rotate(180deg)" : "rotate(0)")};
    transition: 0.4s;
  }
`;

const TemplatesCategory = ({
  categoryName,
  templates,
  setShowPresets,
  setTemplatesListReloadTrigger,
}) => {
  const [expanded, setExpanded] = useState(false);

  React.useEffect(() => {
    setExpanded(false);
  }, []);

  return (
    <StyledTemplatesCategory expanded={expanded}>
      <div
        onClick={() => setExpanded((prev) => !prev)}
        className="category__header"
      >
        <div className="category__name">{categoryName}</div>
        <svg
          className="category__arrow-icon"
          width="15"
          height="8"
          viewBox="0 0 15 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.918463 0.433875C0.594513 0.760751 0.594606 1.28765 0.918672 1.61442L6.70668 7.45057C7.09786 7.845 7.73555 7.84501 8.12674 7.45057L13.9147 1.61442C14.2388 1.28765 14.2389 0.76075 13.915 0.433875C13.587 0.102964 13.0522 0.102889 12.7241 0.433706L7.41671 5.78603L2.10929 0.433707C1.78125 0.102889 1.24641 0.102964 0.918463 0.433875Z"
            fill="#8B8F9A"
          />
        </svg>
      </div>
      <div className="category__body">
        {templates.map((template) => (
          <TemplatesDropboxItem
            templateName={template.name}
            form={template.fields}
            setShowPresets={setShowPresets}
            setTemplatesListReloadTrigger={setTemplatesListReloadTrigger}
          />
        ))}
      </div>
    </StyledTemplatesCategory>
  );
};

export default TemplatesCategory;

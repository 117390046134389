import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Button from "../Button";

const StyledKeysListPlaceholder = styled.div`
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    margin-bottom: 30px;
  }
  h2 {
    font-weight: 500;
    font-size: 25px;
    line-height: 31px;
    color: #232323;
    margin-bottom: 7px;
  }
  h3 {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #505356;
    text-align: center;
    margin-bottom: 30px;
  }
  a {
    text-decoration: none;
  }
  .button.purple {
    min-width: 212px;
  }
`;

const KeysListPlaceholder = () => {
  return (
    <StyledKeysListPlaceholder>
      <svg
        width="72"
        height="72"
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="72" height="72" rx="8" fill="#F6F6F6" />
        <path
          d="M44.3327 56.8333H19.3327C17.0315 56.8333 15.166 54.9679 15.166 52.6667V27.6667H19.3327V52.6667H44.3327V56.8333ZM52.666 48.5H27.666C25.3648 48.5 23.4993 46.6345 23.4993 44.3333V19.3333C23.4993 17.0322 25.3648 15.1667 27.666 15.1667H52.666C54.9672 15.1667 56.8327 17.0322 56.8327 19.3333V44.3333C56.8327 46.6345 54.9672 48.5 52.666 48.5ZM27.666 19.3333V44.3333H52.666V19.3333H27.666ZM42.2494 39.1667C42.2494 39.719 41.8016 40.1667 41.2494 40.1667H39.0827C38.5304 40.1667 38.0827 39.719 38.0827 39.1667V33.9167H32.8327C32.2804 33.9167 31.8327 33.469 31.8327 32.9167V30.75C31.8327 30.1977 32.2804 29.75 32.8327 29.75H38.0827V24.5C38.0827 23.9477 38.5304 23.5 39.0827 23.5H41.2494C41.8016 23.5 42.2494 23.9477 42.2494 24.5V29.75H47.4994C48.0516 29.75 48.4994 30.1977 48.4994 30.75V32.9167C48.4994 33.469 48.0516 33.9167 47.4994 33.9167H42.2494V39.1667Z"
          fill="#505356"
        />
      </svg>
      <h2>Send your first request</h2>
      <h3>
        Generate a key request and it will be added <br /> to the list
        automatically.
      </h3>
      <Link to={"/request"}>
        {" "}
        <Button
          className={"plusKeysRequest"}
          color={"purple"}
          text={"Keys request"}
        />
      </Link>
    </StyledKeysListPlaceholder>
  );
};

export default KeysListPlaceholder;

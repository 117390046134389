import React from "react";
import { useDispatch } from "react-redux";

import { setDeleteModalOn } from "../../../redux/actions/setDeleteKeysListItemModal";

const KeysListSettingsModal = React.forwardRef(
  ({ localState, setShowKeysListItemSettings }, ref) => {
    const dispatch = useDispatch();

    const handleClickOutsideItemSettings = (e) => {
      const path = e.path || (e.composedPath && e.composedPath());
      if (!path.includes(ref.current)) {
        setShowKeysListItemSettings(false);
      }
    };

    React.useEffect(() => {
      document.body.addEventListener("click", handleClickOutsideItemSettings);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <div className="keys-list__settings-modal">
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowKeysListItemSettings(false);
            dispatch(setDeleteModalOn(localState[localState.length - 1]));
          }}
          className="delete-website"
        >
          Delete keys
        </div>
      </div>
    );
  }
);

export default KeysListSettingsModal;

import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";

function AccountActivation() {
  const location = useLocation();
  const info = location.pathname.slice(19);

  const [redirectReady, setRedirectReady] = useState(null);

  const firstSlash = info.indexOf("/");
  const secondSlash = info.indexOf("/", firstSlash + 1);
  const thirdSlash = info.indexOf("/", secondSlash + 1);

  const userId = info.substring(firstSlash + 1, secondSlash);
  const activationCode = info.substring(secondSlash + 1, thirdSlash);

  fetch("https://engine.keysforweb.com/wp-json/bda/v1/check-activation", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userId,
      activationCode,
    }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      console.log(data, "check activation");
      if (data.data.status === "success") {
        setRedirectReady(true);
      }
      if (data.code === "activation_already_done") {
        setRedirectReady(true);
      }
    });

  return <div>{redirectReady && <Redirect to="/" />}</div>;
}

export default AccountActivation;

import React, { useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import useNotification from "../../../hooks/useNotification";

import ReactCrop from "react-image-crop";
import "react-image-crop/lib/ReactCrop.scss";

import FormData from "form-data";
import imageCompression from "browser-image-compression";

import Button from "../../Button";
import ProfileUserCharacteristics from "./UserCharacterstics/ProfileUserCharacteristics";
import ResetProfileModal from "./Modals/ResetProfileModal";

import { setUserProfileInformation } from "../../../redux/actions/setUserProfile";

let imageIsSet = false;

function Profile({ setDeleteAccountVisibility }) {
  const dispatch = useDispatch();
  const currentUser = useSelector(
    ({ currentUserReducer }) => currentUserReducer.currentUser
  );

  const [requiredPassword, setRequiredPassword] = useState("");
  const [showResetProfileModal, setShowResetProfileModal] = useState(false);

  const showNotification = useNotification();

  const inputImage = useRef(null);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const [temporaryUserState, setTemporaryUserState] = useState(currentUser);
  const [imageToCrop, setImageToCrop] = useState();
  const [crop, setCrop] = useState({
    unit: "px",
    width: 104,
    height: 104,
    aspect: 1 / 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const inlineStyle = {
    "border-radius": "8px",
  };

  const [profileGoodGreen, setProfileGoodGreen] = useState({
    avatar: false,
    firstName: false,
    phone: false,
    country: false,
    city: false,
    postalCode: false,
    address: false,
  });

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  React.useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  async function submitCrop(canvas, crop) {
    if (!crop || !canvas) {
      return;
    }

    canvas.toBlob(async function (blob) {
      const options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 208,
        useWebWorker: true,
      };
      try {
        let data = new FormData();
        const compressedBlob = await imageCompression(blob, options);
        const compressedFile = new File([compressedBlob], compressedBlob.name, {
          lastModified: new Date().getTime(),
          type: compressedBlob.type,
        });
        setTemporaryUserState({
          ...temporaryUserState,
          avatar: URL.createObjectURL(compressedFile),
        });
        data.set("avatar", compressedFile, "wptest.png");
        imageIsSet = true;
        fetch("https://engine.keysforweb.com/wp-json/bda/v1/user-data", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("token") || sessionStorage.getItem("token")
            }`,
          },
          body: data,
        })
          .then((resp) => resp.json())
          .then((data) => {
            if (data.status === "success") {
              dispatch(
                setUserProfileInformation({
                  ...temporaryUserState,
                  avatar: URL.createObjectURL(compressedFile),
                })
              );
              showNotification(
                "success",
                "Changes saved",
                "Your changes have been applied and saved successfully.",
                2000
              );
            }

            console.log(data, "IMAGE UPLOADED");
          });
      } catch (error) {
      } finally {
        setImageToCrop(null);
      }
    });
  }

  async function handleImageChange(e) {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      const size = e.target.files[0].size;
      const maxSize = 1000000;

      if (size < maxSize) {
        reader.addEventListener("load", () => setImageToCrop(reader.result));
        reader.readAsDataURL(e.target.files[0]);
      } else {
        showNotification(
          "error",
          "Image is too big",
          "Please, choose another profile picture",
          2000
        );
      }
    }
  }

  const onImageUpload = () => {
    inputImage.current.click();
  };

  const saveUpdatedUserData = () => {
    let data = new FormData();

    for (let prop in temporaryUserState) {
      if (
        temporaryUserState[prop] !== "" &&
        prop !== "email" &&
        prop !== "avatar"
      ) {
        for (let savedProp in currentUser) {
          if (
            prop === savedProp &&
            temporaryUserState[prop] !== currentUser[savedProp]
          ) {
            console.log(prop, temporaryUserState[prop]);
            data.set(prop, temporaryUserState[prop]);
            setProfileGoodGreen((prev) => {
              return {
                ...prev,
                [prop]: true,
              };
            });
            setTimeout(() => {
              setProfileGoodGreen((prev) => {
                return {
                  ...prev,
                  [prop]: false,
                };
              });
            }, 2000);
          }
        }
      }
    }

    console.log(data);

    fetch("https://engine.keysforweb.com/wp-json/bda/v1/user-data", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") || sessionStorage.getItem("token")
        }`,
      },
      body: data,
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data.code === "no_data_updated") {
          showNotification(
            "error",
            "Nothing to update here",
            "You have not changed anything",
            2000
          );
        } else if (data.status === "success") {
          showNotification(
            "success",
            "Changes saved",
            "Your changes have been applied and saved successfully.",
            2000
          );
        } else if (data.code === "empty_data") {
          showNotification(
            "error",
            "Nothing to update here",
            "You have not changed anything",
            2000
          );
        }
      });
    dispatch(setUserProfileInformation(temporaryUserState));
  };

  const resetProfileChanges = () => {
    setTemporaryUserState({
      ...currentUser,
    });
    showNotification("success", "Your changes have been undone", "", 2400);
  };

  const handleChangeEmail = () => {
    fetch("https://engine.keysforweb.com/wp-json/bda/v1/user-data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          localStorage.getItem("token") || sessionStorage.getItem("token")
        }`,
      },
      body: JSON.stringify({
        email: temporaryUserState.email,
        password: requiredPassword,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data, "email changed");
        if (data.status === "success") {
          dispatch(
            setUserProfileInformation({
              ...temporaryUserState,
              email: temporaryUserState.email,
            })
          );
          showNotification(
            "success",
            "Profile has been updated",
            "Your email address has been changed",
            2000
          );
        } else if (data.code === "email_is_busy") {
          showNotification(
            "error",
            "Can't update your email",
            "Sorry, this email is busy",
            2000
          );
        } else if (data.code === "wrong_password") {
          showNotification(
            "error",
            "Can't update your email",
            "Wrong password",
            2000
          );
        } else if (data.code === "rest_invalid_param") {
          showNotification(
            "error",
            "Can't update your email",
            "Please, write your password",
            2000
          );
        }
      });
  };

  const handleDeleteAvatar = () => {
    // let data = new FormData();
    // data.set('avatar', false);

    // for (let pair of data.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }

    fetch("https://engine.keysforweb.com/wp-json/bda/v1/user-data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          localStorage.getItem("token") || sessionStorage.getItem("token")
        }`,
      },
      body: JSON.stringify({
        avatar: false,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        setTemporaryUserState({
          ...temporaryUserState,
          avatar: false,
        });
        dispatch(
          setUserProfileInformation({
            ...currentUser,
            avatar: false,
          })
        );
      });
  };

  return (
    <div className="settings__inner">
      {imageToCrop && (
        <div className="avatar-crop-modal-wrapper">
          <div className="avatar-crop-modal">
            <h2>Crop your photo</h2>
            <ReactCrop
              src={imageToCrop}
              crop={crop}
              onImageLoaded={onLoad}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
              style={inlineStyle}
            />

            <div className="avatar-crop-modal__buttons">
              <Button
                onClick={() => setImageToCrop(null)}
                text={"Cancel"}
                color={"white"}
              />
              <Button
                onClick={() =>
                  submitCrop(previewCanvasRef.current, completedCrop)
                }
                text={"Save changes"}
                color={"purple"}
              />
            </div>
            <canvas
              ref={previewCanvasRef}
              style={{
                width: Math.round(completedCrop?.width ?? 0),
                height: Math.round(completedCrop?.height ?? 0),
                display: "none",
              }}
            />
          </div>
          <div className="avatar-crop-modal-bg-layer" />
        </div>
      )}

      <div className="inner__content--profile">
        <h2>Profile Information</h2>
        {/* UC HERE */}{" "}
        <ProfileUserCharacteristics
          profileGoodGreen={profileGoodGreen}
          setTemporaryUserState={setTemporaryUserState}
          temporaryUserState={temporaryUserState}
          onImageUpload={onImageUpload}
          handleDeleteAvatar={handleDeleteAvatar}
          handleImageChange={handleImageChange}
          inputImage={inputImage}
        />
        <div className="profile__submit-buttons">
          <Button
            onClick={saveUpdatedUserData}
            color={"purple"}
            text={"Save Changes"}
          />
          <Button
            color={"white"}
            onClick={() => setShowResetProfileModal(true)}
            text={"Reset Settings"}
          />
        </div>
        <div className="profile-utility">
          <div className="profile-utility__contact-info">
            <h3>Contact info</h3>
            <h4>Manage your contact information</h4>
            <div className="profile-utility__email-address">
              <label htmlFor="Email address">Email Address</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  readOnly={!currentUser.isSubscriptionValid}
                  onChange={(e) => {
                    setTemporaryUserState({
                      ...temporaryUserState,
                      email: e.target.value,
                    });
                  }}
                  value={temporaryUserState.email}
                />
              </div>
            </div>
            {temporaryUserState.email !== currentUser.email && (
              <div className="profile-utility__password-for-email">
                <label htmlFor="Email address">
                  Password (if changing email)
                </label>
                <div className="input-wrapper">
                  <input
                    type="password"
                    onChange={(e) => {
                      setRequiredPassword(e.target.value);
                    }}
                    value={requiredPassword}
                    readOnly={!currentUser.isSubscriptionValid}
                  />
                </div>
              </div>
            )}
            {temporaryUserState.email !== currentUser.email && (
              <Button
                onClick={() => handleChangeEmail()}
                color={"purple"}
                text={"Save changes"}
              />
            )}
          </div>

          <div className="profile-utility__change-password">
            <h3>Change password</h3>
            <h4>Manage your password</h4>
            <Link to="/settings/changepassword">
              <Button color={"purple"} text={"Update password"} />
            </Link>
          </div>

          <div className="profile-utility__delete-account">
            <h3>Delete account</h3>
            <h4>If you delete your account, you won’t be able to recover it</h4>
            <Button
              color={"white"}
              text={"Delete account"}
              className={"delete-account-button"}
              onClick={() => setDeleteAccountVisibility(true)}
            />
          </div>
        </div>
      </div>

      <ResetProfileModal
        showResetProfileModal={showResetProfileModal}
        setShowResetProfileModal={setShowResetProfileModal}
        resetProfileChanges={resetProfileChanges}
      />
    </div>
  );
}

export default Profile;

import React, { useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import UpgradeSwitcher from "./UpgradeSwitcher";
import SubscriptionPlanCard from "./SubscriptionPlanCard";

import { setUpgradeModalVisibility } from "../../redux/actions/setUpgradeModalVisibility";

const StyledUpgrade = styled.div`
  position: absolute;
  top: 8%;
  left: 50%;
  margin-left: -605px;
  z-index: 10;
  visibility: ${({ upgradeVisibility }) =>
    upgradeVisibility ? "visible" : "hidden"};
  opacity: ${({ upgradeVisibility }) => (upgradeVisibility ? "1" : "0")};

  .upgrade__modal {
    position: relative;
    z-index: 1;

    padding: 45px;
    //height: 100vh;
    //max-height: 518px;
    width: 1120px;

    background: #ffffff;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
  }
  .upgrade__switcher {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 20px;
    .switcher-monthly {
      color: ${({ billingMonthly }) =>
        billingMonthly ? "#232323" : "#8b8f9a"};
      transition: 0.2s;
    }
    .switcher-annually {
      color: ${({ billingMonthly }) =>
        billingMonthly ? "#8b8f9a" : "#232323"};
      transition: 0.2s;
    }
  }
  .upgrade__title {
    display: flex;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 23px;
    color: #232323;
    .title-individuals {
      width: 34%;
      &:after {
        height: 15px;
        content: "";
        display: block;
        border-bottom: 1px solid #ededed;
      }
    }
    .title-teams {
      width: 66%;
      &:after {
        height: 14px;
        content: "";
        display: block;
        border-bottom: 3px solid #4f4f4f;
      }
    }
  }
  .upgrade__body {
    display: flex;
  }
  .upgrade__close-icon {
    position: absolute;
    top: 17px;
    right: 17px;
    cursor: pointer;
  }

  .bg-layer {
    background: #000;
    opacity: 0.6;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  transition: 0.2s;

  @media (max-width: 1240px) {
    margin-left: -445px;
    .upgrade__modal {
      width: 800px;
    }
  } ;
`;

const Upgrade = () => {
  const dispatch = useDispatch();

  const upgradeVisibility = useSelector(
    ({ upgradeModalState }) => upgradeModalState.visibility
  );
  const [billingMonthly, setBillingMonthly] = useState(true);
  const [teamQuantity, setTeamQuantity] = useState(5);
  const [upgradeData, setUpgradeData] = useState(null);
  const [currentSubscriptionPlan, setCurrentSubscriptionPlan] = useState("");

  const [toggleFetchSubscriptions, setToggleFetchSubscriptions] = useState(0);

  React.useEffect(() => {
    if (localStorage.getItem("token") || sessionStorage.getItem("token")) {
      fetch("https://engine.keysforweb.com/wp-json/bda/v1/subscriptions", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") || sessionStorage.getItem("token")
          }`,
        },
      })
        .then((resp) => resp.json())
        .then((data) => {
          console.log(data, "subscriptions");
          setUpgradeData(data);

          // ==== SET CURRENT SUBSCRIPTION PLAN ====
          data.forEach((subscriptionPlan) => {
            switch (subscriptionPlan.name) {
              case "Personal":
                if (
                  subscriptionPlan.list.month[0].currentPlan.status ===
                    "active" ||
                  subscriptionPlan.list.month[0].currentPlan.status ===
                    "trialling"
                ) {
                  console.log("personal spotted");
                  setCurrentSubscriptionPlan("Personal");
                  setBillingMonthly(true);
                }
                if (
                  subscriptionPlan.list.month[0].currentPlan.status ===
                    "cancelled" &&
                  subscriptionPlan.list.month[0].currentPlan.possibleAction ===
                    "reactivate_subscription"
                ) {
                  setCurrentSubscriptionPlan("PersonalCanceled");
                }
                if (
                  subscriptionPlan.list.year[0].currentPlan.status ===
                    "active" ||
                  subscriptionPlan.list.year[0].currentPlan.status ===
                    "trialling"
                ) {
                  setCurrentSubscriptionPlan("Personal");
                  setBillingMonthly(false);
                }
                if (
                  subscriptionPlan.list.year[0].currentPlan.status ===
                    "cancelled" &&
                  subscriptionPlan.list.year[0].currentPlan.possibleAction ===
                    "reactivate_subscription"
                ) {
                  setCurrentSubscriptionPlan("PersonalCanceled");
                }
                return;
              case "Team":
                subscriptionPlan.list.month.forEach((item) => {
                  if (
                    item.currentPlan.status === "active" ||
                    item.currentPlan.status === "trialling"
                  ) {
                    setCurrentSubscriptionPlan("Team");
                    setBillingMonthly(true);
                    setTeamQuantity(item.seatsNumber);
                  } else if (
                    item.currentPlan === "cancelled" &&
                    item.currentPlan.possibleAction ===
                      "reactivate_subscription"
                  ) {
                    setCurrentSubscriptionPlan("TeamCanceled");
                  }
                });
                subscriptionPlan.list.year.forEach((item) => {
                  if (
                    item.currentPlan.status === "active" ||
                    item.currentPlan.status === "trialling"
                  ) {
                    setCurrentSubscriptionPlan("Team");
                    setBillingMonthly(false);
                    setTeamQuantity(item.seatsNumber);
                  } else if (
                    item.currentPlan === "cancelled" &&
                    item.currentPlan.possibleAction ===
                      "reactivate_subscription"
                  ) {
                    setCurrentSubscriptionPlan("TeamCanceled");
                  }
                });
                return;
              default:
                return;
            }
          });
        });
    }
  }, [toggleFetchSubscriptions]);

  return (
    <StyledUpgrade
      upgradeVisibility={upgradeVisibility}
      billingMonthly={billingMonthly}
    >
      <div className="upgrade__modal">
        <div className="upgrade__switcher">
          <div className="switcher-monthly">Pay monthly</div>{" "}
          <UpgradeSwitcher
            billingMonthly={billingMonthly}
            setBillingMonthly={setBillingMonthly}
          />{" "}
          <div className="switcher-annually">Pay annually</div>
        </div>
        <div className="upgrade__title">
          <div className="title-individuals">For individuals</div>
          <div className="title-teams">For teams and businesses</div>
        </div>
        <div className="upgrade__body">
          {upgradeData &&
            upgradeData.map((card, index) => {
              return (
                <SubscriptionPlanCard
                  key={index}
                  billingMonthly={billingMonthly}
                  teamQuantity={teamQuantity}
                  setTeamQuantity={setTeamQuantity}
                  type={card.name}
                  features={card.advantages}
                  list={card.list}
                  currentSubscriptionPlan={currentSubscriptionPlan}
                  setToggleFetchSubscriptions={setToggleFetchSubscriptions}
                />
              );
            })}
        </div>
        <svg
          onClick={() => dispatch(setUpgradeModalVisibility(false))}
          className={"upgrade__close-icon"}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.99692 20C7.34485 20.0024 4.8007 18.9496 2.9254 17.0739C1.05009 15.1981 -0.00238199 12.6533 4.04814e-06 10.0006V9.80059C0.0817668 5.79305 2.54702 2.22119 6.26443 0.724184C9.98185 -0.772822 14.2337 0.0940545 17.0687 2.927C19.9303 5.78691 20.7868 10.0899 19.2384 13.828C17.6901 17.5661 14.0421 20.0024 9.99692 20ZM9.99693 11.4105L11.8812 13.2952C12.2705 13.6846 12.9018 13.6846 13.2911 13.2952C13.6803 12.9059 13.6803 12.2749 13.2911 11.8856L11.4065 10.0006L13.2911 8.11552C13.6803 7.72625 13.6803 7.09522 13.2911 6.70595C12.9018 6.31654 12.2705 6.31654 11.8812 6.70595L9.99693 8.59066L8.11268 6.70595C7.72337 6.31654 7.09207 6.31654 6.70276 6.70595C6.31359 7.09522 6.31359 7.72625 6.70276 8.11551L8.58736 10.0006L6.70283 11.8856C6.31362 12.2749 6.31362 12.906 6.70283 13.2953C7.09213 13.6847 7.72338 13.6847 8.11275 13.2954L9.99693 11.4115V11.4105Z"
            fill="#C2C5CB"
          />
        </svg>
      </div>
      <div className="bg-layer" />
    </StyledUpgrade>
  );
};

export default Upgrade;

import React from "react";
import styled from "styled-components";

import NeedHelpItem from "./NeedHelpItem";

const StyledNeedHelpModal = styled.div`
  position: absolute;
  top: 0;
  left: ${({ left }) => left && 0};
  right: ${({ left }) => !left && 0};
  box-shadow: ${({ left }) =>
    left
      ? "10px 3px 15px rgba(35, 35, 35, 0.05)"
      : "0px 40px 45px rgba(0, 0, 0, 0.08)"};
  z-index: 11;
  background: #ffffff;

  height: 100vh;
  max-width: 415px;
  overflow-y: auto;
  text-align: left;

  .need-help-modal__title {
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #505356;
    a {
      text-decoration: underline;
      color: #505356;
      transition: 0.1s;
    }
    a:hover {
      color: #5541d7;
    }
  }
  .need-help-modal__header {
    //position: fixed;
    //z-index
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 69px;
    //width: 100%;
    border-bottom: 1px solid #dddddd;
    span {
      margin-left: 32px;
    }
    svg {
      margin-right: 32px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .need-help-modal__body {
    padding: 40px 32px 0px;
    display: flex;
    //height: 100vh;
    //max-height: 100vh;
    flex-direction: column;
    //height: calc(100%);
    overflow-y: scroll;
  }
  @media (max-height: 920px) {
    //.need-help-modal__header {
    //  min-height: 69px;
    //}
  }
`;

const NeedHelpModal = ({ setNeedHelpVisibility, left, needHelpItems }) => {
  return (
    <StyledNeedHelpModal left={left}>
      <div className="need-help-modal__header">
        <span>Help</span>

        <svg
          onClick={() => setNeedHelpVisibility(false)}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.99692 20C7.34485 20.0024 4.8007 18.9496 2.9254 17.0739C1.05009 15.1981 -0.00238199 12.6533 4.04814e-06 10.0006V9.80059C0.0817668 5.79305 2.54702 2.22119 6.26443 0.724184C9.98185 -0.772822 14.2337 0.0940545 17.0687 2.927C19.9303 5.78691 20.7868 10.0899 19.2384 13.828C17.6901 17.5661 14.0421 20.0024 9.99692 20ZM9.99693 11.4105L11.8812 13.2952C12.2705 13.6846 12.9018 13.6846 13.2911 13.2952C13.6803 12.9059 13.6803 12.2749 13.2911 11.8856L11.4065 10.0006L13.2911 8.11552C13.6803 7.72625 13.6803 7.09522 13.2911 6.70595C12.9018 6.31654 12.2705 6.31654 11.8812 6.70595L9.99693 8.59066L8.11268 6.70595C7.72337 6.31654 7.09207 6.31654 6.70276 6.70595C6.31359 7.09522 6.31359 7.72625 6.70276 8.11551L8.58736 10.0006L6.70283 11.8856C6.31362 12.2749 6.31362 12.906 6.70283 13.2953C7.09213 13.6847 7.72338 13.6847 8.11275 13.2954L9.99693 11.4115V11.4105Z"
            fill="#C2C5CB"
          />
        </svg>
      </div>
      <div className="need-help-modal__body">
        <div className="need-help-modal__title">
          Visit our website to find out more about our{" "}
          <a
            rel="noreferrer"
            target="_blank"
            href={"https://keysforweb.com/privacy-policy/"}
          >
            Privacy Policy
          </a>{" "}
          and{" "}
          <a
            rel="noreferrer"
            target="_blank"
            href={"https://keysforweb.com/agreement-to-terms/"}
          >
            Terms of Use
          </a>
          .
        </div>

        {needHelpItems.map((content) => (
          <NeedHelpItem text={content.text} subtitle={content.subtitle} />
        ))}
      </div>
    </StyledNeedHelpModal>
  );
};

export default NeedHelpModal;

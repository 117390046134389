import React from "react";
import styled from "styled-components";

import Button from "../Button";

const StyledRequestLinkSentPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
  padding: 45px 30px 15px;
  max-width: 340px;
  background: #ffffff;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  font-size: 25px;
  line-height: 31px;
  text-align: center;
  color: #232323;
  svg {
    margin-bottom: 16px;
  }
  .requestlink__mistaken {
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 7px 0 10px;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #505356;
    .button {
      margin-top: 30px;
    }
  }
`;

const RequestLinkSentPlaceholder = ({
  customFormAttemptsLeft,
  setCustomFormAttemptsLeft,
  setShowRequestLinkSentPlaceholder,
  placeholderPositiveResponse,
}) => {
  return (
    <StyledRequestLinkSentPlaceholder>
      {placeholderPositiveResponse && (
        <svg
          width="58"
          height="58"
          viewBox="0 0 58 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="58" height="58" rx="8" fill="#F2FAF8" />
          <path
            d="M29.0002 45.6668C19.7954 45.6668 12.3335 38.2049 12.3335 29.0002C12.3335 19.7954 19.7954 12.3335 29.0002 12.3335C38.2049 12.3335 45.6668 19.7954 45.6668 29.0002C45.6567 38.2007 38.2007 45.6567 29.0002 45.6668ZM28.9735 42.3335H29.0002C36.3614 42.3261 42.3238 36.3547 42.3202 28.9935C42.3165 21.6323 36.348 15.6668 28.9868 15.6668C21.6256 15.6668 15.6572 21.6323 15.6535 28.9935C15.6498 36.3547 21.6123 42.3261 28.9735 42.3335ZM26.3739 36.6264C25.9834 37.0169 25.3502 37.0169 24.9597 36.6264L19.7073 31.3739C19.3167 30.9834 19.3167 30.3502 19.7073 29.9597L20.6444 29.0226C21.0344 28.6326 21.6665 28.632 22.0573 29.0212L25.6668 32.6168L35.9406 22.3431C36.3321 21.9516 36.9671 21.9527 37.3573 22.3456L38.298 23.2931C38.6862 23.684 38.6851 24.3152 38.2956 24.7048L26.3739 36.6264Z"
            fill="#67BAA7"
          />
        </svg>
      )}
      {!placeholderPositiveResponse && (
        <svg
          width="58"
          height="58"
          viewBox="0 0 58 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="58" height="58" rx="8" fill="#FFF8F0" />
          <path
            d="M45.6317 44H14.0377C13.4287 44 12.8659 43.6823 12.5614 43.1666C12.2569 42.6509 12.2569 42.0156 12.5614 41.4999L28.3576 14.8319C28.6623 14.317 29.2246 14 29.833 14C30.4414 14 31.0037 14.317 31.3085 14.8319L47.1046 41.4999C47.409 42.0153 47.4091 42.6503 47.1051 43.1659C46.801 43.6815 46.2387 43.9994 45.63 44H45.6317ZM29.8356 18.9987L16.9971 40.6665H42.6774L29.8356 18.9987ZM31.5318 34.0012H28.1223V25.6657H31.5318V34.0012Z"
            fill="#FFC04E"
          />
          <path
            d="M28.1308 35.6696H31.5403V39.0031H28.1308V35.6696Z"
            fill="#FFC04E"
          />
        </svg>
      )}
      You’ve submitted the keys
      {placeholderPositiveResponse && (
        <div className="requestlink__mistaken">
          {" "}
          Made a mistake? Send the form again - you have{" "}
          {customFormAttemptsLeft - 1} more attempt.
          <Button
            color={"purple"}
            text={"Try again"}
            onClick={() => {
              setCustomFormAttemptsLeft((prev) => prev - 1);
              setShowRequestLinkSentPlaceholder(false);
            }}
          />
        </div>
      )}
      {!placeholderPositiveResponse && (
        <div className="requestlink__mistaken">
          Unfortunately, you cannot submit the keys again due to security
          reasons. To try again, contact the sender of this form and ask for
          another keys request.
          <Button
            color={"purple"}
            text={"Visit our Website"}
            onClick={() => {
              setShowRequestLinkSentPlaceholder(false);
            }}
          />
        </div>
      )}
    </StyledRequestLinkSentPlaceholder>
  );
};

export default RequestLinkSentPlaceholder;

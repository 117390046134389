import React from "react";
import styled from "styled-components";

import Button from "../../../Button";

const StyledDeleteAccountModal = styled.div`
  position: absolute;
  top: 22%;
  left: 50%;
  margin-left: -213px;
  transition: 0.2s;
  visibility: ${({ deleteAccountVisibility }) =>
    deleteAccountVisibility ? "visible" : "hidden"};
  opacity: ${({ deleteAccountVisibility }) =>
    deleteAccountVisibility ? "1" : "0"};
  .delete-account-cool-icon {
    margin-bottom: 16px;
    padding: 14px;
    background: #fff0ef;
    border-radius: 8px;
  }
  .delete-account-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 10px;
    position: relative;
    background: #ffffff;
    border-radius: 20px;
    z-index: 1;

    width: 100vw;
    max-width: 407px;
  }
  h3 {
    max-width: 367px;
    font-weight: 500;
    font-size: 25px;
    line-height: 31px;
    text-align: center;
    color: #232323;
    margin-bottom: 7px;
  }
  h4 {
    max-width: 367px;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #505356;
    margin-bottom: 30px;
  }
  .delete-account-modal__buttons {
    display: flex;
    align-items: center;
    .button {
      width: 144px;
    }
    .button.white {
      margin-right: 23px;
    }
  }
  .bg-layer {
    background: #000;
    opacity: 0.6;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

const DeleteAccountModal = ({
  deleteAccountVisibility,
  setDeleteAccountVisibility,
}) => {
  const onDeleteAccount = () => {
    fetch("https://engine.keysforweb.com/wp-json/bda/v1/request-deletion", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") || sessionStorage.getItem("token")
        }`,
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        setDeleteAccountVisibility(false);
        console.log(data);
      });
  };

  return (
    <StyledDeleteAccountModal deleteAccountVisibility={deleteAccountVisibility}>
      <div className="delete-account-modal">
        <div className="delete-account-cool-icon">
          <svg
            width="30"
            height="34"
            viewBox="0 0 30 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.3333 33.6663H6.66667C4.82572 33.6663 3.33333 32.174 3.33333 30.333V8.66634H0V5.33301H6.66667V3.66634C6.66667 1.82539 8.15905 0.333008 10 0.333008H20C21.8409 0.333008 23.3333 1.82539 23.3333 3.66634V5.33301H30V8.66634H26.6667V30.333C26.6667 32.174 25.1743 33.6663 23.3333 33.6663ZM6.66667 8.66634V30.333H23.3333V8.66634H6.66667ZM10 3.66634V5.33301H20V3.66634H10Z"
              fill="#FF6562"
            />
          </svg>
        </div>
        <h3>Are you sure you want to permanently delete your account?</h3>
        <h4>
          After receiving your request, we will contact you again by email
          before deleting your account.
        </h4>
        <div className="delete-account-modal__buttons">
          <Button
            color={"white"}
            text={"Cancel"}
            onClick={() => setDeleteAccountVisibility(false)}
          />
          <Button
            color={"red"}
            text={"Delete account"}
            onClick={() => onDeleteAccount()}
          />
        </div>
      </div>
      <div className="bg-layer" />
    </StyledDeleteAccountModal>
  );
};

export default DeleteAccountModal;

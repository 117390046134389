import React, { useState } from "react";
import styled from "styled-components";

import Button from "../Button";
import MembersNumberModal from "./MembersNumberModal";
import { useSelector } from "react-redux";
import useNotification from "../../hooks/useNotification";

const StyledSubscriptionPlanCard = styled.div`
  background: #ffffff;
  border: ${({ type }) =>
    type === "Team" ? "1px solid #5541D7" : "1px solid #81858b"};
  box-sizing: border-box;
  border-radius: 10px;
  padding: 30px 23px 34px 23px;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
  width: 100vw;
  max-width: 360px;

  .plan-card__title {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 25px;
    line-height: 31px;
    color: ${({ type }) => (type === "Team" ? "#5541D7" : "#232323")};
  }
  .plan-card__pricing {
    display: flex;
    .pricing-amount {
      font-weight: 600;
      font-size: 31px;
      line-height: 39px;
      color: #232323;
    }
    .pricing-additional {
      margin-left: 15px;
    }
    .pricing-seats {
      font-size: 14px;
      line-height: 18px;
      color: #232323;
    }
    .pricing-billed-type {
      font-size: 14px;
      line-height: 18px;
      color: #505356;
    }
  }
  .pricing-plain-text {
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    color: #505356;
  }
  .plan-card__options {
    position: relative;
    min-height: 100px;
    margin: 25px 0 20px;
    .options-your-plan {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      max-width: 90px;
      padding: 5px 10px;
      color: #ffffff;
      background: #8b8f9a;
      border-radius: 4px;
      svg {
        margin-right: 8px;
      }
      transition: 0.2s;
    }
    .options-cancel-subscription {
      cursor: pointer;
      margin-top: 15px;
      font-size: 14px;
      line-height: 18px;
      color: #505356;
      transition: 0.3s;
      &:hover {
        color: #8476e3;
      }
    }
    .options-number-of-members {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 15px;
      margin-bottom: 10px;
      height: 40px;
      border: 1px solid #dddddd;
      box-sizing: border-box;
      border-radius: 8px;
      font-size: 16px;
      line-height: 20px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      color: ${({ teamQuantity }) =>
        teamQuantity === null ? "#8b8f9a" : "#232323"};
      svg {
        margin-left: auto;
      }
      cursor: pointer;
    }
    .button {
      &.white {
        max-height: 40px;
      }
      width: 100%;
    }
  }
  .cannot-change-subscription-plan {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    color: #505356;
  }
  .plan-card__current-card-options {
    margin-bottom: 20px;
  }
  .plan-card__features {
    margin-top: auto;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    .features-title {
      color: #232323;
      margin-bottom: 15px;
    }
    .features-item {
      color: #505356;
      margin-bottom: 10px;
      svg {
        margin-right: 15px;
      }
    }
  }
`;

const SubscriptionPlanCard = ({
  type,
  features,
  list,
  setTeamQuantity,
  teamQuantity,
  billingMonthly,
  currentSubscriptionPlan,
  setToggleFetchSubscriptions,
}) => {
  const [membersNumberModalVisibility, setMembersNumberModalVisibility] =
    useState(false);
  const userSeatsNumber = useSelector(
    ({ currentUserReducer }) => currentUserReducer.currentUser.seatsNumber
  );
  const [upgradeAvailable, setUpgradeAvailable] = useState(null);
  const showNotification = useNotification();

  React.useEffect(() => {
    if (list && teamQuantity) {
      if (billingMonthly) {
        if (
          list.month.find(
            (subscriptionPlan) => subscriptionPlan.seatsNumber === teamQuantity
          )?.upgrade
        ) {
          setUpgradeAvailable(true);
        } else {
          setUpgradeAvailable(false);
        }
      }
      if (!billingMonthly) {
        if (
          list.year.find(
            (subscriptionPlan) => subscriptionPlan.seatsNumber === teamQuantity
          )?.upgrade
        ) {
          setUpgradeAvailable(true);
        } else {
          setUpgradeAvailable(false);
        }
      }
    }
  }, [teamQuantity, billingMonthly]); // eslint-disable-line react-hooks/exhaustive-deps

  const upgradeSubscriptionPlan = () => {
    if (billingMonthly) {
      window.open(
        list.month.find(
          (subscriptionPlan) => subscriptionPlan.seatsNumber === teamQuantity
        ).upgrade.actionURL
      );
    }
    if (!billingMonthly) {
      window.open(
        list.year.find(
          (subscriptionPlan) => subscriptionPlan.seatsNumber === teamQuantity
        ).upgrade.actionURL
      );
    }
  };

  const renewSubscription = () => {
    fetch("https://engine.keysforweb.com/wp-json/bda/v1/manage-subscription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          localStorage.getItem("token") || sessionStorage.getItem("token")
        }`,
      },
      body: JSON.stringify({
        action: "reactivate_subscription",
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        setToggleFetchSubscriptions((prev) => prev + 1);
        console.log(data);
        if (data.status === "success") {
          showNotification(
            "success",
            "Subscription has been reactivated",
            "",
            4000
          );
        }
      });
  };

  const cancelSubscription = () => {
    fetch("https://engine.keysforweb.com/wp-json/bda/v1/manage-subscription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          localStorage.getItem("token") || sessionStorage.getItem("token")
        }`,
      },
      body: JSON.stringify({
        action: "cancel_subscription",
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        setToggleFetchSubscriptions((prev) => prev + 1);
        console.log(data);
        if (data.status === "success") {
          showNotification(
            "success",
            "Subscription has been canceled",
            "",
            4000
          );
        }
      });
  };

  return (
    <StyledSubscriptionPlanCard type={type} teamQuantity={teamQuantity}>
      <div className="plan-card__title">{type}</div>

      {/* ===== PERSONAL PRICING ===== */}

      {type === "Personal" && (
        <div className="plan-card__pricing">
          <div className="pricing-amount">
            ${billingMonthly && Math.floor(list.month[0].pricePerMonth)}
            {!billingMonthly && Math.floor(list.year[0].pricePerMonth)}
          </div>
          <div className="pricing-additional">
            <div className="pricing-seats">seat / month</div>
            <div className="pricing-billed-type">
              when billed {billingMonthly ? "monthly" : "annually"}
            </div>
          </div>
        </div>
      )}

      {/* ===== TEAM PRICING ===== */}

      {type === "Team" && teamQuantity === null && (
        <div className="pricing-plain-text">
          For a quote for the Team plan, select the number of team members.
        </div>
      )}
      {type === "Team" && teamQuantity !== null && (
        <div className="plan-card__pricing">
          <div className="pricing-amount">
            {billingMonthly &&
              list.month.map((listItem) => {
                if (teamQuantity === listItem.seatsNumber) {
                  return "$" + Math.floor(listItem.pricePerMonth);
                }
                return null;
              })}
            {!billingMonthly &&
              list.year.map((listItem) => {
                if (teamQuantity === listItem.seatsNumber) {
                  return "$" + Math.floor(listItem.pricePerMonth);
                }
                return null;
              })}
          </div>
          <div className="pricing-additional">
            <div className="pricing-seats">{teamQuantity} seats / month</div>
            <div className="pricing-billed-type">
              when billed {billingMonthly ? "monthly" : "annually"}
            </div>
          </div>
        </div>
      )}

      {/* ===== ENTERPRISE PRICING ===== */}

      {type === "Enterprise" && (
        <div className="pricing-plain-text">
          For a quote for the Enterprise plan, contact our sales team.
        </div>
      )}

      {/* ===== PERSONAL OPTIONS ===== */}

      {type === "Personal" && (
        <div className="plan-card__options">
          {currentSubscriptionPlan === "Personal" ? (
            <div className="plan-card__current-card-options">
              <div className="options-your-plan">
                <svg
                  width="16"
                  height="11"
                  viewBox="0 0 16 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.5 1.3043L5.3 11.0003L0.5 6.43749L1.69642 5.30018L5.3 8.71764L14.3036 0.166992L15.5 1.3043Z"
                    fill="#ffffff"
                  />
                </svg>
                Your plan
              </div>
              <div
                onClick={() => cancelSubscription()}
                className="options-cancel-subscription"
              >
                Cancel subscription
              </div>
            </div>
          ) : (
            <div className={"cannot-change-subscription-plan"}>
              To change your subscription plan, please cancel your current
              subscription first.
              {currentSubscriptionPlan === "PersonalCanceled" && (
                <div
                  onClick={() => renewSubscription()}
                  className="options-cancel-subscription"
                >
                  Renew subscription
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {/* ===== TEAM OPTIONS ===== */}

      {type === "Team" && (
        <div className="plan-card__options">
          {currentSubscriptionPlan === "Team" &&
            userSeatsNumber === teamQuantity && (
              <div className="plan-card__current-card-options">
                <div className="options-your-plan">
                  <svg
                    width="16"
                    height="11"
                    viewBox="0 0 16 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.5 1.3043L5.3 11.0003L0.5 6.43749L1.69642 5.30018L5.3 8.71764L14.3036 0.166992L15.5 1.3043Z"
                      fill="#ffffff"
                    />
                  </svg>
                  Your plan
                </div>
                <div
                  onClick={() => cancelSubscription()}
                  className="options-cancel-subscription"
                >
                  Cancel subscription
                </div>
              </div>
            )}
          {currentSubscriptionPlan === "TeamCanceled" && (
            <div
              onClick={() => renewSubscription()}
              className="options-cancel-subscription"
            >
              Renew subscription
            </div>
          )}
          <div
            onClick={() => setMembersNumberModalVisibility(true)}
            className="options-number-of-members"
          >
            {teamQuantity === null
              ? "Number of team members"
              : teamQuantity + " seats"}
            <svg
              width="17"
              height="10"
              viewBox="0 0 17 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.702107 0.720844C0.315715 1.11073 0.311585 1.74347 0.698116 2.13322L7.78997 9.28406C8.18115 9.6785 8.81885 9.6785 9.21003 9.28406L16.3019 2.13322C16.6884 1.74347 16.6843 1.11073 16.2979 0.720844C15.9067 0.326151 15.2645 0.321707 14.8732 0.71629L8.5 7.14343L2.12678 0.71629C1.73551 0.321706 1.09327 0.326151 0.702107 0.720844Z"
                fill="#8B8F9A"
              />
            </svg>
            <MembersNumberModal
              visibility={membersNumberModalVisibility}
              setVisibility={setMembersNumberModalVisibility}
              setTeamQuantity={setTeamQuantity}
            />
          </div>

          <Button
            isActive={upgradeAvailable}
            color={"purple"}
            onClick={() => upgradeSubscriptionPlan()}
            text={"Upgrade plan"}
          />
        </div>
      )}

      {/* ===== ENTERPRISE OPTIONS ===== */}

      {type === "Enterprise" && (
        <div className="plan-card__options">
          <a
            style={{ textDecoration: "none" }}
            href="https://keysforweb.com/contact-us/"
            rel="noreferrer"
            target="_blank"
          >
            {" "}
            <Button color={"white"} text={"Contact sales"} />
          </a>
        </div>
      )}

      {/* ===== FEATURES ===== */}

      <div className="plan-card__features">
        <div className="features-title">{features.title}</div>
        {features.list.map((feature, index) => {
          return (
            <div key={index} className="features-item">
              <svg
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.5 1.3043L5.3 11.0003L0.5 6.43749L1.69642 5.30018L5.3 8.71764L14.3036 0.166992L15.5 1.3043Z"
                  fill="#232323"
                />
              </svg>
              {feature}
            </div>
          );
        })}
      </div>
    </StyledSubscriptionPlanCard>
  );
};

export default SubscriptionPlanCard;

import React from "react";
import styled from "styled-components";

import Button from "./Button";

const StyledResultMark = styled.div`
  background: #ffffff;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  margin: 10% auto 0;
  padding: 47px 30px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  //justify-content: center;
  align-items: center;
  .result-mark__svg-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    height: 58px;
    width: 58px;
    border-radius: 8px;
    background: ${({ type }) => (type === "completed" ? "#f2faf8" : "#FFF0EF")};
  }
  h3 {
    margin-bottom: 7px;
    font-weight: 500;
    font-size: 25px;
    line-height: 31px;
    text-align: center;
    color: #232323;
  }
  p {
    max-width: 340px;
    margin-bottom: 30px;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #505356;
  }
`;

const ResultMark = ({ type, title, text, setValidationResult }) => {
  const conditionalOnClick = () => {
    if (type === "failure") {
      setValidationResult(null);
    } else {
      window.open("https://keysforweb.com/", "_blank");
    }
  };

  return (
    <StyledResultMark type={type}>
      {type === "completed" && (
        <div className={"result-mark__svg-container"}>
          {" "}
          <svg
            width="33"
            height="33"
            viewBox="0 0 63 63"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31.5 0C14.112 0 0 14.112 0 31.5C0 48.888 14.112 63 31.5 63C48.888 63 63 48.888 63 31.5C63 14.112 48.888 0 31.5 0ZM31.5 56.7C17.6085 56.7 6.3 45.3915 6.3 31.5C6.3 17.6085 17.6085 6.3 31.5 6.3C45.3915 6.3 56.7 17.6085 56.7 31.5C56.7 45.3915 45.3915 56.7 31.5 56.7ZM43.722 19.8135L25.2 38.3355L19.278 32.4135C18.0495 31.185 16.065 31.185 14.8365 32.4135C13.608 33.642 13.608 35.6265 14.8365 36.855L22.995 45.0135C24.2235 46.242 26.208 46.242 27.4365 45.0135L48.195 24.255C49.4235 23.0265 49.4235 21.042 48.195 19.8135C46.9665 18.585 44.9505 18.585 43.722 19.8135Z"
              fill="#5BC044"
            />
          </svg>
        </div>
      )}
      {type === "failure" && (
        <div className={"result-mark__svg-container"}>
          {" "}
          <svg
            width="36"
            height="30"
            viewBox="0 0 36 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M33.632 30H2.03795C1.42891 30 0.866146 29.6823 0.561633 29.1666C0.257121 28.6509 0.257125 28.0156 0.561644 27.4999L16.3578 0.83186C16.6626 0.31702 17.2248 0 17.8333 0C18.4417 0 19.004 0.31702 19.3087 0.83186L35.1049 27.4999C35.4092 28.0153 35.4094 28.6503 35.1053 29.1659C34.8012 29.6815 34.239 29.9994 33.6303 30H33.632ZM17.8358 4.99874L4.99739 26.6665H30.6776L17.8358 4.99874ZM19.532 20.0012H16.1225V11.6657H19.532V20.0012Z"
              fill="#FF6562"
            />
            <path
              d="M16.1311 21.6696H19.5406V25.0031H16.1311V21.6696Z"
              fill="#FF6562"
            />
          </svg>
        </div>
      )}
      <h3>{title}</h3>
      <p>{text}</p>
      <Button
        color={"purple"}
        text={type === "completed" ? "Visit our Website" : "Try again"}
        onClick={() => conditionalOnClick()}
      />
    </StyledResultMark>
  );
};

export default ResultMark;

import React from "react";
import { Route, useLocation, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthedRoute = (props) => {
  const userIsLoggedIn = useSelector(
    ({ currentUserReducer }) => currentUserReducer.isLoggedIn
  );
  const location = useLocation();

  return (
    <Route {...props}>
      {userIsLoggedIn ? (
        props.children
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: location },
          }}
        />
      )}
    </Route>
  );
};

export default AuthedRoute;

const initialState = {
  emailBadRed: false,
  loginBadRed: false,
  nameBadRed: false,
  passwordBadRed: false,
};

const signUpStyleState = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SIGNUP_EMAIL_STYLE':
      return {
        ...state,
        emailBadRed: action.payload,
      };
    case 'SET_SIGNUP_LOGIN_STYLE':
      return {
        ...state,
        loginBadRed: action.payload,
      };
    case 'SET_SIGNUP_NAME_STYLE':
      return {
        ...state,
        nameBadRed: action.payload,
      };
    case 'SET_SIGNUP_PASSWORD_STYLE':
      return {
        ...state,
        passwordBadRed: action.payload,
      };
    default:
      return { ...state };
  }
};

export default signUpStyleState;

import { combineReducers } from "redux";
import settingsContent from "./settingsContent";
import formGenerator from "./formGenerator";
import currentUserReducer from "./currentUserReducer";
import requestLinkState from "./requestLinkState";
import notificationState from "./notificationState";
import signUpStyleState from "./signUpStyleState";
import loginStyleState from "./LoginPage/loginStyleState";
import loginPageComponent from "./LoginPage/loginPageComponent";
import secureVaultState from "./MainScreen/secureVaultState";
import deleteKeysListItemModal from "./MainScreen/deleteKeysListItemModal";
import formFromWebSocket from "./formFromWebSocket";
import upgradeModalState from "./upgradeModalState";
import subscriptionType from "./subscriptionType";

const rootReducer = combineReducers({
  settingsContent,
  formGenerator,
  currentUserReducer,
  requestLinkState,
  notificationState,
  loginStyleState,
  signUpStyleState,
  loginPageComponent,
  secureVaultState,
  deleteKeysListItemModal,
  formFromWebSocket,
  upgradeModalState,
  subscriptionType,
});

export default rootReducer;

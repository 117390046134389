import React from "react";
import styled from "styled-components";

const StyledMemberModal = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 8px;

  &:hover {
    border: 1px solid #505356;
  }

  transition: 0.3s;

  visibility: ${({ visibility }) => (visibility ? "visible" : "hidden")};
  opacity: ${({ visibility }) => (visibility ? "1" : "0")};

  .members-modal__item {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 15px;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #505356;
    svg {
      margin-left: auto;
    }
    &:first-child {
      height: 38px;
      color: #8b8f9a;
      &:hover {
        background: #fff;
        border-radius: 8px 8px 0 0;
      }
    }
    &:last-child {
      margin-bottom: 0;
      border-radius: 0 0 8px 8px;
    }
    cursor: pointer;
    &:hover {
      background: #f6f6f6;
    }
    &.members-number-title {
      cursor: default;
      pointer-events: none;
    }
  }
`;

const MembersNumberModal = ({ visibility, setVisibility, setTeamQuantity }) => {
  return (
    <StyledMemberModal
      onClick={(e) => {
        e.stopPropagation();
        setVisibility(false);
      }}
      visibility={visibility}
    >
      <div className="members-modal__item members-number-title">
        Number of team members
        <svg
          width="17"
          height="10"
          viewBox="0 0 17 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.2979 9.27916C16.6843 8.88927 16.6884 8.25653 16.3019 7.86678L9.21003 0.715936C8.81885 0.321498 8.18115 0.321498 7.78997 0.715936L0.698114 7.86678C0.311584 8.25653 0.315714 8.88927 0.702106 9.27916C1.09327 9.67385 1.73551 9.67829 2.12678 9.28371L8.5 2.85657L14.8732 9.28371C15.2645 9.67829 15.9067 9.67385 16.2979 9.27916Z"
            fill="#8B8F9A"
          />
        </svg>
      </div>
      <div onClick={() => setTeamQuantity(5)} className="members-modal__item">
        5 seats
      </div>
      <div onClick={() => setTeamQuantity(10)} className="members-modal__item">
        10 seats
      </div>
      <div onClick={() => setTeamQuantity(20)} className="members-modal__item">
        20 seats
      </div>
      <div onClick={() => setTeamQuantity(50)} className="members-modal__item">
        50 seats
      </div>
    </StyledMemberModal>
  );
};

export default MembersNumberModal;

import React, { useState } from "react";
import styled from "styled-components";

const StyledNeedHelpItem = styled.div`
  position: relative;
  margin-bottom: 15px;
  padding: 15px 12px;
  overflow: hidden;

  //height: 100vh;
  max-height: ${({ expanded }) => (expanded ? "100vw" : "46px")};
  min-height: 24px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 10px;
  .need-help-modal__subtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    span {
      max-width: 296px;
    }
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    color: #232323;
  }
  .need-help-arrow {
    transform: ${({ expanded }) => (expanded ? "rotate(180deg)" : "rotate(0)")};
    transition: 0.3s;
  }
  .need-help-modal__text {
    display: ${({ expanded }) => (expanded ? "block" : "none")};
    margin-top: 30px;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    p {
      margin-bottom: 15px;
    }
  }
  cursor: pointer;
  transition: 0.8s;
`;

const NeedHelpItem = ({ subtitle, text }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <StyledNeedHelpItem
      onClick={() => setExpanded((prev) => !prev)}
      expanded={expanded}
    >
      <div className="need-help-modal__subtitle">
        <span>{subtitle}</span>
        <svg
          className={"need-help-arrow"}
          width="18"
          height="10"
          viewBox="0 0 18 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.7979 0.720844C17.1843 1.11073 17.1884 1.74347 16.8019 2.13322L9.71003 9.28406C9.31885 9.6785 8.68115 9.6785 8.28997 9.28406L1.19811 2.13322C0.811584 1.74347 0.815713 1.11073 1.20211 0.720846C1.59327 0.326152 2.23551 0.321708 2.62678 0.716291L9 7.14344L15.3732 0.71629C15.7645 0.321706 16.4067 0.326151 16.7979 0.720844Z"
            fill="#8B8F9A"
          />
        </svg>
      </div>
      {text}
    </StyledNeedHelpItem>
  );
};

export default NeedHelpItem;

import {
  setNewNotification,
  setNotificationVisibilty,
  setNotificationOrder,
  setHideForever,
} from "./setNotification";
import { setLoginStyle } from "./setLoginStyleState";
import {
  setSignUpEmailStyle,
  setSignUpLoginStyle,
  setSignUpNameStyle,
  setSignUpPasswordStyle,
} from "./setSignUpStyleState";
import { setLoginPageComponent } from "./setLoginPageComponent";
import { setSubscriptionType } from "./setSubscriptionType";

import {
  notificationId,
  incrementNotificationId,
} from "../../hooks/useNotification";

export const userPostFetch = (user) => {
  return async (dispatch) => {
    function execNotification(type, title, text, timeout) {
      incrementNotificationId();

      const currentId = notificationId;

      function createNotification(currentId) {
        dispatch(
          setNewNotification({
            id: currentId,
            visible: false,
            hideForever: false,
            params: {
              type,
              title,
              text,
            },
          })
        );
        setTimeout(() => {
          dispatch(setNotificationVisibilty(currentId, true));
        }, 1);
      }
      function killNotification(currentId) {
        dispatch(setNotificationVisibilty(currentId, false));
        dispatch(setNotificationOrder(currentId, 0));
        setTimeout(() => {
          dispatch(setHideForever(currentId));
        }, 250);
      }

      createNotification(currentId);
      if (timeout) {
        setTimeout(() => {
          killNotification(currentId);
        }, timeout);
      }
    }

    return await fetch(
      "https://engine.keysforweb.com/wp-json/bda/v1/registration",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data, "user registration post");
        switch (data.message) {
          case "Sorry, this login is busy.":
            execNotification(
              "error",
              "Sign up cancelled",
              "Login is already taken",
              2500
            );

            dispatch(setSignUpLoginStyle(true));
            setTimeout(() => {
              dispatch(setSignUpLoginStyle(false));
            }, 1500);
            break;
          case "Looks like you’re registered already.":
            execNotification(
              "error",
              "Email is already in use",
              "Seems like you're registered. Try to log in",
              2500
            );

            dispatch(setSignUpEmailStyle(true));
            setTimeout(() => {
              dispatch(setSignUpEmailStyle(false));
            }, 1500);
            break;
          case "Invalid parameter(s): login":
            execNotification(
              "error",
              "Sign up cancelled",
              "Invalid login",
              2500
            );

            dispatch(setSignUpLoginStyle(true));
            setTimeout(() => {
              dispatch(setSignUpLoginStyle(false));
            }, 1500);
            break;
          case "Invalid parameter(s): email":
            execNotification(
              "error",
              "Sign up cancelled",
              "Invalid email",
              2500
            );

            dispatch(setSignUpEmailStyle(true));
            setTimeout(() => {
              dispatch(setSignUpEmailStyle(false));
            }, 1500);
            break;
          case "Invalid parameter(s): firstName":
            execNotification(
              "error",
              "Sign up cancelled",
              "Please, write your name",
              2500
            );

            dispatch(setSignUpNameStyle(true));
            setTimeout(() => {
              dispatch(setSignUpNameStyle(false));
            }, 1500);
            break;
          case "Invalid parameter(s): password":
            execNotification(
              "error",
              "Sign up cancelled",
              "Please, set a password",
              2500
            );

            dispatch(setSignUpPasswordStyle(true));
            setTimeout(() => {
              dispatch(setSignUpPasswordStyle(false));
            }, 1500);
            break;
          case "Invalid parameter(s): firstName, email":
            execNotification(
              "error",
              "Sign up cancelled",
              "Please, set your name and email",
              2500
            );

            dispatch(setSignUpNameStyle(true));
            dispatch(setSignUpEmailStyle(true));
            setTimeout(() => {
              dispatch(setSignUpNameStyle(false));
              dispatch(setSignUpEmailStyle(false));
            }, 1500);
            break;
          case "Invalid parameter(s): firstName, email, login":
            execNotification(
              "error",
              "Sign up cancelled",
              "Please, set your name, email and login",
              2500
            );

            dispatch(setSignUpNameStyle(true));
            dispatch(setSignUpEmailStyle(true));
            dispatch(setSignUpLoginStyle(true));
            setTimeout(() => {
              dispatch(setSignUpLoginStyle(false));
              dispatch(setSignUpNameStyle(false));
              dispatch(setSignUpEmailStyle(false));
            }, 1500);
            break;
          case "Invalid parameter(s): firstName, email, login, password":
            execNotification(
              "error",
              "Sign up cancelled",
              "Please, fill the form to sign up",
              2500
            );

            dispatch(setSignUpNameStyle(true));
            dispatch(setSignUpEmailStyle(true));
            dispatch(setSignUpLoginStyle(true));
            dispatch(setSignUpPasswordStyle(true));
            setTimeout(() => {
              dispatch(setSignUpLoginStyle(false));
              dispatch(setSignUpNameStyle(false));
              dispatch(setSignUpEmailStyle(false));
              dispatch(setSignUpPasswordStyle(false));
            }, 1500);
            break;
          case "Invalid parameter(s): email, login, password":
            execNotification(
              "error",
              "Sign up cancelled",
              "Please, fill the form to sign up",
              2500
            );
            dispatch(setSignUpEmailStyle(true));
            dispatch(setSignUpLoginStyle(true));
            dispatch(setSignUpPasswordStyle(true));
            setTimeout(() => {
              dispatch(setSignUpLoginStyle(false));
              dispatch(setSignUpEmailStyle(false));
              dispatch(setSignUpPasswordStyle(false));
            }, 1500);
            break;
          case "Invalid parameter(s): firstName, login, password":
            execNotification(
              "error",
              "Sign up cancelled",
              "Please, fill the form to sign up",
              2500
            );
            dispatch(setSignUpNameStyle(true));
            dispatch(setSignUpLoginStyle(true));
            dispatch(setSignUpPasswordStyle(true));
            setTimeout(() => {
              dispatch(setSignUpLoginStyle(false));
              dispatch(setSignUpNameStyle(false));
              dispatch(setSignUpPasswordStyle(false));
            }, 1500);
            break;
          case "Invalid parameter(s): firstName, email, password":
            execNotification(
              "error",
              "Sign up cancelled",
              "Please, fill the form to sign up",
              2500
            );
            dispatch(setSignUpNameStyle(true));
            dispatch(setSignUpEmailStyle(true));
            dispatch(setSignUpPasswordStyle(true));
            setTimeout(() => {
              dispatch(setSignUpEmailStyle(false));
              dispatch(setSignUpNameStyle(false));
              dispatch(setSignUpPasswordStyle(false));
            }, 1500);
            break;
          case "Invalid parameter(s): email, login":
            execNotification(
              "error",
              "Sign up cancelled",
              "Please, fill the form to sign up",
              2500
            );
            dispatch(setSignUpEmailStyle(true));
            dispatch(setSignUpLoginStyle(true));
            setTimeout(() => {
              dispatch(setSignUpEmailStyle(false));
              dispatch(setSignUpLoginStyle(false));
            }, 1500);
            break;
          case "Invalid parameter(s): firstName, login":
            execNotification(
              "error",
              "Sign up cancelled",
              "Please, fill the form to sign up",
              2500
            );
            dispatch(setSignUpNameStyle(true));
            dispatch(setSignUpLoginStyle(true));
            setTimeout(() => {
              dispatch(setSignUpNameStyle(false));
              dispatch(setSignUpLoginStyle(false));
            }, 1500);
            break;
          case "Invalid parameter(s): email, password":
            execNotification(
              "error",
              "Sign up cancelled",
              "Please, fill the form to sign up",
              2500
            );
            dispatch(setSignUpEmailStyle(true));
            dispatch(setSignUpPasswordStyle(true));
            setTimeout(() => {
              dispatch(setSignUpEmailStyle(false));
              dispatch(setSignUpPasswordStyle(false));
            }, 1500);
            break;
          case "Invalid parameter(s): firstName, password":
            execNotification(
              "error",
              "Sign up cancelled",
              "Please, fill the form to sign up",
              2500
            );
            dispatch(setSignUpNameStyle(true));
            dispatch(setSignUpPasswordStyle(true));
            setTimeout(() => {
              dispatch(setSignUpNameStyle(false));
              dispatch(setSignUpPasswordStyle(false));
            }, 1500);
            break;
          case "Invalid parameter(s): login, password":
            execNotification(
              "error",
              "Sign up cancelled",
              "Please, fill the form to sign up",
              2500
            );
            dispatch(setSignUpLoginStyle(true));
            dispatch(setSignUpPasswordStyle(true));
            setTimeout(() => {
              dispatch(setSignUpLoginStyle(false));
              dispatch(setSignUpPasswordStyle(false));
            }, 1500);
            break;
          default:
            dispatch(setLoginPageComponent("loginCheckEmail"));
            break;
        }
      });
  };
};

export const logoutUser = () => ({
  type: "LOGOUT_USER",
});

export const userLoginFetch = (user, rememberUser) => {
  return async (dispatch) => {
    return await fetch(
      "https://engine.keysforweb.com/wp-json/bda/v1/authorization",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        if (data.message) {
          console.log(data, "user auth post");
          dispatch(setLoginStyle(true));
          setTimeout(() => {
            dispatch(setLoginStyle(false));
          }, 1500);
        } else {
          if (rememberUser) {
            localStorage.setItem("token", data.token);
          } else {
            sessionStorage.setItem("token", data.token);
          }
          const payload = {
            isLoggedIn: true,
            currentUser: {
              ...data.user,
            },
          };
          dispatch(setSubscriptionType(data.user.subscriptionType));
          dispatch(loginUser(payload));
        }
      });
  };
};

export const getProfileFetch = () => {
  return async (dispatch) => {
    if (localStorage.getItem("token") || sessionStorage.getItem("token")) {
      return await fetch(
        "https://engine.keysforweb.com/wp-json/bda/v1/user-data",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${
              localStorage.getItem("token") || sessionStorage.getItem("token")
            }`,
          },
        }
      )
        .then((resp) => resp.json())
        .then((data) => {
          console.log(data);
          if (
            data.code === "jwt_auth_invalid_token" ||
            data.code === "jwt_auth_token_deprecated"
          ) {
            localStorage.removeItem("token");
            dispatch(logoutUser());
          } else {
            const payload = {
              isLoggedIn: true,
              currentUser: {
                ...data.user,
              },
            };
            dispatch(setSubscriptionType(data.user.subscriptionType));
            dispatch(loginUser(payload));
            dispatch(setLoadingUser(false));
          }
        });
    } else {
      dispatch(setLoadingUser(false));
    }
  };
};

const loginUser = (userObj) => ({
  type: "LOGIN_USER",
  payload: userObj,
});

export const setLoadingUser = (payload) => ({
  type: "SET_LOADING_USER",
  payload,
});

import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";

import { setSecureVaultOn } from "../../redux/actions/setSecureVault";

import KeysListItem from "./KeysListItem";
import KeysListItemMobile from "./KeysListItemMobile";
import KeysListItemSkeleton from "./KeyListSkeletons/KeysListItemSkeleton";
import KeysListItemMobileSkeleton from "./KeyListSkeletons/KeysListItemMobileSkeleton";
import SecureVault from "./KeysListModals/SecureVault/SecureVault";
import DeleteModal from "./KeysListModals/DeleteModal";
import SortByModal from "./KeysListModals/SortByModal";
import KeysListPlaceholder from "./KeysListPlaceholder";

const StyledKeysList = styled.div`
  padding: 0 32px;
  position: relative;
  max-height: calc(100% - 400px);
  .keys-list {
    align-items: center;
    position: relative;
    margin: 29px auto 29px;
    max-width: 1376px;
    height: 100%;

    background: #ffffff;
    box-shadow: -10px 9px 21px rgba(35, 35, 35, 0.07);
    border-radius: 10px;
    padding-top: 25px;
  }

  .keysListModal-enter {
    opacity: 0.01;
    transform: translateX(12%);
  }
  .keysListModal-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: 0.3s;
  }
  .keysListModal-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  .keysListModal-exit-active {
    opacity: 0.01;
    transform: translateX(12%);
    transition: 0.3s;
  }

  .secureVaultModal-enter {
    opacity: 0.01;
  }
  .secureVaultModal-enter-active {
    opacity: 1;
    transition: 0.15s;
  }
  .secureVaultModal-exit {
    opacity: 1;
  }
  .secureVaultModal-exit-active {
    opacity: 0.01;
    transition: 0.15s;
  }

  .keys-list--modal__buttons {
    margin-top: 15px;
    padding-top: 15px;
    width: 100%;

    display: flex;
    justify-content: center;
    border-top: 1px solid #e4e4e4;

    button {
      width: 138px;
      &.white {
        margin-right: 25px;
      }
    }
  }

  .bg-layer {
    background: #000;
    opacity: 0.6;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .keys-list--modal__item {
    margin: 0 auto 15px;
    max-width: 343px;

    .input-wrapper {
      display: flex;
      align-items: center;
      svg {
        margin-right: 12px;
        cursor: pointer;
        path {
          transition: 0.2s;
        }
        &:hover {
          path {
            fill: #8476e3;
          }
        }
      }
    }
  }
  .keys-list--modal__item-group {
    background: #f6f6f6;
    border-radius: 8px;
    padding: 5px 0 15px;
    margin: 0 auto 20px;
    width: 75%;
    h4 {
      font-weight: 600;
      font-size: 19px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.005em;

      color: #232323;

      margin: 25px 0 10px;
    }
  }

  .keys-list__header {
    display: flex;
    justify-content: space-between;
    margin: 0 30px 23px;
  }
  .keys-list__plain-info {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;

    color: #232323;
    display: flex;
    align-items: center;
  }
  .keys-list__websites-quantity {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    margin-left: 10px;
    padding: 3.5px 6px;
    background: #ededed;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }
  .keys-list__search-form {
    position: relative;
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #dddddd;

    border-radius: 4px;
    width: 100vw;
    max-width: 238px;

    svg {
      position: absolute;
      pointer-events: none;
      left: 10px;
    }
    input {
      padding-left: 36px;
      background: transparent;
      border: none;
      border-radius: 100px;
      width: calc(100% - 36px);
      height: 24px;
    }
  }
  .keys-list__filters {
    display: flex;
    align-items: center;
  }

  .keys-table__header {
    display: flex;
    align-items: center;

    background: #fff;
    border-bottom: 1px solid #e4e4e4;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.005em;

    color: #505356;
    div {
      padding: 13px 0 16px;
      text-align: center;
      &:first-child {
        width: 13.8%;
        padding-left: 30px;
        text-align: left;
      }
      &:nth-child(2) {
        width: 22.5%;
        text-align: left;
      }
      &:nth-child(3) {
        text-align: left;
        width: 19%;
      }
      &:nth-child(4) {
        text-align: left;
        width: 9.5%;
      }
    }
  }

  .keys-table__item {
    &.isNotVisible {
      display: none;
    }
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0px;
      top: -2px;
      height: 1px;
      width: 100%;
      border-bottom: 0.5px solid #dddddd;
    }
  }

  .trashcan {
    cursor: pointer;
    path {
      transition: 0.15s;
    }
    &:hover {
      path {
        fill: #8476e3;
      }
    }
  }

  .keys-table {
    width: calc(100% - 34px);
    height: 100%;
    border-collapse: collapse;

    .keys-table__body {
      display: block;
      height: 100%;
      max-height: calc(100vh - 253px);
      min-height: calc(100vh - 253px);
      overflow-y: auto;
    }

    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #232323;

    & tr {
      display: flex;
      align-items: center;
      height: 64px;

      td {
        text-align: center;
        &:first-child {
          width: 13.9%;
          padding-left: 30px;
          text-align: left;
        }
        &:nth-child(2) {
          width: 22.6%;
          text-align: left;
        }
        &:nth-child(3) {
          text-align: left;
          width: 18.9%;
        }
        &:nth-child(4) {
          text-align: left;
          width: 9.5%;
        }
      }
    }
  }
  .keys-table__copy-and-share {
    display: flex;
    align-items: center;

    .copy-and-share-button {
      &:first-child {
        margin-right: 12px;
      }
      &.keys-table__share-request-link {
      }
      position: relative;
      display: flex;
      align-items: center;
      padding: 4px 10px 4px 36px;
      background: #505356;
      border-radius: 4px;

      color: #ffffff;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      transition: 0.1s;
      &:hover {
        background: #848789;
      }
      .copy-and-share-icon {
        position: absolute;
        left: 11.5px;
      }
    }
  }
  .keys-table__show-secure-vault {
    position: relative;
    padding: 4px 32px 4px 10px;
    display: flex;
    align-items: center;
    color: #ffffff;
    background: #67baa7;
    border-radius: 4px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.1s;
    &:hover {
      background: #5eaa98;
    }
    svg {
      position: absolute;
      right: 10px;
    }
  }
  .keys-table__settings-dots {
    position: relative;
    margin: 0 30px 0 auto;
    cursor: pointer;
  }
  .keys-list__settings-modal {
    z-index: 2;
    position: absolute;
    top: 25px;
    right: -1px;
    padding: 6px 0;
    background: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ff6562;
    white-space: nowrap;
    border: 1px solid #dddddd;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    .delete-website,
    .delete-keys {
      padding: 4px 10px;
      &:hover {
        background: #eeeeee;
      }
    }
  }

  .keys-table--mobile {
    display: none;
  }
  .keys-table--mobile__item__client-name {
    position: relative;
  }
  .secure-vault-eye-mobile {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
  }

  .keys-table__skeleton {
    display: flex;
    align-items: center;
    height: 64px;
    .skeleton__client-name {
      text-align: left;
      width: calc(13% - 30px);
      padding-left: 30px;
      padding-right: 30px;
    }
    .skeleton__website-url {
      text-align: left;
      width: calc(18% - 30px);
      padding-right: 30px;
    }
    .copy-and-share-button {
      background: #dbdbdb;
      color: #fff;
    }
    .keys-table__show-secure-vault {
      background: #dbdbdb;
    }
    div {
      text-align: center;

      &:nth-child(3) {
        text-align: left;
        width: calc(17% - 30px);
        padding-right: 30px;
      }
      &:nth-child(4) {
        text-align: left;
        width: calc(10% - 30px);
        padding-right: 30px;
      }
    }

    .skeleton__reqeust-link-icon {
      svg {
        path {
          animation-name: keys-list-skeleton-color;
          animation-duration: 1.5s;
          animation-iteration-count: infinite;
        }
      }
    }
    .skeleton__secure-vault-icon {
      svg {
        path {
          animation-name: keys-list-skeleton-color;
          animation-duration: 1.5s;
          animation-iteration-count: infinite;
        }
      }
    }
    .skeleton__delete-icon {
      svg {
        path {
          animation-name: keys-list-skeleton-color;
          animation-duration: 1.5s;
          animation-iteration-count: infinite;
        }
      }
    }
  }

  .secure-vault-eye-mobile.skeleton {
    svg {
      path {
        animation-name: keys-list-skeleton-color;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
      }
    }
  }
  .copy-and-share-button.skeleton {
    animation-name: keys-list-skeleton-color-block;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;

    cursor: default;
  }
  .keys-table__show-secure-vault.skeleton {
    animation-name: keys-list-skeleton-color-block;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }
  .keys-table__settings-dots.skeleton {
    svg {
      path {
        animation-name: keys-list-skeleton-color-stroke;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
      }
    }

    cursor: default;
  }

  @keyframes keys-list-skeleton-color-block {
    0% {
      background: #ecebeb;
    }
    50% {
      background: #dbdbdb;
    }
    100% {
      background: #ecebeb;
    }
  }
  @keyframes keys-list-skeleton-color {
    0% {
      fill: #dbdbdb;
    }
    50% {
      fill: #ecebeb;
    }
    100% {
      fill: #dbdbdb;
    }
  }
  @keyframes keys-list-skeleton-color-stroke {
    0% {
      stroke: #ecebeb;
    }
    50% {
      stroke: #dbdbdb;
    }
    100% {
      stroke: #ecebeb;
    }
  }

  .keys-table--mobile__items--skeletons {
    display: flex;
  }
  .keys-table__skeleton--mobile {
    background: #fff;
    padding: 25px 15px;
    margin: 0 15px;
    max-width: 226px;

    display: flex;
    flex-direction: column;
    align-items: center;

    box-shadow: -10px 9px 21px rgba(35, 35, 35, 0.07);
    border-radius: 11px;
  }

  .keysListAnimation-appear {
    opacity: 0.01;
    transform: translateY(20%);
  }

  .keysListAnimation-appear.keysListAnimation-appear-active {
    opacity: 1;
    transform: translateY(0%);
    transition: 0.2s ease-in;
  }

  @media (max-height: 920px) {
    .keys-table {
      .keys-table__body {
        max-height: calc(100vh - 335px);
      }
    }
  }
  @media (max-width: 1000px) {
    .secure-vault-wrapper {
      margin-left: -250px;
    }
    .keys-list--modal {
      max-width: 445px;
      padding: 30px 30px;
    }
    .keys-list__search-form {
      max-height: 30px;
      padding-left: 30px;
      background: #fff;
      svg {
        padding-left: 0;
      }
      input {
        padding-left: 8px;
      }
    }
    .keys-list-wrapper {
      padding: 0;
      position: relative;
    }
    .keys-list {
      background: transparent;
      box-shadow: none;
    }
    .keys-table {
      display: none;
    }
    .keys-table--mobile {
      // padding: 0 10px;
      max-height: 552px;
      width: calc(100% - 18px);
      border-radius: 11px;
      display: block;
      padding-bottom: 36px;
      overflow-x: scroll;
    }
    .keys-table--mobile__body {
      display: flex;
      flex-direction: row;
    }
    .keys-table--mobile__item {
      background: #fff;
      padding: 25px 15px;
      margin: 0 15px;
      max-width: 226px;

      display: flex;
      flex-direction: column;
      align-items: center;

      box-shadow: -10px 9px 21px rgba(35, 35, 35, 0.07);
      border-radius: 11px;

      .keys-table--mobile__item__expires-in {
        .button {
          min-height: 43px;
        }
      }

      div {
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
      }

      h4 {
        margin-bottom: 14px;
        font-weight: bold;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: 0.005em;
        color: #5e5e5e;
        opacity: 0.8;
      }

      .button {
        width: 196px;
        white-space: nowrap;
      }
    }
  }
  @media (max-width: 600px) {
    .secure-vault-wrapper {
      margin-left: -195px;
    }
    .keys-list--modal {
      max-width: 360px;
      padding: 30px 15px;
    }
  }
  @media (max-width: 420px) {
    .secure-vault-wrapper {
      margin-left: -150px;
    }
    .keys-list--modal {
      max-width: 280px;
      padding: 30px 10px;
    }
  }
`;

function KeysList() {
  const dispatch = useDispatch();

  const [keysList, setKeysList] = useState([]);
  const [substring, setSubstring] = useState("");
  const [keysListIsLoading, setKeysListIsLoading] = useState(false);
  const [useEffectTrigger, setUseEffectTrigger] = useState(0);

  const [sortType, setSortType] = useState("");

  const showSecureVault = useSelector(
    ({ secureVaultState }) => secureVaultState.isVisible
  );
  const showDeleteModal = useSelector(
    ({ deleteKeysListItemModal }) => deleteKeysListItemModal.isVisible
  );
  const newForm = useSelector(({ formFromWebSocket }) => formFromWebSocket);

  React.useEffect(() => {
    if (newForm && newForm.length !== 0) {
      let formShownOnNotificationClick;
      const newFormId = newForm[0].find((prop) => prop.type === "formId").id;
      const keysListClone = JSON.parse(JSON.stringify(keysList));
      keysListClone.forEach((arrayOfKeys, index) => {
        arrayOfKeys.forEach((item) => {
          if (
            item.find(
              (innerProp) =>
                innerProp.type === "formId" && innerProp.id === newFormId
            )
          ) {
            arrayOfKeys.push(newForm[0]);
            formShownOnNotificationClick = arrayOfKeys;
          }
        });
      });
      setKeysList(keysListClone);
      if (Notification.permission === "granted") {
        const notification = new Notification("You've received new keys");
        notification.onclick = function (e) {
          e.preventDefault();
          window.open("https://cranky-hodgkin-37ba67.netlify.app/");
          window.onload = function () {
            dispatch(setSecureVaultOn(formShownOnNotificationClick));
          };
        };
      }
    }
  }, [newForm]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setKeysListIsLoading(true);
    fetch("https://engine.keysforweb.com/wp-json/bda/v1/key-requests", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${
          localStorage.getItem("token") || sessionStorage.getItem("token")
        }`,
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data.data);
        setKeysList(data.data);
        // sortKeysList("timeToExpireInc");
      })
      .finally(() => {
        setKeysListIsLoading(false);
      });
  }, [useEffectTrigger]);

  const onSearchForm = (e) => {
    setSubstring(e.target.value);
  };
  function sortKeysList(sortType) {
    let keysListClone = JSON.parse(JSON.stringify(keysList));
    const receivedItems = keysListClone.filter(
      (item) =>
        item[item.length - 1].find((prop) => prop.type === "isCompleted")
          .value === true
    );
    const unreceivedItems = keysListClone.filter(
      (item) =>
        item[item.length - 1].find((prop) => prop.type === "isCompleted")
          .value === false
    );
    switch (sortType) {
      case "receivedFirst":
        keysListClone = receivedItems.concat(unreceivedItems);
        setKeysListIsLoading(true);
        setKeysList([]);
        setTimeout(() => {
          setKeysList(keysListClone);
          setKeysListIsLoading(false);
        }, 300);

        return;
      case "unreceivedFirst":
        keysListClone = unreceivedItems.concat(receivedItems);
        setKeysListIsLoading(true);
        setKeysList([]);
        setTimeout(() => {
          setKeysList(keysListClone);
          setKeysListIsLoading(false);
        }, 300);
        return;
      case "timeToExpireInc":
        receivedItems.sort(function (a, b) {
          const timeToExpireA = Date.parse(
            a[a.length - 1].find((prop) => prop.type === "expirationDate").value
          );
          const timeToExpireB = Date.parse(
            b[b.length - 1].find((prop) => prop.type === "expirationDate").value
          );
          console.log(timeToExpireA, timeToExpireB);
          return timeToExpireA - timeToExpireB;
        });
        keysListClone = receivedItems.concat(unreceivedItems);
        setKeysListIsLoading(true);
        setKeysList([]);
        setTimeout(() => {
          setKeysList(keysListClone);
          setKeysListIsLoading(false);
        }, 300);
        // setKeysList([...KeysList, ...keysListClone]);
        return;
      case "timeToExpireDec":
        receivedItems.sort(function (a, b) {
          const timeToExpireA = Date.parse(
            a[a.length - 1].find((prop) => prop.type === "expirationDate").value
          );
          const timeToExpireB = Date.parse(
            b[b.length - 1].find((prop) => prop.type === "expirationDate").value
          );
          return timeToExpireB - timeToExpireA;
        });
        keysListClone = receivedItems.concat(unreceivedItems);
        setKeysListIsLoading(true);
        setKeysList([]);
        setTimeout(() => {
          setKeysList(keysListClone);
          setKeysListIsLoading(false);
        }, 300);
        return;
      default:
        return;
    }
  }

  React.useEffect(() => {
    sortKeysList(sortType);
  }, [sortType]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledKeysList>
      <div className="keys-list">
        <div className="keys-list__header">
          <div className="keys-list__plain-info">
            All Websites{" "}
            <div className="keys-list__websites-quantity">
              {keysList.length}
            </div>
          </div>
          <div className="keys-list__filters">
            {" "}
            <div className="keys-list__search-form">
              <input
                onChange={(e) => onSearchForm(e)}
                value={substring}
                type="text"
                placeholder="Search website..."
                results="0"
              />
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.5 11H11.71L11.43 10.73C12.63 9.33002 13.25 7.42001 12.91 5.39002C12.44 2.61002 10.12 0.390015 7.32002 0.0500152C3.09002 -0.469985 -0.469985 3.09002 0.0500152 7.32002C0.390015 10.12 2.61002 12.44 5.39002 12.91C7.42001 13.25 9.33002 12.63 10.73 11.43L11 11.71V12.5L15.25 16.75C15.66 17.16 16.33 17.16 16.74 16.75C17.15 16.34 17.15 15.67 16.74 15.26L12.5 11ZM6.50002 11C4.01002 11 2.00002 8.99002 2.00002 6.50002C2.00002 4.01002 4.01002 2.00002 6.50002 2.00002C8.99002 2.00002 11 4.01002 11 6.50002C11 8.99002 8.99002 11 6.50002 11Z"
                  fill="#505356"
                />
              </svg>
            </div>
            <SortByModal setSortType={setSortType} />
          </div>
        </div>

        <div className="keys-table">
          {keysList.length !== 0 && (
            <div className="keys-table__header">
              <div>Client</div>
              <div>Website URL</div>
              <div>Request URL</div>
              <div>Expiration</div>
              <div>Keys</div>
            </div>
          )}
          <div className="keys-table__body">
            {keysListIsLoading && <KeysListItemSkeleton />}
            {!keysListIsLoading &&
              keysList &&
              keysList.length !== 0 &&
              keysList.map((localState, index) => {
                return (
                  <KeysListItem
                    key={index}
                    localState={localState}
                    substring={substring}
                    setUseEffectTrigger={setUseEffectTrigger}
                    newForm={newForm}
                  />
                );
              })}
            {keysList.length === 0 && <KeysListPlaceholder />}
          </div>
        </div>

        <div className="keys-table--mobile">
          <div className="keys-table--mobile__body">
            {keysListIsLoading && <KeysListItemMobileSkeleton />}
            {!keysListIsLoading &&
              keysList &&
              keysList.length !== 0 &&
              keysList.map((localState, index) => {
                return (
                  <KeysListItemMobile
                    key={index}
                    localState={localState}
                    substring={substring}
                    setUseEffectTrigger={setUseEffectTrigger}
                  />
                );
              })}
          </div>
        </div>

        <CSSTransition
          classNames="secureVaultModal"
          in={showSecureVault}
          timeout={150}
          unmountOnExit
        >
          <SecureVault setUseEffectTrigger={setUseEffectTrigger} />
        </CSSTransition>
        <CSSTransition
          classNames="secureVaultModal"
          in={showDeleteModal}
          timeout={150}
          unmountOnExit
        >
          <DeleteModal
            type={"keysRequest"}
            setUseEffectTrigger={setUseEffectTrigger}
          />
        </CSSTransition>
      </div>
    </StyledKeysList>
  );
}

export default KeysList;

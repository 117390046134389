const initialState = {
  formInJSON: [],
  requestBy: {},
};

const requestLinkState = (state = initialState, action) => {
  const newState = state;
  switch (action.type) {
    case "SET_REQUEST_LINK_STATE":
      return {
        ...state,
        formInJSON: action.formInJSON,
        requestBy: action.user,
      };
    case "SET_REQUEST_LINK_FORM":
      return {
        ...state,
        formInJSON: action.payload,
      };
    case "RESET_REQUEST_LINK_VALUES":
      newState.formInJSON.forEach((item) => {
        if (item.type === "field") {
          if (item.serviceTag === "ftpConnectionType") {
            item.value = "FTP";
            return;
          }
          item.value = "";
        }
        if (item.type === "fieldgroup") {
          item.innerFields.forEach((innerItem) => {
            innerItem.value = "";
          });
        }
      });
      return { ...newState };
    default:
      return { ...state };
  }
};

export default requestLinkState;

const initialState = {
  subscriptionType: "standard",
};

const subscriptionType = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SUBSCRIPTION_TYPE":
      console.log("exec");
      return {
        ...state,
        subscriptionType: action.payload,
      };
    default:
      return { ...state };
  }
};

export default subscriptionType;

import React, { useRef } from "react";
import useNotification from "../../../../hooks/useNotification";

function KeysListModalItem({ item }) {
  const ref = useRef();
  const showNotification = useNotification();

  const copyValueToClipboard = () => {
    ref.current.select();
    document.execCommand("copy");
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(document.createRange());
    showNotification(
      "success",
      "Copied",
      "Value was copied to clipboard",
      1500
    );
  };

  return (
    <div className="keys-list--modal__item">
      <label htmlFor="">{item.title}</label>
      <div className="input-wrapper">
        <input ref={ref} value={item.value} type="text" readOnly />
        {/*<svg*/}
        {/*  onClick={() => copyValueToClipboard()}*/}
        {/*  width="48"*/}
        {/*  height="40"*/}
        {/*  viewBox="0 0 48 40"*/}
        {/*  fill="none"*/}
        {/*  xmlns="http://www.w3.org/2000/svg">*/}
        {/*  <path*/}
        {/*    d="M0 0H40C44.4183 0 48 3.58172 48 8V32C48 36.4183 44.4183 40 40 40H0V0Z"*/}
        {/*    fill="#8B8F9A"*/}
        {/*  />*/}
        {/*  <path*/}
        {/*    d="M30.6667 17.5H23.1667C22.2462 17.5 21.5 18.2462 21.5 19.1667V26.6667C21.5 27.5871 22.2462 28.3333 23.1667 28.3333H30.6667C31.5871 28.3333 32.3333 27.5871 32.3333 26.6667V19.1667C32.3333 18.2462 31.5871 17.5 30.6667 17.5Z"*/}
        {/*    stroke="white"*/}
        {/*    strokeWidth="1.66667"*/}
        {/*    strokeLinecap="round"*/}
        {/*    strokeLinejoin="round"*/}
        {/*  />*/}
        {/*  <path*/}
        {/*    d="M18.1666 22.5H17.3333C16.8913 22.5 16.4673 22.3244 16.1548 22.0118C15.8422 21.6993 15.6666 21.2754 15.6666 20.8333V13.3333C15.6666 12.8913 15.8422 12.4674 16.1548 12.1548C16.4673 11.8423 16.8913 11.6667 17.3333 11.6667H24.8333C25.2753 11.6667 25.6992 11.8423 26.0118 12.1548C26.3244 12.4674 26.5 12.8913 26.5 13.3333V14.1667"*/}
        {/*    stroke="white"*/}
        {/*    strokeWidth="1.66667"*/}
        {/*    strokeLinecap="round"*/}
        {/*    strokeLinejoin="round"*/}
        {/*  />*/}
        {/*</svg>*/}
        <svg
          onClick={() => copyValueToClipboard()}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.9998 19.9988H2.00093C1.46507 20.0175 0.945532 19.8127 0.566391 19.4336C0.18725 19.0545 -0.0174533 18.5349 0.0011681 17.9991V8.00023C-0.0174533 7.46437 0.18725 6.94483 0.566391 6.56569C0.945532 6.18655 1.46507 5.98185 2.00093 6.00047H6.00047V2.00093C5.98185 1.46507 6.18655 0.945532 6.56569 0.566391C6.94483 0.18725 7.46437 -0.0174533 8.00023 0.0011681H17.9991C18.5349 -0.0174533 19.0545 0.18725 19.4336 0.566391C19.8127 0.945532 20.0175 1.46507 19.9988 2.00093V11.9998C20.0172 12.5355 19.8124 13.0549 19.4333 13.434C19.0542 13.8131 18.5348 14.0179 17.9991 13.9995H13.9995V17.9991C14.0179 18.5348 13.8131 19.0542 13.434 19.4333C13.0549 19.8124 12.5355 20.0172 11.9998 19.9988ZM2.00093 8.00023V17.9991H11.9998V13.9995H8.00023C7.46446 14.0179 6.94509 13.8131 6.56602 13.434C6.18695 13.0549 5.98215 12.5355 6.00047 11.9998V8.00023H2.00093ZM8.00023 2.00093V11.9998H17.9991V2.00093H8.00023Z"
            fill="#8B8F9A"
          />
        </svg>
      </div>
    </div>
  );
}

export default KeysListModalItem;

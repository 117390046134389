export const tooltipWpAdminContentBlocks = [
  {
    title: "WP-admin URL:",
    text: "The link to your admin area (e.g., http://www.acme.com/wp-admin).",
  },
  {
    title: "Login (Username or Email):",
    text: "The login for your admin account for the website in question.",
  },
  {
    title: "Password:",
    text: "The password for your admin account for the website in question.",
  },
];
export const tooltipFtpKeysContentBlocks = [
  {
    title: "Connection type:",
    text: "Select the type of connection (FTP or SFTP) you use.",
  },
  {
    title: "Port (optional):",
    text: "If you have a specific port name other than the default, provide it here.",
  },
  {
    title: "Hostname:",
    text: "The link to your hosting admin dashboard (e.g., http://www.ftp.acme.com)",
  },
  {
    title: "Login:",
    text: "The login for your FTP account for the website in question.",
  },
  {
    title: "Password:",
    text: "The password for your FTP account for the website in question.",
  },
];
export const tooltipBluehostContentBlocks = [
  {
    title: "EMAIL or DOMAIN NAME",
    text: "The email you used for registering on Bluehost or the domain name of your site hosted on Bluehost.",
  },
  {
    title: "PASSWORD",
    text: "The password you use to access the My Bluehost admin dashboard.",
  },
];
export const tooltipWebflowContentBlocks = [
  {
    title: "EMAIL or DOMAIN NAME",
    text: "The email you used for registering on Webflow or the username you created for your account there.",
  },
  {
    title: "PASSWORD",
    text: "The password you use to access the Webflow admin dashboard.",
  },
];
export const tooltipFlywheelContentBlocks = [
  {
    title: "EMAIL or DOMAIN NAME",
    text: "The email you used for registering on Flywheel admin account or the email you used for registering there",
  },
  {
    title: "PASSWORD",
    text: "The password you use to access the Flywheel admin dashboard.",
  },
];
export const tooltipJimdoContentBlocks = [
  {
    title: "EMAIL or DOMAIN NAME",
    text: "The email you used for registering on Jimdo.",
  },
  {
    title: "PASSWORD",
    text: "The password you use to access the Jimdo admin dashboard.",
  },
];
export const tooltipNetlifyContentBlocks = [
  {
    title: "EMAIL or DOMAIN NAME",
    text: "The email you used for registering on Netlify.",
  },
  {
    title: "PASSWORD",
    text: "The password you use to access the Netlify admin dashboard.",
  },
];
export const tooltipJoomlaContentBlocks = [
  {
    title: "JOOMLA LOGIN URL",
    text: "The link you use to access your Joomla dashboard (by default, it’s https://example.com/administrator/, where example.com is your site domain name).",
  },
  {
    title: "USERNAME",
    text: "The username you created for your Joomla admin account.",
  },
  {
    title: "PASSWORD",
    text: "The password you use to access the Joomla admin dashboard.",
  },
];
export const tooltipDrupalContentBlocks = [
  {
    title: "DRUPAL LOGIN URL",
    text: "the link you use to access your Drupal dashboard (by default, it’s https://example.com/user/login, where example.com is your site domain name).",
  },
  {
    title: "USERNAME",
    text: "The username you created for your Drupal admin account.",
  },
  {
    title: "PASSWORD",
    text: "The password you use to access the Drupal admin dashboard.",
  },
];

export const generateRequestTooltipContent = [
  {
    title: "",
    text: "Add fields or use pre-built templates first to generate your request.",
  },
];

export const generateRequestLinkCopy = [
  {
    title: "",
    text: "Copy Request Link",
  },
];
export const generateRequestLinkCopied = [
  {
    title: "",
    text: "Request copied!",
  },
];

export const generateRequestShareByEmailTooltipContent = [
  {
    title: "",
    text: "Enter the email address of the user to whom you want to send the request link.",
  },
];

import React, { useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import useNotification from "../hooks/useNotification";

import { generateRequestTooltipContent } from "../assets/tooltipBlocks";
import { resetForm } from "../redux/actions/setFormItems";

import {
  Button,
  MainArea,
  GeneratorHeader,
  GeneratorContentHeader,
  LeaveGeneratorModal,
  Tooltip,
  ResetDefaultModal,
  TemplateDeleteModal,
} from "../components";

import { Prompt } from "react-router-dom";
import RequestGeneratedModal from "../components/Generator/MainArea/RequestGeneratedModal";

const StyledGenerator = styled.div`
  width: 100%;
  margin: 30px auto 20px;
  max-width: 776px;
  h2 {
    padding: 0 0 22px;
    font-weight: 500;
    font-size: 25px;
    line-height: 31px;
    color: #232323;
  }
  .generator__header {
    margin: 0 10px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .generator__static-info {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .generator__content-wrapper {
    margin: 0 10px;
    background: #ffffff;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
  }
  .generator__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    min-height: 225px;
    height: 100vh;
    max-height: calc(100vh - 270px);
  }
  .generator__scroll-area {
    width: 98%;
    height: 100%;
    overflow-y: auto;
  }
  .generator__footer {
    width: 100%;
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(35, 35, 35, 0.08);
    padding: 20px 0;

    .button {
      display: flex;
      align-items: center;
      padding: 10px 35px;
      max-height: 40px;
      width: 100%;
      max-width: 209px;
      &:active {
        background: #342a76;
      }
    }
  }
  .request-link {
    position: relative;
    margin: 0 0 0 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 229px;
    label {
      font-weight: bold;
      font-size: 10px;
      line-height: 16px;

      text-transform: uppercase;

      color: #5e5e5e;
    }
    .input-wrapper {
      display: flex;
      align-items: center;
      width: 100%;

      border: 1px solid #dddddd;
      border-radius: 9px;

      transition: 0.3s;
      .share-request-link-icon {
        margin: 0 12px;
      }
      svg {
        margin-left: auto;
        cursor: pointer;
        path {
          transition: 0.2s;
        }
        &:hover {
          path {
            fill: #8476e3;
          }
        }
      }
      input {
        padding-left: 15px;
        width: calc(100% - 70px);
        border-radius: 9px 0 0 9px;
        height: 35px;
        border: none;
        outline: none;
      }

      &.goodGreen {
        border: 1px solid #5bc044;
      }
    }
  }
  .button-event-wrapper {
    position: relative;
    border-radius: 44px;
  }
`;

function GenerateRequest({ globalId, incrementGlobaldId }) {
  const dispatch = useDispatch();
  const formGenerator = useSelector(({ formGenerator }) => formGenerator);
  const currentUser = useSelector(
    ({ currentUserReducer }) => currentUserReducer.currentUser
  );
  const upgradeState = useSelector(
    ({ upgradeModalState }) => upgradeModalState.visibility
  );

  const showNotification = useNotification();

  const [templatesList, setTemplatesList] = useState(null);
  const [clientHandleBadRed, setClientHandleBadRed] = useState(false);
  const [websiteUrlBadRed, setWebsiteUrlBadRed] = useState(false);
  const [buttonsBadRed, setButtonsBadRed] = useState(false);
  const [requestGeneratedModalVisibility, setRequestGeneratedModalVisibility] =
    useState(false);

  const [showTemplateDeleteModal, setShowTemplateDeleteModal] = useState(false);
  const [templateDeleteId, setTemplateDeleteId] = useState(null);

  const [resetDefaultModalVisibility, setResetDefaultModalVisibility] =
    useState(false);
  const [showLeaveGeneratorModal, setShowLeaveGeneratorModal] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);

  const [tooltipVisibility, setTooltipVisibility] = useState("");

  const [showSaveTemplate, setShowSaveTemplate] = useState(false);
  const [templatesListReloadTrigger, setTemplatesListReloadTrigger] =
    useState(0);
  const [linkId, setLinkId] = useState("");

  const showSaveTemplateModal = () => {
    let formFieldsCreated = true;
    let formIsFilled = true;

    if (
      !formGenerator.find(
        (item) => item.type === "field" || item.type === "fieldgroup"
      )
    ) {
      formFieldsCreated = false;
    }

    formGenerator.forEach((item) => {
      switch (item.type) {
        case "field":
          if (item.title === "") {
            formIsFilled = false;
            return formIsFilled;
          }
          return formIsFilled;
        case "fieldgroup":
          item.innerFields.forEach((innerItem) => {
            if (innerItem.title === "") {
              formIsFilled = false;
              return formIsFilled;
            }
          });
          return formIsFilled;
        default:
          return;
      }
    });

    if (formIsFilled && formFieldsCreated) {
      setShowSaveTemplate(true);
    } else {
      showNotification("error", "Error", "Some fields are uncompleted", 1500);

      if (!formFieldsCreated) {
        setButtonsBadRed(true);
        setTimeout(() => {
          setButtonsBadRed(false);
        }, 1500);
        showNotification(
          "error",
          "Error",
          "You have not created any fields",
          1500
        );
      }
    }
  };

  const sortFields = (a, b) => {
    if (a.order > b.order) {
      return 1;
    } else {
      return -1;
    }
  };

  const saveState = (e) => {
    e.preventDefault();

    if (currentUser.isSubscriptionValid) {
      const clientUniqueHandle = formGenerator.find(
        (item) => item.type === "clientUniqueHandle"
      ).value;
      const websiteUrl = formGenerator.find(
        (item) => item.type === "websiteUrl"
      ).value;

      let formFieldsCreated = true;
      let formIsFilled = true;

      if (formGenerator.length === 5) {
        formFieldsCreated = false;
      }

      formGenerator.forEach((item) => {
        switch (item.type) {
          case "field":
            if (item.title === "") {
              formIsFilled = false;
              return formIsFilled;
            }
            return formIsFilled;
          case "fieldgroup":
            item.innerFields.forEach((innerItem) => {
              if (innerItem.title === "") {
                formIsFilled = false;
                return formIsFilled;
              }
            });
            return formIsFilled;
          default:
            return;
        }
      });

      let formClone = JSON.parse(JSON.stringify(formGenerator));
      if (!formClone.find((item) => item.type === "premadeKfwTemplate")) {
        const formHeaders = formClone.filter(
          (item) => item.type !== "field" && item.type !== "fieldgroup"
        );
        const sortedFields = formClone
          .filter((item) => item.type === "field" || item.type === "fieldgroup")
          .sort(sortFields);
        formClone = formHeaders.concat(sortedFields);
      }

      if (
        clientUniqueHandle !== "" &&
        websiteUrl !== "" &&
        formIsFilled &&
        formFieldsCreated
      ) {
        fetch("https://engine.keysforweb.com/wp-json/bda/v1/key-requests", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${
              localStorage.getItem("token") || sessionStorage.getItem("token")
            }`,
          },
          body: JSON.stringify({
            clientUniqueHandle: clientUniqueHandle,
            formInJSON: formClone,
          }),
        })
          .then((resp) => resp.json())
          .then((data) => {
            console.log(data, "post keys request");
            setLinkId(data.requestId);
          });
        setRequestGeneratedModalVisibility(true);
      } else {
        showNotification("error", "Error", "Some fields are uncompleted", 2000);
        if (clientUniqueHandle === "") {
          setClientHandleBadRed(true);
          setTimeout(() => {
            setClientHandleBadRed(false);
          }, 1500);
        }

        if (websiteUrl === "") {
          setWebsiteUrlBadRed(true);
          setTimeout(() => {
            setWebsiteUrlBadRed(false);
          }, 1500);
        }

        if (!formFieldsCreated) {
          setButtonsBadRed(true);
          setTimeout(() => {
            setButtonsBadRed(false);
          }, 1500);
          showNotification(
            "error",
            "Error",
            "You have not created any fields",
            2000
          );
        }
      }
    } else {
      showNotification(
        "error",
        "Your subscription is expired",
        "Please resubscribe",
        6000
      );
    }
  };

  // GET TEMPLATES
  React.useEffect(() => {
    fetch("https://engine.keysforweb.com/wp-json/bda/v1/request-templates", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") || sessionStorage.getItem("token")
        }`,
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data, "templates");
        setTemplatesList(data.data);
      });
  }, [templatesListReloadTrigger]);

  React.useEffect(() => {
    if (!currentUser.isSubscriptionValid) {
      showNotification("error", "Account is restricted", 2000);
    }

    return function clearForms() {
      dispatch(resetForm());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const unloadCallback = (event) => {
      if (formGenerator.length > 5) {
        event.preventDefault();
        event.returnValue = "Your created fields will be deleted.";
        return "Your created fields will be deleted.";
      }
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledGenerator>
      <GeneratorHeader
        clientHandleBadRed={clientHandleBadRed}
        websiteUrlBadRed={websiteUrlBadRed}
        formGenerator={formGenerator}
      />

      <div className="generator__content-wrapper">
        <div className="generator__content">
          <div className="generator__scroll-area">
            <GeneratorContentHeader
              templatesList={templatesList}
              setTemplatesListReloadTrigger={setTemplatesListReloadTrigger}
              templatesListReloadTrigger={templatesListReloadTrigger}
              setResetDefaultModalVisibility={setResetDefaultModalVisibility}
              setShowTemplateDeleteModal={setShowTemplateDeleteModal}
              setTemplateDeleteId={setTemplateDeleteId}
            />

            <MainArea
              formGenerator={formGenerator}
              globalId={globalId}
              incrementGlobaldId={incrementGlobaldId}
              buttonsBadRed={buttonsBadRed}
              showSaveTemplateModal={showSaveTemplateModal}
              showSaveTemplate={showSaveTemplate}
              setShowSaveTemplate={setShowSaveTemplate}
              setTemplatesListReloadTrigger={setTemplatesListReloadTrigger}
            />
            <RequestGeneratedModal
              requestGeneratedModalVisibility={requestGeneratedModalVisibility}
              setRequestGeneratedModalVisibility={
                setRequestGeneratedModalVisibility
              }
              requestLinkUrl={linkId && `kfw.pw/r/${linkId}`}
              linkId={linkId && linkId}
              clientUniqueHandle={
                formGenerator.find((item) => item.type === "clientUniqueHandle")
                  .value
              }
            />
          </div>
          <div className="generator__footer">
            <div
              onMouseOver={() => {
                if (formGenerator.length < 6) {
                  setTooltipVisibility("generateRequestTooltip");
                }
              }}
              onMouseOut={() => setTooltipVisibility("")}
              className="button-event-wrapper"
            >
              {" "}
              <Button
                onClick={(e) => saveState(e)}
                color={"purple"}
                text={"Generate request"}
                isActive={
                  formGenerator.find((item) => item.type === "field") ||
                  formGenerator.find((item) => item.type === "fieldgroup")
                    ? true
                    : false
                }
              />
              <Tooltip
                specialClass={"generateRequestTooltip"}
                tooltipVisibility={tooltipVisibility}
                contentBlocks={generateRequestTooltipContent}
              />
            </div>
          </div>
        </div>
      </div>

      <ResetDefaultModal
        resetDefaultModalVisibility={resetDefaultModalVisibility}
        setResetDefaultModalVisibility={setResetDefaultModalVisibility}
      />

      <Prompt
        when={!showLeaveGeneratorModal && formGenerator.length > 5}
        message={(location) => {
          if (!upgradeState) {
            setNextLocation(location.pathname);
            setShowLeaveGeneratorModal(true);
            return false;
          }
        }}
      />
      <LeaveGeneratorModal
        showLeaveGeneratorModal={showLeaveGeneratorModal}
        setShowLeaveGeneratorModal={setShowLeaveGeneratorModal}
        nextLocation={nextLocation}
      />
      <TemplateDeleteModal
        templateId={templateDeleteId}
        showTemplateDeleteModal={showTemplateDeleteModal}
        setShowTemplateDeleteModal={setShowTemplateDeleteModal}
        setTemplatesListReloadTrigger={setTemplatesListReloadTrigger}
      />
    </StyledGenerator>
  );
}

export default GenerateRequest;

import React, { useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import useNotification from '../../hooks/useNotification';

import Button from '../Button';
import TeamListSettingsModal from './TeamListSettingsModal';

const StyledPendingMember = styled.div`
  /* width: 100%; */
  display: flex;
  align-items: center;
  padding: 12px 30px;
  border-bottom: 1px solid #f1f1f1;
  .team-member__name {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #232323;
    .team-member__image {
      margin-right: 10px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #f6f6f6;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .team-member__email {
    margin-left: 10px;
    width: 20%;
  }
  .pending-member__status {
    padding: 4px 10px;
    background: #fff8f0;
    border-radius: 50px;
    color: #ffc04e;
  }
  .button {
    margin-left: auto;
  }

  .team-table__settings-dots {
    position: relative;
    margin: 0 30px 0 34px;
    cursor: pointer;
  }
`;

const PendingMember = ({ email, setUseEffectTrigger }) => {
  const [showTeamListSettingsModal, setShowTeamListSettingsModal] = useState(false);
  const memberSettingsRef = useRef(null);

  const showNotification = useNotification();

  const fetchInvite = () => {
    fetch('https://engine.keysforweb.com/wp-json/bda/v1/invite-user', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token') || sessionStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        email: email,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        showNotification('success', 'Invite sent', 'Member of your team has been invited', 1500);
      })
      .finally(() => {
        setUseEffectTrigger((prev) => prev + 1);
      });
  };

  return (
    <StyledPendingMember>
      <div className="team-member__name">
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="40" rx="20" fill="#FFF8F0" />
          <path
            d="M20 30C14.4772 30 10 25.5228 10 20C10 14.4772 14.4772 10 20 10C25.5228 10 30 14.4772 30 20C29.9939 25.5203 25.5203 29.9939 20 30ZM20 12C15.5817 12 12 15.5817 12 20C12 24.4183 15.5817 28 20 28C24.4183 28 28 24.4183 28 20C27.995 15.5838 24.4162 12.005 20 12ZM25 21H19V15H21V19H25V21Z"
            fill="#FFC04E"
          />
        </svg>
      </div>
      <div className="team-member__email"> {email} </div>
      <div className="pending-member__status">Pending</div>

      <Button onClick={() => fetchInvite()} color={'white'} text={'Resend invite'} />

      <div
        onClick={(e) => {
          e.stopPropagation();
          setShowTeamListSettingsModal(true);
        }}
        className="team-table__settings-dots">
        <svg
          width="16"
          height="4"
          viewBox="0 0 16 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14 4C12.8954 4 12 3.10457 12 2C12 0.89543 12.8954 0 14 0C15.1046 0 16 0.89543 16 2C16 2.53043 15.7893 3.03914 15.4142 3.41421C15.0391 3.78929 14.5304 4 14 4ZM8 4C6.89543 4 6 3.10457 6 2C6 0.89543 6.89543 0 8 0C9.10457 0 10 0.89543 10 2C10 2.53043 9.78929 3.03914 9.41421 3.41421C9.03914 3.78929 8.53043 4 8 4ZM2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4Z"
            fill="#81858B"
          />
        </svg>

        <CSSTransition
          classNames="memberSettingsModal"
          in={showTeamListSettingsModal}
          timeout={300}
          onExited={() => console.log('exited')}
          unmountOnExit>
          <TeamListSettingsModal
            ref={memberSettingsRef}
            setShowTeamListSettingsModal={setShowTeamListSettingsModal}
            text={'Delete team member'}
            memberEmail={email}
          />
        </CSSTransition>
      </div>
    </StyledPendingMember>
  );
};

export default PendingMember;

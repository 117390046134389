import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import useNotification from "../../hooks/useNotification";

import Button from "../Button";

const StyledResetPassword = styled.div``;

function ResetPassword({ handleEmailSent }) {
  const showNotification = useNotification();
  const [email, setEmail] = useState("");
  const handleSendInstructions = () => {
    if (email !== "") {
      fetch(
        "https://engine.keysforweb.com/wp-json/bda/v1/reset-password-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
          }),
        }
      )
        .then((resp) => resp.json())
        .then((data) => {
          console.log(data, "reset password from login");
          if (data.status === "success") {
            showNotification(
              "success",
              "Password has been changed",
              "Type your new password to log in",
              2500
            );
            handleEmailSent(email);
          } else if (data.code === "expired_key") {
            showNotification(
              "error",
              "Password can't be changed",
              "Link you're using is expired",
              2500
            );
          } else if (data.code === "rest_invalid_param") {
            showNotification(
              "error",
              "Wrong email",
              "Please, check your email once again",
              2500
            );
          } else if (data.code === "user_not_found") {
            showNotification(
              "error",
              "Wrong email",
              "User with such email doesn't exist",
              2500
            );
          }
        });
    } else {
      handleEmailSent(email);
    }
  };

  const notificationShown = useSelector(
    ({ notificationState }) => notificationState.visible
  );

  return (
    <div className="login__actions type--resetpass ">
      <div className="login__forms">
        <h2>Reset password</h2>
        <h3>
          Enter the email associated with your account, and we’ll send you the
          password reset instructions.
        </h3>

        <label htmlFor="Email Address">Email address</label>
        <div
          className={`input-wrapper ${notificationShown ? "loginBadRed" : ""}`}
        >
          <input
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSendInstructions();
              }
            }}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            type="text"
          />
        </div>

        <Button
          onClick={() => handleSendInstructions()}
          color={"purple"}
          text={"Send instructions"}
        />
      </div>
    </div>
  );
}

export default ResetPassword;

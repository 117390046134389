import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import Button from '../../Button';

const KeysListItemMobileSkeleton = () => {
  return (
    <div className="keys-table--mobile__items--skeletons">
      <div className={`keys-table--mobile__item `}>
        <div className="keys-table--mobile__item__client-name">
          <svg
            className="secure-vault-eye-mobile skeleton"
            width="22"
            height="16"
            viewBox="0 0 22 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11 0.833374C6 0.833374 1.73 3.81379 0 8.02087C1.73 12.228 6 15.2084 11 15.2084C16 15.2084 20.27 12.228 22 8.02087C20.27 3.81379 16 0.833374 11 0.833374ZM11 12.8125C8.24 12.8125 6 10.6659 6 8.02087C6 5.37587 8.24 3.22921 11 3.22921C13.76 3.22921 16 5.37587 16 8.02087C16 10.6659 13.76 12.8125 11 12.8125ZM11 5.14587C9.34 5.14587 8 6.43004 8 8.02087C8 9.61171 9.34 10.8959 11 10.8959C12.66 10.8959 14 9.61171 14 8.02087C14 6.43004 12.66 5.14587 11 5.14587Z"
              fill="#ecebeb"
            />
          </svg>
          <h4>
            <Skeleton variant="text" width={95} />
          </h4>

          <Button color={'skeletonButton'} />
        </div>
        <div className="keys-table--mobile__item__request-link">
          <h4>
            <Skeleton variant="text" width={95} />
          </h4>
          <Button color={'skeletonButton'} />
        </div>
        <div className="keys-table--mobile__item__expires-in">
          <h4>
            <Skeleton variant="text" width={95} />
          </h4>
          <Button color={'skeletonButton'} />
        </div>
        <div className="keys-table--mobile__item__website-url">
          <h4>
            <Skeleton variant="text" width={95} />
          </h4>
          <Button color={'skeletonButton'} />
        </div>
        <div className="keys-table--mobile__item__delete-request">
          <h4>
            <Skeleton variant="text" width={95} />
          </h4>
          <Button color={'skeletonButton'} />
        </div>
      </div>
      <div className={`keys-table--mobile__item `}>
        <div className="keys-table--mobile__item__client-name">
          <svg
            className="secure-vault-eye-mobile skeleton"
            width="22"
            height="16"
            viewBox="0 0 22 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11 0.833374C6 0.833374 1.73 3.81379 0 8.02087C1.73 12.228 6 15.2084 11 15.2084C16 15.2084 20.27 12.228 22 8.02087C20.27 3.81379 16 0.833374 11 0.833374ZM11 12.8125C8.24 12.8125 6 10.6659 6 8.02087C6 5.37587 8.24 3.22921 11 3.22921C13.76 3.22921 16 5.37587 16 8.02087C16 10.6659 13.76 12.8125 11 12.8125ZM11 5.14587C9.34 5.14587 8 6.43004 8 8.02087C8 9.61171 9.34 10.8959 11 10.8959C12.66 10.8959 14 9.61171 14 8.02087C14 6.43004 12.66 5.14587 11 5.14587Z"
              fill="#ecebeb"
            />
          </svg>
          <h4>
            <Skeleton variant="text" width={95} />
          </h4>

          <Button color={'skeletonButton'} />
        </div>
        <div className="keys-table--mobile__item__request-link">
          <h4>
            <Skeleton variant="text" width={95} />
          </h4>
          <Button color={'skeletonButton'} />
        </div>
        <div className="keys-table--mobile__item__expires-in">
          <h4>
            <Skeleton variant="text" width={95} />
          </h4>
          <Button color={'skeletonButton'} />
        </div>
        <div className="keys-table--mobile__item__website-url">
          <h4>
            <Skeleton variant="text" width={95} />
          </h4>
          <Button color={'skeletonButton'} />
        </div>
        <div className="keys-table--mobile__item__delete-request">
          <h4>
            <Skeleton variant="text" width={95} />
          </h4>
          <Button color={'skeletonButton'} />
        </div>
      </div>
      <div className={`keys-table--mobile__item `}>
        <div className="keys-table--mobile__item__client-name">
          <svg
            className="secure-vault-eye-mobile skeleton"
            width="22"
            height="16"
            viewBox="0 0 22 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11 0.833374C6 0.833374 1.73 3.81379 0 8.02087C1.73 12.228 6 15.2084 11 15.2084C16 15.2084 20.27 12.228 22 8.02087C20.27 3.81379 16 0.833374 11 0.833374ZM11 12.8125C8.24 12.8125 6 10.6659 6 8.02087C6 5.37587 8.24 3.22921 11 3.22921C13.76 3.22921 16 5.37587 16 8.02087C16 10.6659 13.76 12.8125 11 12.8125ZM11 5.14587C9.34 5.14587 8 6.43004 8 8.02087C8 9.61171 9.34 10.8959 11 10.8959C12.66 10.8959 14 9.61171 14 8.02087C14 6.43004 12.66 5.14587 11 5.14587Z"
              fill="#ecebeb"
            />
          </svg>
          <h4>
            <Skeleton variant="text" width={95} />
          </h4>

          <Button color={'skeletonButton'} />
        </div>
        <div className="keys-table--mobile__item__request-link">
          <h4>
            <Skeleton variant="text" width={95} />
          </h4>
          <Button color={'skeletonButton'} />
        </div>
        <div className="keys-table--mobile__item__expires-in">
          <h4>
            <Skeleton variant="text" width={95} />
          </h4>
          <Button color={'skeletonButton'} />
        </div>
        <div className="keys-table--mobile__item__website-url">
          <h4>
            <Skeleton variant="text" width={95} />
          </h4>
          <Button color={'skeletonButton'} />
        </div>
        <div className="keys-table--mobile__item__delete-request">
          <h4>
            <Skeleton variant="text" width={95} />
          </h4>
          <Button color={'skeletonButton'} />
        </div>
      </div>
    </div>
  );
};

export default KeysListItemMobileSkeleton;

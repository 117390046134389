import React from "react";
import styled from "styled-components";

import {
  purplebutton,
  redbutton,
  greybutton,
  arrowBackwardsUrl,
  arrowBackwardsWhiteUrl,
} from "../style/variables";

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  text-decoration: none;
  width: fit-content;
  color: #fff;
  padding: 11px 13.5px;

  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.005em;
  border-radius: 44px;
  &.submit-without-verification {
    border-radius: 4px;
  }

  border: none;

  transition: 0.3s;

  .bigPlus {
    margin-right: 11px;
  }

  .bigPlus {
    margin-bottom: 0;
  }

  &#no-click {
    cursor: auto;
    pointer-events: none;
    background: #8b8f9a;
  }

  background: ${({ color }) => color === "purple" && `${purplebutton}`};
  &:hover {
    background: ${({ color }) => color === "purple" && `#8476e3`};
  }
  &:active {
    background: ${({ color }) => color === "purple" && `#342a76`};
  }

  background: ${({ color }) => color === "red" && `${redbutton}`};
  &:hover {
    background: ${({ color }) => color === "red" && `#FF7F7D`};
  }

  background: ${({ color }) => color === "grey" && `${greybutton}`};
  &:hover {
    ${({ color }) => color === "grey" && `#5541d7`};
  }
  background: ${({ color }) => color === "white" && `#fff`};

  &.white {
    padding: 11px 24px;
    border: 1px solid #d3d8e1;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #232323;
    &:hover {
      background: #f6f6f6;
      border: 1px solid #f6f6f6;
    }
    &:active {
      background: #ededed;
      border: 1px solid #ededed;
    }

    &.goback {
      padding-left: 46px;
      background: url("data:image/svg+xml;utf8, ${arrowBackwardsUrl}") no-repeat
        24%;
      &:hover {
        background: #5541d7
          url("data:image/svg+xml;utf8, ${arrowBackwardsWhiteUrl}") no-repeat
          17%;
        color: #fff;
      }
    }
  }
  &.transparent {
    background: transparent;
    color: #707683;

    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.005em;
    opacity: 0.8;
  }

  &.headerButton {
    opacity: 0;
    transform: translateX(-10%);
    transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
    &.isLoggedIn {
      transform: translateX(0);
      opacity: 1;
    }
  }
  &.skeletonButton {
    height: 40px;
    width: 162px;
    background: #ecebeb;
    animation-name: skeleton-button-color;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    cursor: default;
  }
`;

const Button = React.forwardRef(
  (
    {
      className,
      color,
      text,
      onClick,
      type,
      isActive,
      onMouseOver,
      onMouseOut,
    },
    ref
  ) => {
    return (
      <StyledButton
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        ref={ref}
        onClick={onClick}
        color={color}
        className={`button ${color} ${className}`}
        id={`${!isActive && isActive !== undefined && "no-click"}`}
        type={type === "submit" ? "submit" : ""}
      >
        {className && className.includes("plusKeysRequest") && (
          <svg
            className="bigPlus"
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.5 8V13C8.5 13.5523 8.05228 14 7.5 14C6.94772 14 6.5 13.5523 6.5 13V8H1.5C0.947715 8 0.5 7.55228 0.5 7C0.5 6.44772 0.947715 6 1.5 6H6.5V1C6.5 0.447715 6.94772 0 7.5 0C8.05228 0 8.5 0.447715 8.5 1V6H13.5C14.0523 6 14.5 6.44772 14.5 7C14.5 7.55228 14.0523 8 13.5 8H8.5Z"
              fill="white"
            />
          </svg>
        )}{" "}
        {text}
      </StyledButton>
    );
  }
);

export default Button;

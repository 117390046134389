import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import useNotification from "../../../hooks/useNotification";

import { setDeleteModalOff } from "../../../redux/actions/setDeleteKeysListItemModal";

import Button from "../../Button";

const StyledDeleteModal = styled.div`
  position: absolute;
  left: 50%;
  top: 20%;
  margin-left: -212px;
  width: 100%;
  max-width: 424px;
  max-height: 281px;

  text-align: center;
  .keyslist__delete-modal {
    position: relative;
    background: #ffffff;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;

    z-index: 100;

    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      letter-spacing: 0.005em;
      color: #232323;
      margin-bottom: 8px;
      // white-space: nowrap;
    }
    h3 {
      font-weight: normal;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: 0.005em;
      color: #5e5e5e;
    }
  }
  .keyslist__delete-modal-header {
    padding: 37px 0 30px;
  }
  .delete-modal-cool-icon {
    margin: 0 auto 16px;
    padding: 12px 14px;
    max-height: 34px;
    max-width: 30px;
    background: #fff0ef;
    border-radius: 8px;
  }

  .delete-modal__buttons {
    display: flex;
    justify-content: center;
    width: calc(100% - 60px);
    border-radius: 0 0 20px 20px;

    padding: 0 0 30px 0;

    button {
      width: 145px;

      &.white {
        margin-right: 23px;
      }
    }
  }
  .delete-modal-bg-layer {
    background: #000;
    opacity: 0.3;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 1000px) {
    margin-left: -172px;
    max-width: 345px;
    .keyslist__delete-modal {
      h2 {
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        color: #232323;
      }
      h3 {
        font-weight: normal;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: 0.005em;
        color: #5e5e5e;
      }
    }
  }
  @media (max-width: 420px) {
    margin-left: -125px;
    max-width: 250px;
  }
`;

const DeleteModal = ({ setUseEffectTrigger, type }) => {
  const dispatch = useDispatch();
  const showNotification = useNotification();

  const itemId = useSelector(
    ({ deleteKeysListItemModal }) =>
      deleteKeysListItemModal.item.length > 0 &&
      deleteKeysListItemModal.item.find((element) => element.type === "formId")
        .id
  );
  const memberEmail = useSelector(
    ({ deleteKeysListItemModal }) => deleteKeysListItemModal.memberEmail
  );

  const deleteTeamMember = () => {
    fetch(` https://engine.keysforweb.com/wp-json/bda/v1/team/${memberEmail}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") || sessionStorage.getItem("token")
        }`,
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        dispatch(setDeleteModalOff());
        setUseEffectTrigger((prev) => prev + 1);
      });
  };

  const handleDeleteItem = () => {
    fetch(
      `https://engine.keysforweb.com/wp-json/bda/v1/key-requests/${itemId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") || sessionStorage.getItem("token")
          }`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        dispatch(setDeleteModalOff());
        setUseEffectTrigger((prev) => prev + 1);
      });
  };

  return (
    <StyledDeleteModal>
      <div className="keyslist__delete-modal">
        <div className="keyslist__delete-modal-header">
          <div className="delete-modal-cool-icon">
            <svg
              width="30"
              height="34"
              viewBox="0 0 30 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.3333 33.6666H6.66667C4.82572 33.6666 3.33333 32.1742 3.33333 30.3332V8.66657H0V5.33324H6.66667V3.66657C6.66667 1.82562 8.15905 0.333237 10 0.333237H20C21.8409 0.333237 23.3333 1.82562 23.3333 3.66657V5.33324H30V8.66657H26.6667V30.3332C26.6667 32.1742 25.1743 33.6666 23.3333 33.6666ZM6.66667 8.66657V30.3332H23.3333V8.66657H6.66667ZM10 3.66657V5.33324H20V3.66657H10Z"
                fill="#FF6562"
              />
            </svg>
          </div>

          {type === "teamMember" ? (
            <h2>You're about to delete invite</h2>
          ) : (
            <h2>You're about to delete the keys</h2>
          )}
          <h3>This action cannot be undone.</h3>
        </div>

        <div className="delete-modal__buttons">
          <Button
            onClick={() => dispatch(setDeleteModalOff())}
            color={"white"}
            text={"Cancel"}
          />
          {type === "teamMember" ? (
            <Button
              onClick={() => {
                deleteTeamMember();
                showNotification(
                  "success",
                  "Deleted",
                  "Member of your team has been deleted",
                  1500
                );
              }}
              color={"red"}
              text={"Delete"}
            />
          ) : (
            <Button
              onClick={() => handleDeleteItem()}
              color={"red"}
              text={"Delete"}
            />
          )}
        </div>
      </div>

      <div className="delete-modal-bg-layer" />
    </StyledDeleteModal>
  );
};

export default DeleteModal;

import React from "react";

import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";

const UpgradeSwitcher = withStyles((theme) => ({
  root: {
    width: 58,
    height: 36,
    padding: 3,
    // margin: theme.spacing(1),
    marginLeft: 10,
    marginRight: 10,
    "&:hover": {},
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(21px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#5541D7",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#5541D7",
      border: "6px solid #fff",
    },
  },
  thumb: {
    marginTop: 5,
    marginLeft: 5,
    width: 24,
    height: 24,
    boxShadow: 0,
  },
  track: {
    borderRadius: 100,
    // border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#DDDDDD",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      onClick={() => props.setBillingMonthly((prev) => !prev)}
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={!props.billingMonthly}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default UpgradeSwitcher;

import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { setClientHandle, setWebsiteUrl } from "../../redux/actions/setHandles";

const StyledGeneratorHeader = styled.div`
  margin: 0 10px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .generator__static-info {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .generator__client-handle,
  .generator__website-url {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 368px;

    label {
      margin-bottom: 7px;
      font-size: 14px;
      line-height: 18px;
      color: #232323;
    }
    .input-wrapper {
      border-radius: 8px;
      width: 100vw;
      max-width: 368px;
      transition: 0.3s;
      input {
        padding: 0 15px;

        background: #fff;
        width: 100%;
        max-width: 338px;
        height: 38px;

        border: none;
        border-radius: 8px;
        transition: 0.4s;
      }
    }
  }
  .generator__client-handle {
    .input-wrapper {
      border: ${({ clientHandleBadRed }) =>
        clientHandleBadRed ? "1px solid #FF6562" : "1px solid #DDDDDD"};
      input::placeholder {
        color: ${({ clientHandleBadRed }) =>
          clientHandleBadRed ? "#FF6562" : "#DDDDDD"};
      }
    }
  }
  .generator__website-url {
    .input-wrapper {
      border: ${({ websiteUrlBadRed }) =>
        websiteUrlBadRed ? "1px solid #FF6562" : "1px solid #DDDDDD"};
      input::placeholder {
        color: ${({ websiteUrlBadRed }) =>
          websiteUrlBadRed ? "#FF6562" : "#DDDDDD"};
      }
    }
  }
`;

const GeneratorHeader = ({
  clientHandleBadRed,
  websiteUrlBadRed,
  formGenerator,
}) => {
  const dispatch = useDispatch();

  const onChangeClientHandle = (e) => {
    dispatch(setClientHandle(e.target.value));
  };
  const onChangeWebsiteUrl = (e) => {
    dispatch(setWebsiteUrl(e.target.value));
  };

  return (
    <StyledGeneratorHeader
      clientHandleBadRed={clientHandleBadRed}
      websiteUrlBadRed={websiteUrlBadRed}
    >
      <h2>Generate your keys request</h2>
      <div className="generator__static-info">
        {" "}
        <div className={`generator__client-handle`}>
          <label htmlFor="Client unique handle">Client unique handle</label>
          <div className="input-wrapper">
            <input
              onChange={(e) => onChangeClientHandle(e)}
              value={formGenerator[3].value}
              placeholder={clientHandleBadRed ? "Write client name" : ""}
              type="text"
            />
          </div>
        </div>
        <div className={`generator__website-url`}>
          <label className="websiteurl" htmlFor="Website url">
            Website URL
          </label>
          <div className="input-wrapper">
            <input
              value={formGenerator[4].value}
              placeholder={websiteUrlBadRed ? "Write website url" : ""}
              onChange={(e) => onChangeWebsiteUrl(e)}
              type="text"
            />
          </div>
        </div>
      </div>
    </StyledGeneratorHeader>
  );
};

export default GeneratorHeader;

const initialState = [];

const formFromWebSocket = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FORM_FROM_WS':
      return [...action.payload];
    default:
      return [...initialState];
  }
};

export default formFromWebSocket;

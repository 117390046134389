import React from "react";
import styled from "styled-components";
import Button from "../../../Button";

const StyledResetSettings = styled.div`
  position: absolute;
  top: 22%;
  left: 50%;
  margin-left: -213px;
  transition: 0.2s;
  visibility: ${({ showResetProfileModal }) =>
    showResetProfileModal ? "visible" : "hidden"};
  opacity: ${({ showResetProfileModal }) =>
    showResetProfileModal ? "1" : "0"};
  .reset-profile-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 10px;
    position: relative;
    background: #ffffff;
    border-radius: 20px;
    z-index: 1;

    width: 100vw;
    max-width: 407px;

    .reset-profile-modal__cool-icon {
      margin-bottom: 16px;
      background: #f0eeff;
      padding: 8px;
      border-radius: 8px;
    }
    h3 {
      margin-bottom: 7px;
      font-weight: 500;
      font-size: 25px;
      line-height: 31px;
      text-align: center;
      color: #232323;
    }
    h4 {
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #505356;
    }
  }
  .reset-profile-modal__buttons {
    margin-top: 30px;
    display: flex;
    align-items: center;
    .button {
      width: 144px;
    }
    .button.white {
      margin-right: 23px;
    }
  }
  .bg-layer {
    background: #000;
    opacity: 0.6;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

const ResetProfileModal = ({
  resetProfileChanges,
  showResetProfileModal,
  setShowResetProfileModal,
}) => {
  return (
    <StyledResetSettings showResetProfileModal={showResetProfileModal}>
      <div className="reset-profile-modal">
        <div className="reset-profile-modal__cool-icon">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.66602 33.3339V21.6671H18.3327L12.971 27.0338C14.8209 28.9255 17.3535 29.9945 19.9993 30.0005C24.2318 29.9942 28.0019 27.3242 29.4127 23.3338H29.4427C29.6336 22.7915 29.778 22.2339 29.8743 21.6671H33.2277C32.3878 28.3336 26.7186 33.3336 19.9993 33.3339H19.9827C16.4473 33.3444 13.0546 31.94 10.561 29.4338L6.66602 33.3339ZM10.1227 18.3338H6.76935C7.60893 11.6698 13.2743 6.6707 19.991 6.66706H19.9993C23.5354 6.65575 26.9289 8.0602 29.4227 10.5671L33.3327 6.66706V18.3338H21.666L27.036 12.9671C25.1842 11.0732 22.6482 10.004 19.9993 10.0005C15.7669 10.0067 11.9968 12.6768 10.586 16.6671H10.556C10.3636 17.209 10.2192 17.7667 10.1243 18.3338H10.1227Z"
              fill="#5541D7"
            />
          </svg>
        </div>
        <h3>Are you sure you want to reset applied settings?</h3>
        <h4>This action cannot be undone</h4>
        <div className="reset-profile-modal__buttons">
          <Button
            color={"white"}
            text={"Cancel"}
            onClick={() => setShowResetProfileModal(false)}
          />
          <Button
            color={"purple"}
            text={"Reset"}
            onClick={() => {
              resetProfileChanges();
              setShowResetProfileModal(false);
            }}
          />
        </div>
      </div>
      <div className="bg-layer" />
    </StyledResetSettings>
  );
};

export default ResetProfileModal;

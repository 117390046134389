import React from "react";
import { TransitionGroup } from "react-transition-group";

import { TeamList } from "../components";

const Team = () => {
  return (
    <div>
      <TransitionGroup
        transitionName="keysListAnimation"
        transitionAppear={true}
        transitionAppearTimeout={200}
        transitionEnter={false}
        transitionLeave={false}
      >
        <TeamList />
      </TransitionGroup>
    </div>
  );
};

export default Team;

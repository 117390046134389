import React, { useRef, useState } from "react";

function CountriesModal({
  fetchedCountries,
  showCountriesList,
  setShowCountriesList,
  setCountryIndex,
  displayedCountry,
  changeLocationValue,
}) {
  const wholeModalRef = useRef();
  const ref = useRef();
  const [noMatches, setNoMatches] = useState(false);

  let countriesCounter = 0;

  React.useEffect(() => {
    for (let item of ref.current.children) {
      if (
        displayedCountry.length !== 0 &&
        !item.textContent
          .toLowerCase()
          .includes(displayedCountry.toLowerCase()) &&
        item.textContent !== "No match"
      ) {
        item.style.display = "none";
      } else {
        item.style.display = "";
      }
    }

    for (let item of ref.current.children) {
      if (item.style.display === "none") {
        countriesCounter++;
      }
    }
    if (countriesCounter === ref.current.children.length) {
      setNoMatches(true);
    } else {
      setNoMatches(false);
    }
  }, [displayedCountry]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOutsideCountriesModalClick = (e) => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (!path.includes(wholeModalRef.current)) {
      setShowCountriesList(false);
    }
  };

  const setCountryFromModal = (e) => {
    changeLocationValue(e.target.textContent, "country");
    setShowCountriesList(false);
  };

  React.useEffect(() => {
    document.body.addEventListener("click", handleOutsideCountriesModalClick);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      ref={wholeModalRef}
      className={`countries-dropbox ${showCountriesList ? "isActive" : ""}`}
    >
      <div className="input-wrapper">
        <input
          onChange={(e) => {
            changeLocationValue(e.target.value, "country");
            setShowCountriesList(true);
          }}
          value={displayedCountry}
          type="text"
        />
        <svg
          onClick={(e) => {
            e.stopPropagation();
            setShowCountriesList(false);
          }}
          width="15"
          height="8"
          viewBox="0 0 15 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L7.5 7L14 1"
            stroke="#232323"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="countries-dropbox__list" ref={ref}>
        {fetchedCountries &&
          fetchedCountries.map((countryName, countryIndex) => {
            return (
              <div
                onClick={(e) => {
                  setCountryFromModal(e);
                  setCountryIndex(countryIndex);
                  e.stopPropagation();
                  setShowCountriesList(false);
                }}
                className="countries-dropbox__item"
              >
                {countryName}
              </div>
            );
          })}
        {noMatches && (
          <div className="countries-dropbox__item no-match">No match</div>
        )}
      </div>
    </div>
  );
}

export default CountriesModal;

import React from 'react';

import RequestLinkField from './RequestLinkField';

function RequestLinkFieldGroup({
  handleChangeFieldValue,
  badRedTrigger,
  groupTitle,
  innerFields,
  outerId,
  currentForm,
}) {
  return (
    <div className="requestlink__fieldgroup">
      <h4>{groupTitle}</h4>
      {innerFields.map((item) => {
        return (
          <RequestLinkField
            key={item.innerId}
            badRedTrigger={badRedTrigger}
            handleChangeFieldValue={handleChangeFieldValue}
            title={item.title}
            inner={true}
            currentForm={currentForm}
            id={item.innerId}
            outerId={outerId}
            item={item}
          />
        );
      })}
    </div>
  );
}

export default RequestLinkFieldGroup;

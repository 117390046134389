import React, { useState } from "react";
import { Helmet } from "react-helmet";

import { useSelector, useDispatch } from "react-redux";
import { getProfileFetch, setLoadingUser } from "./redux/actions/loginActions";
import { setFormFromWs } from "./redux/actions/setFormFromWs";
import { setSettingsContent } from "./redux/actions/setSettingsContent.js";

import "./scss/App.scss";

import {
  Redirect,
  Route,
  useLocation,
  useHistory,
  Switch,
} from "react-router-dom";

import {
  Header,
  Loader,
  NotificationWrapper,
  Logout,
  Upgrade,
} from "./components";
import {
  Main,
  GenerateRequest,
  LoginPage,
  SignUpPage,
  Settings,
  Team,
  ChangePassword,
  AddCreditCard,
  RequestLink,
  AccountActivation,
  SetNewPassword,
  Page404,
} from "./pages";
import AuthedRoute from "./routes/AuthedRoute";
import { setUpgradeModalVisibility } from "./redux/actions/setUpgradeModalVisibility";

const wsServer = `wss://engine.keysforweb.com/ws?token=${
  localStorage.getItem("token") || sessionStorage.getItem("token")
    ? localStorage.getItem("token") || sessionStorage.getItem("token")
    : ""
}`;
const websocket = new WebSocket(wsServer);

function App() {
  const currentUserState = useSelector(
    ({ currentUserReducer }) => currentUserReducer
  );
  const userRole = useSelector(
    ({ currentUserReducer }) => currentUserReducer.currentUser.role
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [needHelpVisibility, setNeedHelpVisibility] = useState(false);
  const [logoutVisibility, setLogoutVisibility] = useState(false);
  const [signUpParam, setSignUpParam] = useState("");
  const [subscriptionType, setSubscriptionType] = useState(null);

  const [globalId, setGlobalId] = useState(1);

  sessionStorage.setItem("lastPath", location.pathname);
  React.useEffect(() => {
    websocket.onopen = function () {
      console.log("Connected to WebSocket server.");
    };
    websocket.onclose = function () {
      console.log("Disconnected");
    };
    websocket.onmessage = function (e) {
      console.log(e, "WS MESSAGE");
      const data = JSON.parse(e.data);
      if (data && data !== null && data.length !== 0) {
        console.log("form from ws is set");
        dispatch(setFormFromWs(data));
      }
    };
    websocket.onerror = function (e) {
      console.log("Error occured: " + e.data);
    };

    if (location.pathname.slice(0, 3) !== "/r/") {
      dispatch(setLoadingUser(true));
      dispatch(getProfileFetch());

      if (location.pathname !== "/") {
        history.push(sessionStorage.getItem("lastPath"));
      }
    }
    if (location.pathname === "/signup/" && location.search !== "") {
      setSignUpParam(location.search);
    }
    Notification.requestPermission();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://code-eu1.jivosite.com/widget/cWfcvr1z7v";
    script.async = true;

    document.body.appendChild(script);
    if (location.search === "?showUpgrades") {
      dispatch(setUpgradeModalVisibility(true));
    }
    if (
      location.pathname === "/settings" &&
      location.search === "?page=billing" &&
      userRole !== "team-member"
    ) {
      dispatch(setSettingsContent("billing"));
    }

    // ===== PRODUCTION REDIRECT =====
    if (window.location.hostname !== "staging.app.keysforweb.com") {
      console.log(window.location);
      if (
        window.location.hostname !== "app.keysforweb.com" &&
        location.pathname !== "pagenotfound" &&
        location.pathname.slice(0, 3) !== "/r/"
      ) {
        const segment = window.location.pathname;
        window.location = `https://app.keysforweb.com${segment}`;
      }
    }

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const incrementGlobaldId = () => {
    setGlobalId((prev) => prev + 1);
  };

  return (
    <div className="app">
      {location.pathname.slice(0, 3) === "/r/" && (
        <Helmet>
          <title>Keys Request | KeysForWeb</title>
          <meta
            name="description"
            content="Someone would like you to share your access information with them. Please provide your access keys in this form - it's completely secure and not visible to third parties."
          />
        </Helmet>
      )}
      <Header
        setNeedHelpVisibility={setNeedHelpVisibility}
        setLogoutVisibility={setLogoutVisibility}
        display={
          location.pathname.slice(0, 3) === "/r/"
            ? "requestLinkHeader"
            : "defaultHeader"
        }
        formGeneratorHeader={location.pathname === "/request"}
      />
      {currentUserState.isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          <Switch>
            <Route
              path="/account-activation"
              render={() => <AccountActivation />}
            />
            <Route path="/reset-password" render={() => <SetNewPassword />} />
            <Route
              path="/r/"
              render={() => (
                <RequestLink
                  setNeedHelpVisibility={setNeedHelpVisibility}
                  needHelpVisibility={needHelpVisibility}
                  websocket={websocket}
                />
              )}
            />
            <AuthedRoute exact={true} path={"/"}>
              <Main />
            </AuthedRoute>
            <AuthedRoute exact={true} path={"/team"}>
              {userRole === "team-admin" ? (
                <Team />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: { from: location },
                  }}
                />
              )}
            </AuthedRoute>
            <Route
              exact
              path="/login"
              render={() =>
                currentUserState.isLoggedIn ? (
                  <Redirect to="/" />
                ) : (
                  <LoginPage />
                )
              }
            />
            <Route
              exact
              path="/signup"
              render={() =>
                currentUserState.isLoggedIn ? (
                  <Redirect to="/" />
                ) : (
                  <SignUpPage invitationCode={signUpParam} />
                )
              }
            />

            <AuthedRoute exact={true} path={"/request"}>
              <GenerateRequest
                globalId={globalId}
                incrementGlobaldId={incrementGlobaldId}
              />
            </AuthedRoute>
            <AuthedRoute exact path={"/settings"} query={location.search}>
              <Settings
                setLogoutVisibility={setLogoutVisibility}
                query={location.search}
              />
            </AuthedRoute>
            <AuthedRoute exact={true} path={"/settings/changepassword"}>
              <ChangePassword />
            </AuthedRoute>
            <AuthedRoute exact={true} path={"/settings/addcreditcard"}>
              <AddCreditCard />
            </AuthedRoute>
            <Route path="/pagenotfound" render={() => <Page404 />} />
            <Route>
              <Page404 />
            </Route>
          </Switch>

          <NotificationWrapper />

          {/*{(location.pathname === "/" ||*/}
          {/*  location.pathname === "/team" ||*/}
          {/*  location.pathname === "/request" ||*/}
          {/*  location.pathname === "/requestlink" ||*/}
          {/*  location.pathname === "/login") && <Footer />}*/}
        </div>
      )}

      {currentUserState.isLoggedIn && <Upgrade />}
      <Logout
        logoutVisibility={logoutVisibility}
        setLogoutVisibility={setLogoutVisibility}
      />
    </div>
  );
}

export default App;

import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import Button from "../Button";

import { userLoginFetch } from "../../redux/actions/loginActions";

import { loginBackgroundUrl } from "../../style/variables";

const StyledLogin = styled.div`
  width: 55%;
  .login__forms {
    .input-wrapper:nth-child(6) {
      margin-bottom: 0;
    }
  }
  .remember-me {
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #505356;
  }
  .remember-checkbox {
    position: relative;
    margin-right: 7px;
    padding: 12px;
    border: 1px solid #c2c5cb;
    border-radius: 4px;
    cursor: pointer;
    background: ${({ rememberUser }) => (rememberUser ? "#5541D7" : "#ffffff")};
  }
  .remember-user-checkmark {
    position: absolute;
    top: 6px;
    left: 5px;
    display: ${({ rememberUser }) => (rememberUser ? "auto" : "none")};
  }
  .forgot-password {
    display: flex;
    justify-content: space-between;
    text-align: end;
    span {
      cursor: pointer;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #8b8f9a;
      &:hover {
        text-decoration: underline;
      }
    }
    .incorrectLogin {
      font-weight: normal;
      font-size: 12px;
      line-height: 120%;
      letter-spacing: 0.005em;
      color: #ff6562;

      visibility: hidden;
      opacity: 0;
      transition: 0.3s;
      &.visible {
        visibility: visible;
        opacity: 1;
      }
    }
  }
`;

function Login({ handleResetPass, handleSignup }) {
  const dispatch = useDispatch();
  const [loginState, setLoginState] = useState({
    login: "",
    password: "",
  });
  const [passIsVisible, setPassIsVisible] = useState(false);
  const [rememberUser, setRememberUser] = useState(false);

  const loginBadRed = useSelector(
    ({ loginStyleState }) => loginStyleState.loginBadRed
  );

  const ref = useRef();

  const handleChange = (e) => {
    setLoginState({
      ...loginState,
      [e.target.name]: e.target.value,
    });
  };

  const logIn = () => {
    dispatch(userLoginFetch(loginState, rememberUser));
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      logIn();
    }
  };

  const toggleShowPassword = (e) => {
    if (ref.current.type === "password") {
      ref.current.type = "text";
      setPassIsVisible(true);
    } else {
      ref.current.type = "password";
      setPassIsVisible(false);
    }
  };

  return (
    <StyledLogin rememberUser={rememberUser}>
      <div className="login__forms">
        <h2>Log in</h2>
        <h3>
          Don't have an account?{" "}
          <a
            href="https://engine.keysforweb.com/checkout/?edd_action=add_to_cart&download_id=233"
            className={"signUpLink"}
          >
            Sign up
          </a>
        </h3>

        <label htmlFor="Email Address or login">Email address</label>
        <div className={`input-wrapper ${loginBadRed ? "loginBadRed" : ""}`}>
          <input
            onChange={(e) => handleChange(e)}
            name="login"
            value={loginState.login}
            type="text"
          />
        </div>

        <label htmlFor="PASSWORD">Password</label>
        <div className={`input-wrapper ${loginBadRed ? "loginBadRed" : ""}`}>
          <input
            ref={ref}
            className="user-password-input"
            onKeyPress={(e) => handleEnterKeyPress(e)}
            onChange={(e) => handleChange(e)}
            name="password"
            value={loginState.password}
            type="password"
          />
          {!passIsVisible && (
            <svg
              onClick={(e) => toggleShowPassword(e)}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 18.9987C10.3599 19.0191 8.7367 18.6652 7.254 17.9638C6.10469 17.4032 5.07265 16.6288 4.213 15.6822C3.30243 14.7035 2.58547 13.5612 2.1 12.3157L2 11.9998L2.105 11.6838C2.59082 10.4394 3.30624 9.29744 4.214 8.31732C5.07334 7.37078 6.10504 6.59645 7.254 6.03567C8.73671 5.33437 10.3599 4.98045 12 5.00083C13.6401 4.98048 15.2633 5.3344 16.746 6.03567C17.8953 6.59632 18.9274 7.37066 19.787 8.31732C20.6993 9.29473 21.4165 10.4374 21.9 11.6838L22 11.9998L21.895 12.3157C20.3262 16.3989 16.3742 19.068 12 18.9987ZM12 7.00053C8.59587 6.89387 5.47142 8.87533 4.117 11.9998C5.4712 15.1244 8.59579 17.1059 12 16.999C15.4041 17.1054 18.5284 15.124 19.883 11.9998C18.5304 8.87382 15.4047 6.89162 12 7.00053ZM12 14.9993C10.5573 15.0088 9.30937 13.9968 9.02097 12.5834C8.73256 11.1701 9.48427 9.75013 10.8154 9.19385C12.1465 8.63757 13.6852 9.10034 14.4885 10.2985C15.2919 11.4967 15.1354 13.0957 14.115 14.1154C13.5563 14.6806 12.7948 14.9989 12 14.9993Z"
                fill="#8B8F9A"
              />
            </svg>
          )}
          {passIsVisible && (
            <svg
              onClick={(e) => toggleShowPassword(e)}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.97 21L16.614 17.7139C15.1661 18.3594 13.5908 18.6847 12 18.6666C10.3599 18.6866 8.73671 18.34 7.254 17.6532C6.10468 17.1041 5.07264 16.3458 4.213 15.4188C3.30049 14.461 2.5833 13.3413 2.1 12.1199L2 11.8125L2.105 11.5031C2.82781 9.69778 4.04426 8.12218 5.621 6.94897L3 4.38258L4.413 3L21.382 19.6155L19.972 21H19.97ZM7.036 8.33547C5.75792 9.21272 4.74865 10.4149 4.117 11.8125C5.47142 14.8723 8.59587 16.8128 12 16.7083C13.0498 16.7167 14.0936 16.5512 15.087 16.2187L13.287 14.4562C12.8863 14.6485 12.4462 14.749 12 14.75C10.3475 14.7398 9.01037 13.4306 9 11.8125C9.00048 11.3746 9.10309 10.9426 9.3 10.5494L7.036 8.33547ZM19.852 15.3473L18.46 13.9853C19.0456 13.337 19.5256 12.6041 19.883 11.8125C18.5304 8.75121 15.4047 6.81001 12 6.91666C11.753 6.91666 11.505 6.92547 11.265 6.94212L9.5 5.21193C10.3216 5.03983 11.1598 4.95481 12 4.95833C13.6401 4.9384 15.2633 5.285 16.746 5.97177C17.8953 6.52082 18.9274 7.27914 19.787 8.20622C20.6991 9.16283 21.4163 10.2811 21.9 11.5011L22 11.8125L21.895 12.1219C21.4268 13.3146 20.7342 14.4107 19.853 15.3532L19.852 15.3473Z"
                fill="#8B8F9A"
              />
            </svg>
          )}
        </div>
        <div className="remember-forgot">
          <div className="remember-me">
            <div
              onClick={() => setRememberUser((prev) => !prev)}
              className="remember-checkbox"
            >
              {rememberUser && (
                <svg
                  className="remember-user-checkmark"
                  width="15"
                  height="11"
                  viewBox="0 0 15 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.16689 10.5833L0.583252 6.17176L1.8926 4.91157L5.16828 8.06072L5.16689 8.06205L13.0239 0.5L14.3333 1.76019L6.47624 9.32314L5.16782 10.5824L5.16689 10.5833Z"
                    fill="white"
                  />
                </svg>
              )}
            </div>
            Remember me
          </div>
          <div className="forgot-password">
            <span onClick={handleResetPass}>Forgot password?</span>
          </div>
        </div>

        <Button onClick={() => logIn()} color={"purple"} text={"Log in"} />

        {/* **** SIGNUP NAVIGATION!!! **** */}

        {/* <div className="login-footer">
          Don't have an account yet?{' '}
          <Button
            onClick={handleSignup}
            color={'white'}
            text={'Sign up'}
            className={'login-footer__button'}
          />
        </div> */}
      </div>
    </StyledLogin>
  );
}

export default Login;

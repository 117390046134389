import React from 'react';

import KeysListModalItem from './KeysListModalItem';

function KeysListModalItemGroup({ item }) {
  return (
    <div className="keys-list--modal__item-group">
      <h4>{item.groupTitle}</h4>
      {item.innerFields.map((innerItem, id) => {
        return <KeysListModalItem key={id} item={innerItem} />;
      })}
    </div>
  );
}

export default KeysListModalItemGroup;

const initialState = {
  loginBadRed: false,
};

const loginStyleState = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOGIN_STYLE':
      return {
        ...state,
        loginBadRed: action.payload,
      };
    default:
      return { ...state };
  }
};

export default loginStyleState;

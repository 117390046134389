import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  setFieldTitle,
  deleteFormItems,
} from "../../../redux/actions/setFormItems";

function GeneratorField({
  card,
  id,
  outerId,
  inner,
  readOnly,
  presetTitle,
  order,
  innerOrder,
  dragStartHandler,
  dragEndHandler,
  dragOverHandler,
  dropHandler,
  currentDraggedCard,
}) {
  const [draggable, setDraggable] = useState(false);
  const [badRed, setBadRed] = useState(false);
  const [fieldTitleValue, setFieldTitleValue] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [currentlyInMotion, setCurrentlyInMotion] = useState(false);

  const [noTransition, setNoTransition] = useState(false);

  const notificationVisible = useSelector(
    ({ notificationState }) => notificationState.visible
  );
  const formGenerator = useSelector(({ formGenerator }) => formGenerator);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setNoTransition(true);
    setTimeout(() => {
      setNoTransition(false);
    });
  }, [order, innerOrder]);

  React.useEffect(() => {
    if (notificationVisible === true) {
      if (isActive === true) {
        setBadRed(true);
        setTimeout(() => {
          setBadRed(false);
        }, 1500);
      }
    }
  }, [notificationVisible, isActive]);

  React.useEffect(() => {
    setBadRed(false);

    if (readOnly) {
      setIsActive(false);
    }

    if (!readOnly) {
      formGenerator.forEach((item) => {
        if (!inner && item.id === id && item.title) {
          setFieldTitleValue(item.title);
          setIsActive(false);
          setFieldTitleValue("");
        } else if (item.type === "fieldgroup" && item.id === outerId) {
          item.innerFields.forEach((item) => {
            if (item.innerId === id && item.title) {
              setFieldTitleValue(item.title);
              setIsActive(false);
              setFieldTitleValue("");
            }
          });
        }
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e) => {
    setFieldTitleValue(e.target.value);
  };

  const handleClick = (e, id, title, inner, outerId) => {
    e.preventDefault();
    if (fieldTitleValue) {
      setBadRed(false);
      setIsActive(false);
      dispatch(setFieldTitle(id, title, inner, outerId));
      setFieldTitleValue("");
    } else {
      setBadRed(true);
      setTimeout(() => {
        setBadRed(false);
      }, 1000);
    }
  };

  const resetInput = (id, inner, outerId) => {
    setIsActive(true);
    formGenerator.forEach((item) => {
      if (!inner && item.id === id && item.title) {
        setFieldTitleValue(item.title);
      } else if (item.type === "fieldgroup" && item.id === outerId) {
        item.innerFields.forEach((item) => {
          if (item.innerId === id && item.title) {
            setFieldTitleValue(item.title);
          }
        });
      }
    });
    dispatch(setFieldTitle(id, "", inner, outerId));
  };

  const handleDelete = (id, inner, outerId, order, innerOrder) => {
    dispatch(deleteFormItems(id, inner, outerId, order, innerOrder));
  };

  return (
    <div
      onDragOver={(e) => {
        e.preventDefault();
        dragOverHandler(e, card, outerId, inner);
      }}
      onDrop={(e) => {
        setCurrentlyInMotion(false);

        dropHandler(e, card, outerId, inner);
      }}
      className="generator__field--wrapper"
    >
      <div
        onDragStart={() => {
          setCurrentlyInMotion(true);
          dragStartHandler(card, inner, outerId);
        }}
        onDragEnd={(e) => {
          setCurrentlyInMotion(false);
          dragEndHandler(e, outerId);
        }}
        onDragLeave={(e) => {}}
        draggable={draggable}
        className={`generator__field ${badRed ? "badred" : ""} ${
          isActive ? "isActive" : ""
        } ${card.translateY === 1 && "translateDown"} ${
          card.translateY === -1 && "translateUp"
        } ${currentlyInMotion ? "inMotion" : "notInMotion"} ${
          noTransition && "noTransition"
        }`}
      >
        {!presetTitle && (
          <svg
            onMouseDown={(e) => setDraggable(true)}
            onMouseUp={(e) => setDraggable(false)}
            className={`dragging-dots 
          ${currentDraggedCard && "hidden"}
          `}
            width="10"
            height="14"
            viewBox="0 0 10 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.625 1.5C3.625 2.25942 3.01001 2.875 2.25 2.875C1.48999 2.875 0.875 2.25942 0.875 1.5C0.875 0.740578 1.48999 0.125 2.25 0.125C3.01001 0.125 3.625 0.740578 3.625 1.5Z"
              fill="#8B8F9A"
            />
            <path
              d="M3.625 7C3.625 7.75942 3.01001 8.375 2.25 8.375C1.48999 8.375 0.875 7.75942 0.875 7C0.875 6.24058 1.48999 5.625 2.25 5.625C3.01001 5.625 3.625 6.24058 3.625 7Z"
              fill="#8B8F9A"
            />
            <path
              d="M2.25 13.875C3.01001 13.875 3.625 13.2594 3.625 12.5C3.625 11.7406 3.01001 11.125 2.25 11.125C1.48999 11.125 0.875 11.7406 0.875 12.5C0.875 13.2594 1.48999 13.875 2.25 13.875Z"
              fill="#8B8F9A"
            />
            <path
              d="M9.125 1.5C9.125 2.25942 8.51001 2.875 7.75 2.875C6.98999 2.875 6.375 2.25942 6.375 1.5C6.375 0.740578 6.98999 0.125 7.75 0.125C8.51001 0.125 9.125 0.740578 9.125 1.5Z"
              fill="#8B8F9A"
            />
            <path
              d="M7.75 8.375C8.51001 8.375 9.125 7.75942 9.125 7C9.125 6.24058 8.51001 5.625 7.75 5.625C6.98999 5.625 6.375 6.24058 6.375 7C6.375 7.75942 6.98999 8.375 7.75 8.375Z"
              fill="#8B8F9A"
            />
            <path
              d="M9.125 12.5C9.125 13.2594 8.51001 13.875 7.75 13.875C6.98999 13.875 6.375 13.2594 6.375 12.5C6.375 11.7406 6.98999 11.125 7.75 11.125C8.51001 11.125 9.125 11.7406 9.125 12.5Z"
              fill="#8B8F9A"
            />
          </svg>
        )}

        {!isActive && (
          <label htmlFor="">
            {!readOnly &&
              inner &&
              formGenerator.map((item) => {
                let label;
                if (item.type === "fieldgroup" && item.id === outerId) {
                  label = item.innerFields.find(
                    (item) => item.innerId === id
                  ).title;
                }
                return label;
              })}
            {!readOnly &&
              !inner &&
              formGenerator.find((item) => item.id === id).title}
            {readOnly && presetTitle}
            {!readOnly && (
              <svg
                cursor="pointer"
                onClick={() => resetInput(id, inner, outerId)}
                className={"field-change-icon"}
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.125 10.2188V12.8751H2.78125L10.6154 5.04089L7.95917 2.38464L0.125 10.2188ZM12.6696 2.98673C12.9458 2.71048 12.9458 2.26423 12.6696 1.98798L11.0121 0.330478C10.7358 0.0542285 10.2896 0.0542285 10.0133 0.330478L8.71708 1.62673L11.3733 4.28298L12.6696 2.98673Z"
                  fill="#8B8F9A"
                />
              </svg>
            )}
          </label>
        )}

        <div
          onMouseDown={(e) => setDraggable(false)}
          onMouseUp={(e) => setDraggable(true)}
          className="input-wrapper"
        >
          <input
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleClick(e, id, fieldTitleValue, inner, outerId);
              }
            }}
            value={fieldTitleValue}
            placeholder={badRed && "Write title here"}
            onChange={handleChange}
            type="text"
            readOnly={!isActive}
          />
          {isActive && (
            <svg
              onClick={(e) =>
                handleClick(e, id, fieldTitleValue, inner, outerId)
              }
              className="generator__field-complete-icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.001 22C6.48011 21.9939 2.00606 17.5203 2 12V11.8C2.10994 6.30455 6.63505 1.92797 12.1317 2.0009C17.6283 2.07382 22.0357 6.5689 21.9998 12.0653C21.9639 17.5618 17.4981 21.9989 12.001 22ZM8.11622 12.2929C7.72625 11.9045 7.09544 11.9051 6.70622 12.2943C6.31642 12.684 6.31642 13.316 6.70622 13.7058L9.29373 16.293C9.68424 16.6835 10.3174 16.6835 10.7079 16.293L17.2969 9.70466C17.6865 9.3151 17.6872 8.68338 17.2989 8.29245C16.9088 7.89962 16.2734 7.89806 15.8819 8.28952L10.0008 14.17L8.11622 12.2929Z"
                fill="#67BAA7"
              />
            </svg>
          )}
          {isActive && (
            <svg
              onClick={() =>
                handleDelete(id, inner, outerId, order, innerOrder)
              }
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.9969 22C9.34485 22.0024 6.8007 20.9496 4.9254 19.0739C3.05009 17.1981 1.99762 14.6533 2 12.0006V11.8006C2.08177 7.79305 4.54702 4.22119 8.26443 2.72418C11.9818 1.22718 16.2337 2.09405 19.0687 4.927C21.9303 7.78691 22.7868 12.0899 21.2384 15.828C19.6901 19.5661 16.0421 22.0024 11.9969 22ZM11.9969 13.4105L13.8812 15.2952C14.2705 15.6846 14.9018 15.6846 15.2911 15.2952C15.6803 14.9059 15.6803 14.2749 15.2911 13.8856L13.4065 12.0006L15.2911 10.1155C15.6803 9.72625 15.6803 9.09522 15.2911 8.70595C14.9018 8.31654 14.2705 8.31654 13.8812 8.70595L11.9969 10.5907L10.1127 8.70595C9.72337 8.31654 9.09207 8.31654 8.70276 8.70595C8.31359 9.09522 8.31359 9.72625 8.70276 10.1155L10.5874 12.0006L8.70283 13.8856C8.31362 14.2749 8.31362 14.906 8.70283 15.2953C9.09213 15.6847 9.72338 15.6847 10.1128 15.2954L11.9969 13.4115V13.4105Z"
                fill="#81858B"
              />
            </svg>
          )}
        </div>
      </div>
    </div>
  );
}

export default GeneratorField;

import React from 'react';
import { useDispatch } from 'react-redux';

import { setLoginPageComponent } from '../../redux/actions/setLoginPageComponent';

import Button from '../Button';

function LoginCheckEmail() {
  const dispatch = useDispatch();

  return (
    <div className="login__actions type--emailsent">
      <div className="login__forms">
        <h2>Check your email</h2>
        <h3>We have sent a email verification to your email. Check your inbox.</h3>

        <Button
          onClick={() => {
            dispatch(setLoginPageComponent('login'));
          }}
          color={'purple'}
          text={'Log in'}
        />

        <div className="email_question">
          <span>Didn't receive an Email?</span>
          <Button color={'white'} text={'Re-send'} />
        </div>
      </div>
    </div>
  );
}

export default LoginCheckEmail;

import React, { useRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  setNotificationVisibilty,
  setHideForever,
  setNotificationOrder,
  removeAllNotifications,
} from "../../redux/actions/setNotification";

import Notification from "./Notification";

const StyledNotificationWrapper = styled.div`
  position: fixed;
  z-index: 99999;
  top: 99px;
  right: 50px;

  transition: 1s;
  @media (max-height: 920px) {
    top: 79px;
  }
`;

function NotificationWrapper() {
  const dispatch = useDispatch();
  const notifications = useSelector(
    ({ notificationState }) => notificationState
  );
  const notsRefs = useRef([]);

  React.useEffect(() => {
    let visibleState = notifications.filter(
      (notification) => notification.hideForever === false
    );

    const failedValidationNotificationText =
      "If you can't pass the verification, you\n can still send the keys.";

    // GIVING REFS TO NOTIFICATIONS
    notsRefs.current = notsRefs.current.slice(0, notifications.length);

    // CHECK IF ALL NOTIFICATIONS ARE DEPRECATED
    let isDeprecated;

    for (let notification of notifications) {
      isDeprecated = notification.hideForever;
      if (!notification.hideForever) {
        break;
      }
    }

    if (isDeprecated) {
      dispatch(removeAllNotifications());
    }

    // DYNAMIC SET ORDER
    visibleState.forEach((notification, index) => {
      if (notification.order === 0 || notification.order === index + 1) {
        return;
      }
      dispatch(setNotificationOrder(notification.id, index + 1));
    });

    // CHECK IF QUANTITY BEYOND LIMITS
    if (visibleState.length > 3) {
      const arrayOfIndexes = visibleState
        .filter(
          (notification) =>
            notification.params.text !== failedValidationNotificationText
        )
        .map((notification) => notification.id);
      const earliestNotificationId = Math.min(...arrayOfIndexes);
      dispatch(setNotificationVisibilty(earliestNotificationId, false));
      setTimeout(() => {
        dispatch(setHideForever(earliestNotificationId));
      }, 250);
    }
  }, [notifications]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledNotificationWrapper>
      {notifications &&
        notifications.map((notification, index) => {
          return (
            <Notification
              key={index}
              ref={(el) => (notsRefs.current[index] = el)}
              notsRefs={notsRefs}
              notification={notification}
            />
          );
        })}
    </StyledNotificationWrapper>
  );
}

export default NotificationWrapper;

import React, { useState } from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { useSelector } from "react-redux";

import TeamListMember from "./TeamListMember";
import PendingMember from "./PendingMember";
import InviteModal from "./InviteModal";
import DeleteModal from "../KeysList/KeysListModals/DeleteModal";
import SortByModal from "./SortByModal";

const StyledTeamList = styled.div`
  padding: 0 32px;
  position: relative;
  max-height: calc(100% - 90px);
  .team-list {
    align-items: center;
    position: relative;
    margin: 29px auto 29px;
    max-width: 1376px;
    height: 100%;

    background: #ffffff;
    box-shadow: -10px 9px 21px rgba(35, 35, 35, 0.07);
    border-radius: 10px;
    padding-top: 17px;
  }
  .team-list__header {
    display: flex;
    justify-content: space-between;
    margin: 0 17px 17px;
  }
  .team-list__plain-info {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #232323;
  }
  .team-list__search-form {
    position: relative;
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #dddddd;

    border-radius: 4px;
    max-width: 240px;

    svg {
      position: absolute;
      pointer-events: none;
      left: 10px;
    }
    input {
      padding-left: 36px;
      background: transparent;
      border: none;
      border-radius: 100px;
      width: calc(100% - 36px);
      height: 26px;
    }
  }
  .team-list__sort-by {
    position: relative;
    margin-left: 15px;
    padding: 6px 10px;
    display: flex;
    align-items: center;
    max-width: 95px;
    z-index: 1;

    background: rgba(35, 35, 35, 0.1);
    border: 1px solid #ededed;
    border-radius: 4px;
    color: #5e5e5e;
    cursor: pointer;

    svg {
      margin-left: 10px;
    }
  }
  .team-list__websites-quantity {
    margin-left: 10px;
    padding: 3.5px 6px;
    background: #ededed;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
  }
  .team-list__invite-member {
    position: relative;
    margin-left: 24px;
    padding: 0 10px 0 14px;
    height: 25px;
    background: #ededed;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #505356;
    svg {
      margin-right: 10px;
    }
    &:hover {
      background: #f6f6f6;
    }
    &:active {
      background: #ededed;
      color: #232323;
    }
  }
  .team-list__filters {
    display: flex;
    align-items: center;
  }
  .team-table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    .team-table__body {
      display: block;
      height: 70vh;
      max-height: calc(100vh - 210px);
      overflow-y: auto;
    }

    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    color: #656565;
  }
  .team-table__pending {
    .team-list__plain-info {
      padding: 30px 0 15px 20px;
      border-bottom: 1px solid #f1f1f1;
    }
  }
  .team-table__header {
    display: flex;
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #e4e4e4;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.005em;

    color: #707683;
    opacity: 0.8;
    div {
      padding: 13px 0;
      text-align: center;
      &:first-child {
        width: 13.4%;
        padding-left: 30px;
        text-align: left;
      }
      &:nth-child(2) {
        width: 23%;
        text-align: left;
      }
      &:nth-child(3) {
        text-align: left;
        /* width: 17%; */
      }
    }
  }
  .memberSettingsModal-enter {
    opacity: 0.01;
  }
  .memberSettingsModal-enter-active {
    opacity: 1;
    transition: 0.15s;
  }
  .memberSettingsModal-exit {
    opacity: 1;
  }
  .memberSettingsModal-exit-active {
    opacity: 0.01;
    transition: 0.15s;
  }
`;

const TeamList = () => {
  const showDeleteModal = useSelector(
    ({ deleteKeysListItemModal }) => deleteKeysListItemModal.isVisible
  );

  const [useEffectTrigger, setUseEffectTrigger] = useState(0);

  const [substring, setSubstring] = useState("");
  const [sortType, setSortType] = useState("");

  const [inviteModalVisibility, setInviteModalVisibility] = useState(false);
  const [teamMembers, setTeamMembers] = useState(null);

  const onSearchForm = (e) => {
    setSubstring(e.target.value);
  };

  function sortTeamList() {
    let teamListClone = JSON.parse(JSON.stringify(teamMembers));
    switch (sortType) {
      case "alphabetInc":
        teamListClone = teamListClone.sort(function (a, b) {
          if (a.firstName < b.firstName) {
            return -1;
          }
          if (a.firstName > b.firstName) {
            return 1;
          }
          return 0;
        });
        setTeamMembers(teamListClone);

        return;
      case "alphabetDec":
        teamListClone = teamListClone.sort(function (a, b) {
          if (b.firstName < a.firstName) {
            return -1;
          }
          if (b.firstName > a.firstName) {
            return 1;
          }
          return 0;
        });
        setTeamMembers(teamListClone);
        return;
      case "recentlyAdded":
        teamListClone = teamListClone.sort(function (a, b) {
          const aDate = new Date(a.date).getTime();
          const bDate = new Date(b.date).getTime();
          if (aDate > bDate) {
            return -1;
          }
          if (bDate < aDate) {
            return 1;
          }
          return 0;
        });
        setTeamMembers(teamListClone);
        return;

      default:
        return;
    }
  }

  React.useEffect(() => {
    fetch("https://engine.keysforweb.com/wp-json/bda/v1/team", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") || sessionStorage.getItem("token")
        }`,
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data, "team members");
        setTeamMembers(data.data);
      });
  }, [useEffectTrigger]);

  React.useEffect(() => {
    sortTeamList(sortType);
  }, [sortType]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledTeamList>
      <div className="team-list">
        <div className="team-list__header">
          <div className="team-list__plain-info">
            Team Members{" "}
            <div className="team-list__websites-quantity">
              {teamMembers && teamMembers.length}
            </div>
            <div
              onClick={() => setInviteModalVisibility(true)}
              className="team-list__invite-member"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.83342 6.83366V11.0003C6.83342 11.4606 6.46032 11.8337 6.00008 11.8337C5.53984 11.8337 5.16675 11.4606 5.16675 11.0003V6.83366H1.00008C0.539844 6.83366 0.166748 6.46056 0.166748 6.00033C0.166748 5.54009 0.539844 5.16699 1.00008 5.16699H5.16675V1.00033C5.16675 0.540088 5.53984 0.166992 6.00008 0.166992C6.46032 0.166992 6.83342 0.540088 6.83342 1.00033V5.16699H11.0001C11.4603 5.16699 11.8334 5.54009 11.8334 6.00033C11.8334 6.46056 11.4603 6.83366 11.0001 6.83366H6.83342Z"
                  fill="#505356"
                />
              </svg>
              Invite Team Member
              {inviteModalVisibility && (
                <InviteModal
                  setInviteModalVisibility={setInviteModalVisibility}
                  setUseEffectTrigger={setUseEffectTrigger}
                />
              )}
            </div>
          </div>
          <div className="team-list__filters">
            {" "}
            <div className="team-list__search-form">
              <input
                onChange={(e) => onSearchForm(e)}
                value={substring}
                type="text"
                placeholder="Search team member..."
                results="0"
              />
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.5 11H11.71L11.43 10.73C12.63 9.33002 13.25 7.42001 12.91 5.39002C12.44 2.61002 10.12 0.390015 7.32002 0.0500152C3.09002 -0.469985 -0.469985 3.09002 0.0500152 7.32002C0.390015 10.12 2.61002 12.44 5.39002 12.91C7.42001 13.25 9.33002 12.63 10.73 11.43L11 11.71V12.5L15.25 16.75C15.66 17.16 16.33 17.16 16.74 16.75C17.15 16.34 17.15 15.67 16.74 15.26L12.5 11ZM6.50002 11C4.01002 11 2.00002 8.99002 2.00002 6.50002C2.00002 4.01002 4.01002 2.00002 6.50002 2.00002C8.99002 2.00002 11 4.01002 11 6.50002C11 8.99002 8.99002 11 6.50002 11Z"
                  fill="#707683"
                />
              </svg>
            </div>
            <SortByModal setSortType={setSortType} />
          </div>
        </div>

        <div className="team-table">
          <div className="team-table__header">
            <div>Name</div>
            <div>Email</div>
            <div>Role</div>
          </div>
          <div className="team-table__body">
            {teamMembers &&
              teamMembers
                .filter((member) => member.status === "joined")
                .filter(
                  (member) =>
                    (member.firstName + " " + member.lastName)
                      .toLowerCase()
                      .includes(substring.toLowerCase()) ||
                    member.email.includes(substring.toLowerCase())
                )
                .map((member, index) => {
                  return (
                    <TeamListMember
                      key={index}
                      name={member.firstName + " " + member.lastName}
                      email={member.email}
                      role={member.role}
                      avatar={member.avatar}
                    />
                  );
                })}
            {teamMembers &&
              teamMembers
                .filter((member) => member.status === "pending")
                .filter(
                  (member) =>
                    (member.firstName + " " + member.lastName)
                      .toLowerCase()
                      .includes(substring.toLowerCase()) ||
                    member.email.includes(substring.toLowerCase())
                ).length > 0 && (
                <div className="team-table__pending">
                  <div className="team-list__plain-info">
                    Pending Invites{" "}
                    <div className="team-list__websites-quantity">
                      {teamMembers &&
                        teamMembers.filter(
                          (member) => member.status === "pending"
                        ).length}
                    </div>
                  </div>
                  {teamMembers &&
                    teamMembers
                      .filter((member) => member.status === "pending")
                      .map((member, index) => {
                        return (
                          <PendingMember
                            key={index}
                            email={member.email}
                            setUseEffectTrigger={setUseEffectTrigger}
                          />
                        );
                      })}
                </div>
              )}
          </div>
        </div>

        {/* <div className="keys-table--mobile">
          <div className="keys-table--mobile__body">
            {keysListIsLoading && <KeysListItemMobileSkeleton />}
        {!keysListIsLoading &&
          keysList &&
          keysList.length !== 0 &&
          keysList.map((localState, index) => {
            let localStateFirst = localState[0];
            // console.log(localStateFirst);
            return (
              <KeysListItemMobile
                key={index}
                localState={localStateFirst}
                substring={substring}
              />
            );
          })}
          </div>
        </div> */}

        <CSSTransition
          classNames="memberSettingsModal"
          in={showDeleteModal}
          timeout={150}
          unmountOnExit
        >
          <DeleteModal
            type={"teamMember"}
            setUseEffectTrigger={setUseEffectTrigger}
          />
        </CSSTransition>
      </div>
    </StyledTeamList>
  );
};

export default TeamList;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import useNotification from "../../hooks/useNotification";

import Button from "../Button";

import { setSecureVaultOn } from "../../redux/actions/setSecureVault";
import { setDeleteModalOn } from "../../redux/actions/setDeleteKeysListItemModal";

function KeysListItemMobile({ localState, substring }) {
  const showNotification = useNotification();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState();

  const clientUniqueHandle = localState[localState.length - 1].find(
    (item) => item.type === "clientUniqueHandle"
  ).value;

  function checkTimeRemaining() {
    if (
      localState[localState.length - 1].find(
        (item) => item.type === "expirationDate"
      )
    ) {
      const expDate = Date.parse(
        localState[localState.length - 1].find(
          (item) => item.type === "expirationDate"
        ).value
      );
      const currentDate = Date.parse(
        new Date(new Date().toUTCString().substr(0, 25))
      );

      setTimeRemaining((expDate - currentDate) / 1000 / 60 / 60);

      if (currentDate >= expDate) {
        setIsVisible(false);
      }
    }
  }

  const copyRequestLinkToClipboard = () => {
    const requestLinkid = localState.find((item) => item.type === "formId").id;
    const currentRequestLink = `https://app.keysforweb.com/requestlink#${requestLinkid}`;

    const clipboardDummy = document.createElement("textarea");
    document.body.appendChild(clipboardDummy);
    clipboardDummy.style.opacity = "0";
    clipboardDummy.value = currentRequestLink;
    clipboardDummy.select();
    document.execCommand("copy");
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(document.createRange());
    document.body.removeChild(clipboardDummy);

    showNotification(
      "success",
      "Copied",
      `Request link copied to clipboard`,
      2000
    );
  };

  React.useEffect(() => {
    window.addEventListener("storage", () => {
      setIsCompleted(true);
    });
    setIsCompleted(
      localState[localState.length - 1].find(
        (item) => item.type === "isCompleted"
      ).value
    );

    if (
      substring.length !== 0 &&
      !clientUniqueHandle.toLowerCase().includes(substring.toLowerCase())
    ) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [substring]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    checkTimeRemaining();
    setInterval(checkTimeRemaining, 60000);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={`keys-table--mobile__item ${!isVisible ? "isNotVisible" : ""}`}
    >
      <div className="keys-table--mobile__item__client-name">
        {isCompleted && (
          <svg
            className="secure-vault-eye-mobile"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setSecureVaultOn(localState));
            }}
            width="22"
            height="16"
            viewBox="0 0 22 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 0.833374C6 0.833374 1.73 3.81379 0 8.02087C1.73 12.228 6 15.2084 11 15.2084C16 15.2084 20.27 12.228 22 8.02087C20.27 3.81379 16 0.833374 11 0.833374ZM11 12.8125C8.24 12.8125 6 10.6659 6 8.02087C6 5.37587 8.24 3.22921 11 3.22921C13.76 3.22921 16 5.37587 16 8.02087C16 10.6659 13.76 12.8125 11 12.8125ZM11 5.14587C9.34 5.14587 8 6.43004 8 8.02087C8 9.61171 9.34 10.8959 11 10.8959C12.66 10.8959 14 9.61171 14 8.02087C14 6.43004 12.66 5.14587 11 5.14587Z"
              fill="#5BC044"
            />
          </svg>
        )}
        <h4>Client name</h4>
        <Button color={"white"} text={`@${localState && clientUniqueHandle}`} />
      </div>
      <div className="keys-table--mobile__item__request-link">
        <h4>Request link</h4>
        <Button
          color={"purple"}
          text={`Copy request link`}
          onClick={() => copyRequestLinkToClipboard()}
        />
      </div>
      <div className="keys-table--mobile__item__expires-in">
        <h4>Expires in</h4>
        <Button
          color={"white"}
          text={
            isCompleted &&
            (timeRemaining > 1
              ? `${Math.round(timeRemaining)} h`
              : `${Math.round(timeRemaining * 60)} min`)
          }
        />
      </div>
      <div className="keys-table--mobile__item__website-url">
        <h4>Website url</h4>
        <Button color={"white"} text={`Copy website URL`} />
      </div>
      <div className="keys-table--mobile__item__delete-request">
        <h4>Delete</h4>
        <Button
          color={"white"}
          text={`Delete request`}
          onClick={() => dispatch(setDeleteModalOn(localState))}
        />
      </div>
    </div>
  );
}

export default KeysListItemMobile;

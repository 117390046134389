import React, { useState } from "react";
import styled from "styled-components";

const StyledBilling = styled.div`
  h2 {
    margin-left: 30px;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #232323;
  }

  height: 100%;
  max-height: 810px;
  margin: 30px auto 20px;
  width: 75vw;
  max-width: 1120px;

  .inner__content--billing {
    background: #ffffff;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding-top: 30px;
    width: 100%;
    max-width: 1030px;
    .billing__header {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      .billing__change-payment-method-button {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        padding: 4px 10px;
        background: #5541d7;
        color: #ffffff;
        border-radius: 4px;
        margin-left: 24px;
        transition: 0.3s;
        cursor: pointer;
        a {
          text-decoration: none;
          color: #ffffff;
        }
        &:hover {
          background: #8476e3;
        }
      }
    }
    .billing__table {
      height: 100vh;
      max-height: calc(100vh - 250px);
      overflow-y: auto;
      .billing__placeholder {
        font-weight: 300;
        font-size: 18px;
        line-height: 23px;
        color: #505356;
        margin-top: 10px;
        margin-left: 30px;
      }
    }
  }

  .billing__titles {
    height: 47px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    border-bottom: 1px solid #dddddd;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #7b7b7b;

    .billing__date-title {
      width: 16%;
    }
    .billing__status-title {
      width: 14%;
    }
    .billing__name-title {
      width: 18.4%;
    }
    .billing__method-title {
      width: 18.5%;
    }
    .billing__amount-title {
      width: 11.5%;
    }
  }
  .billing__item {
    height: 63px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    border-bottom: 1px solid #dddddd;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #232323;
    .billing__date {
      width: 16.1%;
    }
    .billing__status {
      width: 14%;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      .received {
        background: #f2faf8;
        border-radius: 50px;
        padding: 4px 10px;
        color: #458072;
        max-width: fit-content;
      }
      .failed {
        background: #fff0ef;
        border-radius: 50px;
        padding: 4px 10px;
        color: #ba4b49;
        max-width: fit-content;
      }
    }
    .billing__name {
      width: 18.4%;
    }
    .billing__method {
      width: 18.5%;
    }
    .billing__amount {
      width: 11.5%;
    }
  }
  .billing__invoice {
    position: relative;
    display: flex;
    padding: 5px 10px 5px 36px;
    background: #8b8f9a;
    border-radius: 4px;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    cursor: pointer;
    svg {
      position: absolute;
      left: 14px;
    }
    &:hover {
      background: #aeb1b8;
    }
    &:active {
      background: #81858b;
    }
    transition: 0.2s;
  }
`;

function Billing() {
  const [billingData, setBillingData] = useState(null);

  React.useEffect(() => {
    fetch("https://engine.keysforweb.com/wp-json/bda/v1/billings", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") || sessionStorage.getItem("token")
        }`,
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data, "billing");
        setBillingData(data);
      });
  }, []);

  return (
    <StyledBilling>
      <div className="inner__content--billing">
        <div className="billing__header">
          {" "}
          <h2>Billing</h2>
          <div className="billing__change-payment-method-button">
            <a
              // rel="noreferrer"
              // target="_blank"
              href={billingData && billingData.paymentMethodUpdateURL}
            >
              {" "}
              Change Payment Method{" "}
            </a>
          </div>
        </div>
        <div className="billing__table">
          {billingData && billingData.payments.length === 0 && (
            <div className="billing__placeholder">
              This account has no payments yet.
            </div>
          )}
          {billingData && billingData.payments.length > 0 && (
            <div className="billing__titles">
              <div className="billing__date-title">Date</div>
              <div className="billing__status-title">Status</div>
              <div className="billing__name-title">Name on the Card</div>
              <div className="billing__method-title">Payment Method</div>
              <div className="billing__amount-title">Amount</div>
              <div className="billing__invoice-title">Invoice</div>
            </div>
          )}
          {billingData &&
            billingData.payments.map((payment, index) => {
              return (
                <div key={index} className="billing__item">
                  <div className="billing__date">{payment.date}</div>
                  <div className="billing__status">
                    {" "}
                    <div className="received">Received</div>{" "}
                  </div>
                  <div className="billing__name">{payment.nameOnCard}</div>
                  <div className="billing__method">
                    {" "}
                    {payment.payment_method.brand.charAt(0).toUpperCase() +
                      payment.payment_method.brand.slice(1)}{" "}
                    {payment.payment_method.last4}{" "}
                  </div>
                  <div className="billing__amount"> ${payment.amount} </div>
                  <div className="billing__invoice">
                    {" "}
                    <div
                      onClick={() => window.open(payment.invoiceDownloadLink)}
                      className="download-invoice"
                    >
                      <svg
                        width="12"
                        height="18"
                        viewBox="0 0 12 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.8334 17.3337H0.166748V15.667H11.8334V17.3337ZM6.00008 14.0003L1.00008 9.00033L2.17508 7.82533L5.16675 10.8087V0.666992H6.83342V10.8087L9.82508 7.82533L11.0001 9.00033L6.00008 14.0003Z"
                          fill="#ffffff"
                        />
                      </svg>
                      Download Invoice
                    </div>{" "}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </StyledBilling>
  );
}

export default Billing;

import React, { useState, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

import TeamListSettingsModal from "./TeamListSettingsModal";

const StyledTeamListMember = styled.div`
  /* width: 100%; */
  display: flex;
  position: relative;
  align-items: center;
  padding: 12px 30px;
  border-bottom: 1px solid #f1f1f1;
  .team-member__name {
    width: 14%;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #232323;
    .team-member__image {
      margin-right: 10px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #f6f6f6;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .team-member__email {
    width: 24%;
  }

  .team-table__settings-dots {
    position: relative;
    margin: 0 30px 0 auto;
    cursor: pointer;
  }
  svg {
    position: relative;
  }
  cursor: default;

  .memberSettingsModal-enter {
    opacity: 0.01;
  }
  .memberSettingsModal-enter-active {
    opacity: 1;
    transition: 0.15s;
  }
  .memberSettingsModal-exit {
    opacity: 1;
  }
  .memberSettingsModal-exit-active {
    opacity: 0.01;
    transition: 0.15s;
  }
`;

const TeamListMember = ({ name, avatar, email, role }) => {
  const [showTeamListSettingsModal, setShowTeamListSettingsModal] =
    useState(false);

  const memberSettingsRef = useRef(null);

  return (
    <StyledTeamListMember>
      <div className="team-member__name">
        {!avatar ? (
          <div className="team-member__image">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 9C11.4862 9 13.5 6.98625 13.5 4.5C13.5 2.01375 11.4862 0 9 0C6.51375 0 4.5 2.01375 4.5 4.5C4.5 6.98625 6.51375 9 9 9ZM9 2.25C10.2375 2.25 11.25 3.2625 11.25 4.5C11.25 5.7375 10.2375 6.75 9 6.75C7.7625 6.75 6.75 5.7375 6.75 4.5C6.75 3.2625 7.7625 2.25 9 2.25ZM9 10.125C5.99625 10.125 0 11.6325 0 14.625V16.875C0 17.4937 0.50625 18 1.125 18H16.875C17.4937 18 18 17.4937 18 16.875V14.625C18 11.6325 12.0037 10.125 9 10.125ZM15.75 15.75H2.25V14.6363C2.475 13.8263 5.9625 12.375 9 12.375C12.0375 12.375 15.525 13.8263 15.75 14.625V15.75Z"
                fill="#8B8F9A"
              />
            </svg>
          </div>
        ) : (
          <img
            className={"team-member__image"}
            src={avatar}
            alt="memberAvatar"
          />
        )}
        {name}
      </div>
      <div className="team-member__email"> {email} </div>
      <div className="team-member__role"> {role} </div>

      {role !== "Administrator" && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowTeamListSettingsModal(true);
          }}
          className="team-table__settings-dots"
        >
          <svg
            width="16"
            height="4"
            viewBox="0 0 16 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 4C12.8954 4 12 3.10457 12 2C12 0.89543 12.8954 0 14 0C15.1046 0 16 0.89543 16 2C16 2.53043 15.7893 3.03914 15.4142 3.41421C15.0391 3.78929 14.5304 4 14 4ZM8 4C6.89543 4 6 3.10457 6 2C6 0.89543 6.89543 0 8 0C9.10457 0 10 0.89543 10 2C10 2.53043 9.78929 3.03914 9.41421 3.41421C9.03914 3.78929 8.53043 4 8 4ZM2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4Z"
              fill="#81858B"
            />
          </svg>

          <CSSTransition
            classNames="memberSettingsModal"
            in={showTeamListSettingsModal}
            timeout={300}
            onExited={() => console.log("exited")}
            unmountOnExit
          >
            <TeamListSettingsModal
              ref={memberSettingsRef}
              setShowTeamListSettingsModal={setShowTeamListSettingsModal}
              text={"Delete team member"}
              memberEmail={email}
            />
          </CSSTransition>
        </div>
      )}
      {/* {showTeamListSettingsModal && (
        <TeamListSettingsModal
          ref={memberSettingsRef}
          setShowTeamListSettingsModal={setShowTeamListSettingsModal}
        />
      )} */}
    </StyledTeamListMember>
  );
};

export default TeamListMember;

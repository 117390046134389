import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import styled from "styled-components";

import { Profile, Billing, Upgrade, DeleteAccountModal } from "../components";
import { setSettingsContent } from "../redux/actions/setSettingsContent.js";
import { setUpgradeModalVisibility } from "../redux/actions/setUpgradeModalVisibility";

const StyledSettingsPage = styled.div`
  display: flex;
  height: 100vh;
  max-height: calc(100vh - 100px);

  .settings-content {
    margin: 0 auto;
  }
  .settings__side-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    height: calc(100vh - 70px);
    min-width: 247px;
    max-height: calc(100vh - 70px);
    background: #ffffff;

    border-right: 1px solid #dddddd;
    h2 {
      margin: 30px 121px 0px 32px;
      padding-bottom: 20px;

      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      color: #505356;
    }

    button {
      width: 124px;
      padding-right: 8px;
      margin-top: 20px;
    }

    h3 {
      position: relative;

      display: flex;
      align-items: center;
      width: calc(100% - 32px);

      padding: 16px 0 16px 32px;

      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #505356;
      cursor: pointer;
      transition: 0.2s;
      &.profile {
        svg {
          margin-left: 3px;
          margin-right: 18px;
        }
      }
      svg {
        margin-right: 17px;
      }
      &.activeSetting {
        transition: 0.2s;
        &:after {
          transition: 0.2s;
          content: "";
          position: absolute;
          top: 15%;
          right: 0;
          height: 70%;
          width: 3px;
          background: #5541d7;
        }
        &:hover {
          &:after {
            transition: 0.2s;
            content: "";
            position: absolute;
            top: 15%;
            right: 0;
            height: 70%;
            width: 3px;
            background: #5541d7;
          }
        }
      }
      &:hover {
        &:after {
          transition: 0.2s;
          content: "";
          position: absolute;
          top: 20%;
          right: 0;
          height: 60%;
          width: 3px;
          background: #cfc9f7;
        }
      }
    }
    .logout {
      margin: 0 0 30px 1px;
      border-top: 1px solid #dddddd;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #8b8f9a;
      &:hover {
        color: #5541d7;
        svg {
          path {
            fill: #5541d7;
          }
        }
        &:after {
          background: transparent;
        }
      }
      svg {
        margin-right: 17px;
      }
    }
    .button {
      margin-left: 32px;
    }
  }

  .settings__inner {
    h2 {
      padding-top: 30px;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      color: #232323;
    }

    height: 100%;
    max-height: 810px;
    margin: 30px auto 20px;
    width: 75vw;
    max-width: 1120px;
  }
  .settingsChild-appear {
    opacity: 0.01;
    transform: translateX(12%);
  }
  .settingsChild-appear.settingsChild-appear-active {
    opacity: 1;
    transform: translateX(0%);
    transition: 0.3s;
  }
  @media (max-width: 1000px) {
    .settings__side-menu {
      display: none;
    }
    .settings__inner {
      h2 {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: center;

        padding-bottom: 18px;
      }

      height: 90%;
      margin: 30px auto 20px;
      width: 92vw;
      max-width: 1120px;
    }
  }
`;

function Settings({ setLogoutVisibility, query }) {
  const settingsContent = useSelector(
    ({ settingsContent }) => settingsContent.content
  );
  const currentUserRole = useSelector(
    ({ currentUserReducer }) => currentUserReducer.currentUser.role
  );
  const subscriptionType = useSelector(
    ({ subscriptionType }) => subscriptionType.subscriptionType
  );
  const dispatch = useDispatch();

  const [deleteAccountVisibility, setDeleteAccountVisibility] = useState(false);

  const onSelectSettingsContent = (content) => {
    dispatch(setSettingsContent(content));
  };

  return (
    <StyledSettingsPage>
      <div className="settings__side-menu">
        <h2>Settings</h2>
        <h3
          className={
            settingsContent === "profile" ? "profile activeSetting" : "profile"
          }
          onClick={() => onSelectSettingsContent("profile")}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 9C11.4862 9 13.5 6.98625 13.5 4.5C13.5 2.01375 11.4862 0 9 0C6.51375 0 4.5 2.01375 4.5 4.5C4.5 6.98625 6.51375 9 9 9ZM9 2.25C10.2375 2.25 11.25 3.2625 11.25 4.5C11.25 5.7375 10.2375 6.75 9 6.75C7.7625 6.75 6.75 5.7375 6.75 4.5C6.75 3.2625 7.7625 2.25 9 2.25ZM9 10.125C5.99625 10.125 0 11.6325 0 14.625V16.875C0 17.4937 0.50625 18 1.125 18H16.875C17.4937 18 18 17.4937 18 16.875V14.625C18 11.6325 12.0037 10.125 9 10.125ZM15.75 15.75H2.25V14.6363C2.475 13.8263 5.9625 12.375 9 12.375C12.0375 12.375 15.525 13.8263 15.75 14.625V15.75Z"
              fill="#505356"
            />
          </svg>
          Profile
        </h3>
        {(currentUserRole === "team-admin" || currentUserRole === "client") &&
          subscriptionType !== "lifetime" && (
            <h3
              className={settingsContent === "billing" ? "activeSetting" : ""}
              onClick={() => onSelectSettingsContent("billing")}
            >
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V2C20 0.89 19.11 0 18 0ZM17 14H3C2.45 14 2 13.55 2 13V8H18V13C18 13.55 17.55 14 17 14ZM18 4H2V3C2 2.45 2.45 2 3 2H17C17.55 2 18 2.45 18 3V4Z"
                  fill="#505356"
                />
              </svg>
              Billing
            </h3>
          )}
        {(currentUserRole === "team-admin" || currentUserRole === "client") &&
          subscriptionType !== "lifetime" && (
            <h3
              onClick={() => dispatch(setUpgradeModalVisibility(true))}
              className={
                settingsContent === "upgrade" ? "upgrade activeSetting" : ""
              }
            >
              {" "}
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20ZM9.984 18H10C14.4167 17.9956 17.9942 14.4127 17.992 9.996C17.9898 5.57929 14.4087 2 9.992 2C5.57528 2 1.99421 5.57929 1.992 9.996C1.98979 14.4127 5.56729 17.9956 9.984 18ZM11 14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V8.83L7.11568 10.707C6.72572 11.0955 6.09489 11.0949 5.70568 10.7057C5.31594 10.3159 5.31594 9.68406 5.70568 9.29432L9.29289 5.70711C9.68342 5.31658 10.3166 5.31658 10.7071 5.70711L14.2943 9.29432C14.6841 9.68406 14.6841 10.3159 14.2943 10.7057C13.9051 11.0949 13.2743 11.0955 12.8843 10.707L11 8.83V14Z"
                  fill="#5541D7"
                />
              </svg>
              Upgrade
            </h3>
          )}
        <h3
          className="logout"
          onClick={() => setLogoutVisibility(true)}
          //  onClick={() => handleLogout()}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 18H7C5.89543 18 5 17.1046 5 16V12H7V16H16V2H7V6H5V2C5 0.89543 5.89543 0 7 0H16C17.1046 0 18 0.89543 18 2V16C18 17.1046 17.1046 18 16 18ZM9 13V10H0V8H9V5L14 9L9 13Z"
              fill="#8B8F9A"
            />
          </svg>
          Log out
        </h3>
      </div>
      {settingsContent === "profile" && (
        <TransitionGroup className="settings-content profile">
          <CSSTransition
            classNames="settingsChild"
            appear={true}
            in={true}
            timeout={{ enter: 300 }}
          >
            <Profile setDeleteAccountVisibility={setDeleteAccountVisibility} />
          </CSSTransition>
        </TransitionGroup>
      )}
      {settingsContent === "billing" && (
        <TransitionGroup className="settings-content billing">
          <CSSTransition
            classNames="settingsChild"
            appear={true}
            in={true}
            timeout={{ enter: 300 }}
          >
            <Billing />
          </CSSTransition>
        </TransitionGroup>
      )}
      {settingsContent === "upgrade" && (
        <TransitionGroup className="settings-content upgrade">
          <CSSTransition
            classNames="settingsChild"
            appear={true}
            in={true}
            timeout={{ enter: 300 }}
          >
            <Upgrade />
          </CSSTransition>
        </TransitionGroup>
      )}
      {deleteAccountVisibility && (
        <DeleteAccountModal
          deleteAccountVisibility={deleteAccountVisibility}
          setDeleteAccountVisibility={setDeleteAccountVisibility}
        />
      )}
    </StyledSettingsPage>
  );
}

export default Settings;

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Button from "../components/Button";
import { useSelector } from "react-redux";

const StyledPage404 = styled.div`
  margin: 70px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-weight: bold;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #232323;
  }
  h4 {
    margin-top: 10px;
    max-width: 384px;
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #5e5e5e;
  }
  a {
    text-decoration: none;
  }
  .button {
    min-width: 172px;
    margin-top: 30px;
  }
`;

const Page404 = () => {
  const currentUserState = useSelector(
    ({ currentUserReducer }) => currentUserReducer
  );
  return (
    <StyledPage404>
      <svg
        width="477"
        height="381"
        viewBox="0 0 477 381"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d)">
          <rect
            x="31.5"
            y="15.5"
            width="431"
            height="333"
            rx="15.5"
            fill="#F6F6F6"
            stroke="#E2E4E4"
          />
          <path
            d="M31 31C31 22.1634 38.1634 15 47 15H247V349H47C38.1634 349 31 341.837 31 333V31Z"
            fill="white"
          />
          <path
            d="M398 12V54C398 60.6274 403.373 66 410 66H466"
            stroke="#E2E4E4"
            stroke-width="4"
          />
          <path
            d="M247 292H313.726C320.354 292 325.726 286.627 325.726 280V222.5C325.726 215.873 331.099 210.5 337.726 210.5H373.899C380.527 210.5 385.899 205.127 385.899 198.5V194C385.899 187.373 391.272 182 397.899 182H409C415.627 182 421 176.627 421 170V122.5C421 115.873 415.627 110.5 409 110.5H332.712C326.084 110.5 320.712 105.127 320.712 98.5V57C320.712 50.3726 315.339 45 308.712 45H247"
            stroke="#E2E4E4"
            stroke-width="4"
          />
          <circle cx="55" cy="35" r="8" fill="#EA3527" />
          <circle cx="77" cy="35" r="8" fill="#EF8942" />
          <circle cx="99" cy="35" r="8" fill="#65C4B0" />
          <path
            d="M278 148H344C350.627 148 356 142.627 356 136V111"
            stroke="#4D4D4D"
            stroke-width="3"
          />
          <path
            d="M463 236H389C382.373 236 377 230.627 377 224V211"
            stroke="#4D4D4D"
            stroke-width="3"
          />
          <circle cx="355.5" cy="109.5" r="5.5" fill="#4D4D4D" />
          <circle cx="436" cy="66" r="6" fill="#E2E4E4" />
          <circle cx="368" cy="321" r="6" fill="#E2E4E4" />
          <circle cx="288" cy="292" r="6" fill="#4D4D4D" />
          <path d="M288 292V350" stroke="#4D4D4D" stroke-width="4" />
          <path
            d="M368 351V296C368 289.373 373.373 284 380 284H464"
            stroke="#E2E4E4"
            stroke-width="4"
          />
          <circle r="6" transform="matrix(-1 0 0 1 377 210)" fill="#4D4D4D" />
          <path d="M247 239V122L198 155.789V204.203L247 239Z" fill="#E8E8E8" />
          <path d="M247 239V122L296 155.789V204.203L247 239Z" fill="#4D4D4D" />
          <circle cx="247" cy="180" r="20" fill="white" />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0"
            width="477"
            height="381"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="-10" dy="9" />
            <feGaussianBlur stdDeviation="10.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.137255 0 0 0 0 0.137255 0 0 0 0 0.137255 0 0 0 0.07 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
      </svg>

      <h2>404</h2>
      {currentUserState.isLoggedIn ? (
        <h4>
          Looks like the page you're looking for is missing. Check for any typos
          or try to start your search anew from the Home Page.
        </h4>
      ) : (
        <h4>
          Request you're attempting to visit doesn't exist. Check your URL for
          typos or contact your requester.
        </h4>
      )}

      {currentUserState.isLoggedIn && (
        <Link to="/">
          <Button color={"purple"} text={"Back to Home"} />
        </Link>
      )}
    </StyledPage404>
  );
};

export default Page404;

import React, { useState } from "react";
import { useSelector } from "react-redux";

function RequestLinkField({
  badRedTrigger,
  handleChangeFieldValue,
  currentForm,
  title,
  inner,
  id,
  outerId,
  dropdown,
  item,
  ftpPortTrigger,
}) {
  const [requestLinkFieldValue, setRequestLinkFieldValue] = useState("");

  const [badRed, setBadRed] = useState(false);

  const [dropdownVisibility, setDropdownVisibility] = useState(false);

  const notifications = useSelector(
    ({ notificationState }) => notificationState
  );

  function showDropdown() {
    setDropdownVisibility(true);
  }

  const setPlaceholderValue = () => {
    switch (title) {
      case "WP-ADMIN URL":
        return "https://example.com/wp-admin/";
      case "LOGIN (USERNAME OR EMAIL)":
        return "admin";
      case "PORT":
        return "21/22";
      case "HOSTNAME":
        return "ftp.example.com";
      case "login":
        return "login12345";
      default:
        return;
    }
  };

  const handleChangeFieldValueComponent = (value, inner, id, outerId, item) => {
    setRequestLinkFieldValue(value);
    handleChangeFieldValue(value, inner, id, outerId, item);
  };

  React.useEffect(() => {
    if (notifications.length > 0 && badRedTrigger !== 0) {
      if (requestLinkFieldValue === "") {
        setBadRed(true);
        setTimeout(() => {
          setBadRed(false);
        }, 1500);
      }
    }
  }, [badRedTrigger]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (dropdown) {
      handleChangeFieldValueComponent("FTP", inner, id, outerId, item);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (item.serviceTag === "ftpPort") {
      if (
        currentForm.find((item) => item.serviceTag === "ftpConnectionType")
          .value === "FTP"
      ) {
        handleChangeFieldValueComponent("21", inner, id, outerId, item);
      } else if (
        currentForm.find((item) => item.serviceTag === "ftpConnectionType")
          .value === "SFTP"
      ) {
        handleChangeFieldValueComponent("22", inner, id, outerId, item);
      }
    }
  }, [ftpPortTrigger]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={`requestlink__field `}>
      <label htmlFor="">{title}</label>
      {dropdown && dropdownVisibility && (
        <div className={`ftp-sftp-dropdown`}>
          <div
            onClick={() => setDropdownVisibility(false)}
            className="ftp-sftp-dropdown__current"
          >
            {" "}
            <span>{requestLinkFieldValue}</span>
          </div>
          <div
            onClick={(e) => {
              handleChangeFieldValueComponent("FTP", inner, id, outerId, item);
              setDropdownVisibility(false);
            }}
            className="ftp-sftp-dropdown__ftp"
          >
            <span>FTP</span>
          </div>
          <div
            onClick={(e) => {
              handleChangeFieldValueComponent("SFTP", inner, id, outerId, item);
              setDropdownVisibility(false);
            }}
            className="ftp-sftp-dropdown__ftp"
          >
            <span>SFTP</span>
          </div>
        </div>
      )}

      <div className={`input-wrapper ${badRed ? "badred" : ""} `}>
        <input
          value={requestLinkFieldValue}
          onChange={(e) =>
            handleChangeFieldValueComponent(
              e.target.value,
              inner,
              id,
              outerId,
              item
            )
          }
          type="text"
          placeholder={badRed ? "Fill form" : setPlaceholderValue()}
          readOnly={dropdown}
          className={`${dropdown ? "withDropDown" : ""} `}
          onClick={dropdown ? showDropdown : null}
        />
      </div>
    </div>
  );
}

export default RequestLinkField;

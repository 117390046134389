import React from "react";

export const requestLinkNeedHelpItems = [
  {
    subtitle: "Why did I receive this request?",
    text: (
      <div className="need-help-modal__text">
        <p>
          Your business partner sent this request to get admin-level access to
          your WordPress website. It can be necessary for various development
          and maintenance works. If you trust this person and agree to share
          your admin privileges with them, you can proceed with filling out the
          form(s).
        </p>
        <p>
          If you do not know the person who sent the request and/or do not wish
          to share your access keys, ignore this request and do not fill out the
          form(s). Make sure you share your sensitive information like login or
          password only with trusted people and legal entities. Should you get
          an unwanted request again, please contact us at info@keysforweb.com.
        </p>
      </div>
    ),
  },
  {
    subtitle: "What should I do??",
    text: (
      <div className="need-help-modal__text">
        <p>
          Provide your credentials for the WordPress admin area (dashboard). If
          you can’t remember your password but are still logged in, you can
          change it in Users > Profile on the WordPress admin dashboard.
        </p>
        <p>
          1. Fill the fields of the form (hover over the form tips for more
          information).
        </p>
        <p>
          2. Click Verify to let the bot check the validity of the data. If the
          check is successful, the keys will be sent automatically. You’ll be
          able to send the keys even if they don’t pass the check - your
          counterpart will receive the form with the corresponding note.{" "}
        </p>
        <p>
          Once that is done, the sender of this request has 24 hours to copy the
          keys from the form. No one else can view the data. After 24 hours
          pass, the data will be erased completely.
        </p>
      </div>
    ),
  },
  {
    subtitle: "Is it safe?",
    text: (
      <div className="need-help-modal__text">
        <p>
          Absolutely. This form uses the most up-to-date algorithms of
          encryption to make sure no one apart from you and the sender of this
          request catches your data. We don’t store it either - your keys will
          only be in the form for as long as 24 hours. And, of course, the whole
          process is protected from any interference or data loss - your access
          keys are safe and sound.
        </p>
      </div>
    ),
  },
];

export const mainScreenNeedHelpItems = [
  {
    subtitle: "How to request the keys?",
    text: (
      <div className="need-help-modal__text">
        <p>
          1. Click the + Keys Request button. <br />
          2. Select the template from the Choose Saved Template menu or Build a
          custom request: add fields, group them if necessary and name each
          field and group. <br />
          3. You can save your custom template if you’ve built a new form.{" "}
          <br />
          4. Click Generate Request and copy the link. <br />
        </p>
        <p>
          Later on, you’ll be able to send a saved request template with the
          Request button next to a site on the list on the main page.
        </p>
      </div>
    ),
  },
  {
    subtitle: "Where do I find templates?",
    text: (
      <div className="need-help-modal__text">
        <p>
          All your templates reside on the main profile page: click Request to
          send them. Besides, you can find your templates in the Request
          Builder: <br />
          1. Open it (+ Keys Request button) <br />
          2. Click Choose Saved Template <br />
        </p>
        <p>
          {" "}
          The menu with your templates will drop down from that field - select
          the one you need.
        </p>
      </div>
    ),
  },
  {
    subtitle: "Do form templates store keys, too?",
    text: (
      <div className="need-help-modal__text">
        <p>
          No. We don’t store keys in any form anywhere after their
          self-deletion.
        </p>
      </div>
    ),
  },
  {
    subtitle: "I received several versions of the form. Why is that?",
    text: (
      <div className="need-help-modal__text">
        <p>
          If you receive the form 2 or 3 times, it means your counterpart
          decided something was wrong with their first try. Most likely, the
          latest version (first on the list) is the correct one.
        </p>
      </div>
    ),
  },
  {
    subtitle: "Do you verify all types of keys??",
    text: (
      <div className="need-help-modal__text">
        <p>
          Verification works in the prebuilt templates for the most popular
          types of keys. Unfortunately, the system can’t check the keys from the
          custom forms. However, we’re working on broadening the range of
          verifiable templates.
        </p>
      </div>
    ),
  },
  {
    subtitle: "Can people send keys without verification??",
    text: (
      <div className="need-help-modal__text">
        <p>
          Verification is obligatory where applicable. However, if keys don’t
          pass the verification, the person can choose to send them anyway. In
          that case, the keys come with a warning saying they haven’t passed the
          check.
        </p>
      </div>
    ),
  },
  {
    subtitle: "How do I invite team members?",
    text: (
      <div className="need-help-modal__text">
        <p>
          You need to be on the Team or Enterprise plan and be the admin. If you
          are, go to the Teams page and click Invite Team Member.
        </p>
      </div>
    ),
  },
  {
    subtitle: "Can team members view the keys I requested?",
    text: (
      <div className="need-help-modal__text">
        <p>No. Each member can only view the keys they requested.</p>
      </div>
    ),
  },
];

import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { logoutUser } from "../redux/actions/loginActions";
import { removeAllNotifications } from "../redux/actions/setNotification";

import Button from "./Button";

const StyledLogout = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  margin-left: -245px;
  transition: 0.2s;
  visibility: ${({ logoutVisibility }) =>
    logoutVisibility ? "visible" : "hidden"};
  opacity: ${({ logoutVisibility }) => (logoutVisibility ? "1" : "0")};
  .logout-cool-icon {
    margin-bottom: 16px;
    padding: 14px;
    background: #e9e9e9;
    border-radius: 8px;
  }
  .logout-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 10px;
    position: relative;
    background: #ffffff;
    border-radius: 20px;
    z-index: 1;

    width: 100vw;
    max-width: 489px;

    h3 {
      margin-bottom: 8px;

      font-weight: bold;
      font-size: 24px;
      line-height: 120%;
      text-align: center;
      letter-spacing: 0.005em;
      color: #232323;
    }
    h4 {
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.005em;
      color: #5e5e5e;
    }
  }
  .logout-modal__buttons {
    display: flex;
    margin-top: 30px;
    .button {
      min-width: 138px;
      &:first-child {
        margin-right: 23px;
      }
    }
  }
  .bg-layer {
    background: #000;
    opacity: 0.6;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1000px) {
    .logout-modal--wrapper {
      margin-left: -220px;
    }
    .logout-modal {
      max-width: 439px;
    }
  }
  @media (max-width: 561px) {
    .logout-modal--wrapper {
      margin-left: -180px;
    }
    .logout-modal {
      max-width: 360px;
    }
  }
  @media (max-width: 431px) {
    .logout-modal--wrapper {
      margin-left: -160px;
    }
    .logout-modal {
      max-width: 300px;
    }
  }
  @media (max-width: 331px) {
    .logout-modal--wrapper {
      margin-left: -127px;
    }
    .logout-modal {
      max-width: 235px;
      h3 {
        font-size: 20px;
      }
      h4 {
        font-size: 14px;
      }
    }
  }
`;

const Logout = ({ logoutVisibility, setLogoutVisibility }) => {
  const dispatch = useDispatch();

  const logoutModalRef = React.useRef();

  const handleLogout = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    dispatch(logoutUser());
    dispatch(removeAllNotifications());

    setLogoutVisibility(false);

    window.location = "https://engine.keysforweb.com/logout/";
  };

  const handleOutsideLogoutModalClick = (e) => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (!path.includes(logoutModalRef.current)) {
      setLogoutVisibility(false);
    }
  };

  React.useEffect(() => {
    if (logoutVisibility) {
      document.body.addEventListener("click", handleOutsideLogoutModalClick);
    }

    return function remove() {
      document.body.removeEventListener("click", handleOutsideLogoutModalClick);
    };
  }, [logoutVisibility]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledLogout logoutVisibility={logoutVisibility}>
      <div ref={logoutModalRef} className="logout-modal">
        <div className="logout-cool-icon">
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.6667 30H11.6667C9.82572 30 8.33333 28.5076 8.33333 26.6667V20H11.6667V26.6667H26.6667V3.33333H11.6667V10H8.33333V3.33333C8.33333 1.49238 9.82572 0 11.6667 0H26.6667C28.5076 0 30 1.49238 30 3.33333V26.6667C30 28.5076 28.5076 30 26.6667 30ZM15 21.6667V16.6667H0V13.3333H15V8.33333L23.3333 15L15 21.6667Z"
              fill="#5541D7"
            />
          </svg>
        </div>
        <h3>Logout</h3>
        <h4>Are you sure you want to logout?</h4>
        <div className="logout-modal__buttons">
          <Button
            color={"white"}
            text={"Cancel"}
            onClick={() => setLogoutVisibility(false)}
          />
          <Button color={"purple"} text={"Log out"} onClick={handleLogout} />
        </div>
      </div>
      <div className="bg-layer" />
    </StyledLogout>
  );
};

export default Logout;

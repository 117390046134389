import React, { useState } from "react";
import styled from "styled-components";
import { Link, NavLink, useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { setLoginPageComponent } from "../../redux/actions/setLoginPageComponent";

import Button from "../Button";
import HeaderModal from "./HeaderModal";
import { NeedHelpModal } from "../index";

import { mainScreenNeedHelpItems } from "../NeedHelpModal/needHelpItemsContent";

const StyledHeader = styled.div`
  position: relative;
  min-height: 69px;
  width: 100%;
  display: flex;
  align-items: center;

  background: #fff;
  border-bottom: 0.5px solid #dddddd;

  .button.white {
    top: 20px;
  }

  .header-container {
    position: relative;
    width: 94.5vw;
    margin: 0 auto;
    height: 100%;
    max-width: 1376px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header__right-side,
  .header__middle,
  .header__left-side {
    a {
      text-decoration: none;
    }
    display: flex;
    align-items: center;
  }
  .header__middle {
    &.default-header {
      position: absolute;
      top: 34.3%;
      left: 45.3%;
    }
    &.keys-request-page {
      position: absolute;
      top: 14%;
      left: 44%;
    }
  }
  .right-side-header-help {
    margin-right: 10px;
    cursor: pointer;
  }
  .white.goback {
    padding-left: 35px;
    position: ${({ formGeneratorHeader }) =>
      formGeneratorHeader ? "auto" : "absolute"};
    display: none;
    visibility: hidden;
    opacity: 0;
    transform: translateX(-45%);

    transition: 0.3s;
    &.isShown {
      display: block;
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
    }
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #505356;
    top: 18%;
    left: 1%;
    min-width: 172px;
  }
  a {
    margin: 0;
    outline: 0;
    &:hover,
    &:active,
    &:focus {
      outline: 0;
    }
  }
  .apptitle {
  }
  .user {
    display: none;
    align-items: center;
    position: relative;

    cursor: pointer;
    opacity: 0;
    transform: translateX(10%);
    transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);

    .user__burger {
      display: none;
    }
    .user__image {
      img {
        object-fit: cover;
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      .svg-container {
        width: 40px;
        height: 40px;
        border-radius: 50%;

        background: #f6f6f6;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .user__name {
      padding-left: 10px;
      color: #232323;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: 0.005em;
    }

    .user__menu-button {
      padding-left: 10px;
      position: relative;

      svg {
        transition: 0.3s;

        &.upside-down {
          transform: rotate(180deg);
        }
      }
    }

    &.isLoggedIn {
      display: flex;
      transform: translateX(0);
      opacity: 1;
    }
  }
  .keys-request-by {
    display: flex;
    align-items: center;
    visibility: visible;
    height: 100%;
    p {
      font-weight: 600;
      font-size: 13px;
      line-height: 120%;
      letter-spacing: 0.005em;
      color: #5e5e5e;
    }
    .svg-container {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: #8b8f9a;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .request-author {
    margin-left: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    p {
      margin-bottom: 3px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #505356;
    }
  }
  .request-author__name {
    font-weight: bold;
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #232323;
  }
  .header__left-side {
    .button.purple {
      white-space: nowrap;
      max-height: 40px;
      width: 100vw;
      max-width: 160px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
    }
  }
  @media (max-height: 920px) {
    //.button.white {
    //  top: 10px;
    //}
    //.white.goback {
    //
    //}
  }
  @media (max-width: 1000px) {
    .user {
      .user__burger {
        display: block;
        margin-left: auto;
      }
      .user__image,
      .user__name,
      .user__menu-button {
        display: none;
      }
    }
    .header__modal {
      .modal-keys-request--mobile {
        display: flex;
      }
    }
    .button.purple.headerButton {
      display: none;
    }
  }
  @media (max-width: 376px) {
    max-height: 55px;
  }
`;

function Header({
  display,
  formGeneratorHeader,
  setNeedHelpVisibility,
  setLogoutVisibility,
}) {
  const requestLinkState = useSelector(
    ({ requestLinkState }) => requestLinkState
  );
  const loginPageComponent = useSelector(
    ({ loginPageComponent }) => loginPageComponent.type
  );
  const currentUserState = useSelector(
    ({ currentUserReducer }) => currentUserReducer
  );
  const [menuVisibility, setMenuVisibility] = useState(false);
  const [rightSideNeedHelpVisibility, setRightSideNeedHelpVisibility] =
    useState(false);

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const sortRef = React.useRef();
  const handleOutsideHeaderModalClick = (e) => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (!path.includes(sortRef.current)) {
      setMenuVisibility(false);
    }
  };

  const menuClicked = () => {
    setMenuVisibility(!menuVisibility);
  };
  const handleGoBack = () => {
    if (!currentUserState.isLoggedIn) {
      dispatch(setLoginPageComponent("login"));
    } else {
      history.goBack();
    }
  };

  React.useEffect(() => {
    document.body.addEventListener("click", handleOutsideHeaderModalClick);
  }, []);

  return (
    <StyledHeader formGeneratorHeader={formGeneratorHeader}>
      <div className="header-container">
        {/*======= HEADER LEFT SIDE =======*/}
        <div className="header__left-side">
          {" "}
          {display === "defaultHeader" &&
            location.pathname !== "/login" &&
            !(
              currentUserState.isLoggedIn &&
              location.pathname === "/settings/changepassword"
            ) &&
            !formGeneratorHeader && (
              <Link to="/request">
                <Button
                  className={`headerButton plusKeysRequest ${
                    currentUserState.isLoggedIn ? "isLoggedIn" : ""
                  } `}
                  color={"purple"}
                  text={"Keys Request"}
                />
              </Link>
            )}
          <Button
            onClick={() => handleGoBack()}
            text={"Go Back"}
            color={"white goback"}
            className={`${
              (!currentUserState.isLoggedIn &&
                loginPageComponent === "resetpass") ||
              (currentUserState.isLoggedIn &&
                location.pathname === "/settings/changepassword") ||
              formGeneratorHeader
                ? "isShown"
                : ""
            }`}
          />
          {display === "requestLinkHeader" && (
            <Button
              onClick={() => setNeedHelpVisibility(true)}
              className={"need-help-button"}
              color={"white"}
              text={"Need help?"}
            />
          )}
          {display === "requestLinkHeader" && (
            <svg
              className={"need-help-button--mobile"}
              onClick={() => setNeedHelpVisibility(true)}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.8 19.2H13.2V16.8H10.8V19.2ZM12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM12 21.6C6.708 21.6 2.4 17.292 2.4 12C2.4 6.708 6.708 2.4 12 2.4C17.292 2.4 21.6 6.708 21.6 12C21.6 17.292 17.292 21.6 12 21.6ZM12 4.8C9.348 4.8 7.2 6.948 7.2 9.6H9.6C9.6 8.28 10.68 7.2 12 7.2C13.32 7.2 14.4 8.28 14.4 9.6C14.4 12 10.8 11.7 10.8 15.6H13.2C13.2 12.9 16.8 12.6 16.8 9.6C16.8 6.948 14.652 4.8 12 4.8Z"
                fill="#8B8F9A"
              />
            </svg>
          )}
        </div>

        {/*======= HEADER MIDDLE =======*/}
        {display === "requestLinkHeader" && (
          <div className="header__middle keys-request-page">
            <div className="keys-request-by">
              {requestLinkState.requestBy.avatar && (
                <img src={requestLinkState.requestBy.avatar} alt="userimg" />
              )}
              {!requestLinkState.requestBy.avatar && (
                <div className="svg-container">
                  <svg
                    width="24"
                    height="20"
                    viewBox="0 0 42 34"
                    fill="black"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M36.9783 6.04367H32.5956L30.1761 2.3915C29.263 1.02193 27.6652 0.200195 26.0217 0.200195H15.9783C14.3348 0.200195 12.737 1.02193 11.8239 2.3915L9.40435 6.04367H5.02174C2.23696 6.04367 0 8.28063 0 11.0654V28.7785C0 31.5632 2.23696 33.8002 5.02174 33.8002H36.9783C39.763 33.8002 42 31.5632 42 28.7785V11.0654C42 8.28063 39.763 6.04367 36.9783 6.04367ZM21 29.6915C14.7457 29.6915 9.67826 24.6241 9.67826 18.3698C9.67826 12.1154 14.7457 7.09367 21 7.09367C27.2543 7.09367 32.3217 12.1611 32.3217 18.4154C32.3217 24.6241 27.2543 29.6915 21 29.6915ZM36.3391 13.1198C36.2935 13.1198 36.2478 13.1198 36.1565 13.1198H34.3304C33.5087 13.0741 32.8696 12.3893 32.9152 11.5676C32.9609 10.7915 33.5543 10.198 34.3304 10.1524H36.1565C36.9783 10.1067 37.663 10.7458 37.7087 11.5676C37.7543 12.3893 37.1609 13.0741 36.3391 13.1198Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
              <div className="request-author">
                <p>keys request by</p>
                <div className="request-author__name">
                  {requestLinkState.requestBy.firstName}
                </div>
              </div>
            </div>
          </div>
        )}
        {display === "defaultHeader" && (
          <div className="header__middle default-header">
            <NavLink
              to="/"
              activeStyle={{
                margin: "0 auto",
              }}
            >
              <svg
                className="apptitle"
                width="131"
                height="23"
                viewBox="0 0 131 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.25635 17.5882L2.9675 12.0578V17.5882H0V0.118679H2.9675V10.8947L7.94524 5.62539H11.5589L6.12645 11.3457L11.9897 17.5882H8.25635Z"
                  fill="#232323"
                />
                <path
                  d="M23.7148 11.7492H15.3866C15.4504 13.031 15.7695 13.9804 16.3439 14.5975C16.9342 15.1988 17.8037 15.4995 18.9524 15.4995C20.2766 15.4995 21.5051 15.0722 22.6378 14.2178L23.4994 16.259C22.925 16.7179 22.2151 17.0898 21.3695 17.3746C20.5399 17.6436 19.6943 17.7781 18.8327 17.7781C16.8544 17.7781 15.2989 17.2243 14.1661 16.1166C13.0334 15.0089 12.467 13.4899 12.467 11.5593C12.467 10.3409 12.7143 9.25697 13.2089 8.30753C13.7034 7.3581 14.3974 6.62229 15.2909 6.1001C16.1843 5.56209 17.1974 5.29309 18.3302 5.29309C19.9894 5.29309 21.2977 5.8311 22.2549 6.90712C23.2282 7.96732 23.7148 9.43103 23.7148 11.2982V11.7492ZM18.402 7.42931C17.6043 7.42931 16.9501 7.66667 16.4396 8.14138C15.945 8.60028 15.6259 9.27279 15.4823 10.1589H21.1062C21.0105 9.25697 20.7313 8.57654 20.2686 8.11765C19.8219 7.65876 19.1997 7.42931 18.402 7.42931Z"
                  fill="#232323"
                />
                <path
                  d="M37.5965 5.60165L31.9726 18.3953C31.3344 19.8827 30.5208 20.9666 29.5316 21.6471C28.5424 22.3275 27.3219 22.7785 25.8701 23L25.2 20.7214C26.3328 20.4682 27.1783 20.1438 27.7367 19.7482C28.3111 19.3684 28.7738 18.7988 29.1248 18.0392L29.5077 17.161L24.4821 5.60165H27.6171L31.0871 14.2178L34.629 5.60165H37.5965Z"
                  fill="#232323"
                />
                <path
                  d="M43.5347 17.7781C41.3489 17.7781 39.6099 17.2718 38.3176 16.259L39.1552 14.1703C40.4475 15.1197 41.9233 15.5944 43.5825 15.5944C44.3005 15.5944 44.8509 15.4757 45.2338 15.2384C45.6327 15.001 45.8321 14.6687 45.8321 14.2415C45.8321 13.8775 45.6965 13.5927 45.4253 13.387C45.154 13.1655 44.6914 12.9756 44.0372 12.8173L42.027 12.3664C39.8253 11.8916 38.7245 10.7761 38.7245 9.01961C38.7245 8.29171 38.9319 7.65084 39.3467 7.09701C39.7615 6.52735 40.3438 6.08428 41.0937 5.7678C41.8435 5.45133 42.713 5.29309 43.7022 5.29309C44.5637 5.29309 45.3933 5.42759 46.1911 5.69659C46.9888 5.94978 47.6908 6.32164 48.297 6.81218L47.4116 8.82972C46.199 7.94358 44.9546 7.50052 43.6783 7.50052C42.9922 7.50052 42.4578 7.62711 42.0749 7.88029C41.692 8.13347 41.5005 8.4816 41.5005 8.92467C41.5005 9.27279 41.6122 9.54971 41.8355 9.75542C42.0749 9.96113 42.4737 10.1352 43.0321 10.2776L45.0902 10.7286C46.2868 10.9976 47.1563 11.4169 47.6987 11.9866C48.2571 12.5404 48.5363 13.2683 48.5363 14.1703C48.5363 15.278 48.0896 16.1562 47.1962 16.805C46.3027 17.4537 45.0822 17.7781 43.5347 17.7781Z"
                  fill="#232323"
                />
                <path
                  d="M56.9357 2.46853C55.6434 2.46853 54.9973 3.17269 54.9973 4.58101V5.60165H57.7015V7.83282H54.9973V17.5882H52.0058V7.83282H49.7084V5.60165H52.0058V4.4386C52.0058 3.01445 52.3648 1.9226 53.0828 1.16306C53.8167 0.387685 54.8617 0 56.2178 0C56.9676 0 57.5978 0.0949434 58.1084 0.28483V2.63468C57.6616 2.52391 57.2708 2.46853 56.9357 2.46853Z"
                  fill="#232323"
                />
                <path
                  d="M65.4865 17.7781C64.2581 17.7781 63.1732 17.5249 62.2319 17.0186C61.3065 16.5122 60.5886 15.7922 60.078 14.8586C59.5835 13.9092 59.3362 12.8015 59.3362 11.5356C59.3362 10.2697 59.5835 9.16994 60.078 8.23633C60.5886 7.28689 61.3065 6.559 62.2319 6.05263C63.1732 5.54627 64.2581 5.29309 65.4865 5.29309C66.6991 5.29309 67.768 5.54627 68.6934 6.05263C69.6187 6.559 70.3287 7.28689 70.8233 8.23633C71.3338 9.16994 71.5891 10.2697 71.5891 11.5356C71.5891 12.8015 71.3338 13.9092 70.8233 14.8586C70.3287 15.7922 69.6187 16.5122 68.6934 17.0186C67.768 17.5249 66.6991 17.7781 65.4865 17.7781ZM65.4626 15.452C66.4837 15.452 67.2575 15.1276 67.784 14.4788C68.3264 13.8142 68.5976 12.8332 68.5976 11.5356C68.5976 10.2539 68.3264 9.2807 67.784 8.6161C67.2415 7.93567 66.4757 7.59546 65.4865 7.59546C64.4814 7.59546 63.7076 7.93567 63.1652 8.6161C62.6227 9.2807 62.3515 10.2539 62.3515 11.5356C62.3515 12.8332 62.6148 13.8142 63.1413 14.4788C63.6837 15.1276 64.4575 15.452 65.4626 15.452Z"
                  fill="#232323"
                />
                <path
                  d="M80.7978 5.31682C81.2605 5.31682 81.6593 5.38012 81.9944 5.50671L81.9704 8.21259C81.4599 8.00688 80.9334 7.90403 80.391 7.90403C79.3699 7.90403 78.5881 8.19677 78.0457 8.78225C77.5192 9.36773 77.256 10.1431 77.256 11.1084V17.5882H74.2885V8.99587C74.2885 7.72996 74.2246 6.59856 74.097 5.60165H76.897L77.1363 7.71414C77.4394 6.93877 77.9181 6.34537 78.5722 5.93395C79.2263 5.52253 79.9682 5.31682 80.7978 5.31682Z"
                  fill="#232323"
                />
                <path
                  d="M103.745 5.60165L99.0066 17.5882H96.3981L93.4067 9.73168L90.4871 17.5882H87.8546L83.1162 5.60165H86.1794L89.2905 14.0516L92.4255 5.60165H94.5793L97.7143 14.1228L100.825 5.60165H103.745Z"
                  fill="#232323"
                />
                <path
                  d="M116.118 11.7492H107.79C107.854 13.031 108.173 13.9804 108.747 14.5975C109.338 15.1988 110.207 15.4995 111.356 15.4995C112.68 15.4995 113.909 15.0722 115.041 14.2178L115.903 16.259C115.328 16.7179 114.618 17.0898 113.773 17.3746C112.943 17.6436 112.098 17.7781 111.236 17.7781C109.258 17.7781 107.702 17.2243 106.57 16.1166C105.437 15.0089 104.87 13.4899 104.87 11.5593C104.87 10.3409 105.118 9.25697 105.612 8.30753C106.107 7.3581 106.801 6.62229 107.694 6.1001C108.588 5.56209 109.601 5.29309 110.734 5.29309C112.393 5.29309 113.701 5.8311 114.658 6.90712C115.632 7.96732 116.118 9.43103 116.118 11.2982V11.7492ZM110.805 7.42931C110.008 7.42931 109.354 7.66667 108.843 8.14138C108.348 8.60028 108.029 9.27279 107.886 10.1589H113.51C113.414 9.25697 113.135 8.57654 112.672 8.11765C112.225 7.65876 111.603 7.42931 110.805 7.42931Z"
                  fill="#232323"
                />
                <path
                  d="M125.663 5.29309C126.716 5.29309 127.642 5.55418 128.439 6.07637C129.253 6.58273 129.883 7.30272 130.33 8.23633C130.777 9.16994 131 10.2539 131 11.4881C131 12.7224 130.769 13.8222 130.306 14.7874C129.859 15.7368 129.229 16.4727 128.415 16.9948C127.618 17.517 126.7 17.7781 125.663 17.7781C124.77 17.7781 123.972 17.5882 123.27 17.2085C122.584 16.8287 122.066 16.2986 121.715 15.6182V17.5882H118.771V0.118679H121.739V7.40557C122.105 6.74097 122.632 6.22669 123.318 5.86275C124.004 5.48297 124.786 5.29309 125.663 5.29309ZM124.874 15.452C125.863 15.452 126.629 15.1039 127.171 14.4076C127.713 13.7114 127.985 12.7382 127.985 11.4881C127.985 10.2539 127.713 9.30444 127.171 8.63984C126.629 7.95941 125.855 7.6192 124.85 7.6192C123.844 7.6192 123.071 7.95941 122.528 8.63984C121.986 9.32026 121.715 10.2855 121.715 11.5356C121.715 12.8015 121.986 13.7747 122.528 14.4551C123.071 15.1197 123.852 15.452 124.874 15.452Z"
                  fill="#232323"
                />
              </svg>
            </NavLink>
          </div>
        )}
        {/* <div className="header__middle"> */}
        {/* {display === 'requestLinkHeader' && (
            <div className="keys-request-by">
              {requestLinkState.requestBy.avatar && (
                <img src={requestLinkState.requestBy.avatar} alt="userimg" />
              )}
              {!requestLinkState.requestBy.avatar && (
                <div className="svg-container">
                  <svg
                    width="24"
                    height="20"
                    viewBox="0 0 42 34"
                    fill="black"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M36.9783 6.04367H32.5956L30.1761 2.3915C29.263 1.02193 27.6652 0.200195 26.0217 0.200195H15.9783C14.3348 0.200195 12.737 1.02193 11.8239 2.3915L9.40435 6.04367H5.02174C2.23696 6.04367 0 8.28063 0 11.0654V28.7785C0 31.5632 2.23696 33.8002 5.02174 33.8002H36.9783C39.763 33.8002 42 31.5632 42 28.7785V11.0654C42 8.28063 39.763 6.04367 36.9783 6.04367ZM21 29.6915C14.7457 29.6915 9.67826 24.6241 9.67826 18.3698C9.67826 12.1154 14.7457 7.09367 21 7.09367C27.2543 7.09367 32.3217 12.1611 32.3217 18.4154C32.3217 24.6241 27.2543 29.6915 21 29.6915ZM36.3391 13.1198C36.2935 13.1198 36.2478 13.1198 36.1565 13.1198H34.3304C33.5087 13.0741 32.8696 12.3893 32.9152 11.5676C32.9609 10.7915 33.5543 10.198 34.3304 10.1524H36.1565C36.9783 10.1067 37.663 10.7458 37.7087 11.5676C37.7543 12.3893 37.1609 13.0741 36.3391 13.1198Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
              <div className="request-author">
                <p>keys request by</p>
                <div className="request-author__name">{requestLinkState.requestBy.firstName}</div>
              </div>
            </div>
          )} */}
        {/* {display === 'defaultHeader' && (
            <NavLink
              to="/"
              activeStyle={{
                margin: '0 auto',
              }}>
              <svg
                className="apptitle"
                width="131"
                height="23"
                viewBox="0 0 131 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.25635 17.5882L2.9675 12.0578V17.5882H0V0.118679H2.9675V10.8947L7.94524 5.62539H11.5589L6.12645 11.3457L11.9897 17.5882H8.25635Z"
                  fill="#232323"
                />
                <path
                  d="M23.7148 11.7492H15.3866C15.4504 13.031 15.7695 13.9804 16.3439 14.5975C16.9342 15.1988 17.8037 15.4995 18.9524 15.4995C20.2766 15.4995 21.5051 15.0722 22.6378 14.2178L23.4994 16.259C22.925 16.7179 22.2151 17.0898 21.3695 17.3746C20.5399 17.6436 19.6943 17.7781 18.8327 17.7781C16.8544 17.7781 15.2989 17.2243 14.1661 16.1166C13.0334 15.0089 12.467 13.4899 12.467 11.5593C12.467 10.3409 12.7143 9.25697 13.2089 8.30753C13.7034 7.3581 14.3974 6.62229 15.2909 6.1001C16.1843 5.56209 17.1974 5.29309 18.3302 5.29309C19.9894 5.29309 21.2977 5.8311 22.2549 6.90712C23.2282 7.96732 23.7148 9.43103 23.7148 11.2982V11.7492ZM18.402 7.42931C17.6043 7.42931 16.9501 7.66667 16.4396 8.14138C15.945 8.60028 15.6259 9.27279 15.4823 10.1589H21.1062C21.0105 9.25697 20.7313 8.57654 20.2686 8.11765C19.8219 7.65876 19.1997 7.42931 18.402 7.42931Z"
                  fill="#232323"
                />
                <path
                  d="M37.5965 5.60165L31.9726 18.3953C31.3344 19.8827 30.5208 20.9666 29.5316 21.6471C28.5424 22.3275 27.3219 22.7785 25.8701 23L25.2 20.7214C26.3328 20.4682 27.1783 20.1438 27.7367 19.7482C28.3111 19.3684 28.7738 18.7988 29.1248 18.0392L29.5077 17.161L24.4821 5.60165H27.6171L31.0871 14.2178L34.629 5.60165H37.5965Z"
                  fill="#232323"
                />
                <path
                  d="M43.5347 17.7781C41.3489 17.7781 39.6099 17.2718 38.3176 16.259L39.1552 14.1703C40.4475 15.1197 41.9233 15.5944 43.5825 15.5944C44.3005 15.5944 44.8509 15.4757 45.2338 15.2384C45.6327 15.001 45.8321 14.6687 45.8321 14.2415C45.8321 13.8775 45.6965 13.5927 45.4253 13.387C45.154 13.1655 44.6914 12.9756 44.0372 12.8173L42.027 12.3664C39.8253 11.8916 38.7245 10.7761 38.7245 9.01961C38.7245 8.29171 38.9319 7.65084 39.3467 7.09701C39.7615 6.52735 40.3438 6.08428 41.0937 5.7678C41.8435 5.45133 42.713 5.29309 43.7022 5.29309C44.5637 5.29309 45.3933 5.42759 46.1911 5.69659C46.9888 5.94978 47.6908 6.32164 48.297 6.81218L47.4116 8.82972C46.199 7.94358 44.9546 7.50052 43.6783 7.50052C42.9922 7.50052 42.4578 7.62711 42.0749 7.88029C41.692 8.13347 41.5005 8.4816 41.5005 8.92467C41.5005 9.27279 41.6122 9.54971 41.8355 9.75542C42.0749 9.96113 42.4737 10.1352 43.0321 10.2776L45.0902 10.7286C46.2868 10.9976 47.1563 11.4169 47.6987 11.9866C48.2571 12.5404 48.5363 13.2683 48.5363 14.1703C48.5363 15.278 48.0896 16.1562 47.1962 16.805C46.3027 17.4537 45.0822 17.7781 43.5347 17.7781Z"
                  fill="#232323"
                />
                <path
                  d="M56.9357 2.46853C55.6434 2.46853 54.9973 3.17269 54.9973 4.58101V5.60165H57.7015V7.83282H54.9973V17.5882H52.0058V7.83282H49.7084V5.60165H52.0058V4.4386C52.0058 3.01445 52.3648 1.9226 53.0828 1.16306C53.8167 0.387685 54.8617 0 56.2178 0C56.9676 0 57.5978 0.0949434 58.1084 0.28483V2.63468C57.6616 2.52391 57.2708 2.46853 56.9357 2.46853Z"
                  fill="#232323"
                />
                <path
                  d="M65.4865 17.7781C64.2581 17.7781 63.1732 17.5249 62.2319 17.0186C61.3065 16.5122 60.5886 15.7922 60.078 14.8586C59.5835 13.9092 59.3362 12.8015 59.3362 11.5356C59.3362 10.2697 59.5835 9.16994 60.078 8.23633C60.5886 7.28689 61.3065 6.559 62.2319 6.05263C63.1732 5.54627 64.2581 5.29309 65.4865 5.29309C66.6991 5.29309 67.768 5.54627 68.6934 6.05263C69.6187 6.559 70.3287 7.28689 70.8233 8.23633C71.3338 9.16994 71.5891 10.2697 71.5891 11.5356C71.5891 12.8015 71.3338 13.9092 70.8233 14.8586C70.3287 15.7922 69.6187 16.5122 68.6934 17.0186C67.768 17.5249 66.6991 17.7781 65.4865 17.7781ZM65.4626 15.452C66.4837 15.452 67.2575 15.1276 67.784 14.4788C68.3264 13.8142 68.5976 12.8332 68.5976 11.5356C68.5976 10.2539 68.3264 9.2807 67.784 8.6161C67.2415 7.93567 66.4757 7.59546 65.4865 7.59546C64.4814 7.59546 63.7076 7.93567 63.1652 8.6161C62.6227 9.2807 62.3515 10.2539 62.3515 11.5356C62.3515 12.8332 62.6148 13.8142 63.1413 14.4788C63.6837 15.1276 64.4575 15.452 65.4626 15.452Z"
                  fill="#232323"
                />
                <path
                  d="M80.7978 5.31682C81.2605 5.31682 81.6593 5.38012 81.9944 5.50671L81.9704 8.21259C81.4599 8.00688 80.9334 7.90403 80.391 7.90403C79.3699 7.90403 78.5881 8.19677 78.0457 8.78225C77.5192 9.36773 77.256 10.1431 77.256 11.1084V17.5882H74.2885V8.99587C74.2885 7.72996 74.2246 6.59856 74.097 5.60165H76.897L77.1363 7.71414C77.4394 6.93877 77.9181 6.34537 78.5722 5.93395C79.2263 5.52253 79.9682 5.31682 80.7978 5.31682Z"
                  fill="#232323"
                />
                <path
                  d="M103.745 5.60165L99.0066 17.5882H96.3981L93.4067 9.73168L90.4871 17.5882H87.8546L83.1162 5.60165H86.1794L89.2905 14.0516L92.4255 5.60165H94.5793L97.7143 14.1228L100.825 5.60165H103.745Z"
                  fill="#232323"
                />
                <path
                  d="M116.118 11.7492H107.79C107.854 13.031 108.173 13.9804 108.747 14.5975C109.338 15.1988 110.207 15.4995 111.356 15.4995C112.68 15.4995 113.909 15.0722 115.041 14.2178L115.903 16.259C115.328 16.7179 114.618 17.0898 113.773 17.3746C112.943 17.6436 112.098 17.7781 111.236 17.7781C109.258 17.7781 107.702 17.2243 106.57 16.1166C105.437 15.0089 104.87 13.4899 104.87 11.5593C104.87 10.3409 105.118 9.25697 105.612 8.30753C106.107 7.3581 106.801 6.62229 107.694 6.1001C108.588 5.56209 109.601 5.29309 110.734 5.29309C112.393 5.29309 113.701 5.8311 114.658 6.90712C115.632 7.96732 116.118 9.43103 116.118 11.2982V11.7492ZM110.805 7.42931C110.008 7.42931 109.354 7.66667 108.843 8.14138C108.348 8.60028 108.029 9.27279 107.886 10.1589H113.51C113.414 9.25697 113.135 8.57654 112.672 8.11765C112.225 7.65876 111.603 7.42931 110.805 7.42931Z"
                  fill="#232323"
                />
                <path
                  d="M125.663 5.29309C126.716 5.29309 127.642 5.55418 128.439 6.07637C129.253 6.58273 129.883 7.30272 130.33 8.23633C130.777 9.16994 131 10.2539 131 11.4881C131 12.7224 130.769 13.8222 130.306 14.7874C129.859 15.7368 129.229 16.4727 128.415 16.9948C127.618 17.517 126.7 17.7781 125.663 17.7781C124.77 17.7781 123.972 17.5882 123.27 17.2085C122.584 16.8287 122.066 16.2986 121.715 15.6182V17.5882H118.771V0.118679H121.739V7.40557C122.105 6.74097 122.632 6.22669 123.318 5.86275C124.004 5.48297 124.786 5.29309 125.663 5.29309ZM124.874 15.452C125.863 15.452 126.629 15.1039 127.171 14.4076C127.713 13.7114 127.985 12.7382 127.985 11.4881C127.985 10.2539 127.713 9.30444 127.171 8.63984C126.629 7.95941 125.855 7.6192 124.85 7.6192C123.844 7.6192 123.071 7.95941 122.528 8.63984C121.986 9.32026 121.715 10.2855 121.715 11.5356C121.715 12.8015 121.986 13.7747 122.528 14.4551C123.071 15.1197 123.852 15.452 124.874 15.452Z"
                  fill="#232323"
                />
              </svg>
            </NavLink>
          )} */}
        {/* </div> */}

        {/* ======= HEADER RIGHT SIDE ======= */}
        <div className="header__right-side">
          {" "}
          {display === "requestLinkHeader" && (
            <a href="https://www.keysforweb.com" className="apptitle">
              <svg
                width="131"
                height="23"
                viewBox="0 0 131 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.25635 17.5882L2.9675 12.0578V17.5882H0V0.118679H2.9675V10.8947L7.94524 5.62539H11.5589L6.12645 11.3457L11.9897 17.5882H8.25635Z"
                  fill="#232323"
                />
                <path
                  d="M23.7148 11.7492H15.3866C15.4504 13.031 15.7695 13.9804 16.3439 14.5975C16.9342 15.1988 17.8037 15.4995 18.9524 15.4995C20.2766 15.4995 21.5051 15.0722 22.6378 14.2178L23.4994 16.259C22.925 16.7179 22.2151 17.0898 21.3695 17.3746C20.5399 17.6436 19.6943 17.7781 18.8327 17.7781C16.8544 17.7781 15.2989 17.2243 14.1661 16.1166C13.0334 15.0089 12.467 13.4899 12.467 11.5593C12.467 10.3409 12.7143 9.25697 13.2089 8.30753C13.7034 7.3581 14.3974 6.62229 15.2909 6.1001C16.1843 5.56209 17.1974 5.29309 18.3302 5.29309C19.9894 5.29309 21.2977 5.8311 22.2549 6.90712C23.2282 7.96732 23.7148 9.43103 23.7148 11.2982V11.7492ZM18.402 7.42931C17.6043 7.42931 16.9501 7.66667 16.4396 8.14138C15.945 8.60028 15.6259 9.27279 15.4823 10.1589H21.1062C21.0105 9.25697 20.7313 8.57654 20.2686 8.11765C19.8219 7.65876 19.1997 7.42931 18.402 7.42931Z"
                  fill="#232323"
                />
                <path
                  d="M37.5965 5.60165L31.9726 18.3953C31.3344 19.8827 30.5208 20.9666 29.5316 21.6471C28.5424 22.3275 27.3219 22.7785 25.8701 23L25.2 20.7214C26.3328 20.4682 27.1783 20.1438 27.7367 19.7482C28.3111 19.3684 28.7738 18.7988 29.1248 18.0392L29.5077 17.161L24.4821 5.60165H27.6171L31.0871 14.2178L34.629 5.60165H37.5965Z"
                  fill="#232323"
                />
                <path
                  d="M43.5347 17.7781C41.3489 17.7781 39.6099 17.2718 38.3176 16.259L39.1552 14.1703C40.4475 15.1197 41.9233 15.5944 43.5825 15.5944C44.3005 15.5944 44.8509 15.4757 45.2338 15.2384C45.6327 15.001 45.8321 14.6687 45.8321 14.2415C45.8321 13.8775 45.6965 13.5927 45.4253 13.387C45.154 13.1655 44.6914 12.9756 44.0372 12.8173L42.027 12.3664C39.8253 11.8916 38.7245 10.7761 38.7245 9.01961C38.7245 8.29171 38.9319 7.65084 39.3467 7.09701C39.7615 6.52735 40.3438 6.08428 41.0937 5.7678C41.8435 5.45133 42.713 5.29309 43.7022 5.29309C44.5637 5.29309 45.3933 5.42759 46.1911 5.69659C46.9888 5.94978 47.6908 6.32164 48.297 6.81218L47.4116 8.82972C46.199 7.94358 44.9546 7.50052 43.6783 7.50052C42.9922 7.50052 42.4578 7.62711 42.0749 7.88029C41.692 8.13347 41.5005 8.4816 41.5005 8.92467C41.5005 9.27279 41.6122 9.54971 41.8355 9.75542C42.0749 9.96113 42.4737 10.1352 43.0321 10.2776L45.0902 10.7286C46.2868 10.9976 47.1563 11.4169 47.6987 11.9866C48.2571 12.5404 48.5363 13.2683 48.5363 14.1703C48.5363 15.278 48.0896 16.1562 47.1962 16.805C46.3027 17.4537 45.0822 17.7781 43.5347 17.7781Z"
                  fill="#232323"
                />
                <path
                  d="M56.9357 2.46853C55.6434 2.46853 54.9973 3.17269 54.9973 4.58101V5.60165H57.7015V7.83282H54.9973V17.5882H52.0058V7.83282H49.7084V5.60165H52.0058V4.4386C52.0058 3.01445 52.3648 1.9226 53.0828 1.16306C53.8167 0.387685 54.8617 0 56.2178 0C56.9676 0 57.5978 0.0949434 58.1084 0.28483V2.63468C57.6616 2.52391 57.2708 2.46853 56.9357 2.46853Z"
                  fill="#232323"
                />
                <path
                  d="M65.4865 17.7781C64.2581 17.7781 63.1732 17.5249 62.2319 17.0186C61.3065 16.5122 60.5886 15.7922 60.078 14.8586C59.5835 13.9092 59.3362 12.8015 59.3362 11.5356C59.3362 10.2697 59.5835 9.16994 60.078 8.23633C60.5886 7.28689 61.3065 6.559 62.2319 6.05263C63.1732 5.54627 64.2581 5.29309 65.4865 5.29309C66.6991 5.29309 67.768 5.54627 68.6934 6.05263C69.6187 6.559 70.3287 7.28689 70.8233 8.23633C71.3338 9.16994 71.5891 10.2697 71.5891 11.5356C71.5891 12.8015 71.3338 13.9092 70.8233 14.8586C70.3287 15.7922 69.6187 16.5122 68.6934 17.0186C67.768 17.5249 66.6991 17.7781 65.4865 17.7781ZM65.4626 15.452C66.4837 15.452 67.2575 15.1276 67.784 14.4788C68.3264 13.8142 68.5976 12.8332 68.5976 11.5356C68.5976 10.2539 68.3264 9.2807 67.784 8.6161C67.2415 7.93567 66.4757 7.59546 65.4865 7.59546C64.4814 7.59546 63.7076 7.93567 63.1652 8.6161C62.6227 9.2807 62.3515 10.2539 62.3515 11.5356C62.3515 12.8332 62.6148 13.8142 63.1413 14.4788C63.6837 15.1276 64.4575 15.452 65.4626 15.452Z"
                  fill="#232323"
                />
                <path
                  d="M80.7978 5.31682C81.2605 5.31682 81.6593 5.38012 81.9944 5.50671L81.9704 8.21259C81.4599 8.00688 80.9334 7.90403 80.391 7.90403C79.3699 7.90403 78.5881 8.19677 78.0457 8.78225C77.5192 9.36773 77.256 10.1431 77.256 11.1084V17.5882H74.2885V8.99587C74.2885 7.72996 74.2246 6.59856 74.097 5.60165H76.897L77.1363 7.71414C77.4394 6.93877 77.9181 6.34537 78.5722 5.93395C79.2263 5.52253 79.9682 5.31682 80.7978 5.31682Z"
                  fill="#232323"
                />
                <path
                  d="M103.745 5.60165L99.0066 17.5882H96.3981L93.4067 9.73168L90.4871 17.5882H87.8546L83.1162 5.60165H86.1794L89.2905 14.0516L92.4255 5.60165H94.5793L97.7143 14.1228L100.825 5.60165H103.745Z"
                  fill="#232323"
                />
                <path
                  d="M116.118 11.7492H107.79C107.854 13.031 108.173 13.9804 108.747 14.5975C109.338 15.1988 110.207 15.4995 111.356 15.4995C112.68 15.4995 113.909 15.0722 115.041 14.2178L115.903 16.259C115.328 16.7179 114.618 17.0898 113.773 17.3746C112.943 17.6436 112.098 17.7781 111.236 17.7781C109.258 17.7781 107.702 17.2243 106.57 16.1166C105.437 15.0089 104.87 13.4899 104.87 11.5593C104.87 10.3409 105.118 9.25697 105.612 8.30753C106.107 7.3581 106.801 6.62229 107.694 6.1001C108.588 5.56209 109.601 5.29309 110.734 5.29309C112.393 5.29309 113.701 5.8311 114.658 6.90712C115.632 7.96732 116.118 9.43103 116.118 11.2982V11.7492ZM110.805 7.42931C110.008 7.42931 109.354 7.66667 108.843 8.14138C108.348 8.60028 108.029 9.27279 107.886 10.1589H113.51C113.414 9.25697 113.135 8.57654 112.672 8.11765C112.225 7.65876 111.603 7.42931 110.805 7.42931Z"
                  fill="#232323"
                />
                <path
                  d="M125.663 5.29309C126.716 5.29309 127.642 5.55418 128.439 6.07637C129.253 6.58273 129.883 7.30272 130.33 8.23633C130.777 9.16994 131 10.2539 131 11.4881C131 12.7224 130.769 13.8222 130.306 14.7874C129.859 15.7368 129.229 16.4727 128.415 16.9948C127.618 17.517 126.7 17.7781 125.663 17.7781C124.77 17.7781 123.972 17.5882 123.27 17.2085C122.584 16.8287 122.066 16.2986 121.715 15.6182V17.5882H118.771V0.118679H121.739V7.40557C122.105 6.74097 122.632 6.22669 123.318 5.86275C124.004 5.48297 124.786 5.29309 125.663 5.29309ZM124.874 15.452C125.863 15.452 126.629 15.1039 127.171 14.4076C127.713 13.7114 127.985 12.7382 127.985 11.4881C127.985 10.2539 127.713 9.30444 127.171 8.63984C126.629 7.95941 125.855 7.6192 124.85 7.6192C123.844 7.6192 123.071 7.95941 122.528 8.63984C121.986 9.32026 121.715 10.2855 121.715 11.5356C121.715 12.8015 121.986 13.7747 122.528 14.4551C123.071 15.1197 123.852 15.452 124.874 15.452Z"
                  fill="#232323"
                />
              </svg>
            </a>
          )}
          {display === "defaultHeader" &&
            currentUserState.isLoggedIn &&
            location.pathname !== "/login" &&
            location.pathname !== "/reset-password" &&
            location.pathname !== "/pagenotfound" && (
              <svg
                className="right-side-header-help"
                onClick={() => setRightSideNeedHelpVisibility((prev) => !prev)}
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="20" cy="20" r="20" fill="white" />
                <path
                  d="M20.001 30C14.4801 29.9939 10.0061 25.5203 10 20V19.8C10.1099 14.3045 14.635 9.92797 20.1317 10.0009C25.6283 10.0738 30.0357 14.5689 29.9998 20.0653C29.9639 25.5618 25.4981 29.9989 20.001 30ZM19.985 28H20.001C24.4182 27.9956 27.996 24.4127 27.9938 19.996C27.9916 15.5793 24.4102 12 19.993 12C15.5758 12 11.9944 15.5793 11.9922 19.996C11.99 24.4127 15.5678 27.9956 19.985 28ZM21.0011 26H19.0009V24H21.0011V26ZM21.0011 23H19.0009C18.9693 21.6977 19.6471 20.4808 20.7711 19.822C21.4311 19.316 22.0012 18.88 22.0012 18C22.0012 16.8954 21.1057 16 20.001 16C18.8963 16 18.0008 16.8954 18.0008 18H16.0006V17.91C16.0167 16.481 16.794 15.169 18.0398 14.4684C19.2855 13.7678 20.8105 13.785 22.0402 14.5134C23.2699 15.2419 24.0175 16.571 24.0014 18C23.9298 19.079 23.351 20.0603 22.4412 20.645C21.6189 21.1612 21.0858 22.0328 21.0011 23Z"
                  fill="#505356"
                />
              </svg>
            )}
          {display === "defaultHeader" && location.pathname !== "/login" && (
            <div
              ref={sortRef}
              onClick={menuClicked}
              className={`user ${
                currentUserState.isLoggedIn ? "isLoggedIn" : ""
              } `}
            >
              <div className="user__image">
                {currentUserState.currentUser.avatar && (
                  <img
                    src={currentUserState.currentUser.avatar}
                    alt="userimg"
                  />
                )}
                {!currentUserState.currentUser.avatar && (
                  <div className="svg-container">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
                        fill="#F6F6F6"
                      />
                      <path
                        d="M20 20C22.4862 20 24.5 17.9862 24.5 15.5C24.5 13.0138 22.4862 11 20 11C17.5138 11 15.5 13.0138 15.5 15.5C15.5 17.9862 17.5138 20 20 20ZM20 13.25C21.2375 13.25 22.25 14.2625 22.25 15.5C22.25 16.7375 21.2375 17.75 20 17.75C18.7625 17.75 17.75 16.7375 17.75 15.5C17.75 14.2625 18.7625 13.25 20 13.25ZM20 21.125C16.9963 21.125 11 22.6325 11 25.625V27.875C11 28.4937 11.5062 29 12.125 29H27.875C28.4937 29 29 28.4937 29 27.875V25.625C29 22.6325 23.0037 21.125 20 21.125ZM26.75 26.75H13.25V25.6363C13.475 24.8263 16.9625 23.375 20 23.375C23.0375 23.375 26.525 24.8263 26.75 25.625V26.75Z"
                        fill="#505356"
                      />
                    </svg>
                  </div>
                )}
              </div>

              <div className="user__menu-button">
                <svg
                  className={menuVisibility ? "upside-down" : ""}
                  width="17"
                  height="10"
                  viewBox="0 0 17 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.702107 0.720844C0.315715 1.11073 0.311585 1.74347 0.698116 2.13322L7.78997 9.28406C8.18115 9.6785 8.81885 9.6785 9.21003 9.28406L16.3019 2.13322C16.6884 1.74347 16.6843 1.11073 16.2979 0.720844C15.9067 0.326151 15.2645 0.321707 14.8732 0.71629L8.5 7.14343L2.12678 0.71629C1.73551 0.321706 1.09327 0.326151 0.702107 0.720844Z"
                    fill="#505356"
                  />
                </svg>
              </div>
              <svg
                onClick={menuClicked}
                className="user__burger"
                width="24"
                height="16"
                viewBox="0 0 24 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.33333 16H22.6667C23.4 16 24 15.4 24 14.6667C24 13.9333 23.4 13.3333 22.6667 13.3333H1.33333C0.6 13.3333 0 13.9333 0 14.6667C0 15.4 0.6 16 1.33333 16ZM1.33333 9.33333H22.6667C23.4 9.33333 24 8.73333 24 8C24 7.26667 23.4 6.66667 22.6667 6.66667H1.33333C0.6 6.66667 0 7.26667 0 8C0 8.73333 0.6 9.33333 1.33333 9.33333ZM0 1.33333C0 2.06667 0.6 2.66667 1.33333 2.66667H22.6667C23.4 2.66667 24 2.06667 24 1.33333C24 0.6 23.4 0 22.6667 0H1.33333C0.6 0 0 0.6 0 1.33333Z"
                  fill="#505356"
                />
              </svg>
            </div>
          )}
        </div>

        <HeaderModal
          isVisible={menuVisibility}
          setLogoutVisibility={setLogoutVisibility}
        />
      </div>
      {location.pathname.slice(0, 3) !== "/r/" &&
        currentUserState.isLoggedIn &&
        rightSideNeedHelpVisibility && (
          <NeedHelpModal
            setNeedHelpVisibility={setRightSideNeedHelpVisibility}
            left={false}
            needHelpItems={mainScreenNeedHelpItems}
          />
        )}
    </StyledHeader>
  );
}

export default Header;

import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { CSSTransition } from "react-transition-group";
import useNotification from "../../../hooks/useNotification";

import { GeneratorField, GeneratorFieldGroup } from "../../../components";
import MainAreaButtons from "./MainAreaButtons";
import {
  swapFieldOrder,
  setFieldTranslateY,
  setTemplateName,
} from "../../../redux/actions/setFormItems";

const StyledMainArea = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 27px;
  .preset0-container {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    align-items: center;
    width: 100%;
    h3 {
      margin-top: 29px;
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      color: #232323;
    }
  }
  .save-template-area {
    margin-top: auto;
    .save-template-title {
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
      color: #505356;
    }
    &.inactive {
      color: #a7a9aa;
      .save-template-title {
        color: #a7a9aa;
      }
      .save-template-area--turnon {
        svg {
          cursor: default;
          pointer-events: none;
        }
        .save-template-checkbox {
          cursor: default;
          pointer-events: none;
          background: #ededed;
          border: 1px solid #dddddd;
          box-sizing: border-box;
          border-radius: 4px;
        }
      }
      .save-template-area__template-name {
        input {
          color: #c5c7cc;
          cursor: default;
        }
        .save-template-area__save-button {
          background: #c5c7cc;
          cursor: default;
          pointer-events: none;
        }
      }
    }
    .save-template-area--turnon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-right: 8px;
        cursor: pointer;
      }
    }
    .save-template-checkbox {
      margin-right: 8px;
      width: 22px;
      height: 22px;
      background: #ffffff;
      border: 1px solid #81858b;
      border-radius: 4px;
      cursor: pointer;
      transition: 0.2s;
      &:hover {
        background: #e9e9e9;
      }
    }
    .save-template-area__template-block {
      margin-top: 15px;
      label {
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        color: #232323;
      }
    }
    .save-template-area__template-name {
      margin-top: 7px;
      display: flex;
      align-items: center;
      max-width: 368px;

      border: 1px solid #dddddd;
      box-sizing: border-box;
      border-radius: 8px;
      height: 40px;
      input {
        padding-left: 15px;
        width: 100vw;
        max-width: 287px;
        height: 72%;
        border: none;
      }
      .save-template-area__save-button {
        margin-left: auto;
        text-align: center;
        margin-right: 9px;
        cursor: pointer;
        transition: 0.2s;
        path {
          transition: 0.3s;
        }
        &:hover {
          path:first-child {
            fill: #75cbb7;
          }
        }
      }
      .save-template-area__close-button {
        margin-right: 8px;
        cursor: pointer;
      }
    }
  }
  .saveTemplateInput-enter {
    opacity: 0.01;
    transform: translateX(5%);
  }
  .saveTemplateInput-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: 0.15s;
  }
  .saveTemplateInput-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  .saveTemplateInput-exit-active {
    opacity: 0.01;
    transform: translateX(5%);
    transition: 0.15s;
  }
`;

const MainArea = ({
  formGenerator,
  globalId,
  incrementGlobaldId,
  buttonsBadRed,
  showSaveTemplateModal,
  showSaveTemplate,
  setShowSaveTemplate,
  setTemplatesListReloadTrigger,
}) => {
  const dispatch = useDispatch();
  const showNotification = useNotification();

  const templateBlockRef = useRef(null);

  const [formIsReady, setFormIsReady] = useState(true);
  const [currentDraggedCard, setCurrentDraggedCard] = useState(null);
  const [dragStartInner, setDragStartInner] = useState(null);
  const [dragStartOuterId, setDragStartOuterId] = useState(null);
  const [newTemplateName, setNewTemplateName] = useState("");

  function dragStartHandler(card, inner, outerId) {
    setCurrentDraggedCard(card);
    setDragStartInner(inner);
    if (inner) {
      setDragStartOuterId(outerId);
    }
  }
  function dragEndHandler(e, outerId) {
    e.preventDefault();
    formGenerator.forEach((item) => {
      if (item.type === "fieldgroup") {
        dispatch(setFieldTranslateY(1, 1, true, item.id));
      }
    });
    dispatch(setFieldTranslateY(1, 1, false, outerId));
    setCurrentDraggedCard(null);
  }
  function dragOverHandler(e, draggedOverCard, outerId, draggedOverInner) {
    e.preventDefault();

    if (draggedOverInner === dragStartInner) {
      if (dragStartInner) {
        if (dragStartOuterId === outerId) {
          dispatch(
            setFieldTranslateY(
              currentDraggedCard.order || currentDraggedCard.innerOrder,
              draggedOverCard.order || draggedOverCard.innerOrder,
              dragStartInner,
              outerId
            )
          );
        }
      } else if (!dragStartInner) {
        dispatch(
          setFieldTranslateY(
            currentDraggedCard.order || currentDraggedCard.innerOrder,
            draggedOverCard.order || draggedOverCard.innerOrder,
            dragStartInner,
            outerId
          )
        );
      }
    }
  }
  function dropHandler(e, card, outerId, droppedOnInner) {
    e.preventDefault();
    if (droppedOnInner === dragStartInner) {
      if (dragStartInner) {
        if (dragStartOuterId === outerId) {
          dispatch(setFieldTranslateY(1, 1, dragStartInner, outerId));
          dispatch(
            swapFieldOrder(currentDraggedCard, card, dragStartInner, outerId)
          );
        }
      } else if (!dragStartInner) {
        dispatch(setFieldTranslateY(1, 1, dragStartInner, outerId));
        dispatch(
          swapFieldOrder(currentDraggedCard, card, dragStartInner, outerId)
        );
      }
    }
    setCurrentDraggedCard(null);
  }

  const setFormCondition = (boolean) => {
    setFormIsReady(boolean);
  };

  const checkIfFormIsReady = () => {
    let isReady = false;
    for (let item of formGenerator) {
      if (item.type === "field") {
        isReady = item.title !== "";
        if (!isReady) {
          break;
        }
      }
      if (item.type === "fieldgroup") {
        for (let innerItem of item.innerFields) {
          if (innerItem.type === "field") {
            isReady = innerItem.title !== "";
            if (!isReady) {
              break;
            }
          }
        }
        if (!isReady) {
          break;
        }
      }
    }
    return isReady;
  };

  React.useEffect(() => {
    if (checkIfFormIsReady()) {
      setFormIsReady(true);
    } else {
      setFormIsReady(false);
    }
  }, [formGenerator]); // eslint-disable-line react-hooks/exhaustive-deps

  const sortFields = (a, b) => {
    if (a.order > b.order) {
      return 1;
    } else {
      return -1;
    }
  };

  const saveTemplate = () => {
    if (newTemplateName !== "") {
      dispatch(setTemplateName(newTemplateName));
      let currentFormGenerator = JSON.parse(JSON.stringify(formGenerator));
      currentFormGenerator.forEach((item) => {
        switch (item.type) {
          case "clientUniqueHandle":
            item.value = "";
            break;
          case "websiteUrl":
            item.value = "";
            break;
          default:
            return;
        }
      });

      fetch("https://engine.keysforweb.com/wp-json/bda/v1/request-templates", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            localStorage.getItem("token") || sessionStorage.getItem("token")
          }`,
        },
        body: JSON.stringify({
          templateName: newTemplateName,
          templateInJSON: currentFormGenerator,
        }),
      })
        .then((resp) => resp.json())
        .then((data) => {
          setTemplatesListReloadTrigger((prev) => prev + 1);
          console.log(data);
        });

      showNotification("success", "Great!", "Template successfuly saved", 1500);
    } else {
      showNotification(
        "error",
        "Blank template name",
        "Set a name for your template",
        1500
      );
    }
  };

  return (
    <StyledMainArea>
      {!formGenerator.find((item) => item.type === "premadeKfwTemplate") &&
        formGenerator
          .slice(5)
          .sort(sortFields)
          .map((item, index) => {
            switch (item.type) {
              case "field":
                return (
                  <GeneratorField
                    currentDraggedCard={currentDraggedCard}
                    card={item}
                    setFormCondition={setFormCondition}
                    key={item.id}
                    readOnly={false}
                    id={item.id}
                    inner={false}
                    order={item.order}
                    dragStartHandler={dragStartHandler}
                    dragEndHandler={dragEndHandler}
                    dragOverHandler={dragOverHandler}
                    dropHandler={dropHandler}
                  />
                );
              case "fieldgroup":
                return (
                  <GeneratorFieldGroup
                    currentDraggedCard={currentDraggedCard}
                    group={item}
                    key={item.id}
                    readOnly={false}
                    id={item.id}
                    innerFields={item.innerFields}
                    order={item.order}
                    dragStartHandler={dragStartHandler}
                    dragEndHandler={dragEndHandler}
                    dragOverHandler={dragOverHandler}
                    dropHandler={dropHandler}
                  />
                );
              default:
                return null;
            }
          })}

      {formGenerator.find(
        (item) => item.type === "premadeKfwTemplate" && item.id === 0
      ) && (
        <div className="preset0-container">
          <h3>Wp-admin</h3>
          {formGenerator.map((field, index) => {
            while (index > 5 && index < 9) {
              return (
                <GeneratorField
                  card={field}
                  key={field.id}
                  readOnly={true}
                  presetTitle={field.title}
                  id={field.id}
                  inner={false}
                />
              );
            }
            return null;
          })}
          <h3>FTP keys</h3>
          {formGenerator.map((field, index) => {
            while (index >= 9) {
              return (
                <GeneratorField
                  card={field}
                  key={field.id}
                  readOnly={true}
                  presetTitle={field.title}
                  id={field.id}
                  inner={false}
                />
              );
            }
            return null;
          })}
        </div>
      )}

      {formGenerator.find(
        (item) => item.type === "premadeKfwTemplate" && item.id === 1
      ) && (
        <div className="preset0-container">
          <h3>
            {formGenerator.find((prop) => prop.type === "templateName").value}
          </h3>
          {formGenerator.map((field, index) => {
            while (index > 5) {
              return (
                <GeneratorField
                  card={field}
                  key={field.id}
                  readOnly={true}
                  presetTitle={field.title}
                  id={field.id}
                  inner={false}
                />
              );
            }
            return null;
          })}
        </div>
      )}

      {/*===== FTP PRESET =====*/}
      {formGenerator.find(
        (item) => item.type === "premadeKfwTemplate" && item.id === 2
      ) && (
        <div className="preset0-container">
          <h3>FTP keys</h3>
          {formGenerator.map((field, index) => {
            while (index > 5) {
              return (
                <GeneratorField
                  card={field}
                  key={field.id}
                  readOnly={true}
                  presetTitle={field.title}
                  id={field.id}
                  inner={false}
                />
              );
            }
            return null;
          })}
        </div>
      )}

      {/*===== BLUEHOST PRESET =====*/}
      {formGenerator.find(
        (item) => item.type === "premadeKfwTemplate" && item.id === 3
      ) && (
        <div className="preset0-container">
          <h3>
            {formGenerator.find((item) => item.type === "templateName").value}
          </h3>
          {formGenerator.map((field, index) => {
            while (index > 5) {
              return (
                <GeneratorField
                  card={field}
                  key={field.id}
                  readOnly={true}
                  presetTitle={field.title}
                  id={field.id}
                  inner={false}
                />
              );
            }
            return null;
          })}
        </div>
      )}

      {/*===== WEBFLOW PRESET =====*/}
      {formGenerator.find(
        (item) => item.type === "premadeKfwTemplate" && item.id === 4
      ) && (
        <div className="preset0-container">
          <h3>
            {formGenerator.find((item) => item.type === "templateName").value}
          </h3>
          {formGenerator.map((field, index) => {
            while (index > 5) {
              return (
                <GeneratorField
                  card={field}
                  key={field.id}
                  readOnly={true}
                  presetTitle={field.title}
                  id={field.id}
                  inner={false}
                />
              );
            }
            return null;
          })}
        </div>
      )}

      {/*===== FLYWHEEL PRESET =====*/}
      {formGenerator.find(
        (item) => item.type === "premadeKfwTemplate" && item.id === 5
      ) && (
        <div className="preset0-container">
          <h3>
            {formGenerator.find((item) => item.type === "templateName").value}
          </h3>
          {formGenerator.map((field, index) => {
            while (index > 5) {
              return (
                <GeneratorField
                  card={field}
                  key={field.id}
                  readOnly={true}
                  presetTitle={field.title}
                  id={field.id}
                  inner={false}
                />
              );
            }
            return null;
          })}
        </div>
      )}

      {/*===== JIMDO PRESET =====*/}
      {formGenerator.find(
        (item) => item.type === "premadeKfwTemplate" && item.id === 6
      ) && (
        <div className="preset0-container">
          <h3>
            {formGenerator.find((item) => item.type === "templateName").value}
          </h3>
          {formGenerator.map((field, index) => {
            while (index > 5) {
              return (
                <GeneratorField
                  card={field}
                  key={field.id}
                  readOnly={true}
                  presetTitle={field.title}
                  id={field.id}
                  inner={false}
                />
              );
            }
            return null;
          })}
        </div>
      )}

      {/*===== NETLIFY PRESET =====*/}
      {formGenerator.find(
        (item) => item.type === "premadeKfwTemplate" && item.id === 7
      ) && (
        <div className="preset0-container">
          <h3>
            {formGenerator.find((item) => item.type === "templateName").value}
          </h3>
          {formGenerator.map((field, index) => {
            while (index > 5) {
              return (
                <GeneratorField
                  card={field}
                  key={field.id}
                  readOnly={true}
                  presetTitle={field.title}
                  id={field.id}
                  inner={false}
                />
              );
            }
            return null;
          })}
        </div>
      )}

      {/*===== JOOMLA PRESET =====*/}
      {formGenerator.find(
        (item) => item.type === "premadeKfwTemplate" && item.id === 8
      ) && (
        <div className="preset0-container">
          <h3>
            {formGenerator.find((item) => item.type === "templateName").value}
          </h3>
          {formGenerator.map((field, index) => {
            while (index > 5) {
              return (
                <GeneratorField
                  card={field}
                  key={field.id}
                  readOnly={true}
                  presetTitle={field.title}
                  id={field.id}
                  inner={false}
                />
              );
            }
            return null;
          })}
        </div>
      )}

      {/*===== DRUPAL PRESET =====*/}
      {formGenerator.find(
        (item) => item.type === "premadeKfwTemplate" && item.id === 9
      ) && (
        <div className="preset0-container">
          <h3>
            {formGenerator.find((item) => item.type === "templateName").value}
          </h3>
          {formGenerator.map((field, index) => {
            while (index > 5) {
              return (
                <GeneratorField
                  card={field}
                  key={field.id}
                  readOnly={true}
                  presetTitle={field.title}
                  id={field.id}
                  inner={false}
                />
              );
            }
            return null;
          })}
        </div>
      )}

      {!formGenerator.find((item) => item.type === "premadeKfwTemplate") && (
        <MainAreaButtons
          incrementGlobaldId={incrementGlobaldId}
          globalId={globalId}
          buttonsBadRed={buttonsBadRed}
          checkIfFormIsReady={checkIfFormIsReady}
        />
      )}

      {!formGenerator.find((prop) => prop.type === "premadeKfwTemplate") &&
        formGenerator.find(
          (element) => element.type === "field" || element.type === "fieldgroup"
        ) && (
          <div className={`save-template-area ${!formIsReady && "inactive"}`}>
            <div className="save-template-area--turnon">
              {!showSaveTemplate && (
                <div
                  onClick={() => showSaveTemplateModal()}
                  className="save-template-checkbox"
                />
              )}
              {showSaveTemplate && formIsReady && (
                <svg
                  onClick={() => setShowSaveTemplate(false)}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="23"
                    rx="3.5"
                    fill="#5541D7"
                    stroke="#81858B"
                  />
                  <path
                    d="M10.1671 16.5833L5.5835 12.1718L6.89284 10.9116L10.1685 14.0607L10.1671 14.0621L18.0241 6.5L19.3335 7.76019L11.4765 15.3231L10.1681 16.5824L10.1671 16.5833Z"
                    fill="white"
                  />
                </svg>
              )}
              {showSaveTemplate && !formIsReady && (
                <svg
                  onClick={() => {
                    setShowSaveTemplate(false);
                    templateBlockRef.current.scrollIntoView();
                  }}
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM7.71 13.29C7.32 13.68 6.69 13.68 6.3 13.29L2.71 9.7C2.32 9.31 2.32 8.68 2.71 8.29C3.1 7.9 3.73 7.9 4.12 8.29L7 11.17L13.88 4.29C14.27 3.9 14.9 3.9 15.29 4.29C15.68 4.68 15.68 5.31 15.29 5.7L7.71 13.29Z"
                    fill="#81858B"
                  />
                </svg>
              )}

              <div className="save-template-title">Save template</div>
            </div>

            <CSSTransition
              classNames="saveTemplateInput"
              in={showSaveTemplate}
              timeout={150}
              onExited={() => console.log("exited")}
              unmountOnExit
            >
              <div
                ref={templateBlockRef}
                className="save-template-area__template-block"
              >
                <label htmlFor="Template Name">Template Name</label>
                <div className="save-template-area__template-name">
                  <input
                    readOnly={!formIsReady}
                    value={newTemplateName}
                    onChange={(e) => setNewTemplateName(e.target.value)}
                    type="text"
                  />
                  <svg
                    onClick={() => {
                      setShowSaveTemplate(false);
                      saveTemplate();
                    }}
                    className="save-template-area__save-button"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.001 20C4.48011 19.9939 0.00606298 15.5203 0 10V9.80001C0.109942 4.30455 4.63505 -0.0720256 10.1317 0.000898217C15.6283 0.0738221 20.0357 4.5689 19.9998 10.0653C19.9639 15.5618 15.4981 19.9989 10.001 20ZM6.11622 10.2929C5.72625 9.90447 5.09544 9.90508 4.70622 10.2943C4.31642 10.684 4.31642 11.316 4.70622 11.7058L7.29373 14.293C7.68424 14.6835 8.31735 14.6835 8.70787 14.293L15.2969 7.70466C15.6865 7.3151 15.6872 6.68338 15.2989 6.29245C14.9088 5.89962 14.2734 5.89806 13.8819 6.28952L8.0008 12.17L6.11622 10.2929Z"
                      fill="#67BAA7"
                    />
                  </svg>
                  <svg
                    onClick={() => setShowSaveTemplate(false)}
                    className="save-template-area__close-button"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.9969 22C9.34485 22.0024 6.8007 20.9496 4.9254 19.0739C3.05009 17.1981 1.99762 14.6533 2 12.0006V11.8006C2.08177 7.79305 4.54702 4.22119 8.26443 2.72418C11.9818 1.22718 16.2337 2.09405 19.0687 4.927C21.9303 7.78691 22.7868 12.0899 21.2384 15.828C19.6901 19.5661 16.0421 22.0024 11.9969 22ZM11.9969 13.4105L13.8812 15.2952C14.2705 15.6846 14.9018 15.6846 15.2911 15.2952C15.6803 14.9059 15.6803 14.2749 15.2911 13.8856L13.4065 12.0006L15.2911 10.1155C15.6803 9.72625 15.6803 9.09522 15.2911 8.70595C14.9018 8.31654 14.2705 8.31654 13.8812 8.70595L11.9969 10.5907L10.1127 8.70595C9.72337 8.31654 9.09207 8.31654 8.70276 8.70595C8.31359 9.09522 8.31359 9.72625 8.70276 10.1155L10.5874 12.0006L8.70283 13.8856C8.31362 14.2749 8.31362 14.906 8.70283 15.2953C9.09213 15.6847 9.72338 15.6847 10.1128 15.2954L11.9969 13.4115V13.4105Z"
                      fill="#81858B"
                    />
                  </svg>
                </div>
              </div>
            </CSSTransition>
          </div>
        )}
    </StyledMainArea>
  );
};

export default MainArea;

import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { setDeleteMemberModalOn } from "../../redux/actions/setDeleteKeysListItemModal";

const StyledTeamListSettingsModal = styled.div`
  z-index: 2;
  position: absolute;
  top: 25px;
  right: -1px;
  padding: 6px 0;
  background: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ff6562;
  white-space: nowrap;
  border: 1px solid #dddddd;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  .delete-website,
  .delete-keys {
    padding: 4px 10px;
    &:hover {
      background: #eeeeee;
    }
  }
`;

const TeamListSettingsModal = React.forwardRef(
  ({ setShowTeamListSettingsModal, text, memberEmail }, ref) => {
    const dispatch = useDispatch();

    const handleClickOutsideMemberSettings = (e) => {
      const path = e.path || (e.composedPath && e.composedPath());
      if (!path.includes(ref.current)) {
        setShowTeamListSettingsModal(false);
      }
    };

    React.useEffect(() => {
      document.body.addEventListener("click", handleClickOutsideMemberSettings);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <StyledTeamListSettingsModal>
        <div
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setDeleteMemberModalOn(memberEmail));
            setShowTeamListSettingsModal(false);
          }}
          className="delete-website"
        >
          {text}
        </div>
      </StyledTeamListSettingsModal>
    );
  }
);

export default TeamListSettingsModal;

import React, { useRef, useState } from "react";
import styled from "styled-components";
import useNotification from "../../../hooks/useNotification";

import Tooltip from "../../Tooltip";
import Button from "../../Button";

import {
  generateRequestLinkCopy,
  generateRequestLinkCopied,
  generateRequestShareByEmailTooltipContent,
} from "../../../assets/tooltipBlocks";

const StyledRequestGeneratedModal = styled.div`
  position: absolute;
  visibility: ${({ requestGeneratedModalVisibility }) =>
    requestGeneratedModalVisibility ? "visible" : "hidden"};
  opacity: ${({ requestGeneratedModalVisibility }) =>
    requestGeneratedModalVisibility ? "1" : "0"};
  top: 0;
  left: 50%;
  margin-left: -212px;
  max-width: 424px;
  .request-generate-modal {
    padding: 30px 40px;
    position: relative;
    background: #ffffff;
    border-radius: 10px;
    z-index: 100;
    .request-generated-modal__close-icon {
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
    }
    h2 {
      padding: 0;
      margin-bottom: 7px;
      font-weight: 600;
      font-size: 25px;
      line-height: 31px;
      text-align: center;
      color: #232323;
    }
    h3 {
      margin-bottom: 25px;
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #505356;
    }
    .request-generated__fields {
      label {
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        color: #232323;
      }
      .input-wrapper {
        height: 40px;
        margin-top: 8px;
        margin-bottom: 20px;
        width: 100vw;
        max-width: 344px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        background: #ffffff;
        border: 1px solid #dddddd;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 0 15px;
        .svg-container {
          position: relative;
        }
        svg {
          cursor: pointer;
        }
        input {
          width: 90%;
          border: none;
        }
      }
    }
    .button.purple {
      margin: 0 auto;
      width: 100vw;
      max-width: 209px;
    }
  }
  .bg-layer {
    background: #232323;
    opacity: 0.2;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

const RequestGeneratedModal = ({
  setRequestGeneratedModalVisibility,
  requestGeneratedModalVisibility,
  requestLinkUrl,
  linkId,
  clientUniqueHandle,
}) => {
  const showNotification = useNotification();
  const [tooltipVisibility, setTooltipVisibility] = useState("");
  const [requestUrlCopied, setRequestUrlCopied] = useState(false);
  const [emailForSharing, setEmailForSharing] = useState("");

  const onShareRequestUrl = () => {
    fetch(
      `https://engine.keysforweb.com/wp-json/bda/v1/share-request/${linkId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            localStorage.getItem("token") || sessionStorage.getItem("token")
          }`,
        },
        body: JSON.stringify({
          email: emailForSharing,
        }),
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data.status === "success") {
          showNotification(
            "success",
            "Request has been shared",
            "Your keys request has been sent",
            1500
          );
        } else if (data.code === "rest_invalid_param") {
          showNotification(
            "error",
            "Wrong email",
            "Request can't be shared",
            1500
          );
        }
      });
  };

  const copyLinkRef = useRef(null);
  const copyLinkToClipboard = () => {
    copyLinkRef.current.select();
    document.execCommand("copy");
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(document.createRange());
    setRequestUrlCopied(true);
  };
  return (
    <StyledRequestGeneratedModal
      requestGeneratedModalVisibility={requestGeneratedModalVisibility}
    >
      <div className="request-generate-modal">
        <svg
          onClick={() => setRequestGeneratedModalVisibility(false)}
          className="request-generated-modal__close-icon"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.99692 20C7.34485 20.0024 4.8007 18.9496 2.9254 17.0739C1.05009 15.1981 -0.00238199 12.6533 4.04814e-06 10.0006V9.80059C0.0817668 5.79305 2.54702 2.22119 6.26443 0.724184C9.98185 -0.772822 14.2337 0.0940545 17.0687 2.927C19.9303 5.78691 20.7868 10.0899 19.2384 13.828C17.6901 17.5661 14.0421 20.0024 9.99692 20ZM9.99693 11.4105L11.8812 13.2952C12.2705 13.6846 12.9018 13.6846 13.2911 13.2952C13.6803 12.9059 13.6803 12.2749 13.2911 11.8856L11.4065 10.0006L13.2911 8.11552C13.6803 7.72625 13.6803 7.09522 13.2911 6.70595C12.9018 6.31654 12.2705 6.31654 11.8812 6.70595L9.99693 8.59066L8.11268 6.70595C7.72337 6.31654 7.09207 6.31654 6.70276 6.70595C6.31359 7.09522 6.31359 7.72625 6.70276 8.11551L8.58736 10.0006L6.70283 11.8856C6.31362 12.2749 6.31362 12.906 6.70283 13.2953C7.09213 13.6847 7.72338 13.6847 8.11275 13.2954L9.99693 11.4115V11.4105Z"
            fill="#C2C5CB"
          />
        </svg>

        <h2>Generated request for {clientUniqueHandle}</h2>
        <h3>
          Copy the link to the request or send it directly to the recipient by
          email
        </h3>
        <div className="request-generated__fields">
          <label htmlFor="Request Link">Request Link</label>
          <div className="input-wrapper">
            <input
              ref={copyLinkRef}
              readOnly
              type="text"
              value={"https://" + requestLinkUrl}
            />
            <div className="svg-container">
              {" "}
              <svg
                onMouseOver={() =>
                  setTooltipVisibility("generateRequestLinkCopy")
                }
                onMouseOut={() => setTooltipVisibility("")}
                onClick={() => copyLinkToClipboard()}
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.9998 19.9988H2.00093C1.46507 20.0175 0.945532 19.8127 0.566391 19.4336C0.18725 19.0545 -0.0174533 18.5349 0.0011681 17.9991V8.00023C-0.0174533 7.46437 0.18725 6.94483 0.566391 6.56569C0.945532 6.18655 1.46507 5.98185 2.00093 6.00047H6.00047V2.00093C5.98185 1.46507 6.18655 0.945532 6.56569 0.566391C6.94483 0.18725 7.46437 -0.0174533 8.00023 0.0011681H17.9991C18.5349 -0.0174533 19.0545 0.18725 19.4336 0.566391C19.8127 0.945532 20.0175 1.46507 19.9988 2.00093V11.9998C20.0172 12.5355 19.8124 13.0549 19.4333 13.434C19.0542 13.8131 18.5348 14.0179 17.9991 13.9995H13.9995V17.9991C14.0179 18.5348 13.8131 19.0542 13.434 19.4333C13.0549 19.8124 12.5355 20.0172 11.9998 19.9988ZM2.00093 8.00023V17.9991H11.9998V13.9995H8.00023C7.46446 14.0179 6.94509 13.8131 6.56602 13.434C6.18695 13.0549 5.98215 12.5355 6.00047 11.9998V8.00023H2.00093ZM8.00023 2.00093V11.9998H17.9991V2.00093H8.00023Z"
                  fill="#505356"
                />
              </svg>
              <Tooltip
                tooltipVisibility={tooltipVisibility}
                contentBlocks={
                  requestUrlCopied
                    ? generateRequestLinkCopied
                    : generateRequestLinkCopy
                }
                specialClass={"generateRequestLinkCopy"}
              />
            </div>
          </div>

          <label htmlFor="Email">Email</label>
          <div className="input-wrapper">
            <input
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  onShareRequestUrl();
                }
              }}
              onChange={(e) => setEmailForSharing(e.target.value)}
              type="text"
            />
            <div className="svg-container">
              <svg
                onMouseOver={() =>
                  setTooltipVisibility("generateRequestShareByEmailTooltip")
                }
                onMouseOut={() => setTooltipVisibility("")}
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9993 21.8332C5.01626 21.8332 0.166016 16.9829 0.166016 10.9998C0.166016 5.01675 5.01626 0.166504 10.9993 0.166504C16.9824 0.166504 21.8327 5.01675 21.8327 10.9998C21.8261 16.9802 16.9797 21.8266 10.9993 21.8332ZM2.33268 11.1862C2.38394 15.9542 6.27722 19.7852 11.0454 19.7597C15.8137 19.7339 19.6655 15.8613 19.6655 11.093C19.6655 6.32472 15.8137 2.45209 11.0454 2.42633C6.27722 2.40083 2.38394 6.23183 2.33268 10.9998V11.1862ZM13.166 16.4165H9.91602V12.0832H8.83268V9.9165H12.0827V14.2498H13.166V16.4165ZM12.0827 7.74984H9.91602V5.58317H12.0827V7.74984Z"
                  fill="#505356"
                />
              </svg>
              <Tooltip
                tooltipVisibility={tooltipVisibility}
                contentBlocks={generateRequestShareByEmailTooltipContent}
                specialClass={"generateRequestShareByEmailTooltip"}
              />
            </div>
          </div>
        </div>
        <Button
          onClick={onShareRequestUrl}
          color={"purple"}
          text={"Share by Email"}
        />
      </div>
      <div className="bg-layer" />
    </StyledRequestGeneratedModal>
  );
};

export default RequestGeneratedModal;

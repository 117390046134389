import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useNotification from "../../hooks/useNotification";

import Button from "../Button";

import { setUserProfileInformation } from "../../redux/actions/setUserProfile";

const StyledPrivacy = styled.div`
  .inner__content--privacy {
    padding: 40px 0 110px 40px;
    background: #ffffff;
    box-shadow: -10px 9px 21px rgba(35, 35, 35, 0.07);
    border-radius: 20px;

    h3 {
      font-weight: normal;
      font-size: 20px;
      line-height: 120%;
      letter-spacing: 0.005em;
      color: #232323;

      margin-bottom: 10px;
    }
    h4 {
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: 0.005em;

      color: #5e5e5e;
    }
  }

  .privacy-container {
    padding-right: 24px;
    width: 408px;
    border-right: 0.5px solid #dddddd;
  }

  .privacy__notifications {
    border-bottom: 0.5px solid #dddddd;
    margin-bottom: 40px;
    padding-bottom: 17.5px;

    h4 {
      margin-bottom: 17.5px;
    }
  }

  .privacy__checkbox-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12.5px 0;

    label {
      margin-bottom: 3px;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      color: #232323;
      margin-right: 56px;
    }
    input {
      margin-right: 10px;
      border: 1px solid #8b8f9a;
      box-sizing: border-box;
      border-radius: 2px;
    }
  }

  .privacy__contact-info {
    border-bottom: 0.5px solid #dddddd;
    margin-bottom: 40px;
    padding-bottom: 30px;

    button {
      margin-top: 20px;
      min-width: 188px;
    }

    h4 {
      margin-bottom: 20px;
    }
  }

  .privacy__email-address {
    label {
      font-weight: bold;
      font-size: 10px;
      line-height: 16px;
      text-transform: uppercase;
      color: #5e5e5e;
    }

    .input-wrapper {
      margin-top: 8px;
      input {
        padding: 0 15px;
        width: 100%;
        max-width: 318px;
        border: 1px solid #dddddd;
        height: 40px;
        border-radius: 8px;

        transition: 0.3s;
        &.badRed {
          border: 1px solid #ff6562;
        }
      }
    }
  }
  .privacy__password {
    h4 {
      margin-bottom: 20px;
    }
  }
  .login__actions {
    &.type--changepassword {
      background: url("data:image/svg+xml;utf8, #{$typechangepasswordurl}")
        no-repeat 50% 7%;
    }
  }
`;

function Privacy() {
  const dispatch = useDispatch();
  const currentUser = useSelector(
    ({ currentUserReducer }) => currentUserReducer.currentUser
  );
  const notificationState = useSelector(
    ({ notificationState }) => notificationState
  );

  const showNotification = useNotification();

  const [typedEmail, setTypedEmail] = useState(currentUser.email);

  React.useEffect(() => {
    if (!currentUser.isSubscriptionValid) {
      showNotification(
        "error",
        "red",
        "Your email has not been confirmed",
        "Click here to resend a confirmation email.",
        5000
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeEmail = () => {
    fetch("https://engine.keysforweb.com/wp-json/bda/v1/user-data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        email: typedEmail,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data.status === "success") {
          showNotification(
            "sucess",
            "Check your inbox",
            "Instructions have been sent to your email",
            2000
          );
          dispatch(
            setUserProfileInformation({
              isSubscriptionValid: "",
            })
          );
        }
        if (data.code === "reg_is_busy") {
          showNotification(
            "error",
            "Email is already taken",
            "Please, choose another email",
            2000
          );
        }
        if (data.code === "rest_invalid_param") {
          showNotification(
            "error",
            "Invalid email",
            "Please, type in a valid email",
            2000
          );
        }
      });
  };
  const handleConfirmEmail = () => {
    fetch("https://engine.keysforweb.com/wp-json/bda/v1/activation-email", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        showNotification(
          "success",
          "Check your inbox",
          "Instructions have been sent to your email.",
          2000
        );
      });
  };

  return (
    <StyledPrivacy>
      <div className="settings__inner">
        <h2>Privacy and data</h2>
        <div className="inner__content--privacy">
          <div className="privacy-container">
            <div className="privacy__notifications">
              <h3>Notifications</h3>
              <h4>Choose type of notifications you want to receive</h4>
              <div className="privacy__checkbox-block">
                <div className="privacy__checkbox-item">
                  <input type="checkbox" />
                  <label htmlFor="Save template">Save template</label>
                </div>

                <div className="privacy__checkbox-item">
                  <input type="checkbox" />
                  <label htmlFor="Save template">Save template</label>
                </div>
              </div>
              <div className="privacy__checkbox-block">
                <div className="privacy__checkbox-item">
                  <input type="checkbox" />
                  <label htmlFor="Save template">Save template</label>
                </div>

                <div className="privacy__checkbox-item">
                  <input type="checkbox" />
                  <label htmlFor="Save template">Save template</label>
                </div>
              </div>
            </div>

            <div className="privacy__contact-info">
              <h3>Contact info</h3>
              <h4>
                Below is your contact information used for sending the relevant
                notifications and upates
              </h4>
              <div className="privacy__email-address">
                <label htmlFor="Email address">EMAIL ADDRESS</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    value={typedEmail}
                    onChange={(e) => setTypedEmail(e.target.value)}
                    readOnly={!currentUser.isSubscriptionValid}
                    className={`${
                      notificationState.visible &&
                      notificationState.params.type === "error"
                        ? "badRed"
                        : ""
                    }`}
                  />
                </div>
              </div>

              {currentUser.isSubscriptionValid && (
                <Button
                  onClick={() => handleChangeEmail()}
                  color={"purple"}
                  text={"Change email"}
                />
              )}
              {!currentUser.isSubscriptionValid && (
                <Button
                  onClick={() => handleConfirmEmail()}
                  color={"purple"}
                  text={"Confirm email"}
                />
              )}
            </div>

            <div className="privacy__password">
              <h3>Change password</h3>
              <h4>You can change or update your personal information</h4>
            </div>
            <Link to="/settings/changepassword">
              <Button color={"purple"} text={"Change password"} />
            </Link>
          </div>
        </div>
      </div>
    </StyledPrivacy>
  );
}

export default Privacy;

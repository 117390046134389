import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  setFieldGroupItems,
  setFieldGroupTitle,
} from "../../../redux/actions/setFormItems";

import GeneratorField from "./GeneratorField";

function GeneratorFieldGroup({
  group,
  id,
  innerFields,
  readOnly,
  order,
  dragStartHandler,
  dragEndHandler,
  dragOverHandler,
  dropHandler,
  currentDraggedCard,
}) {
  const dispatch = useDispatch();
  const [noTransition, setNoTransition] = useState(false);

  const onAddInnerField = (obj, fieldGroupId, innerId) => {
    dispatch(setFieldGroupItems(obj, fieldGroupId, innerId));
  };

  const onAddFieldTitle = (e, id) => {
    const groupTitle = e.target.value;
    dispatch(setFieldGroupTitle(id, groupTitle));
  };

  let lastInnerOrder;
  function setLastInnerOrder() {
    if (innerFields.length === 0) {
      lastInnerOrder = 1;
      return;
    }

    let innerOrderArray = [];
    innerFields.forEach((item) => {
      if (item.type === "field") {
        innerOrderArray.push(item.innerOrder);
      }
    });
    lastInnerOrder = Math.max.apply(Math, innerOrderArray) + 1;
  }

  let innerId = group.innerFields.length - 1;

  React.useEffect(() => {
    setNoTransition(true);
    setTimeout(() => {
      setNoTransition(false);
    });
  }, [order]);

  const sortInnerFields = (a, b) => {
    if (a.innerOrder > b.innerOrder) {
      return 1;
    } else {
      return -1;
    }
  };

  return (
    <div
      onDragOver={(e) => {
        e.preventDefault();
        dragOverHandler(e, group, id, false);
      }}
      onDrop={(e) => {
        dropHandler(e, group, id, false);
      }}
      className="generator__field-group--wrapper"
    >
      <div
        className={`generator__field-group ${
          group.translateY === 1 && "translateDown"
        } ${group.translateY === -1 && "translateUp"} ${
          noTransition && "noTransition"
        }`}
      >
        <input
          onChange={(e) => onAddFieldTitle(e, id)}
          value={group.groupTitle}
          type="text"
          placeholder="Change group title"
          className={`field-change-title ${
            group.groupTitle !== "" && "entitled"
          }`}
        ></input>
        {innerFields.sort(sortInnerFields).map((item) => {
          return (
            <GeneratorField
              key={item.innerId}
              readOnly={readOnly}
              id={item.innerId}
              outerId={id}
              inner={true}
              card={item}
              order={order}
              innerOrder={item.innerOrder}
              dragStartHandler={dragStartHandler}
              dragEndHandler={dragEndHandler}
              dragOverHandler={dragOverHandler}
              dropHandler={dropHandler}
              currentDraggedCard={currentDraggedCard}
            />
          );
        })}
        {!group.innerFields.find(
          (item) => item.type === "field" && item.title === ""
        ) && (
          <div
            onClick={() => {
              setLastInnerOrder();
              innerId++;
              onAddInnerField(
                {
                  type: "field",
                  title: "",
                  value: "",
                  innerId: innerId,
                  innerOrder: lastInnerOrder,
                },
                id
              );
            }}
            className="add-field inside-field-group"
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.83342 6.83317V10.9998C6.83342 11.4601 6.46032 11.8332 6.00008 11.8332C5.53984 11.8332 5.16675 11.4601 5.16675 10.9998V6.83317H1.00008C0.539844 6.83317 0.166748 6.46008 0.166748 5.99984C0.166748 5.5396 0.539844 5.1665 1.00008 5.1665H5.16675V0.999837C5.16675 0.5396 5.53984 0.166504 6.00008 0.166504C6.46032 0.166504 6.83342 0.5396 6.83342 0.999837V5.1665H11.0001C11.4603 5.1665 11.8334 5.5396 11.8334 5.99984C11.8334 6.46008 11.4603 6.83317 11.0001 6.83317H6.83342Z"
                fill="white"
              />
            </svg>
            Add field
          </div>
        )}
      </div>
    </div>
  );
}

export default GeneratorFieldGroup;

const initialState = {
  isLoading: false,
  isLoggedIn: false,
  currentUser: {},
};

const currentUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_USER':
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
        currentUser: action.payload.currentUser,
      };
    case 'LOGOUT_USER':
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        currentUser: {},
      };
    case 'SET_LOADING_USER':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_USER_PROFILE_INFORMATION':
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default currentUserReducer;

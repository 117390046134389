import React, { useState, useRef } from "react";

const StatesModal = ({
  showStatesList,
  fetchedStates,
  setShowStatesList,
  temporaryUserState,
  changeLocationValue,
  displayedState,
}) => {
  const ref = useRef(null);
  const [noMatches, setNoMatches] = useState(false);

  const setStateFromModal = (e) => {
    changeLocationValue(e.target.textContent, "state");
    setShowStatesList(false);
  };

  let statesCounter = 0;
  React.useEffect(() => {
    for (let item of ref.current.children) {
      if (
        displayedState !== 0 &&
        !item.textContent
          .toLowerCase()
          .includes(displayedState.toLowerCase()) &&
        item.textContent !== "No match"
      ) {
        item.style.display = "none";
      } else {
        item.style.display = "";
      }
    }

    for (let item of ref.current.children) {
      if (item.style.display === "none") {
        statesCounter++;
      }
    }
    if (statesCounter === ref.current.children.length) {
      setNoMatches(true);
    } else {
      setNoMatches(false);
    }
  }, [displayedState]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={`states-dropbox ${showStatesList ? "isActive" : ""}`}>
      <div className="input-wrapper">
        <input
          onChange={(e) => {
            changeLocationValue(e.target.value, "state");
            setShowStatesList(true);
          }}
          value={displayedState}
          type="text"
        />
        <svg
          onClick={(e) => {
            e.stopPropagation();
            setShowStatesList(false);
          }}
          width="15"
          height="8"
          viewBox="0 0 15 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L7.5 7L14 1"
            stroke="#232323"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="countries-dropbox__list" ref={ref}>
        {fetchedStates &&
          fetchedStates.map((state, index) => {
            return (
              <div
                key={index}
                onClick={(e) => {
                  setStateFromModal(e);
                  e.stopPropagation();
                  setShowStatesList(false);
                }}
                className="countries-dropbox__item"
              >
                {state}
              </div>
            );
          })}
        {noMatches && (
          <div className="countries-dropbox__item no-match">No match</div>
        )}
      </div>
    </div>
  );
};

export default StatesModal;

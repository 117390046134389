import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import Button from "../Button";

const StyledGeneratorModal = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  margin-left: -213px;
  transition: 0.2s;
  visibility: ${({ showLeaveGeneratorModal }) =>
    showLeaveGeneratorModal ? "visible" : "hidden"};
  opacity: ${({ showLeaveGeneratorModal }) =>
    showLeaveGeneratorModal ? "1" : "0"};
  .leave-generator-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 10px;
    position: relative;
    background: #ffffff;
    border-radius: 10px;
    z-index: 1;

    width: 100vw;
    max-width: 407px;

    .leave-generator-modal__cool-icon {
      background: #fff0ef;
      border-radius: 8px;
      padding: 14px;
      margin-bottom: 30px;
    }

    h3 {
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 25px;
      line-height: 31px;
      text-align: center;
      color: #232323;
      max-width: 367px;
    }
    h4 {
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #505356;
    }
  }
  .leave-generator-modal__buttons {
    .button {
      width: 138px;
    }
    display: flex;
    margin-top: 30px;
    .button:first-child {
      margin-right: 23px;
    }
  }
  .bg-layer {
    background: #000;
    opacity: 0.6;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

const LeaveGeneratorModal = ({
  showLeaveGeneratorModal,
  setShowLeaveGeneratorModal,
  nextLocation,
}) => {
  const history = useHistory();

  React.useEffect(() => {
    console.log(nextLocation);
  }, [nextLocation]);

  const handleLeave = () => {
    console.log(nextLocation);
    history.push(nextLocation);
  };

  return (
    <StyledGeneratorModal showLeaveGeneratorModal={showLeaveGeneratorModal}>
      <div className="leave-generator-modal">
        <div className="leave-generator-modal__cool-icon">
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.6667 30H11.6667C9.82572 30 8.33333 28.5076 8.33333 26.6667V20H11.6667V26.6667H26.6667V3.33333H11.6667V10H8.33333V3.33333C8.33333 1.49238 9.82572 0 11.6667 0H26.6667C28.5076 0 30 1.49238 30 3.33333V26.6667C30 28.5076 28.5076 30 26.6667 30ZM15 21.6667V16.6667H0V13.3333H15V8.33333L23.3333 15L15 21.6667Z"
              fill="#FF6562"
            />
          </svg>
        </div>
        <h3>Are you sure you want to go back?</h3>
        <h4>Your created fields will be deleted.</h4>
        <div className="leave-generator-modal__buttons">
          <Button
            color={"white"}
            text={"Cancel"}
            onClick={() => setShowLeaveGeneratorModal(false)}
          />
          <Button color={"red"} text={"Leave"} onClick={handleLeave} />
        </div>
      </div>

      <div className="bg-layer" />
    </StyledGeneratorModal>
  );
};

export default LeaveGeneratorModal;

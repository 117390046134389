const initialState = {
  type: "login",
  emailForReset: "",
};

const loginPageComponent = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOGIN_PAGE_COMPONENT":
      return {
        ...state,
        type: action.payload,
        emailForReset: action.email,
      };
    default:
      return { ...state };
  }
};

export default loginPageComponent;

import React, { useRef, useState } from "react";

import Button from "../../../Button";
import CountriesModal from "../Modals/CountriesModal";
import StatesModal from "../Modals/StatesModal";
import { useSelector } from "react-redux";

const ProfileUserCharacteristics = ({
  profileGoodGreen,
  setTemporaryUserState,
  temporaryUserState,
  onImageUpload,
  handleDeleteAvatar,
  handleImageChange,
  inputImage,
}) => {
  const countriesRef = useRef(null);
  const [countryIndex, setCountryIndex] = useState(15);
  const [showCountriesList, setShowCountriesList] = useState(false);
  const [showStatesList, setShowStatesList] = useState(false);
  const subscriptionType = useSelector(
    ({ subscriptionType }) => subscriptionType.subscriptionType
  );

  // ======= COUNTRIES STATES =======
  const [countriesDatabase, setCountriesDatabase] = useState({});
  const [displayedCountry, setDisplayedCountry] = useState("");
  // ======= STATES STATES =======
  const [statesDatabase, setStatesDatabase] = useState({});
  const [displayedState, setDisplayedState] = useState("");

  const handleOutsideCountriesModalClick = (e) => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (!path.includes(countriesRef.current)) {
      setShowCountriesList(false);
    }
  };
  React.useEffect(() => {
    // ======= GET COUNTRIES =======
    fetch("https://engine.keysforweb.com/wp-json/bda/v1/countries", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem("token") || sessionStorage.getItem("token")
        }`,
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data, "COUNTRIES");

        // ======= SET DISPLAYED COUNTRY =======
        setCountriesDatabase(data);
        for (let acr in data) {
          if (acr === temporaryUserState.country) {
            setDisplayedCountry(data[acr]);
          }
        }
      });
    document.body.addEventListener("click", handleOutsideCountriesModalClick);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // ======= GET STATES =======
  React.useEffect(() => {
    fetch(
      `https://engine.keysforweb.com/wp-json/bda/v1/states/${temporaryUserState.country}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") || sessionStorage.getItem("token")
          }`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data, "states");
        if (!data || data.code === "rest_no_route") {
          setStatesDatabase(null);
        } else {
          // ======= SET DISPLAYED STATE =======
          setStatesDatabase(data);
          for (let acr in data) {
            if (acr === temporaryUserState.state) {
              setDisplayedState(data[acr]);
            }
          }
        }
      });
  }, [temporaryUserState.country]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeLocationValue = (value, region) => {
    switch (region) {
      case "country":
        setDisplayedCountry(value);
        Object.values(countriesDatabase).forEach((countryName, index) => {
          if (countryName === value) {
            setTemporaryUserState({
              ...temporaryUserState,
              country: Object.keys(countriesDatabase)[index],
            });
          }
        });
        return;
      case "state":
        setDisplayedState(value);
        Object.values(statesDatabase).forEach((stateName, index) => {
          if (stateName === value) {
            setTemporaryUserState({
              ...temporaryUserState,
              state: Object.keys(statesDatabase)[index],
            });
          }
        });
        return;
      default:
        return;
    }
  };

  return (
    <div className="profile__user-characteristics">
      <div className="user-characteristics__block">
        <div className="profile-name">
          <label htmlFor="Your name">First Name</label>
          <div
            className={`input-wrapper ${
              profileGoodGreen.firstName ? "goodGreen" : ""
            } `}
          >
            <input
              autoComplete="new-password"
              onChange={(e) => {
                setTemporaryUserState({
                  ...temporaryUserState,
                  firstName: e.target.value,
                });
              }}
              value={temporaryUserState.firstName}
              type="text"
            />
          </div>
        </div>
        {subscriptionType !== "lifetime" && (
          <div ref={countriesRef} className="profile-country">
            <label htmlFor="Country">Country</label>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setShowCountriesList(true);
              }}
              className={`input-wrapper ${
                profileGoodGreen.country ? "goodGreen" : ""
              } `}
            >
              <input
                autoComplete="new-password"
                onChange={(e) => {
                  changeLocationValue(e.target.value, "country");
                  setShowCountriesList(true);
                }}
                value={displayedCountry}
                type="text"
              />
              <svg
                width="15"
                height="8"
                viewBox="0 0 15 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L7.5 7L14 1"
                  stroke="#232323"
                  strokeWidth="2"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                />
              </svg>
              <CountriesModal
                changeLocationValue={changeLocationValue}
                displayedCountry={displayedCountry}
                fetchedCountries={Object.values(countriesDatabase)}
                setCountryIndex={setCountryIndex}
                showCountriesList={showCountriesList}
                setShowCountriesList={setShowCountriesList}
              />
            </div>
          </div>
        )}
        {subscriptionType !== "lifetime" && (
          <div className="profile-state-province">
            <label htmlFor="State / Province">State / Province</label>
            <div
              onClick={(e) => {
                if (statesDatabase !== null) {
                  e.stopPropagation();
                  setShowStatesList(true);
                }
              }}
              className={`input-wrapper ${
                profileGoodGreen.address ? "goodGreen" : ""
              } `}
            >
              <input
                autoComplete="new-password"
                onChange={(e) => {
                  changeLocationValue(e.target.value, "state");
                  setShowStatesList(true);
                }}
                value={displayedState}
                type="text"
              />
              {statesDatabase !== null && (
                <svg
                  width="15"
                  height="8"
                  viewBox="0 0 15 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7.5 7L14 1"
                    stroke="#232323"
                    strokeWidth="2"
                    strokeLinejoin="round"
                    strokeLinecap="round"
                  />
                </svg>
              )}
              <StatesModal
                changeLocationValue={changeLocationValue}
                displayedState={displayedState}
                fetchedStates={Object.values(statesDatabase)}
                temporaryUserState={temporaryUserState}
                setTemporaryUserState={setTemporaryUserState}
                countryIndex={countryIndex}
                showStatesList={showStatesList}
                setShowStatesList={setShowStatesList}
              />
            </div>
          </div>
        )}
        {subscriptionType !== "lifetime" && (
          <div className="profile-address billing-zip">
            <label htmlFor="Billing Zip / Postal Code">
              Billing Zip / Postal Code
            </label>
            <div
              className={`input-wrapper ${
                profileGoodGreen.address ? "goodGreen" : ""
              } `}
            >
              <input
                onChange={(e) => {
                  setTemporaryUserState({
                    ...temporaryUserState,
                    postalCode: e.target.value,
                  });
                }}
                value={
                  temporaryUserState.postalCode
                    ? temporaryUserState.postalCode
                    : ""
                }
                type="text"
              />
            </div>
          </div>
        )}
      </div>
      <div className="user-characteristics__block">
        <div className="profile-phone">
          <label htmlFor="Last name">Last name</label>
          <div
            className={`input-wrapper ${
              profileGoodGreen.phone ? "goodGreen" : ""
            } `}
          >
            <input
              onChange={(e) => {
                setTemporaryUserState({
                  ...temporaryUserState,
                  lastName: e.target.value,
                });
              }}
              value={temporaryUserState.lastName}
              type="tel"
            />
          </div>
        </div>
        {subscriptionType !== "lifetime" && (
          <div className="profile-city">
            <label htmlFor="City">City</label>
            <div
              className={`input-wrapper ${
                profileGoodGreen.city ? "goodGreen" : ""
              } `}
            >
              <input
                onChange={(e) => {
                  setTemporaryUserState({
                    ...temporaryUserState,
                    city: e.target.value,
                  });
                }}
                value={temporaryUserState.city ? temporaryUserState.city : ""}
                type="text"
              />
            </div>
          </div>
        )}
        {subscriptionType !== "lifetime" && (
          <div className="profile-postal">
            <label htmlFor="Address">Address</label>
            <div
              className={`input-wrapper ${
                profileGoodGreen.postalCode ? "goodGreen" : ""
              } `}
            >
              <input
                onChange={(e) => {
                  setTemporaryUserState({
                    ...temporaryUserState,
                    address: e.target.value,
                  });
                }}
                value={
                  temporaryUserState.address ? temporaryUserState.address : ""
                }
                type="text"
              />
            </div>
          </div>
        )}
      </div>
      <div className="profile-avatar">
        {
          <div
            onClick={() => onImageUpload()}
            className={`profile-avatar__img ${
              temporaryUserState.avatar ? "" : "noPic"
            }`}
          >
            {temporaryUserState.avatar && (
              <img src={temporaryUserState.avatar} alt="userimg" />
            )}
          </div>
        }
        <div className="profile-avatar__select">
          <div className="profile-avatar__select-buttons">
            <Button
              onClick={() => onImageUpload()}
              color={"purple"}
              text={"Upload new image"}
            />
            <Button
              onClick={() => handleDeleteAvatar()}
              color={"transparent"}
              text={"Delete photo"}
            />
          </div>
          <input
            type="file"
            id="user-image-upload"
            onChange={(e) => handleImageChange(e)}
            accept="image/png, image/jpeg, image/jpg"
            ref={inputImage}
            style={{ display: "none" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileUserCharacteristics;

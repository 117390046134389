import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setSettingsContent } from "../../redux/actions/setSettingsContent.js";
import { setUpgradeModalVisibility } from "../../redux/actions/setUpgradeModalVisibility";

const StyledHeaderModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 32%;
  right: 0;
  width: 265px;
  background: #ffffff;
  box-shadow: -10px 10px 25px rgba(35, 35, 35, 0.25);
  border-radius: 8px;
  z-index: 1000;
  transition: 0.2s;

  transform: ${({ isVisible }) =>
    isVisible ? "translateY(15%)" : "translateY(-30%)"};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  opacity: ${({ isVisible }) => (isVisible ? "1" : "0")};

  .header-modal__item {
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 33%;
    width: 100%;
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: #505356;
    padding: 16px 0;
    transition: 0.2s;
    &:hover {
      background: #f6f6f6;
    }

    svg {
      margin-left: 30px;
      margin-right: 13px;
    }
  }

  .modal-keys-request--mobile {
    display: none;
    svg {
      margin-right: 13px;
    }
  }
  .modal-profile {
    svg {
      margin-right: 17px;
    }
  }
  .modal-home {
    svg {
      margin-right: 16px;
    }
  }

  .modal-logout {
    padding: 16px 0;
    display: flex;
    align-items: center;

    height: 34%;
    width: 100%;

    cursor: pointer;

    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: #5e5e5e;

    border-top: 1px solid #dddddd;

    transition: 0.2s;

    &:hover {
      color: #5541d7;
      svg {
        path {
          transition: 0.2s;
          fill: #5541d7;
        }
      }
    }
    svg {
      margin-left: 30px;
      margin-right: 15px;
    }
  }
`;

function HeaderModal({ isVisible, setLogoutVisibility }) {
  const dispatch = useDispatch();

  const userRole = useSelector(
    ({ currentUserReducer }) => currentUserReducer.currentUser.role
  );

  const subscriptionType = useSelector(
    ({ subscriptionType }) => subscriptionType.subscriptionType
  );

  const onSelectSettingsContent = (content) => {
    dispatch(setSettingsContent(content));
  };

  return (
    <StyledHeaderModal isVisible={isVisible}>
      <Link to="/" className="header-modal__item modal-home">
        <svg
          width="16"
          height="19"
          viewBox="0 0 16 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 19H1C0.447715 19 0 18.5423 0 17.9777V8.17837C0 7.90727 0.105451 7.64729 0.293 7.45563L7.293 0.299815C7.48057 0.107858 7.73509 0 8.0005 0C8.26591 0 8.52043 0.107858 8.708 0.299815L15.708 7.45563C15.8957 7.64708 16.0009 7.90728 16 8.17837V17.9777C16 18.5423 15.5523 19 15 19ZM6 11.8442H10V16.9555H14V8.60158L8 2.46803L2 8.60158V16.9555H6V11.8442Z"
            fill="#505356"
          />
        </svg>

        <span>Home</span>
      </Link>
      {userRole === "team-admin" && (
        <Link to="/team" className="header-modal__item modal-team">
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 0C4.23858 0 2 2.23858 2 5C2 7.76142 4.23858 10 7 10C9.76142 10 12 7.76142 12 5C12 2.23858 9.76142 0 7 0ZM4 5C4 3.34315 5.34315 2 7 2C8.65685 2 10 3.34315 10 5C10 6.65685 8.65685 8 7 8C5.34315 8 4 6.65685 4 5Z"
              fill="#505356"
            />
            <path
              d="M14.9084 5.21828C14.6271 5.07484 14.3158 5.00006 14 5.00006V3.00006C14.6316 3.00006 15.2542 3.14956 15.8169 3.43645C15.8789 3.46805 15.9399 3.50121 16 3.5359C16.4854 3.81614 16.9072 4.19569 17.2373 4.65055C17.6083 5.16172 17.8529 5.75347 17.9512 6.37737C18.0496 7.00126 17.9987 7.63958 17.8029 8.24005C17.6071 8.84053 17.2719 9.38611 16.8247 9.83213C16.3775 10.2782 15.8311 10.6119 15.2301 10.8062C14.6953 10.979 14.1308 11.037 13.5735 10.9772C13.5046 10.9698 13.4357 10.9606 13.367 10.9496C12.7438 10.8497 12.1531 10.6038 11.6431 10.2319L11.6421 10.2311L12.821 8.61557C13.0761 8.80172 13.3717 8.92477 13.6835 8.97474C13.9953 9.02471 14.3145 9.00014 14.615 8.90302C14.9155 8.80591 15.1887 8.63903 15.4123 8.41602C15.6359 8.19302 15.8035 7.92024 15.9014 7.62001C15.9993 7.31978 16.0247 7.00063 15.9756 6.68869C15.9264 6.37675 15.8041 6.08089 15.6186 5.82531C15.4331 5.56974 15.1898 5.36172 14.9084 5.21828Z"
              fill="#505356"
            />
            <path
              d="M17.9981 18C17.9981 17.475 17.8947 16.9551 17.6938 16.47C17.4928 15.9849 17.1983 15.5442 16.8271 15.1729C16.4558 14.8017 16.0151 14.5072 15.53 14.3062C15.0449 14.1053 14.525 14.0019 14 14.0019V12C14.6821 12 15.3584 12.1163 16 12.3431C16.0996 12.3783 16.1983 12.4162 16.2961 12.4567C17.0241 12.7583 17.6855 13.2002 18.2426 13.7574C18.7998 14.3145 19.2417 14.9759 19.5433 15.7039C19.5838 15.8017 19.6217 15.9004 19.6569 16C19.8837 16.6416 20 17.3179 20 18H17.9981Z"
              fill="#505356"
            />
            <path
              d="M14 18H12C12 15.2386 9.76142 13 7 13C4.23858 13 2 15.2386 2 18H0C0 14.134 3.13401 11 7 11C10.866 11 14 14.134 14 18Z"
              fill="#505356"
            />
          </svg>

          <span>My Team</span>
        </Link>
      )}

      <Link
        onClick={() => onSelectSettingsContent("profile")}
        to="/settings"
        className="header-modal__item modal-profile"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V15C0 15.55 0.45 16 1 16H15C15.55 16 16 15.55 16 15V14C16 11.34 10.67 10 8 10Z"
            fill="#505356"
          />
        </svg>
        <span>Profile</span>
      </Link>
      {(userRole === "team-admin" || userRole === "client") &&
        subscriptionType !== "lifetime" && (
          <Link
            onClick={() => onSelectSettingsContent("billing")}
            to="/settings"
            className="header-modal__item"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.99777 16C4.89818 16 4.00851 16.9 4.00851 18C4.00851 19.1 4.89818 20 5.99777 20C7.09736 20 7.99703 19.1 7.99703 18C7.99703 16.9 7.09736 16 5.99777 16ZM0 1C0 1.55 0.449833 2 0.999629 2H1.99926L5.59792 9.59L4.24842 12.03C3.51869 13.37 4.47834 15 5.99777 15H16.9937C17.5435 15 17.9933 14.55 17.9933 14C17.9933 13.45 17.5435 13 16.9937 13H5.99777L7.09736 11H14.5446C15.2943 11 15.9541 10.59 16.2939 9.97L19.8726 3.48C20.2425 2.82 19.7627 2 19.0029 2H4.20844L3.53869 0.57C3.37875 0.22 3.01888 0 2.63902 0H0.999629C0.449833 0 0 0.45 0 1ZM15.9941 16C14.8945 16 14.0048 16.9 14.0048 18C14.0048 19.1 14.8945 20 15.9941 20C17.0937 20 17.9933 19.1 17.9933 18C17.9933 16.9 17.0937 16 15.9941 16Z"
                fill="#505356"
              />
            </svg>

            <span>Billing</span>
          </Link>
        )}
      {(userRole === "team-admin" || userRole === "client") &&
        subscriptionType !== "lifetime" && (
          <div
            onClick={() => dispatch(setUpgradeModalVisibility(true))}
            className="header-modal__item"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20ZM9.984 18H10C14.4167 17.9956 17.9942 14.4127 17.992 9.996C17.9898 5.57929 14.4087 2 9.992 2C5.57528 2 1.99421 5.57929 1.992 9.996C1.98979 14.4127 5.56729 17.9956 9.984 18ZM11 14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V8.83L7.11568 10.707C6.72572 11.0955 6.09489 11.0949 5.70568 10.7057C5.31594 10.3159 5.31594 9.68406 5.70568 9.29432L9.29289 5.70711C9.68342 5.31658 10.3166 5.31658 10.7071 5.70711L14.2943 9.29432C14.6841 9.68406 14.6841 10.3159 14.2943 10.7057C13.9051 11.0949 13.2743 11.0955 12.8843 10.707L11 8.83V14Z"
                fill="#505356"
              />
            </svg>

            <span>Upgrade</span>
          </div>
        )}

      <Link to="/request" className="modal-keys-request--mobile">
        <svg
          width="19"
          height="20"
          viewBox="0 0 19 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.8278 10C16.8278 9.77 16.818 9.55 16.7984 9.32L18.6157 7.91C19.0065 7.61 19.114 7.05 18.8698 6.61L17.0427 3.38C16.7984 2.94 16.2708 2.76 15.8214 2.96L13.7208 3.87C13.3593 3.61 12.9782 3.38 12.5777 3.19L12.2943 0.88C12.2357 0.38 11.8156 0 11.3271 0H7.68272C7.18443 0 6.7643 0.38 6.70568 0.88L6.42234 3.19C6.02176 3.38 5.64071 3.61 5.27921 3.87L3.17859 2.96C2.72915 2.76 2.20155 2.94 1.95729 3.38L0.130237 6.62C-0.114022 7.06 -0.00654815 7.61 0.384266 7.92L2.20155 9.33C2.18201 9.55 2.17224 9.77 2.17224 10C2.17224 10.23 2.18201 10.45 2.20155 10.68L0.384266 12.09C-0.00654815 12.39 -0.114022 12.95 0.130237 13.39L1.95729 16.62C2.20155 17.06 2.72915 17.24 3.17859 17.04L5.27921 16.13C5.64071 16.39 6.02176 16.62 6.42234 16.81L6.70568 19.12C6.7643 19.62 7.18443 20 7.67295 20H11.3173C11.8058 20 12.2259 19.62 12.2845 19.12L12.5679 16.81C12.9685 16.62 13.3495 16.39 13.711 16.13L15.8116 17.04C16.2611 17.24 16.7887 17.06 17.0329 16.62L18.86 13.39C19.1042 12.95 18.9968 12.4 18.606 12.09L16.7887 10.68C16.818 10.45 16.8278 10.23 16.8278 10ZM9.53908 13.5C7.6534 13.5 6.11946 11.93 6.11946 10C6.11946 8.07 7.6534 6.5 9.53908 6.5C11.4248 6.5 12.9587 8.07 12.9587 10C12.9587 11.93 11.4248 13.5 9.53908 13.5Z"
            fill="#8B8F9A"
          />
        </svg>
        <span>Keys request</span>
      </Link>
      <div onClick={() => setLogoutVisibility(true)} className="modal-logout">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.58333 2.58333H8.08333C8.5875 2.58333 9 2.17083 9 1.66667C9 1.1625 8.5875 0.75 8.08333 0.75H2.58333C1.575 0.75 0.75 1.575 0.75 2.58333V15.4167C0.75 16.425 1.575 17.25 2.58333 17.25H8.08333C8.5875 17.25 9 16.8375 9 16.3333C9 15.8292 8.5875 15.4167 8.08333 15.4167H2.58333V2.58333Z"
            fill="#8B8F9A"
          />
          <path
            d="M16.9292 8.67919L14.3717 6.12169C14.0783 5.82836 13.5833 6.03003 13.5833 6.44253V8.08336H7.16667C6.6625 8.08336 6.25 8.49586 6.25 9.00003C6.25 9.5042 6.6625 9.9167 7.16667 9.9167H13.5833V11.5575C13.5833 11.97 14.0783 12.1717 14.3625 11.8784L16.92 9.32086C17.1033 9.1467 17.1033 8.85336 16.9292 8.67919Z"
            fill="#8B8F9A"
          />
        </svg>
        <span>Logout</span>
      </div>
    </StyledHeaderModal>
  );
}

export default HeaderModal;

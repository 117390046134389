const initialState = {
  visibility: false,
};

const upgradeModalState = (state = initialState, action) => {
  switch (action.type) {
    case "SET_UPGRADE_MODAL_VISIBILITY":
      return {
        ...state,
        visibility: action.payload,
      };
    default:
      return { ...state };
  }
};

export default upgradeModalState;

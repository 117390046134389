import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import useNotification from "../hooks/useNotification";

import { Button, Loader, LoginStatic } from "../components";
import { redbutton } from "../style/variables";

const StyledChangePassword = styled.div`
  display: flex;
  width: 100%;
  max-width: 1214px;
  height: calc(100vh - 115px);
  max-height: 718px;
  margin: 30px auto 0;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: -10px 9px 21px rgba(35, 35, 35, 0.07);
  .login__actions {
    width: 55%;
    min-height: 618px;
    &.type--resetpass {
      background: url("data:image/svg+xml;utf8, #{$typeresetpassurl}") no-repeat
        50% 7%;
      .button {
        width: 182px;
      }
    }
  }
  .login__forms {
    padding: 65px 15px 0 15px;
    max-width: 368px;
    margin: 85px auto 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    h2 {
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 25px;
      line-height: 31px;
      text-align: center;
      color: #232323;
    }
    h3 {
      margin: 10px 0 30px;
      font-weight: normal;
      font-size: 16px;
      line-height: 120%;
      text-align: center;
      letter-spacing: 0.005em;
      color: #5e5e5e;
    }
    .signUpLink {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration: none;
    }
    label {
      margin-bottom: 7px;
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
      text-align: left;
      color: #232323;
    }

    .input-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      margin: 0 auto 25px;
      width: 100%;
      max-width: 368px;
      border-radius: 8px;
      border: 1px solid #dddddd;
      span {
        position: absolute;
        bottom: -18px;
      }
      &.focus-within {
        border: 1px solid rgba(85, 65, 215, 0.5);
      }
      transition: 0.3s;
      svg {
        cursor: pointer;
        padding-right: 10px;
        &.redEye {
          path {
            fill: ${redbutton};
          }
        }
        &:hover {
          path {
            fill: ${redbutton};
          }
        }
      }
      .user-password-input {
        width: 83.2%;
        //max-width: 276px;
      }
      input {
        padding: 0 15px;
        height: 40px;
        width: 100%;
        max-width: 318px;
        border-radius: 8px;
        border: none;
      }

      &.loginBadRed {
        border: 1px solid #ff6562;
      }
    }
    .button {
      margin: 5px auto 0;
      min-width: 200px;
    }
  }
`;

function ChangePassword() {
  const [passBadRed, setPassBadRed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showNotification = useNotification();
  const history = useHistory();

  const oldPassRef = useRef();
  const newPassRef = useRef();
  const confirmNewPassRef = useRef();

  const toggleShowPassword = (e, ref) => {
    if (ref.current.type === "password") {
      ref.current.type = "text";
    } else {
      ref.current.type = "password";
    }
  };

  const confirmChangePassword = () => {
    if (
      confirmNewPassRef.current.value === "" ||
      newPassRef.current.value === "" ||
      oldPassRef.current.value === ""
    ) {
      showNotification(
        "error",
        "Some fields are empty",
        "Fill every field to proceed",
        2000
      );
    } else {
      if (confirmNewPassRef.current.value !== newPassRef.current.value) {
        setPassBadRed(true);
        setTimeout(() => {
          setPassBadRed(false);
        }, 1500);
        showNotification(
          "error",
          "Cannot change your password",
          "Your passwords don't match",
          2000
        );
      } else {
        setIsLoading(true);
        fetch("https://engine.keysforweb.com/wp-json/bda/v1/user-data", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              localStorage.getItem("token") || sessionStorage.getItem("token")
            }`,
          },
          body: JSON.stringify({
            currentPassword: oldPassRef.current.value,
            newPassword: newPassRef.current.value,
          }),
        })
          .then((resp) => resp.json())
          .then((data) => {
            console.log(data, "change password");

            setIsLoading(false);

            if (data.status === "success") {
              showNotification(
                "success",
                "Success!",
                "Your password has been changed",
                2000
              );
              history.push("/");
            } else if (data.code === "incorrect_current_password") {
              showNotification(
                "error",
                "Incorrect password",
                "Please, check if you made any typos in your old password",
                2000
              );
            }
          });
      }
    }
  };

  return (
    <StyledChangePassword>
      {isLoading === false && (
        <div className="login__actions type--changepassword ">
          <div className="login__forms">
            <h2>Update password</h2>

            <label htmlFor="Old password">Old password</label>
            <div className="input-wrapper">
              <input
                ref={oldPassRef}
                type="password"
                className="user-password-input"
              />
              <svg
                onClick={(e) => toggleShowPassword(e, oldPassRef)}
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 3.49977C14.4741 3.49977 17.5725 5.45227 19.085 8.54144C18.5441 9.65977 17.7833 10.6223 16.8758 11.4014L18.1683 12.6939C19.4425 11.5664 20.4508 10.1548 21.0833 8.54144C19.4975 4.51727 15.5833 1.66644 11 1.66644C9.83579 1.66644 8.71746 1.84977 7.66329 2.18894L9.17579 3.70144C9.77163 3.58227 10.3766 3.49977 11 3.49977ZM10.0191 4.54477L11.9166 6.44227C12.4391 6.67144 12.8608 7.09311 13.09 7.61561L14.9875 9.51311C15.0608 9.20144 15.1158 8.87144 15.1158 8.53227C15.125 6.25894 13.2733 4.41644 11 4.41644C10.6608 4.41644 10.34 4.46227 10.0191 4.54477ZM1.84246 1.54727L4.29913 4.00394C2.80496 5.17727 1.62246 6.73561 0.916626 8.54144C2.50246 12.5656 6.41663 15.4164 11 15.4164C12.3933 15.4164 13.7316 15.1506 14.96 14.6648L18.095 17.7998L19.3875 16.5073L3.13496 0.245605L1.84246 1.54727ZM8.71746 8.42227L11.11 10.8148C11.0733 10.8239 11.0366 10.8331 11 10.8331C9.73496 10.8331 8.70829 9.80644 8.70829 8.54144C8.70829 8.49561 8.71746 8.46811 8.71746 8.42227ZM5.60079 5.3056L7.20496 6.90977C6.99413 7.41394 6.87496 7.96394 6.87496 8.54144C6.87496 10.8148 8.72663 12.6664 11 12.6664C11.5775 12.6664 12.1275 12.5473 12.6225 12.3364L13.5208 13.2348C12.7141 13.4548 11.8708 13.5831 11 13.5831C7.52579 13.5831 4.42746 11.6306 2.91496 8.54144C3.55663 7.23061 4.49163 6.14894 5.60079 5.3056Z"
                  fill="#8B8F9A"
                />
              </svg>
            </div>

            <label htmlFor="New password">New password</label>
            <div className="input-wrapper">
              <input
                ref={newPassRef}
                type="password"
                className="user-password-input"
              />
              <svg
                onClick={(e) => toggleShowPassword(e, newPassRef)}
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 3.49977C14.4741 3.49977 17.5725 5.45227 19.085 8.54144C18.5441 9.65977 17.7833 10.6223 16.8758 11.4014L18.1683 12.6939C19.4425 11.5664 20.4508 10.1548 21.0833 8.54144C19.4975 4.51727 15.5833 1.66644 11 1.66644C9.83579 1.66644 8.71746 1.84977 7.66329 2.18894L9.17579 3.70144C9.77163 3.58227 10.3766 3.49977 11 3.49977ZM10.0191 4.54477L11.9166 6.44227C12.4391 6.67144 12.8608 7.09311 13.09 7.61561L14.9875 9.51311C15.0608 9.20144 15.1158 8.87144 15.1158 8.53227C15.125 6.25894 13.2733 4.41644 11 4.41644C10.6608 4.41644 10.34 4.46227 10.0191 4.54477ZM1.84246 1.54727L4.29913 4.00394C2.80496 5.17727 1.62246 6.73561 0.916626 8.54144C2.50246 12.5656 6.41663 15.4164 11 15.4164C12.3933 15.4164 13.7316 15.1506 14.96 14.6648L18.095 17.7998L19.3875 16.5073L3.13496 0.245605L1.84246 1.54727ZM8.71746 8.42227L11.11 10.8148C11.0733 10.8239 11.0366 10.8331 11 10.8331C9.73496 10.8331 8.70829 9.80644 8.70829 8.54144C8.70829 8.49561 8.71746 8.46811 8.71746 8.42227ZM5.60079 5.3056L7.20496 6.90977C6.99413 7.41394 6.87496 7.96394 6.87496 8.54144C6.87496 10.8148 8.72663 12.6664 11 12.6664C11.5775 12.6664 12.1275 12.5473 12.6225 12.3364L13.5208 13.2348C12.7141 13.4548 11.8708 13.5831 11 13.5831C7.52579 13.5831 4.42746 11.6306 2.91496 8.54144C3.55663 7.23061 4.49163 6.14894 5.60079 5.3056Z"
                  fill="#8B8F9A"
                />
              </svg>
            </div>

            <label htmlFor="Confirm new password">Confirm new password</label>
            <div className={`input-wrapper ${passBadRed ? "loginBadRed" : ""}`}>
              <input
                ref={confirmNewPassRef}
                type="password"
                className="user-password-input"
              />
              <svg
                onClick={(e) => toggleShowPassword(e, confirmNewPassRef)}
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 3.49977C14.4741 3.49977 17.5725 5.45227 19.085 8.54144C18.5441 9.65977 17.7833 10.6223 16.8758 11.4014L18.1683 12.6939C19.4425 11.5664 20.4508 10.1548 21.0833 8.54144C19.4975 4.51727 15.5833 1.66644 11 1.66644C9.83579 1.66644 8.71746 1.84977 7.66329 2.18894L9.17579 3.70144C9.77163 3.58227 10.3766 3.49977 11 3.49977ZM10.0191 4.54477L11.9166 6.44227C12.4391 6.67144 12.8608 7.09311 13.09 7.61561L14.9875 9.51311C15.0608 9.20144 15.1158 8.87144 15.1158 8.53227C15.125 6.25894 13.2733 4.41644 11 4.41644C10.6608 4.41644 10.34 4.46227 10.0191 4.54477ZM1.84246 1.54727L4.29913 4.00394C2.80496 5.17727 1.62246 6.73561 0.916626 8.54144C2.50246 12.5656 6.41663 15.4164 11 15.4164C12.3933 15.4164 13.7316 15.1506 14.96 14.6648L18.095 17.7998L19.3875 16.5073L3.13496 0.245605L1.84246 1.54727ZM8.71746 8.42227L11.11 10.8148C11.0733 10.8239 11.0366 10.8331 11 10.8331C9.73496 10.8331 8.70829 9.80644 8.70829 8.54144C8.70829 8.49561 8.71746 8.46811 8.71746 8.42227ZM5.60079 5.3056L7.20496 6.90977C6.99413 7.41394 6.87496 7.96394 6.87496 8.54144C6.87496 10.8148 8.72663 12.6664 11 12.6664C11.5775 12.6664 12.1275 12.5473 12.6225 12.3364L13.5208 13.2348C12.7141 13.4548 11.8708 13.5831 11 13.5831C7.52579 13.5831 4.42746 11.6306 2.91496 8.54144C3.55663 7.23061 4.49163 6.14894 5.60079 5.3056Z"
                  fill="#8B8F9A"
                />
              </svg>
              <span
                className={`incorrectNewPassword ${
                  passBadRed ? "visible" : ""
                }`}
              >
                The email or password is incorrect
              </span>
            </div>

            <Button
              onClick={() => confirmChangePassword()}
              color={"purple"}
              text={"Update password"}
            />
          </div>
        </div>
      )}
      {!isLoading && <LoginStatic />}

      {isLoading === true && <Loader />}
    </StyledChangePassword>
  );
}

export default ChangePassword;

const initialState = [
  {
    type: "formId",
    id: 0,
  },
  {
    type: "templateName",
    value: "",
  },
  {
    type: "isCompleted",
    value: false,
  },
  {
    type: "clientUniqueHandle",
    value: "",
  },
  {
    type: "websiteUrl",
    value: "",
  },
];

const formGenerator = (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case "RESET_FORM":
      return [
        ...[
          {
            type: "formId",
            id: 0,
          },
          {
            type: "templateName",
            value: "",
          },
          {
            type: "isCompleted",
            value: false,
          },
          {
            type: "clientUniqueHandle",
            value: "",
          },
          {
            type: "websiteUrl",
            value: "",
          },
        ],
      ];
    case "SET_FORM_ID":
      newState.find((item) => item.type === "formId").id = action.payload;
      return [...newState];
    // case 'SET_COMPLETED':
    //   newState.find((item) => item.type === 'isCompleted').value = action.payload;
    //   return [...newState];
    case "SET_CLIENT_HANDLE":
      newState.find((item) => item.type === "clientUniqueHandle").value =
        action.payload;
      return [...newState];
    case "SET_WEBSITE_URL":
      newState.find((item) => item.type === "websiteUrl").value =
        action.payload;
      return [...newState];
    case "SET_FORM_ITEMS":
      newState.push(action.payload);
      return [...newState];
    case "DELETE_FORM_ITEMS":
      if (!action.inner) {
        // DECREASING ORDER
        newState.forEach((item) => {
          if (
            (item.type === "field" || item.type === "fieldgroup") &&
            item.order > action.order
          ) {
            item.order -= 1;
          }
        });
        // DELETING ITEM
        newState.forEach((item, index) => {
          if (item.id === action.id) {
            newState.splice(index, 1);
          }
        });
      } else if (action.inner) {
        newState.forEach((item, index) => {
          const outerIndex = index;
          if (item.id === action.outerId) {
            // DECREASING ORDER
            item.innerFields.forEach((innerItem) => {
              if (innerItem.innerOrder > action.innerOrder) {
                innerItem.innerOrder -= 1;
              }
            });
            // DELETING INNER ITEM
            item.innerFields.forEach((innerItem, index) => {
              if (innerItem.innerId === action.id) {
                item.innerFields.splice(index, 1);

                if (item.innerFields.length === 0) {
                  newState.forEach((item) => {
                    if (item.order > action.order) {
                      item.order -= 1;
                    }
                  });
                  newState.splice(outerIndex, 1);
                }
              }
            });
          }
        });
      }
      return [...newState];
    case "SWAP_FIELD_ORDER":
      const securedDraggedOrder =
        action.draggedCard.order || action.draggedCard.innerOrder;
      const securedDroppedOnOrder =
        action.droppedOnCard.order || action.droppedOnCard.innerOrder;

      if (action.inner !== true) {
        if (securedDraggedOrder < securedDroppedOnOrder) {
          newState.forEach((item) => {
            if (
              item.order <= securedDroppedOnOrder &&
              item.order > securedDraggedOrder
            ) {
              item.order -= 1;
            }
            if (item.id === action.draggedCard.id) {
              item.order = securedDroppedOnOrder;
            }
          });
        }
        if (securedDraggedOrder > securedDroppedOnOrder) {
          newState.forEach((item) => {
            if (
              item.order >= securedDroppedOnOrder &&
              item.order < securedDraggedOrder
            ) {
              item.order += 1;
            }
            if (item.id === action.draggedCard.id) {
              item.order = securedDroppedOnOrder;
            }
          });
        }
      } else {
        const currentFieldGroup = newState.find(
          (item) => item.type === "fieldgroup" && item.id === action.outerId
        );

        if (securedDraggedOrder < securedDroppedOnOrder) {
          currentFieldGroup.innerFields.forEach((innerItem) => {
            if (
              innerItem.innerOrder <= securedDroppedOnOrder &&
              innerItem.innerOrder > securedDraggedOrder
            ) {
              innerItem.innerOrder -= 1;
            }
            if (innerItem.innerId === action.draggedCard.innerId) {
              innerItem.innerOrder = securedDroppedOnOrder;
            }
          });
        }
        if (securedDraggedOrder > securedDroppedOnOrder) {
          currentFieldGroup.innerFields.forEach((innerItem) => {
            if (innerItem.innerOrder >= securedDroppedOnOrder) {
              innerItem.innerOrder += 1;
            }
            if (innerItem.innerId === action.draggedCard.innerId) {
              innerItem.innerOrder = securedDroppedOnOrder;
            }
          });
        }
      }

      return [...newState];
    case "SET_FIELD_TRANSLATE_Y":
      if (action.inner !== true) {
        if (action.draggedOrder === action.draggedOverOrder) {
          newState.forEach((item) => {
            delete item.translateY;
          });
        }
        if (action.draggedOrder > action.draggedOverOrder) {
          newState.forEach((item) => {
            if (
              item.order < action.draggedOrder &&
              item.order >= action.draggedOverOrder
            ) {
              item.translateY = 1;
            }
          });
        }
        if (action.draggedOverOrder > action.draggedOrder) {
          newState.forEach((item) => {
            if (
              item.order <= action.draggedOverOrder &&
              item.order > action.draggedOrder
            ) {
              item.translateY = -1;
            }
          });
        }
      } else {
        const currentFieldGroup = newState.find(
          (item) => item.type === "fieldgroup" && item.id === action.outerId
        );
        if (action.draggedOrder === action.draggedOverOrder) {
          currentFieldGroup.innerFields.forEach((innerItem) => {
            delete innerItem.translateY;
          });
        }
        if (action.draggedOrder > action.draggedOverOrder) {
          currentFieldGroup.innerFields.forEach((innerItem) => {
            if (
              innerItem.innerOrder < action.draggedOrder &&
              innerItem.innerOrder >= action.draggedOverOrder
            ) {
              innerItem.translateY = 1;
            }
          });
        }
        if (action.draggedOverOrder > action.draggedOrder) {
          currentFieldGroup.innerFields.forEach((innerItem) => {
            if (
              innerItem.innerOrder <= action.draggedOverOrder &&
              innerItem.innerOrder > action.draggedOrder
            ) {
              innerItem.translateY = -1;
            }
          });
        }
      }

      return [...newState];

    case "SET_FIELD_GROUP_ITEMS":
      newState.forEach((item) => {
        if (item.id === action.fieldGroupId) {
          item.innerFields.push(action.payload);
        }
      });
      return [...newState];
    case "SET_FIELD_TITLE":
      newState.forEach((item) => {
        if (!action.inner && item.id === action.id) {
          item.title = action.payload;
        } else if (item.type === "fieldgroup" && item.id === action.outerId) {
          item.innerFields.forEach((item) => {
            if (item.innerId === action.id) {
              item.title = action.payload;
            }
          });
        }
      });
      return [...newState];
    case "SET_FIELD_GROUP_TITLE":
      newState.forEach((item) => {
        if (item.id === action.id) {
          item.groupTitle = action.payload;
        }
      });
      return [...newState];
    case "SET_FIELD_VALUE":
      newState.forEach((item) => {
        if (!action.inner && item.id === action.id) {
          item.value = action.payload;
        } else if (item.type === "fieldgroup" && item.id === action.outerId) {
          item.innerFields.forEach((item) => {
            if (item.innerId === action.id) {
              item.value = action.payload;
            }
          });
        }
      });
      return [...newState];
    case "SET_TEMPLATE_NAME":
      newState.find((item) => item.type === "templateName").value =
        action.templateName;
      return [...newState];
    case "SET_TEMPLATE":
      newState = action.form;
      newState.find((item) => item.type === "clientUniqueHandle").value =
        action.clientUniqueHandle;
      newState.find((item) => item.type === "websiteUrl").value =
        action.websiteUrl;
      return [...newState];
    default:
      return state;
  }
};

export default formGenerator;

import React, { useState, useRef } from "react";
import styled from "styled-components";

const StyledSortByModal = styled.div`
  position: relative;
  margin-left: 15px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  max-width: 95px;
  z-index: 1;

  background: ${({ sortByVisibility }) =>
    sortByVisibility ? "#fff" : "rgba(35, 35, 35, 0.1)"};
  border: ${({ sortByVisibility }) =>
    sortByVisibility ? "1px solid #EDEDED" : "1px solid transparent"};
  border-radius: 4px;
  color: #5e5e5e;
  cursor: pointer;
  svg {
    margin-left: 10px;
  }
  .keys-list__sort-by-dropdown {
    position: absolute;
    top: 26px;
    right: -1px;
    z-index: 15;
    width: 100vw;
    max-width: 166px;
    padding: 9px 0;
    background: #ffffff;
    border: 1px solid #ededed;
    border-radius: 4px 0 4px 4px;
  }
  .keys-list__dropdown-option {
    padding: 5px 0 5px 9px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #505356;
    &:hover {
      background: #f6f6f6;
    }
  }
`;

const SortByModal = ({ setSortType }) => {
  const [sortByVisibility, setSortByVisibility] = useState(false);

  const sortByRef = useRef(null);

  const handleClickOutsideSortByModal = (e) => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (!path.includes(sortByRef.current)) {
      setSortByVisibility(false);
    }
  };

  React.useEffect(() => {
    document.body.addEventListener("click", handleClickOutsideSortByModal);
    return function cleanup() {
      document.body.removeEventListener("click", handleClickOutsideSortByModal);
    };
  }, []);

  return (
    <StyledSortByModal
      sortByVisibility={sortByVisibility}
      onClick={() => setSortByVisibility((prev) => !prev)}
      ref={sortByRef}
    >
      Sort by{" "}
      <svg
        width="12"
        height="7"
        viewBox="0 0 12 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.702509 0.712509C1.09284 0.319409 1.72829 0.318286 2.12 0.710005L5.44407 4.03407C5.7511 4.3411 6.2489 4.3411 6.55593 4.03407L9.88 0.710005C10.2717 0.318287 10.9072 0.319409 11.2975 0.712509C11.6859 1.10365 11.6848 1.73524 11.295 2.125L6.73124 6.68876C6.32739 7.09261 5.67261 7.09261 5.26876 6.68876L0.705005 2.125C0.315239 1.73524 0.314123 1.10365 0.702509 0.712509Z"
          fill="#5E5E5E"
        />
      </svg>
      {sortByVisibility && (
        <div className="keys-list__sort-by-dropdown">
          <div
            onClick={() => setSortType("alphabetInc")}
            className="keys-list__dropdown-option"
          >
            ↑ AZ
          </div>
          <div
            onClick={() => setSortType("alphabetDec")}
            className="keys-list__dropdown-option"
          >
            ↓ ZA
          </div>
          <div
            onClick={() => setSortType("recentlyAdded")}
            className="keys-list__dropdown-option"
          >
            Recently added
          </div>
        </div>
      )}
    </StyledSortByModal>
  );
};

export default SortByModal;

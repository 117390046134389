import React from "react";
import styled from "styled-components";
import Button from "../../Button";

const StyledTemplateDeleteModal = styled.div`
  position: absolute;
  top: 32%;
  left: 50%;
  margin-left: -213px;
  transition: 0.05s;
  visibility: ${({ showTemplateDeleteModal }) =>
    showTemplateDeleteModal ? "visible" : "hidden"};
  opacity: ${({ showTemplateDeleteModal }) =>
    showTemplateDeleteModal ? "1" : "0"};
  .template-delete-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 10px;
    position: relative;
    background: #ffffff;
    border-radius: 20px;
    z-index: 1;

    width: 100vw;
    max-width: 407px;

    .template-delete-modal__cool-icon {
      margin-bottom: 16px;
      background: #fff0ef;
      padding: 8px;
      border-radius: 8px;
    }
    h3 {
      margin-bottom: 7px;
      font-weight: 500;
      font-size: 25px;
      line-height: 31px;
      text-align: center;
      color: #232323;
    }
    h4 {
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #505356;
    }
  }
  .template-delete-modal__buttons {
    margin-top: 30px;
    display: flex;
    align-items: center;
    .button {
      width: 144px;
    }
    .button.white {
      margin-right: 23px;
    }
  }
  .bg-layer {
    background: #000;
    opacity: 0.6;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

const TemplateDeleteModal = ({
  templateId,
  showTemplateDeleteModal,
  setShowTemplateDeleteModal,
  setTemplatesListReloadTrigger,
}) => {
  const handleDeleteTemplate = () => {
    fetch(
      `https://engine.keysforweb.com/wp-json/bda/v1/request-templates/${templateId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") || sessionStorage.getItem("token")
          }`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data, "templade deleted");
        setTemplatesListReloadTrigger((prev) => prev + 1);
      });
  };
  return (
    <StyledTemplateDeleteModal
      showTemplateDeleteModal={showTemplateDeleteModal}
    >
      <div className="template-delete-modal">
        <div className="template-delete-modal__cool-icon">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28.3333 36.6668H11.6667C9.82572 36.6668 8.33333 35.1744 8.33333 33.3335V11.6668H5V8.3335H11.6667V6.66683C11.6667 4.82588 13.1591 3.3335 15 3.3335H25C26.8409 3.3335 28.3333 4.82588 28.3333 6.66683V8.3335H35V11.6668H31.6667V33.3335C31.6667 35.1744 30.1743 36.6668 28.3333 36.6668ZM11.6667 11.6668V33.3335H28.3333V11.6668H11.6667ZM15 6.66683V8.3335H25V6.66683H15Z"
              fill="#FF6562"
            />
          </svg>
        </div>
        <h3>You’re about to delete template</h3>
        <h4>This action cannot be undone</h4>
        <div className="template-delete-modal__buttons">
          <Button
            color={"white"}
            text={"Cancel"}
            onClick={() => setShowTemplateDeleteModal(false)}
          />
          <Button
            color={"red"}
            text={"Delete"}
            onClick={() => {
              handleDeleteTemplate();
              setShowTemplateDeleteModal(false);
            }}
          />
        </div>
      </div>
      <div className="bg-layer" />
    </StyledTemplateDeleteModal>
  );
};

export default TemplateDeleteModal;

import React, { useState, useRef } from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { useDispatch } from "react-redux";
import useNotification from "../../hooks/useNotification";

import { setSecureVaultOn } from "../../redux/actions/setSecureVault";
import KeysListSettingsModal from "./KeysListModals/KeysListSettingsModal";
import ShareRequestUrlModal from "./KeysListModals/ShareRequestUrlModal";

const StyledKeysListItem = styled.div``;

function KeysListItem({ localState, substring, setUseEffectTrigger, newForm }) {
  const showNotification = useNotification();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const [showKeysListItemSettings, setShowKeysListItemSettings] =
    useState(false);
  const [showShareRequestUrlModal, setShowShareRequestUrlModal] =
    useState(false);
  const [timeRemaining, setTimeRemaining] = useState();

  const shareRequestUrlRef = useRef(null);
  const itemSettingsRef = useRef(null);

  function checkTimeRemaining() {
    if (
      localState[localState.length - 1].find(
        (item) => item.type === "expirationDate"
      ) &&
      localState[localState.length - 1].find(
        (item) => item.type === "isCompleted"
      ).value
    ) {
      const expDate = Date.parse(
        localState[localState.length - 1].find(
          (item) => item.type === "expirationDate"
        ).value
      );
      const currentDate = Date.parse(
        new Date(new Date().toUTCString().substr(0, 25))
      );

      setTimeRemaining((expDate - currentDate) / 1000 / 60 / 60);

      console.log("aaa exec");

      if (currentDate >= expDate) {
        setIsVisible(false);
      }
    }
  }

  const clientUniqueHandle = localState[localState.length - 1].find(
    (item) => item.type === "clientUniqueHandle"
  ).value;
  const websiteUrl = localState[localState.length - 1].find(
    (item) => item.type === "websiteUrl"
  ).value;

  const copyRequestLinkToClipboard = () => {
    const requestLinkid = localState[localState.length - 1].find(
      (item) => item.type === "formId"
    ).id;
    const currentRequestLink = `https://kfw.pw/r/${requestLinkid}`;

    const clipboardDummy = document.createElement("textarea");
    document.body.appendChild(clipboardDummy);
    clipboardDummy.style.opacity = "0";
    clipboardDummy.value = currentRequestLink;
    clipboardDummy.select();
    document.execCommand("copy");
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(document.createRange());
    document.body.removeChild(clipboardDummy);

    showNotification(
      "success",
      "Copied",
      `Request link copied to clipboard`,
      2000
    );
  };

  const handleClickOutsideShareRequestModal = (e) => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (!path.includes(shareRequestUrlRef.current)) {
      setShowShareRequestUrlModal(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("storage", () => {
      setIsCompleted(true);
    });
    setIsCompleted(
      localState[localState.length - 1].find(
        (item) => item.type === "isCompleted"
      ).value
    );
  }, [localState]);

  React.useEffect(() => {
    if (
      substring.length !== 0 &&
      !clientUniqueHandle.toLowerCase().includes(substring.toLowerCase()) &&
      !websiteUrl.toLowerCase().includes(substring.toLowerCase())
    ) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [substring]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    document.body.addEventListener(
      "click",
      handleClickOutsideShareRequestModal
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    checkTimeRemaining();
    setInterval(checkTimeRemaining, 60000);
  }, [newForm]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <tr className={`keys-table__item ${!isVisible ? "isNotVisible" : ""}`}>
      <td>@{localState && clientUniqueHandle}</td>
      <td>
        {localState &&
          localState[localState.length - 1].find(
            (item) => item.type === "websiteUrl"
          ).value}
      </td>
      <td>
        <div className="keys-table__copy-and-share">
          <div
            onClick={() => copyRequestLinkToClipboard()}
            className="keys-table__copy-request-link copy-and-share-button"
          >
            <svg
              className="copy-and-share-icon"
              width="18"
              height="10"
              viewBox="0 0 18 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.1666 9.16668H10.6666V7.50001H13.1666C14.5473 7.50001 15.6666 6.38072 15.6666 5.00001C15.6666 3.6193 14.5473 2.50001 13.1666 2.50001H10.6666V0.833344H13.1666C15.4678 0.833344 17.3333 2.69882 17.3333 5.00001C17.3333 7.3012 15.4678 9.16668 13.1666 9.16668ZM7.33329 9.16668H4.83329C2.53211 9.16668 0.666626 7.3012 0.666626 5.00001C0.666626 2.69882 2.53211 0.833344 4.83329 0.833344H7.33329V2.50001H4.83329C3.45258 2.50001 2.33329 3.6193 2.33329 5.00001C2.33329 6.38072 3.45258 7.50001 4.83329 7.50001H7.33329V9.16668ZM13.1666 5.83334H4.83329V4.16668H13.1666V5.83334Z"
                fill="#FFFFFF"
              />
            </svg>
            Copy
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setShowShareRequestUrlModal(true);
            }}
            className="keys-table__share-request-link copy-and-share-button"
          >
            <svg
              className="copy-and-share-icon"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.1676 13.6667H2.0009C1.08042 13.6667 0.334229 12.9205 0.334229 12V2.83334C0.334229 1.91287 1.08042 1.16668 2.0009 1.16668H5.33423V2.83334H2.0009V12H11.1676V8.66668H12.8342V12C12.8342 12.9205 12.088 13.6667 11.1676 13.6667ZM6.7509 8.42251L5.5759 7.24418L10.8201 2.00001H7.83423V0.333344H13.6676V6.16668H12.0009V3.17918L6.7509 8.42251Z"
                fill="#FFFFFF"
              />
            </svg>
            Request
            <CSSTransition
              classNames="keysListModal"
              in={showShareRequestUrlModal}
              timeout={300}
              onExited={() => console.log("exited")}
              unmountOnExit
            >
              <ShareRequestUrlModal
                ref={shareRequestUrlRef}
                setShowShareRequestUrlModal={setShowShareRequestUrlModal}
                requestId={
                  localState[0].find((prop) => prop.type === "formId").id
                }
              />
            </CSSTransition>
          </div>
        </div>
      </td>
      <td>
        {isCompleted && (
          <span>
            {timeRemaining > 1 && `${Math.round(timeRemaining)} h`}
            {timeRemaining <= 1 && `${Math.round(timeRemaining * 60)} min`}
          </span>
        )}
      </td>
      <td>
        {isCompleted && (
          <div
            onClick={() => {
              dispatch(setSecureVaultOn(localState));
            }}
            className="keys-table__show-secure-vault"
          >
            See keys{" "}
            <svg
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.99996 2.6087C4.66663 2.6087 1.81996 4.50175 0.666626 7.17392C1.81996 9.84609 4.66663 11.7391 7.99996 11.7391C11.3333 11.7391 14.18 9.84609 15.3333 7.17392C14.18 4.50175 11.3333 2.6087 7.99996 2.6087ZM7.99996 10.2174C6.15996 10.2174 4.66663 8.85392 4.66663 7.17392C4.66663 5.49392 6.15996 4.13044 7.99996 4.13044C9.83996 4.13044 11.3333 5.49392 11.3333 7.17392C11.3333 8.85392 9.83996 10.2174 7.99996 10.2174ZM7.99996 5.34783C6.89329 5.34783 5.99996 6.16349 5.99996 7.17392C5.99996 8.18436 6.89329 9.00001 7.99996 9.00001C9.10663 9.00001 9.99996 8.18436 9.99996 7.17392C9.99996 6.16349 9.10663 5.34783 7.99996 5.34783Z"
                fill="white"
              />
            </svg>
          </div>
        )}
      </td>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setShowKeysListItemSettings(true);
        }}
        className="keys-table__settings-dots"
      >
        <svg
          width="16"
          height="4"
          viewBox="0 0 16 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.16659 1.99999H2.17492M7.99992 1.99999H8.00825M13.8333 1.99999H13.8416M2.99992 1.99999C2.99992 2.221 2.91212 2.43297 2.75584 2.58925C2.59956 2.74553 2.3876 2.83332 2.16659 2.83332C1.94557 2.83332 1.73361 2.74553 1.57733 2.58925C1.42105 2.43297 1.33325 2.221 1.33325 1.99999C1.33325 1.77898 1.42105 1.56701 1.57733 1.41073C1.73361 1.25445 1.94557 1.16666 2.16659 1.16666C2.3876 1.16666 2.59956 1.25445 2.75584 1.41073C2.91212 1.56701 2.99992 1.77898 2.99992 1.99999ZM8.83325 1.99999C8.83325 2.221 8.74545 2.43297 8.58917 2.58925C8.43289 2.74553 8.22093 2.83332 7.99992 2.83332C7.7789 2.83332 7.56694 2.74553 7.41066 2.58925C7.25438 2.43297 7.16658 2.221 7.16658 1.99999C7.16658 1.77898 7.25438 1.56701 7.41066 1.41073C7.56694 1.25445 7.7789 1.16666 7.99992 1.16666C8.22093 1.16666 8.43289 1.25445 8.58917 1.41073C8.74545 1.56701 8.83325 1.77898 8.83325 1.99999ZM14.6666 1.99999C14.6666 2.221 14.5788 2.43297 14.4225 2.58925C14.2662 2.74553 14.0543 2.83332 13.8333 2.83332C13.6122 2.83332 13.4003 2.74553 13.244 2.58925C13.0877 2.43297 12.9999 2.221 12.9999 1.99999C12.9999 1.77898 13.0877 1.56701 13.244 1.41073C13.4003 1.25445 13.6122 1.16666 13.8333 1.16666C14.0543 1.16666 14.2662 1.25445 14.4225 1.41073C14.5788 1.56701 14.6666 1.77898 14.6666 1.99999Z"
            stroke="#8B8F9A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <CSSTransition
          classNames="secureVaultModal"
          in={showKeysListItemSettings}
          timeout={300}
          onExited={() => console.log("exited")}
          unmountOnExit
        >
          <KeysListSettingsModal
            ref={itemSettingsRef}
            localState={localState}
            setShowKeysListItemSettings={setShowKeysListItemSettings}
          />
        </CSSTransition>
      </div>
    </tr>
  );
}

export default KeysListItem;

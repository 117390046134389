import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import Button from "../Button";

const StyledNotification = styled.div`
  .notification {
    overflow: hidden;
    min-width: 325px;
    max-width: 341px;

    position: absolute;
    top: 0;
    right: 0;

    opacity: 0;
    transform: translateX(10%);
    transition: all 0.25s cubic-bezier(0.25, 0.5, 0.5, 0.9);

    &.visible {
      opacity: 1;
      transform: translateX(0);
      &.order1 {
        transform: translateY(0);
      }
      &.order2 {
        transform: ${({ notsRefs }) =>
          notsRefs.current.find((not) => not.classList.contains("order1")) &&
          `translateY(${
            notsRefs.current.find((not) => not.classList.contains("order1"))
              .offsetHeight + 15
          }px)`};
      }
      &.order3 {
        transform: ${({ notsRefs }) =>
          notsRefs.current.find((not) => not.classList.contains("order1")) &&
          notsRefs.current.find((not) => not.classList.contains("order2")) &&
          `translateY(${
            notsRefs.current.find((not) => not.classList.contains("order1"))
              .offsetHeight +
            notsRefs.current.find((not) => not.classList.contains("order2"))
              .offsetHeight +
            30
          }px)`};
      }
      &.order4 {
        transform: ${({ notsRefs }) =>
          notsRefs.current.find((not) => not.classList.contains("order1")) &&
          notsRefs.current.find((not) => not.classList.contains("order2")) &&
          notsRefs.current.find((not) => not.classList.contains("order3")) &&
          `translateY(${
            notsRefs.current.find((not) => not.classList.contains("order1"))
              .offsetHeight +
            notsRefs.current.find((not) => not.classList.contains("order2"))
              .offsetHeight +
            notsRefs.current.find((not) => not.classList.contains("order3"))
              .offsetHeight +
            45
          }px)`};
      }
    }
    &.invisible {
      opacity: 0;
      transform: translateX(10%);
    }
    &.hideForever {
      position: absolute;
      visibility: hidden;
    }

    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;

    padding: 12px 0px 12px 0;

    background: #ffffff;
    box-shadow: -10px 9px 21px rgba(35, 35, 35, 0.07);
    border-radius: 5px;
    svg {
      min-width: 24px;
      min-height: 24px;
      margin-left: 21px;
    }
    z-index: 10000;
  }

  .notification__content {
    margin-left: 20px;
    .button {
      margin-top: 14px;
    }
  }

  .notification-title {
    letter-spacing: 0.005em;
    font-weight: ${({ isSubmitWithoutVerificationNotification }) =>
      isSubmitWithoutVerificationNotification ? "500" : "500"};
    font-size: ${({ isSubmitWithoutVerificationNotification }) =>
      isSubmitWithoutVerificationNotification ? "18px" : "18px"};
    line-height: ${({ isSubmitWithoutVerificationNotification }) =>
      isSubmitWithoutVerificationNotification ? "23px" : "23px"};
    color: #232323;
  }
  .notification-text {
    margin-top: 5px;
    font-weight: 300;
    letter-spacing: 0.005em;
    font-size: ${({ isSubmitWithoutVerificationNotification }) =>
      isSubmitWithoutVerificationNotification ? "16px" : "16px"};
    line-height: ${({ isSubmitWithoutVerificationNotification }) =>
      isSubmitWithoutVerificationNotification ? "20px" : "20px"};
    color: ${({ isSubmitWithoutVerificationNotification }) =>
      isSubmitWithoutVerificationNotification ? "#505356" : "#505356"};

    max-width: 244px;
  }

  .notification__stripe {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;

    width: 8px;
    height: 100%;
    border-radius: 5px 0px 0px 5px;
    &.error {
      background: #ff6562;
    }
    &.success {
      background: #67baa7;
    }
  }
`;

const Notification = React.forwardRef(({ notification, notsRefs }, ref) => {
  const currentForm = useSelector(
    ({ requestLinkState }) => requestLinkState.formInJSON
  );

  const [
    isSubmitWithoutVerificationNotification,
    setIsSubmitWithoutVerificationNotification,
  ] = useState(false);
  React.useEffect(() => {
    if (
      notification.params.text ===
      "If you can't pass the verification, you\n can still send the keys."
    ) {
      setIsSubmitWithoutVerificationNotification(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledNotification
      notification={notification}
      notsRefs={notsRefs}
      isSubmitWithoutVerificationNotification={
        isSubmitWithoutVerificationNotification
      }
    >
      <div
        ref={ref}
        className={`notification ${
          notification.visible ? "visible" : "invisible"
        } ${notification.hideForever && "hideForever"} 
      ${"order" + notification.order} `}
      >
        {notification.params.showStripe === true && (
          <div
            className={`notification__stripe ${notification.params.type}`}
          ></div>
        )}
        {notification.params.type === "error" &&
          notification.params.showIcon === true && (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.7782 7.22943C16.4824 6.93364 16.0045 6.93364 15.7088 7.22943L12 10.9306L8.29124 7.22184C7.99545 6.92605 7.51763 6.92605 7.22184 7.22184C6.92605 7.51763 6.92605 7.99545 7.22184 8.29124L10.9306 12L7.22184 15.7088C6.92605 16.0045 6.92605 16.4824 7.22184 16.7782C7.51763 17.0739 7.99545 17.0739 8.29124 16.7782L12 13.0694L15.7088 16.7782C16.0045 17.0739 16.4824 17.0739 16.7782 16.7782C17.0739 16.4824 17.0739 16.0045 16.7782 15.7088L13.0694 12L16.7782 8.29124C17.0664 8.00303 17.0664 7.51763 16.7782 7.22943Z"
                fill="#FF6562"
              />
              <circle cx="12" cy="12" r="11" stroke="#FF6562" strokeWidth="2" />
            </svg>
          )}
        {notification.params.type === "success" &&
          notification.params.showIcon === true && (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM12 21.6C6.708 21.6 2.4 17.292 2.4 12C2.4 6.708 6.708 2.4 12 2.4C17.292 2.4 21.6 6.708 21.6 12C21.6 17.292 17.292 21.6 12 21.6ZM16.656 7.548L9.6 14.604L7.344 12.348C6.876 11.88 6.12 11.88 5.652 12.348C5.184 12.816 5.184 13.572 5.652 14.04L8.76 17.148C9.228 17.616 9.984 17.616 10.452 17.148L18.36 9.24C18.828 8.772 18.828 8.016 18.36 7.548C17.892 7.08 17.124 7.08 16.656 7.548Z"
                fill="#67BAA7"
              />
            </svg>
          )}
        <div className="notification__content">
          <h4 className="notification-title">{notification.params.title}</h4>
          <div className="notification-text">{notification.params.text}</div>
          {notification.params.buttonOnClick && (
            <Button
              color={"purple"}
              text={"Submit without verification"}
              className={"submit-without-verification"}
              onClick={() => notification.params.buttonOnClick(currentForm)}
            />
          )}
        </div>
      </div>
    </StyledNotification>
  );
});

export default Notification;

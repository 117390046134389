const initialState = {
  isVisible: false,
  localState: [],
};

const secureVaultState = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SECURE_VAULT_ON':
      return {
        ...state,
        isVisible: true,
        localState: action.payload,
      };
    case 'SET_SECURE_VAULT_OFF':
      return {
        ...state,
        isVisible: false,
        // localState: [],
      };
    default:
      return state;
  }
};

export default secureVaultState;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setFormItems } from '../../../redux/actions/setFormItems';

const MainAreaButtons = ({ incrementGlobaldId, globalId, buttonsBadRed, checkIfFormIsReady }) => {
  const dispatch = useDispatch();
  const formGenerator = useSelector(({ formGenerator }) => formGenerator);

  const [fieldsCompleted, setFieldsCompleted] = useState(false);

  const onAddField = (obj) => {
    dispatch(setFormItems(obj));
  };
  const onAddFieldGroup = (obj) => {
    dispatch(setFormItems(obj));
  };

  let lastOrder;
  function setLastOrder() {
    if (formGenerator.length === 5) {
      lastOrder = 1;
      return;
    }

    let orderArray = [];
    formGenerator.forEach((item) => {
      if (item.type === 'field' || item.type === 'fieldgroup') {
        orderArray.push(item.order);
      }
    });
    lastOrder = Math.max.apply(Math, orderArray) + 1;
  }

  React.useEffect(() => {
    if (formGenerator.length <= 5) {
      setFieldsCompleted(true);
    } else {
      setFieldsCompleted(checkIfFormIsReady);
    }
  }, [formGenerator]);

  return (
    <div className={`generator__buttons ${!fieldsCompleted ? 'inactive' : 'active'}`}>
      <div
        onClick={() => {
          setLastOrder();
          incrementGlobaldId();
          onAddField({
            type: 'field',
            title: '',
            value: '',
            id: globalId,
            order: lastOrder,
          });
        }}
        className={`add-field ${buttonsBadRed ? 'badred' : ''}`}>
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.83329 6.83317V10.9998C6.83329 11.4601 6.4602 11.8332 5.99996 11.8332C5.53972 11.8332 5.16663 11.4601 5.16663 10.9998V6.83317H0.99996C0.539722 6.83317 0.166626 6.46008 0.166626 5.99984C0.166626 5.5396 0.539722 5.1665 0.99996 5.1665H5.16663V0.999837C5.16663 0.5396 5.53972 0.166504 5.99996 0.166504C6.4602 0.166504 6.83329 0.5396 6.83329 0.999837V5.1665H11C11.4602 5.1665 11.8333 5.5396 11.8333 5.99984C11.8333 6.46008 11.4602 6.83317 11 6.83317H6.83329Z"
            fill="white"
          />
        </svg>
        Single field
      </div>
      <div
        onClick={() => {
          setLastOrder();
          incrementGlobaldId();
          onAddFieldGroup({
            type: 'fieldgroup',
            groupTitle: '',
            innerFields: [
              {
                type: 'field',
                title: '',
                value: '',
                innerId: 0,
                innerOrder: 1,
              },
            ],
            id: globalId,
            order: lastOrder,
          });
        }}
        className={`add-field-group ${buttonsBadRed ? 'badred' : ''}`}>
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.83329 6.83317V10.9998C6.83329 11.4601 6.4602 11.8332 5.99996 11.8332C5.53972 11.8332 5.16663 11.4601 5.16663 10.9998V6.83317H0.99996C0.539722 6.83317 0.166626 6.46008 0.166626 5.99984C0.166626 5.5396 0.539722 5.1665 0.99996 5.1665H5.16663V0.999837C5.16663 0.5396 5.53972 0.166504 5.99996 0.166504C6.4602 0.166504 6.83329 0.5396 6.83329 0.999837V5.1665H11C11.4602 5.1665 11.8333 5.5396 11.8333 5.99984C11.8333 6.46008 11.4602 6.83317 11 6.83317H6.83329Z"
            fill="white"
          />
        </svg>
        Field group
      </div>
    </div>
  );
};

export default MainAreaButtons;

import React, { useState } from "react";
import styled from "styled-components";
import useNotification from "../../../hooks/useNotification";
import Button from "../../Button";

const StyledShareRequestUrlModal = styled.div`
  padding: 15px 27px 15px 20px;
  position: absolute;
  z-index: 2;
  top: ${({ type }) => (type === "inBuilder" ? "-230px" : "28px")};
  right: 0;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 17px 24px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  width: 100vw;
  max-width: 278px;
  h2 {
    padding-bottom: 0 !important;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #232323;
  }
  h3 {
    margin-bottom: 15px;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #505356;
  }
  label {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #232323;
  }
  input {
    margin-top: 7px;
    padding: 0 0 0 15px;

    height: 38px;
    border: 1px solid #dddddd !important;
    border-radius: 8px !important;
    width: 98% !important;
    transition: 0.3s;

    &.badRed {
      border: 1px solid #ff6562 !important;
    }
  }
  .badRed-label {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.005em;
    color: #ff6562;
    opacity: 0;
    transition: 0.2s;
    margin-bottom: 5px;
    margin-top: 4px;
    &.visible {
      opacity: 1;
    }
  }
  cursor: default;
  .share-request-url-modal-close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .button.purple {
    min-width: 169px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
  }
`;

const ShareRequestUrlModal = React.forwardRef(
  ({ setShowShareRequestUrlModal, requestId, type }, ref) => {
    const [emailForSharing, setEmailForSharing] = useState("");
    const [badRed, setBadRed] = useState(false);

    const showNotification = useNotification();

    const onShareRequestUrl = () => {
      if (!emailForSharing.includes("@")) {
        setBadRed(true);
        setTimeout(() => {
          setBadRed(false);
        }, 2300);
      } else {
        fetch(
          `https://engine.keysforweb.com/wp-json/bda/v1/share-request/${requestId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${
                localStorage.getItem("token") || sessionStorage.getItem("token")
              }`,
            },
            body: JSON.stringify({
              email: emailForSharing,
            }),
          }
        )
          .then((resp) => resp.json())
          .then((data) => {
            console.log(data);
            if (data.status === "success") {
              showNotification(
                "success",
                "Email has been shared",
                "Your keys request has been sent",
                1500
              );
            }
          });
      }
    };

    return (
      <StyledShareRequestUrlModal type={type}>
        <svg
          onClick={(e) => {
            e.stopPropagation();
            setShowShareRequestUrlModal(false);
          }}
          className="share-request-url-modal-close-icon"
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.2095 2.79065C12.5282 -0.890604 6.47197 -0.890604 2.79072 2.79065C-0.890527 6.4719 -0.890527 12.5281 2.79072 16.2094C6.47197 19.8906 12.5282 19.8906 16.2095 16.2094C19.9501 12.5281 19.9501 6.4719 16.2095 2.79065ZM13.3595 12.2906C13.6563 12.5875 13.6563 13.0625 13.3595 13.3594C13.2407 13.4781 13.0626 13.5969 12.8251 13.5969C12.5876 13.5969 12.4688 13.5375 12.2907 13.3594L9.5001 10.5688L6.70947 13.3594C6.59072 13.4781 6.4126 13.5969 6.1751 13.5969C5.9376 13.5969 5.81885 13.5375 5.64072 13.3594C5.34385 13.0625 5.34385 12.5875 5.64072 12.2906L8.43135 9.50002L5.64072 6.7094C5.34385 6.41252 5.34385 5.93752 5.64072 5.64065C5.75947 5.5219 5.9376 5.40315 6.1751 5.40315C6.4126 5.40315 6.53135 5.46252 6.70947 5.64065L9.5001 8.43127L12.2907 5.64065C12.4095 5.5219 12.5876 5.40315 12.8251 5.40315C13.0626 5.40315 13.1813 5.46252 13.3595 5.64065C13.6563 5.93752 13.6563 6.41252 13.3595 6.7094L10.5688 9.50002L13.3595 12.2906Z"
            fill="#8B8F9A"
          />
        </svg>
        <h2>Share Request URL</h2>
        <h3>
          Enter the email address of the user to whom you want to send the
          request link.
        </h3>
        <label htmlFor="email address">Email Address</label>
        <input
          className={`${badRed && "badRed"}`}
          onChange={(e) => setEmailForSharing(e.target.value)}
          value={emailForSharing}
          type="text"
        />
        <div className={`badRed-label ${badRed && "visible"}`}>
          Enter a valid email address
        </div>
        <Button
          onClick={() => onShareRequestUrl()}
          color={"purple"}
          text={"Share Request URL"}
        />
      </StyledShareRequestUrlModal>
    );
  }
);

export default ShareRequestUrlModal;

import React, { useState } from "react";
import styled from "styled-components";

import TemplatesDropboxItem from "./TemplatesDropboxItem";
import TemplatesCategory from "./TemplatesCategory";

const StyledTemplatesDropdown = styled.div`
  width: 100%;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 0 0 6px 6px;
  .templates-blank {
    text-align: left;
    width: 90%;
    margin-bottom: 10px;
    .templates-blank__header {
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      color: #505356;
    }
    .templates-blank__body {
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
      color: #505356;
    }
  }
`;

const TemplatesDropbox = ({
  templatesType,
  setShowPresets,
  templatesList,
  setTemplatesListReloadTrigger,
  substring,
  setShowTemplateDeleteModal,
  setTemplateDeleteId,
}) => {
  return (
    <StyledTemplatesDropdown>
      {/* ======= PREBUILT FORMS ======= */}
      {/* {templatesList &&
        templatesType === 'prebuilt' &&
        templatesList
          .find((obj) => obj.name === 'Prebuilt forms')
          .categories.map((category) => (
            <TemplatesCategory
              categoryName={category.name}
              templates={category.templates}
              setShowPresets={setShowPresets}
              setTemplatesListReloadTrigger={setTemplatesListReloadTrigger}
            />
          ))} */}

      {templatesList &&
        templatesType === "prebuilt" &&
        templatesList
          .find((obj) => obj.name === "Prebuilt forms")
          .categories.map((category, index) => {
            const templates = category.templates.filter((template) =>
              template.name.toLowerCase().includes(substring.toLowerCase())
            );
            return templates.length > 1 ? (
              <TemplatesCategory
                key={index}
                categoryName={category.name}
                templates={category.templates}
                setShowPresets={setShowPresets}
                setTemplatesListReloadTrigger={setTemplatesListReloadTrigger}
              />
            ) : (
              templates.length !== 0 && (
                <TemplatesDropboxItem
                  key={index}
                  templateName={category.templates[0].name}
                  form={category.templates[0].fields}
                  setShowPresets={setShowPresets}
                  big={true}
                  setTemplatesListReloadTrigger={setTemplatesListReloadTrigger}
                />
              )
            );
          })}

      {/* ======= CUSTOM FORMS ======= */}
      {templatesList &&
        templatesType === "custom" &&
        templatesList.find((obj) => obj.name === "Custom forms").templates
          .length !== 0 &&
        templatesList
          .find((obj) => obj.name === "Custom forms")
          .templates.map((template, index) => (
            <TemplatesDropboxItem
              key={index}
              templateName={template.name}
              form={template.fields}
              big={true}
              setShowPresets={setShowPresets}
              setTemplatesListReloadTrigger={setTemplatesListReloadTrigger}
              setShowTemplateDeleteModal={setShowTemplateDeleteModal}
              setTemplateDeleteId={setTemplateDeleteId}
            />
          ))}
      {templatesList &&
        templatesType === "custom" &&
        templatesList.find((obj) => obj.name === "Custom forms").templates
          .length === 0 && (
          <div className="templates-blank">
            <div className="templates-blank__header">No templates yet!</div>
            <div className="templates-blank__body">
              You can create your own fields and save them as a template or use
              our prebuilt fields.
            </div>
          </div>
        )}
    </StyledTemplatesDropdown>
  );
};

export default TemplatesDropbox;

import React, { useState, useRef } from "react";
import styled from "styled-components";

import TemplatesDropbox from "./Templates/TemplatesDropbox";

const StyledGeneratorContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 18px 0;
  border-bottom: 1px solid rgba(35, 35, 35, 0.08);

  .generator__reset-forms {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #8b8f9a;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      color: #555050;
    }
  }
  .generator__presets {
    display: flex;
    text-align: center;
    align-items: center;

    margin-right: 20px;

    position: relative;

    width: 100%;
    max-width: 171px;
    height: 28px;
    border: 1px solid #dddddd;
    border-radius: 4px;

    transition: 0.3s;
    svg {
      margin: 0 9.3px 0 12.5px;
    }

    &:hover {
      cursor: pointer;
      border: 1px solid rgba(85, 65, 215, 0.5);
    }
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.15px;
    color: #232323;
  }
  .generator__choose-template-button {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #505356;
  }
  .presets-dropbox {
    width: 100vw;
    max-width: 315px;
    padding-bottom: 10px;

    display: flex;
    flex-direction: column;

    border: 1px solid #d3d8e1;
    border-radius: 7px;
    background: #ffffff;
    box-shadow: 5px 5px 16px rgba(204, 215, 222, 0.05);

    position: absolute;
    z-index: 2;
    top: 35px;

    visibility: ${({ showPresets }) => (showPresets ? "visible" : "hidden")};
    opacity: ${({ showPresets }) => (showPresets ? 1 : 0)};

    transition: 0.1s;
  }
  .preset-dropbox__header {
    padding: 15px 0 13px 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #232323;

    display: flex;
    align-items: center;

    border-bottom: 1px solid #ededed;

    .preset-dropdown__list-name {
      position: relative;
      width: 50%;
      text-align: left;
      font-size: 16px;
      &.prebuilt-list-name::after {
        display: ${({ templatesType }) =>
          templatesType === "prebuilt" ? "auto" : "none"};
        content: "";
        height: 3px;
        width: 60%;
        background: #5541d7;
        position: absolute;
        top: 29px;
        left: 0;
      }
      &.custom-list-name::after {
        display: ${({ templatesType }) =>
          templatesType === "custom" ? "auto" : "none"};
        content: "";
        height: 3px;
        width: 60%;
        background: #5541d7;
        position: absolute;
        top: 29px;
        left: 0;
      }
    }
  }
  .preset-dropdown__filter {
    display: flex;
    align-items: center;
    position: relative;
    margin: 15px auto 8px;
    width: calc(100% - 30px);
    border: 1px solid #dddddd;
    border-radius: 4px;
    svg {
      position: absolute;
      cursor: default;
      pointer-events: none;
    }
    input {
      margin-left: 1px;
      padding-left: 39px;
      width: calc(100% - 43.5px);
      height: 28px;
      border: none;
    }
  }
  .generator__reset-forms {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.15px;
    color: #919191;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      color: #555050;
    }
  }
`;

const GeneratorContentHeader = ({
  templatesList,
  setTemplatesListReloadTrigger,
  templatesListReloadTrigger,
  setResetDefaultModalVisibility,
  setShowTemplateDeleteModal,
  setTemplateDeleteId,
}) => {
  const [substring, setSubstring] = useState("");

  const [showPresets, setShowPresets] = useState(false);
  const [templatesType, setTemplatesType] = useState("prebuilt");

  const templateDropboxRef = useRef(null);

  const handleClickOutsideTemplateDropbox = (e) => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (!path.includes(templateDropboxRef.current)) {
      setShowPresets(false);
    }
  };

  React.useEffect(() => {
    document.body.addEventListener("click", handleClickOutsideTemplateDropbox);
  }, []);

  return (
    <StyledGeneratorContentHeader
      templatesType={templatesType}
      showPresets={showPresets}
    >
      <div ref={templateDropboxRef} className="generator__presets">
        <div
          onClick={() => {
            setShowPresets((prev) => !prev);
          }}
          className="generator__choose-template-button"
        >
          {" "}
          <svg
            width="15"
            height="8"
            viewBox="0 0 15 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L7.5 7L14 1"
              stroke="#8B8F9A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span htmlFor="Choose saved template">Choose template</span>
        </div>

        <div className={`presets-dropbox`}>
          <div className="preset-dropbox__header">
            <div
              onClick={() => setTemplatesType("prebuilt")}
              className="preset-dropdown__list-name prebuilt-list-name"
            >
              Prebuilt fields
            </div>
            <div
              onClick={() => setTemplatesType("custom")}
              className="preset-dropdown__list-name custom-list-name"
            >
              Custom fields
            </div>
          </div>
          <div className="preset-dropdown__filter">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4909 9.19386L10.246 9.53831L10.5448 9.83716L15.3619 14.654C15.4516 14.7482 15.5011 14.8736 15.5 15.0036C15.4988 15.1349 15.4462 15.2605 15.3533 15.3533C15.2605 15.4462 15.1349 15.4988 15.0036 15.5C14.8735 15.5011 14.7481 15.4516 14.6539 15.3619L9.83783 10.5461L9.53898 10.2472L9.19454 10.4921C8.37102 11.0777 7.40219 11.4253 6.39423 11.4968C5.38626 11.5683 4.37806 11.361 3.4801 10.8976C2.58213 10.4342 1.82907 9.73255 1.30341 8.86958C0.77776 8.00662 0.499804 7.01562 0.5 6.00519V6.00503C0.49989 5.13875 0.704258 4.28467 1.09649 3.51226C1.48871 2.73985 2.05772 2.07091 2.75724 1.55986C3.45676 1.0488 4.26704 0.710064 5.12217 0.571189C5.9773 0.432315 6.85314 0.497227 7.67845 0.760647C8.50376 1.02407 9.25523 1.47855 9.87176 2.08715C10.4883 2.69574 10.9525 3.44124 11.2265 4.26304C11.5006 5.08483 11.5768 5.9597 11.449 6.8165C11.3211 7.6733 10.9929 8.48783 10.4909 9.19386ZM6.00461 1.50221C4.81033 1.50221 3.66496 1.97661 2.82047 2.82106C1.97597 3.66552 1.50154 4.81084 1.50154 6.00509C1.50154 7.19934 1.97597 8.34467 2.82047 9.18912C3.66496 10.0336 4.81033 10.508 6.00461 10.508C7.19889 10.508 8.34427 10.0336 9.18876 9.18912C10.0333 8.34467 10.5077 7.19934 10.5077 6.00509C10.5077 4.81084 10.0333 3.66552 9.18876 2.82106C8.34427 1.97661 7.19889 1.50221 6.00461 1.50221Z"
                fill="#505356"
                stroke="#505356"
              />
            </svg>

            <input
              type="text"
              value={substring}
              onChange={(e) => setSubstring(e.target.value)}
              placeholder="Search values"
            />
          </div>
          <TemplatesDropbox
            substring={substring}
            templatesType={templatesType}
            templatesList={templatesList}
            setShowPresets={setShowPresets}
            setTemplatesListReloadTrigger={setTemplatesListReloadTrigger}
            templatesListReloadTrigger={templatesListReloadTrigger}
            setShowTemplateDeleteModal={setShowTemplateDeleteModal}
            setTemplateDeleteId={setTemplateDeleteId}
          />
        </div>
      </div>
      <div
        onClick={() => {
          setResetDefaultModalVisibility(true);
        }}
        className="generator__reset-forms"
      >
        Reset to default
      </div>
    </StyledGeneratorContentHeader>
  );
};

export default GeneratorContentHeader;

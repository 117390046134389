import React, { useState } from "react";
import styled from "styled-components";

import { useSelector, useDispatch } from "react-redux";

import {
  setNotificationVisibilty,
  setHideForever,
  setNotificationOrder,
} from "../redux/actions/setNotification";

const StyledProgressBar = styled.div`
  max-height: 316px;
  max-width: 340px;
  margin: 150px auto;
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 100px 30px;
  .progress-bar {
    width: 340px;
    height: 5px;
    position: relative;
    background: #b9bbc0;
    border-radius: 100px;
  }
  .filler {
    background: #5541d7;
    height: 100%;
    border-radius: inherit;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .progress-text {
    margin-top: 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      font-weight: 600;
      font-size: 25px;
      line-height: 31px;
      text-align: center;
      color: #232323;
    }
    h3 {
      max-width: 340px;
      margin-top: 10px;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #505356;
    }
  }
`;

function ProgressBar() {
  const dispatch = useDispatch();
  const [currentProgress, setCurrentProgress] = useState(0);

  const notifications = useSelector(
    ({ notificationState }) => notificationState
  );

  const failedValidationNotificationText =
    "If you can't pass the verification, you\n can still send the keys.";

  React.useEffect(() => {
    const killNotificationByText = (text) => {
      if (
        notifications.find(
          (item) => item.params.text === text && item.hideForever === false
        )
      ) {
        const currentId = notifications.find(
          (item) => item.params.text === text && item.hideForever === false
        ).id;
        dispatch(setNotificationVisibilty(currentId, false));
        dispatch(setNotificationOrder(currentId, 0));
        setTimeout(() => {
          dispatch(setHideForever(currentId));
        }, 250);
      }
    };
    killNotificationByText(failedValidationNotificationText);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentProgress((oldProgress) => {
        const newProgress = oldProgress + 1;
        if (newProgress === 100) {
          clearInterval(interval);
        }
        return newProgress;
      });
    }, 17);
  }, []);

  return (
    <StyledProgressBar>
      <div className="progress-bar">
        <div className="filler" style={{ width: `${currentProgress}%` }}></div>
      </div>

      <div className="progress-text">
        <h2>Verification in process...</h2>
        <h3>Please do not close the screen until verification is complete</h3>
      </div>
    </StyledProgressBar>
  );
}

export default ProgressBar;

export const resetForm = () => ({
  type: 'RESET_FORM',
});

export const setFormItems = (obj) => ({
  type: 'SET_FORM_ITEMS',
  payload: obj,
});

export const deleteFormItems = (id, inner, outerId, order, innerOrder) => ({
  type: 'DELETE_FORM_ITEMS',
  id,
  inner,
  outerId,
  order,
  innerOrder,
});

export const setFieldGroupItems = (obj, fieldGroupId, innerId) => ({
  type: 'SET_FIELD_GROUP_ITEMS',
  fieldGroupId,
  payload: obj,
  innerId,
});

export const swapFieldOrder = (draggedCard, droppedOnCard, inner, outerId) => ({
  type: 'SWAP_FIELD_ORDER',
  draggedCard,
  droppedOnCard,
  inner,
  outerId,
});

export const setFieldTranslateY = (draggedOrder, draggedOverOrder, inner, outerId) => ({
  type: 'SET_FIELD_TRANSLATE_Y',
  draggedOrder,
  draggedOverOrder,
  inner,
  outerId,
});

export const setFieldTitle = (id, title, inner, outerId) => ({
  type: 'SET_FIELD_TITLE',
  id,
  payload: title,
  inner,
  outerId,
});

export const setFieldGroupTitle = (id, groupTitle) => ({
  type: 'SET_FIELD_GROUP_TITLE',
  id,
  payload: groupTitle,
});

export const setFieldValue = (id, inner, outerId, value) => ({
  type: 'SET_FIELD_VALUE',
  id,
  outerId,
  inner,
  payload: value,
});

export const setTemplate = (form, clientUniqueHandle, websiteUrl) => ({
  type: 'SET_TEMPLATE',
  form,
  clientUniqueHandle,
  websiteUrl,
});

export const setTemplateName = (templateName) => ({
  type: 'SET_TEMPLATE_NAME',
  templateName,
});

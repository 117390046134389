import React from "react";
import { TransitionGroup } from "react-transition-group";

import { KeysList } from "../components";

function Main() {
  return (
    <div>
      <TransitionGroup
        transitionName="keysListAnimation"
        transitionAppear={true}
        transitionAppearTimeout={200}
        transitionEnter={false}
        transitionLeave={false}
      >
        <KeysList />
      </TransitionGroup>
    </div>
  );
}

export default Main;

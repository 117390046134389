const initialState = {
  isVisible: false,
  item: [],
};

const deleteKeysListItemModal = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DELETE_MEMBER_MODAL_ON':
      return {
        ...state,
        isVisible: true,
        memberEmail: action.payload,
      };
    case 'SET_DELETE_MODAL_ON':
      return {
        ...state,
        isVisible: true,
        item: action.payload,
      };
    case 'SET_DELETE_MODAL_OFF':
      return {
        ...state,
        isVisible: false,
        // item: [],
      };
    default:
      return state;
  }
};

export default deleteKeysListItemModal;

import React from "react";
import styled from "styled-components";

const StyledTooltip = styled.div`
  position: absolute;
  top: 0;
  left: 35px;
  &.generateRequestTooltip {
    top: -11px;
    left: 210px;
  }
  &.generateRequestLinkCopy {
    top: -12.5px;
    max-height: 146px;
  }
  &.generateRequestShareByEmailTooltip {
    top: -25px;
  }
  z-index: 10;

  visibility: ${({ tooltipVisibility, specialClass }) =>
    tooltipVisibility === specialClass ? "visible" : "hidden"};
  opacity: ${({ tooltipVisibility, specialClass }) =>
    tooltipVisibility === specialClass ? "1" : "0"};

  padding: 15px 15px 0 15px;
  width: 100vw;
  max-width: 258px;

  text-align: left;
  background: #7b7b7b;
  border-radius: 5px;
  transition: 0.4s;
  .tooltip-triangle {
    position: absolute;
    top: calc(50% - 8px);
    left: -9px;
  }
  .tooltip__content-block {
    margin-bottom: 15px;
    h5 {
      margin-bottom: 5px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
    }
    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: 0.005em;
      color: #ffffff;
      opacity: 0.8;
    }
  }
`;

const Tooltip = ({ tooltipVisibility, specialClass, contentBlocks }) => {
  console.log(contentBlocks);
  return (
    <StyledTooltip
      tooltipVisibility={tooltipVisibility}
      specialClass={specialClass}
      className={specialClass}
    >
      {!specialClass.includes("Preset") && (
        <svg
          className={"tooltip-triangle"}
          width="9"
          height="18"
          viewBox="0 0 9 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.6"
            d="M0 9L9 0.339745L9 17.6603L0 9Z"
            fill="#232323"
          />
        </svg>
      )}
      {contentBlocks.map((block, index) => (
        <div key={index} className="tooltip__content-block">
          {block.title !== "" && <h5>{block.title}</h5>}
          <p>{block.text}</p>
        </div>
      ))}
    </StyledTooltip>
  );
};

export default Tooltip;

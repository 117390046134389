import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { setSecureVaultOff } from "../../../../redux/actions/setSecureVault";

import Button from "../../../Button";
import KeysListModalItem from "./KeysListModalItem";
import KeysListModalItemGroup from "./KeysListModalItemGroup";
import {
  setDeleteModalOff,
  setDeleteModalOn,
} from "../../../../redux/actions/setDeleteKeysListItemModal";

const StyledSecureVault = styled.div`
  position: absolute;
  left: 50%;
  margin-left: -297.5px;
  top: -3%;
  z-index: 10;
  width: 100%;
  max-width: 595px;
  .keys-list--modal {
    position: relative;
    background: #ffffff;
    border-radius: 20px;
    padding: 26px 0px 15px;
    width: 100vw;
    max-width: 595px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 100;
    .keys-list__slider-item {
      padding: 0 20px;
      width: 100vw;
      min-width: 555px;
      max-width: 555px;
      max-height: 485px;
      overflow-y: auto;
      h4 {
        margin: 10px 0;
        font-weight: 600;
        font-size: 19px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.005em;
        color: #232323;
      }
    }
    label {
      font-weight: bold;
      font-size: 10px;
      line-height: 16px;
      text-transform: uppercase;
      color: #5e5e5e;
    }
    .input-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 343px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #dddddd;
      border-radius: 8px;
      input {
        border: none;
        width: 75%;
        margin-left: 5%;
      }
      svg {
        cursor: pointer;
      }
    }
  }
  .secure-vault__header {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #e4e4e4;
    h2 {
      font-weight: bold;
      font-size: 24px;
      line-height: 120%;
      letter-spacing: 0.005em;
      color: #232323;
      margin-bottom: 8px;
    }
    h3 {
      font-weight: normal;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: 0.005em;
      color: #5e5e5e;
      margin-bottom: 15px;
    }
  }
  .keys-list__older-keys {
    margin-top: 15px;
    border: 1px solid #dddddd;
    border-radius: 8px;
    padding: 10px;

    position: relative;

    font-size: 16px;
    line-height: 20px;
    color: #505356;
    cursor: pointer;

    svg {
      margin-left: 24px;
    }
  }
  .keys-list__older-keys-dropdown {
    position: absolute;
    background: #fff;
    top: 35px;
    left: -1px;
    border: 1px solid #dddddd;
    width: 100%;
    border-radius: 0 0 8px 8px;
    .older-keys-dropdown__label {
      padding: 5px 0 0 10px;
      font-size: 14px;
      line-height: 18px;
      color: #8b8f9a;
    }
    .keys-list__older-key {
      padding: 8px 0 8px 10px;
      font-size: 16px;
      line-height: 20px;
      color: #505356;
      transition: 0.2s;
      &:hover {
        color: #8d89ad;
      }
      &.purple-text {
        font-size: 16px;
        line-height: 20px;
        color: #5541d7;
      }
      svg {
        margin-left: 11px;
      }
    }
  }
  .keys-list__slider-wrapper {
    padding-top: 10px;
    display: flex;
    max-width: 595px;
    overflow: auto;
  }
  @media (min-width: 920px) {
    .keys-list--modal {
      .wrapper {
        max-height: 430px;
      }
    }
  }
`;

const SecureVault = ({ setUseEffectTrigger }) => {
  const dispatch = useDispatch();
  const localState = useSelector(
    ({ secureVaultState }) => secureVaultState.localState
  );

  const [showOlderKeys, setShowOlderKeys] = React.useState(false);
  const [showCurrentForm, setShowCurrentForm] = React.useState(
    localState.length - 1
  );

  function getHandsomeDate(stateDate) {
    const temporaryDate = new Date(
      Date.parse(stateDate.replace(/-/g, "/"))
    ).toString();
    return `${temporaryDate.slice(7, 10)} ${temporaryDate.slice(
      3,
      7
    )}, ${temporaryDate.slice(15, 21)}`;
  }

  const handleEscape = (e) => {
    if (e.key === "Escape") {
      dispatch(setSecureVaultOff());
    }
  };

  // CLOSE AFTER 60 SECONDS
  let fadeTimeout;
  function setFadeTimeout() {
    fadeTimeout = setTimeout(() => {
      dispatch(setSecureVaultOff());
    }, 60000);
  }
  function clearFadeTimeout() {
    clearTimeout(fadeTimeout);
  }

  React.useEffect(() => {
    document.addEventListener("keydown", (e) => handleEscape(e));
    setFadeTimeout();
    return function cleanup() {
      document.removeEventListener("keydown", (e) => handleEscape(e));
      clearFadeTimeout();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledSecureVault>
      <div className="keys-list--modal">
        <div className="secure-vault__header">
          <h2>You opened Secure Vault</h2>
          <h3>It will be closed in 1 minute</h3>
        </div>
        {localState.length > 1 && (
          <div
            onClick={() => setShowOlderKeys((prev) => !prev)}
            className="keys-list__older-keys"
          >
            Browse older keys{" "}
            <svg
              width="17"
              height="10"
              viewBox="0 0 17 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.702107 0.720844C0.315715 1.11073 0.311585 1.74347 0.698116 2.13322L7.78997 9.28406C8.18115 9.6785 8.81885 9.6785 9.21003 9.28406L16.3019 2.13322C16.6884 1.74347 16.6843 1.11073 16.2979 0.720844C15.9067 0.326151 15.2645 0.321707 14.8732 0.71629L8.5 7.14343L2.12678 0.71629C1.73551 0.321706 1.09327 0.326151 0.702107 0.720844Z"
                fill="#8B8F9A"
              />
            </svg>
            {showOlderKeys && (
              <div className="keys-list__older-keys-dropdown">
                <div className="older-keys-dropdown__label">Sent on the</div>
                {localState.map((item, index) => {
                  return (
                    <div
                      onClick={() => setShowCurrentForm(index)}
                      className={`keys-list__older-key ${
                        index === showCurrentForm && "purple-text"
                      }`}
                    >
                      {getHandsomeDate(
                        item.find((prop) => prop.type === "expirationDate")
                          .value
                      )}
                      {index === showCurrentForm && (
                        <svg
                          width="15"
                          height="11"
                          viewBox="0 0 15 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.00034 11L0 6.18738L1.42838 4.81262L5.00185 8.24806L5.00034 8.24951L13.5716 0L15 1.37476L6.42872 9.62524L5.00135 10.999L5.00034 11Z"
                            fill="#5541D7"
                          />
                        </svg>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}

        <div className="keys-list__slider-wrapper">
          {localState.map((array, index) => {
            if (index === showCurrentForm) {
              return (
                <div className="keys-list__slider-item">
                  {/*===== CUSTOM FORMS =====*/}
                  {!array.find((prop) => prop.type === "premadeKfwTemplate") &&
                    array.map((item) => {
                      switch (item.type) {
                        case "field":
                          return (
                            <KeysListModalItem key={item.id} item={item} />
                          );
                        case "fieldgroup":
                          return (
                            <KeysListModalItemGroup key={item.id} item={item} />
                          );
                        default:
                          return null;
                      }
                    })}

                  {/*===== PRESET WP + FTP =====*/}
                  {array.find(
                    (prop) =>
                      prop.type === "premadeKfwTemplate" && prop.id === 0
                  ) && (
                    <div className={"wrapper"}>
                      <div className="keys-list--modal__item-group">
                        <h4>Wp-admin keys</h4>
                        {array.map((item, index) => {
                          while (
                            index > 5 &&
                            index < 9 &&
                            item.type === "field"
                          ) {
                            return (
                              <KeysListModalItem key={item.id} item={item} />
                            );
                          }
                          return null;
                        })}
                      </div>
                      <div className="keys-list--modal__item-group">
                        <h4>FTP keys</h4>
                        {array.map((item, index) => {
                          while (index >= 9 && item.type === "field") {
                            return (
                              <KeysListModalItem key={item.id} item={item} />
                            );
                          }
                          return null;
                        })}
                      </div>
                    </div>
                  )}

                  {/*===== PRESET WP =====*/}
                  {array.find(
                    (prop) =>
                      prop.type === "premadeKfwTemplate" && prop.id === 1
                  ) && (
                    <div className={"wrapper"}>
                      <div className="keys-list--modal__item-group">
                        <h4>Wp-admin keys</h4>
                        {array.map((item, index) => {
                          while (
                            index > 5 &&
                            index < 9 &&
                            item.type === "field"
                          ) {
                            return (
                              <KeysListModalItem key={item.id} item={item} />
                            );
                          }
                          return null;
                        })}
                      </div>
                    </div>
                  )}

                  {/*===== PRESET FTP =====*/}
                  {array.find(
                    (prop) =>
                      prop.type === "premadeKfwTemplate" && prop.id === 2
                  ) && (
                    <div className={"wrapper"}>
                      <div className="keys-list--modal__item-group">
                        <h4>FTP keys</h4>
                        {array.map((item, index) => {
                          while (index > 5 && item.type === "field") {
                            return (
                              <KeysListModalItem key={item.id} item={item} />
                            );
                          }
                          return null;
                        })}
                      </div>
                    </div>
                  )}
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>

        {localState.find(
          (prop) => prop.type === "premadeKfwTemplate" && prop.id === 2
        ) && (
          <div className="wrapper">
            <h4>FTP keys</h4>
            {localState.map((item, index) => {
              while (index > 5 && item.type === "field") {
                return <KeysListModalItem key={item.id} item={item} />;
              }
              return null;
            })}
          </div>
        )}
        <div className="keys-list--modal__buttons">
          <Button
            onClick={(e) => {
              e.stopPropagation();
              clearTimeout(fadeTimeout);
              dispatch(setSecureVaultOff());
              dispatch(setDeleteModalOn(localState[localState.length - 1]));
            }}
            text={"Delete"}
            color={"white"}
          />
          <Button
            onClick={() => {
              clearTimeout(fadeTimeout);
              dispatch(setSecureVaultOff());
            }}
            text={"Close"}
            color={"purple"}
          />
        </div>
      </div>

      <div className="bg-layer" />
    </StyledSecureVault>
  );
};

export default SecureVault;

import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import useNotification from "../../hooks/useNotification";

import Button from "../Button";

const StyledEmailSend = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    font-weight: 500;
    font-size: 25px;
    line-height: 31px;
    text-align: center;
    color: #232323;
    margin-bottom: 7px;
    white-space: nowrap;
  }
  h3 {
    max-width: 345px;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #505356;
    span {
      font-weight: 600;
      color: #5541d7;
      cursor: pointer;
    }
    margin-bottom: 30px;
  }
  .button.purple {
    min-width: 200px;
  }
`;

function EmailSent({ handleLogin }) {
  const showNotification = useNotification();
  const emailForReset = useSelector(
    ({ loginPageComponent }) => loginPageComponent.email
  );

  const handleSendInstructions = () => {
    if (emailForReset !== "") {
      fetch(
        "https://engine.keysforweb.com/wp-json/bda/v1/reset-password-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: emailForReset,
          }),
        }
      )
        .then((resp) => resp.json())
        .then((data) => {
          console.log(data);
          showNotification("success", "Resent", "Email has been resent", 2500);
        });
    }
  };

  return (
    <StyledEmailSend className="login__actions">
      <h2>Check your email</h2>
      <h3>
        We have sent you the password reset instructions. Didn't receive an
        Email? <span onClick={() => handleSendInstructions()}>Resend</span>
      </h3>

      <Button onClick={handleLogin} color={"purple"} text={"Back to Login"} />
    </StyledEmailSend>
  );
}

export default EmailSent;

import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { setTemplate } from "../../../redux/actions/setFormItems";

const StyledTemplatesDropdownItem = styled.div`
  display: flex;
  align-items: center;
  width: 92.5%;
  justify-content: space-between;
  font-weight: normal;
  font-size: ${({ big }) => (big ? "16px" : "14px")};
  line-height: ${({ big }) => (big ? "20px" : "18px")};
  letter-spacing: 0.25px;
  /* color: #232323; */
  margin: 10px 0;

  color: ${({ activeTemplate, currentTemplateName }) =>
    activeTemplate === currentTemplateName ? "#5541D7" : "#505356"};

  cursor: pointer;
  transition: 0.2s;

  svg {
    path {
      transition: 0.2s;
    }
  }
  svg:hover {
    path {
      fill: #ff6562;
    }
  }
  &.blank {
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #5e5e5e;
    padding-right: 0;
  }
  &:hover {
    color: rgba(89, 44, 102, 0.8);
  }
`;

const TemplatesDropboxItem = ({
  templateName,
  form,
  big,
  setShowPresets,
  setTemplatesListReloadTrigger,
  setShowTemplateDeleteModal,
  setTemplateDeleteId,
}) => {
  const clientUniqueHandle = useSelector(
    ({ formGenerator }) =>
      formGenerator.find((prop) => prop.type === "clientUniqueHandle").value
  );
  const websiteUrl = useSelector(
    ({ formGenerator }) =>
      formGenerator.find((prop) => prop.type === "websiteUrl").value
  );
  const currentTemplateName = useSelector(
    ({ formGenerator }) =>
      formGenerator.find((prop) => prop.type === "templateName").value
  );

  const [activeTemplate, setActiveTemplate] = useState(null);

  const dispatch = useDispatch();

  const showTemplate = (e) => {
    e.stopPropagation();

    setActiveTemplate(templateName);
    dispatch(setTemplate(form, clientUniqueHandle, websiteUrl));

    setShowPresets(false);
  };

  const onDeleteIcon = (e, form) => {
    e.stopPropagation();
    const templateId = form.find((property) => property.type === "formId").id;
    setTemplateDeleteId(templateId);
    setShowTemplateDeleteModal(true);
  };

  return (
    <StyledTemplatesDropdownItem
      big={big}
      activeTemplate={activeTemplate}
      currentTemplateName={currentTemplateName}
      onClick={(e) => showTemplate(e)}
    >
      {templateName}
      {!form.find((prop) => prop.type === "premadeKfwTemplate") && (
        <svg
          onClick={(e) => {
            setShowPresets(false);
            onDeleteIcon(e, form);
          }}
          width="16"
          height="18"
          viewBox="0 0 16 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.1667 17.3332H3.83333C2.91286 17.3332 2.16667 16.587 2.16667 15.6665V4.83317H0.5V3.1665H3.83333V2.33317C3.83333 1.4127 4.57953 0.666504 5.5 0.666504H10.5C11.4205 0.666504 12.1667 1.4127 12.1667 2.33317V3.1665H15.5V4.83317H13.8333V15.6665C13.8333 16.587 13.0871 17.3332 12.1667 17.3332ZM3.83333 4.83317V15.6665H12.1667V4.83317H3.83333ZM5.5 2.33317V3.1665H10.5V2.33317H5.5Z"
            fill="#8B8F9A"
          />
        </svg>
      )}
    </StyledTemplatesDropdownItem>
  );
};

export default TemplatesDropboxItem;

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useNotification from "../hooks/useNotification";

import {
  setRequestLinkState,
  setRequestLinkForm,
} from "../redux/actions/setRequestLinkState";

import {
  NeedHelpModal,
  Loader,
  ProgressBar,
  ResultMark,
  RequestLinkMainArea,
  RequestLinkSentPlaceholder,
} from "../components";

import { requestLinkNeedHelpItems } from "../components/NeedHelpModal/needHelpItemsContent";

function RequestLink({ needHelpVisibility, setNeedHelpVisibility, websocket }) {
  const dispatch = useDispatch();
  const showNotification = useNotification();

  const notifications = useSelector(
    ({ notificationState }) => notificationState
  );
  const currentForm = useSelector(
    ({ requestLinkState }) => requestLinkState.formInJSON
  );

  const [ftpPortTrigger, setFtpPortTrigger] = useState(0);

  const [customFormAttemptsLeft, setCustomFormAttemptsLeft] = useState(null);

  const [formIsLoading, setFormIsLoading] = useState(false);
  const [validationResult, setValidationResult] = useState(null);
  const [isValidating, setIsValidating] = useState(false);
  const [allowRenderMainArea, setAllowRenderMainArea] = useState(false);

  const [showRequestLinkSentPlaceholder, setShowRequestLinkSentPlaceholder] =
    useState(false);
  const [placeholderPositiveResponse, setPlaceholderPositiveResponse] =
    useState(null);

  const [badRedTrigger, setBadRedTrigger] = useState(0);

  const location = useLocation();
  const requestId = location.pathname.slice(3, location.pathname.length);

  function submitWithoutVerification(form) {
    let noValidationForm = JSON.parse(JSON.stringify(form));
    noValidationForm.forEach((item) => {
      if (item.type === "premadeKfwTemplate") {
        item.doValidation = false;
      }
    });
    submitForm(noValidationForm);
  }

  const submitForm = (form) => {
    let formIsFilled = true;

    form.forEach((item) => {
      switch (item.type) {
        case "field":
          if (item.value === "") {
            formIsFilled = false;
            return formIsFilled;
          }
          return formIsFilled;
        case "fieldgroup":
          item.innerFields.forEach((innerItem) => {
            if (innerItem.value === "") {
              formIsFilled = false;
              return formIsFilled;
            }
          });
          return formIsFilled;
        default:
          return;
      }
    });

    if (!formIsFilled) {
      setBadRedTrigger((prev) => prev + 1);
      showNotification(
        "error",
        "Please, complete a request",
        "Some inputs are uncompleted.",
        2000
      );
    } else {
      if (
        form.find((item) => item.type === "premadeKfwTemplate") &&
        form.find((item) => item.type === "premadeKfwTemplate").doValidation
      ) {
        setAllowRenderMainArea(false);
        setIsValidating(true);
      }

      let currentFormClone = JSON.parse(JSON.stringify(form));

      currentFormClone.find((item) => item.type === "isCompleted").value = true;

      fetch(
        `https://engine.keysforweb.com/wp-json/bda/v1/key-requests/${requestId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            formInJSON: currentFormClone,
          }),
        }
      )
        .then((resp) => resp.json())
        .then((data) => {
          console.log(data, "request link keys sent");
          setIsValidating(false);
          if (data.status === "success") {
            if (!form.find((item) => item.type === "premadeKfwTemplate")) {
              setPlaceholderPositiveResponse(true);
            }
            if (
              form.find((item) => item.type === "premadeKfwTemplate") &&
              form.find((item) => item.type === "premadeKfwTemplate")
                .doValidation
            ) {
              setValidationResult(true);
            }
            websocket.send(
              JSON.stringify({
                action: "updateKeyRequest",
                id: currentFormClone.find((item) => item.type === "formId").id,
              })
            );
            showNotification(
              "success",
              "Great!",
              "Your information has been successfully delivered",
              3500
            );
          } else if (data.code === "validation_error") {
            if (
              !localStorage.getItem(`failedValidations_${currentForm[0].id}`)
            ) {
              localStorage.setItem(
                `failedValidations_${currentForm[0].id}`,
                JSON.stringify({
                  id: currentForm[0].id,
                  counter: 1,
                })
              );
              if (
                form.find((item) => item.type === "premadeKfwTemplate")
                  .doValidation
              ) {
                setValidationResult(false);
              }
            } else {
              let counter = JSON.parse(
                localStorage.getItem(`failedValidations_${currentForm[0].id}`)
              ).counter;
              counter++;
              localStorage.setItem(
                `failedValidations_${currentForm[0].id}`,
                JSON.stringify({
                  id: currentForm[0].id,
                  counter,
                })
              );
              if (
                form.find((item) => item.type === "premadeKfwTemplate")
                  .doValidation
              ) {
                setValidationResult(false);
              }
            }
            showNotification(
              "error",
              data.message,
              "We couldn't send you keys to developer",
              2000
            );
          } else if (data.code === "form_cannot_be_saved") {
            if (!form.find((item) => item.type === "premadeKfwTemplate")) {
              setPlaceholderPositiveResponse(false);
            }
          }
        })
        .finally(() => {
          setAllowRenderMainArea(true);
          if (!form.find((item) => item.type === "premadeKfwTemplate")) {
            setShowRequestLinkSentPlaceholder(true);
          }
        });
    }
  };

  const handleChangeFieldValue = (value, inner, id, outerId, item) => {
    let currentFormClone = JSON.parse(JSON.stringify(currentForm));

    currentFormClone.forEach((item) => {
      if (!inner && item.type === "field" && item.id === id) {
        item.value = value;
        dispatch(setRequestLinkForm(currentFormClone));
      } else if (item.type === "fieldgroup" && item.id === outerId) {
        item.innerFields.forEach((item) => {
          if (item.innerId === id) {
            item.value = value;
            dispatch(setRequestLinkForm(currentFormClone));
          }
        });
      }
    });

    // CHECK IF CONNECTION TYPE CHANGED TO SET EXPECTED PORTS
    if (item.serviceTag === "ftpConnectionType") {
      setFtpPortTrigger((prev) => prev + 1);
    }
  };

  React.useEffect(() => {
    if (window.location.hostname !== "staging.app.keysforweb.com") {
      if (window.location.hostname !== "kfw.pw") {
        window.location = `https://kfw.pw/r/${requestId}`;
      }
    }

    if (!requestId) {
      window.location = `https://kfw.pw/pagenotfound`;
    }

    setFormIsLoading(true);
    fetch(
      `https://engine.keysforweb.com/wp-json/bda/v1/key-requests/${requestId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data, "requestlinkdata");
        setCustomFormAttemptsLeft(data.attemptsLeft);
        if (
          data.code === "invalid_form" ||
          data.code === "rest_forbidden" ||
          data.code === "form_is_not_available"
        ) {
          window.location = `https://kfw.pw/pagenotfound`;
        } else {
          dispatch(setRequestLinkState(data));
        }
      })
      .finally(() => {
        setFormIsLoading(false);
        setAllowRenderMainArea(true);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="requestlink">
      {!isValidating && validationResult === null && (
        <div className="requestlink__isnotloading">
          {formIsLoading && <Loader />}

          {!formIsLoading &&
            !showRequestLinkSentPlaceholder &&
            currentForm.length > 0 &&
            validationResult === null &&
            allowRenderMainArea &&
            !isValidating && (
              <RequestLinkMainArea
                notifications={notifications}
                badRedTrigger={badRedTrigger}
                handleChangeFieldValue={handleChangeFieldValue}
                submitForm={submitForm}
                submitWithoutVerification={submitWithoutVerification}
                ftpPortTrigger={ftpPortTrigger}
              />
            )}
          {showRequestLinkSentPlaceholder && (
            <RequestLinkSentPlaceholder
              customFormAttemptsLeft={
                customFormAttemptsLeft && customFormAttemptsLeft
              }
              setCustomFormAttemptsLeft={setCustomFormAttemptsLeft}
              setShowRequestLinkSentPlaceholder={
                setShowRequestLinkSentPlaceholder
              }
              placeholderPositiveResponse={placeholderPositiveResponse}
            />
          )}
        </div>
      )}
      {isValidating && validationResult === null && <ProgressBar />}
      {validationResult === true && (
        <ResultMark
          type={"completed"}
          title={"Successfully verified!"}
          text={
            "You’ve successfully verified all keys. The results were sent automatically."
          }
          setValidationResult={setValidationResult}
        />
      )}
      {validationResult === false && (
        <ResultMark
          type={"failure"}
          title={"Verification failed"}
          text={
            "An error occurred while checking keys. Please check all the information and try again."
          }
          setValidationResult={setValidationResult}
        />
      )}
      {needHelpVisibility && (
        <NeedHelpModal
          setNeedHelpVisibility={setNeedHelpVisibility}
          left={true}
          needHelpItems={requestLinkNeedHelpItems}
        />
      )}
    </div>
  );
}

export default RequestLink;

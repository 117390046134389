export const setDeleteMemberModalOn = (payload) => ({
  type: 'SET_DELETE_MEMBER_MODAL_ON',
  payload,
});

export const setDeleteModalOn = (payload) => ({
  type: 'SET_DELETE_MODAL_ON',
  payload,
});

export const setDeleteModalOff = () => ({
  type: 'SET_DELETE_MODAL_OFF',
});

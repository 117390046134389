import React from "react";
import styled from "styled-components";
import Button from "../Button";
import { useDispatch } from "react-redux";

import { resetForm } from "../../redux/actions/setFormItems";

const StyledResetDefaultModal = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  margin-left: -213px;
  transition: 0.2s;
  visibility: ${({ resetDefaultModalVisibility }) =>
    resetDefaultModalVisibility ? "visible" : "hidden"};
  opacity: ${({ resetDefaultModalVisibility }) =>
    resetDefaultModalVisibility ? "1" : "0"};
  .reset-default-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    position: relative;
    background: #ffffff;
    border-radius: 10px;
    z-index: 1;

    width: 100vw;
    max-width: 407px;

    .reset-default-modal__cool-icon {
      padding: 16px;
      margin-bottom: 16px;
      background: #f0eeff;
      border-radius: 8px;
    }

    h3 {
      margin-bottom: 7px;
      font-weight: 500;
      font-size: 25px;
      line-height: 31px;
      text-align: center;
      color: #232323;
    }
    h4 {
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #505356;
      margin-bottom: 30px;
    }
    .reset-default-modal__buttons {
      display: flex;
      .button {
        &.white {
          margin-right: 23px;
        }
        width: 138px;
        max-height: 40px;
      }
    }
  }
  .bg-layer {
    background: #232323;
    opacity: 0.2;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

const ResetDefaultModal = ({
  resetDefaultModalVisibility,
  setResetDefaultModalVisibility,
}) => {
  const dispatch = useDispatch();

  return (
    <StyledResetDefaultModal
      resetDefaultModalVisibility={resetDefaultModalVisibility}
    >
      <div className="reset-default-modal">
        <div className="reset-default-modal__cool-icon">
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.666016 27.3334V15.6666H12.3327L6.97101 21.0333C8.82095 22.925 11.3535 23.994 13.9993 24C18.2318 23.9937 22.0019 21.3237 23.4127 17.3333H23.4427C23.6336 16.791 23.778 16.2334 23.8743 15.6666H27.2277C26.3878 22.3331 20.7186 27.3331 13.9993 27.3334H13.9827C10.4473 27.3439 7.05462 25.9395 4.56102 23.4333L0.666016 27.3334ZM4.12268 12.3333H0.769349C1.60893 5.66932 7.27435 0.670212 13.991 0.666572H13.9993C17.5354 0.655258 20.9289 2.05971 23.4227 4.56664L27.3327 0.666572V12.3333H15.666L21.036 6.96664C19.1842 5.07272 16.6482 4.00354 13.9993 3.99997C9.76694 4.00622 5.99682 6.67628 4.58602 10.6666H4.55602C4.36361 11.2085 4.21917 11.7662 4.12435 12.3333H4.12268Z"
              fill="#5541D7"
            />
          </svg>
        </div>
        <h3>Are you sure you want to reset fields to default?</h3>
        <h4>This action cannot be undone</h4>
        <div className="reset-default-modal__buttons">
          <Button
            color={"white"}
            text={"Cancel"}
            onClick={() => setResetDefaultModalVisibility(false)}
          />
          <Button
            color={"purple"}
            text={"Reset"}
            onClick={() => {
              dispatch(resetForm());
              setResetDefaultModalVisibility(false);
            }}
          />
        </div>
      </div>
      <div className="bg-layer" />
    </StyledResetDefaultModal>
  );
};

export default ResetDefaultModal;
